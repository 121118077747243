import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import { useState, useEffect } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { studentRecordsStoreAPI } from "../../Service";
import Cookies from "js-cookie";
import AlertComponent from "../../layouts/Alert";

function Records() {
  const list = [
    { name: "Home", url: "/" },
    { name: "Records", url: null },
  ];
  let [searchParams, setSearchParams] = useSearchParams();
  let type = searchParams.get("type");
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);

  const [eightthDiv, SeteightthDivState] = useState('none');
  const [ninthDiv, SetninthDivState] = useState('none');
  const [tenthDiv, SettenthDivState] = useState('none');
  const [ElenthDiv, SetElenthDivState] = useState('none');
  const [TwelvethDiv, SetTwelvethDivState] = useState('none');

  const existing_types = [
    "working-professionals",
    "college-graduates",
    "10th-12th",
    "8th-9th",
  ];
  const navigate = useNavigate();
  let USER_TOKEN = Cookies.get("usr_token");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (existing_types.includes(type) === false) {
      navigate("/");
    }
    if (USER_TOKEN === undefined) {
      navigate("/?is_login=true&is_message=Please Login to Continue");
    } else {
    }
  }, []);

  const initialFormData = Object.freeze({
    type: type,
    name: null,
    age: null,
    father_name: null,
    mother_name: null,
    parents_email: null,
    parents_phone: null,
    class: null,
    "8th9thsub1": null,
    "8th9thsub2": null,
    "8th9thsub3": null,
    "8th9thsub4": null,
    "8th9thsub5": null,
    "8th9thsub6": null,
    "8th9thsub7": null,
    "8th9thsub8": null,
    "8th9thsub9": null,
    "8th9thsub10": null,
    "8thscores1": null,
    "8thscores2": null,
    "8thscores3": null,
    "8thscores4": null,
    "8thscores5": null,
    "8thscores6": null,
    "8thscores7": null,
    "8thscores8": null,
    "8thscores9": null,
    "8thscores10": null,
    "9thscores1": null,
    "9thscores2": null,
    "9thscores3": null,
    "9thscores4": null,
    "9thscores5": null,
    "9thscores6": null,
    "9thscores7": null,
    "9thscores8": null,
    "9thscores9": null,
    "9thscores10": null,
    "10thsub1": null,
    "10thsub2": null,
    "10thsub3": null,
    "10thsub4": null,
    "10thsub5": null,
    "10thsub6": null,
    "10thsub7": null,
    "10thsub8": null,
    "10thsub9": null,
    "10thsub10": null,
    "10thscores1": null,
    "10thscores2": null,
    "10thscores3": null,
    "10thscores4": null,
    "10thscores5": null,
    "10thscores6": null,
    "10thscores7": null,
    "10thscores8": null,
    "10thscores9": null,
    "10thscores10": null,
    "11th12thsub1": null,
    "11th12thsub2": null,
    "11th12thsub3": null,
    "11th12thsub4": null,
    "11th12thsub5": null,
    "11th12thsub6": null,
    "11th12thsub7": null,
    "11th12thsub8": null,
    "11th12thsub9": null,
    "11th12thsub10": null,
    "11thscores1": null,
    "11thscores2": null,
    "11thscores3": null,
    "11thscores4": null,
    "11thscores5": null,
    "11thscores6": null,
    "11thscores7": null,
    "11thscores8": null,
    "11thscores9": null,
    "11thscores10": null,
    "12thscores1": null,
    "12thscores2": null,
    "12thscores3": null,
    "12thscores4": null,
    "12thscores5": null,
    "12thscores6": null,
    "12thscores7": null,
    "12thscores8": null,
    "12thscores9": null,
    "12thscores10": null,
    hobbies: null,
    strength: null,
    weakness: null,
    role_model: null,
    activities: null,
    father_works_at: null,
    father_works_as: null,
    mother_works_at: null,
    mother_works_as: null,
    father_annual_income: null,
    mother_annual_income: null,
    aspire: null,
    parents_opinion_about_your_career: null,
    description: null,
    college: null,
    city: null,
    course: null,
    year_of_completion: null,
    semester: null,
    cgpa: null,
    current_work_place: null,
    designation: null,
    no_of_years_worked: null,
    education_qualification: null,
    nature_of_work: null,
    work_status: null,
  });

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    if(e.target.name == 'class'){
      var classValue = e.target.value.trim();
      if(classValue == '8th'){
        SeteightthDivState('flex');
        SetninthDivState('none');
      }else if(classValue == '9th'){
        SeteightthDivState('flex');
        SetninthDivState('flex');
      }else if(classValue == '10th'){
        SettenthDivState('flex');
        SetElenthDivState('none');
        SetTwelvethDivState('none');
      }else if(classValue == '11th'){
        SettenthDivState('flex');
        SetElenthDivState('flex');
        SetTwelvethDivState('none');
      }else if(classValue == '12th'){
        SettenthDivState('flex');
        SetElenthDivState('flex');
        SetTwelvethDivState('flex');
      }
    }
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = await studentRecordsStoreAPI(USER_TOKEN, formData);
    if (res?.is_success) {
      navigate(`/counselling/guidelines`);
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      SetAlertDisplay(true)
      SetAlertMessage(res?.errors)
    }
  };

  return (
    <div>
      <HeaderComponent />
      <BreadCumb name={"Records"} list={list} />
      <section className="wrapper bg-light student-detail-form">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="box-shadow"
                  style={{ backgroundColor: "#e2eafa" }}
                >
                  <div className="py-3 text-center record-header">
                    <p className="mb-0">
                      We want to understand you better!
                    </p>
                    <p className="mb-0">
                      (Kindly fill this record before your counselling session)
                    </p>
                  </div>
                  <div className="p-5">
                    <AlertComponent
                      AlertDisplay={AlertDisplay}
                      SetAlertDisplay={SetAlertDisplay}
                      AlertMessage={AlertMessage}
                    />
                  </div>


                  <div className="p-3">
                    <div className="form-group row">
                      <label
                        htmlFor="inputstudentname"
                        className="col-sm-2 mt-1 col-form-label text-right"
                      >
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="col-sm-5 mt-1">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          onChange={handleChange}
                          id="inputstudentname"
                        />
                      </div>
                      <label
                        htmlFor="inputstudentage"
                        className="col-sm-1 mt-1 col-form-label float-right"
                      >
                        Age <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="col-sm-3 mt-1">
                        <input
                          type="number"
                          min="5"
                          name="age"
                          className="form-control"
                          onChange={handleChange}
                          id="inputstudentage"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="inputfathername"
                        className="col-sm-2 mt-1 col-form-label text-right"
                      >
                        Father's Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="col-sm-5 mt-1">
                        <input
                          type="text"
                          name="father_name"
                          className="form-control"
                          onChange={handleChange}
                          id="inputfathername"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="inputmothername"
                        className="col-sm-2 mt-1 col-form-label text-right"
                      >
                        Mother's Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="col-sm-5 mt-1">
                        <input
                          type="text"
                          name="mother_name"
                          className="form-control"
                          onChange={handleChange}
                          id="inputmothername"
                        />
                      </div>
                    </div>
                    {type!='working-professionals' && (
                      <div className="form-group row">
                        <label
                          htmlFor="inputparentemail"
                          className="col-sm-2 mt-1 col-form-label text-right"
                        >
                          Parent's Email ID{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="col-sm-5 mt-1">
                          <input
                            type="email"
                            name="parents_email"
                            className="form-control"
                            onChange={handleChange}
                            id="inputparentemail"
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label
                        htmlFor="inputparentmobile"
                        className="col-sm-2 mt-1 col-form-label text-right"
                      >
                        Parent's Mobile Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="col-sm-5 mt-1">
                        <input
                          type="number"
                          name="parents_phone"
                          className="form-control"
                          onChange={handleChange}
                          id="inputparentmobile"
                        />
                      </div>
                    </div>

                    {type === "8th-9th" && (
                      <div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Class <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-1">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="class"
                                id="class8th"
                                value="8th"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="class8th"
                              >
                                8th
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="class"
                                id="class9th"
                                value="9th"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="class9th"
                              >
                                9th
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row" style={{ display: eightthDiv }}>
                          <label
                            htmlFor="8th9thsub"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Subjects in Class 8th &amp; 9th <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub1"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub2"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub3"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub4"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub5"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub6"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub7"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub8"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub9"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8th9thsub10"
                            />
                          </div>
                        </div>
                        
                        <div className="form-group row" style={{ display: eightthDiv }}>
                          <label
                            htmlFor="8th9thsub"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Scores in 8th <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores1"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores2"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores3"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores4"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores5"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores6"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores7"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores8"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores9"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="8thscores10"
                            />
                          </div>
                        </div>

                        <div className="form-group row" style={{ display: ninthDiv }}>
                          <label
                            htmlFor="9thscores"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Scores in 9th <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores1"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores2"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores3"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores4"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores5"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores6"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores7"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores8"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores9"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="9thscores10"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {type === "10th-12th" && (
                      <div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Class <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-1">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="class"
                                id="class10th"
                                value="10th"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="class10th"
                              >
                                10th
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="class"
                                id="class11th"
                                value="11th"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="class11th"
                              >
                                11th
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="class"
                                id="class12th"
                                value="12th"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="class12th"
                              >
                                12th
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row" style={{ display : tenthDiv }}>
                          <label
                            htmlFor="10thsub1"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Subject in Class 10th <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              name="10thsub1"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thsub2"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thsub3"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thsub4"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thsub5"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thsub6"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thsub7"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thsub8"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thsub9"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thsub10"
                            />
                          </div>
                        </div>

                        <div className="form-group row" style={{ display : tenthDiv }}>
                          <label
                            htmlFor="10thscores"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Scores in 10th <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores1"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores2"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores3"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores4"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores5"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores6"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores7"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores8"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores9"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="10thscores10"
                            />
                          </div>
                        </div>

                        <div className="form-group row" style={{ display : (ElenthDiv == 'flex' || TwelvethDiv == 'flex') ? ElenthDiv : "none" }}>
                          <label
                            htmlFor="11th12thsub1"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Subject in Class 11th &amp; 12th <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              name="11th12thsub1"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11th12thsub2"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11th12thsub3"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11th12thsub4"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11th12thsub5"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11th12thsub6"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11th12thsub7"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11th12thsub8"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11th12thsub9"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11th12thsub10"
                            />
                          </div>
                        </div>

                        <div className="form-group row" style={{ display : ElenthDiv }}>
                          <label
                            htmlFor="11thscores"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Scores in 11th <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores1"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores2"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores3"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores4"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores5"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores6"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores7"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores8"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores9"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="11thscores10"
                            />
                          </div>
                        </div>

                        <div className="form-group row" style={{ display : TwelvethDiv }}>
                          <label
                            htmlFor="12thscores"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Scores in 12th <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores1"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores2"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores3"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores4"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores5"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores6"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores7"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores8"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores9"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <input
                              type="number"
                              min="0"
                              className="form-control"
                              onChange={handleChange}
                              id=""
                              name="12thscores10"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {type === "college-graduates" && (
                      <div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            College
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="text"
                              name="college"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            City
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Course
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="text"
                              name="course"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Year of completion
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="number"
                              name="year_of_completion"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Semester
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="number"
                              name="semester"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            CGPA
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="number"
                              name="cgpa"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {type === "working-professionals" && (
                      <div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Current Work Place
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="text"
                              name="current_work_place"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            City
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Designation
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="text"
                              name="designation"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            No of years you have worked
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-3">
                            <input
                              type="number"
                              name="no_of_years_worked"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Education Qualification{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-3">
                            <input
                              type="text"
                              name="education_qualification"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row mt-2">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Work Status{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-1">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="work_status"
                                id="CurrentlyWorking"
                                value="1"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="CurrentlyWorking"
                              >
                                Currently Working
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="work_status"
                                id=" In-between jobs"
                                value="2"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor=" In-between jobs"
                              >
                                In-between jobs
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group row mt-2">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Nature of work{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-5 mt-1">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="nature_of_work"
                                id="Privatesector"
                                value="1"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="Privatesector"
                              >
                                Private sector
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="nature_of_work"
                                id="PublicSector"
                                value="2"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="PublicSector"
                              >
                                Public Sector
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="nature_of_work"
                                id=" SelfEmployment"
                                value="3"
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor=" SelfEmployment"
                              >
                                Self - Employment
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {(type === "8th-9th" || type === "10th-12th") && (
                      <div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Hobbies/What makes you happy{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-10 mt-5">
                            <input
                              type="text"
                              name="hobbies"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Your Strengths<span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-10 mt-3">
                            <input
                              type="text"
                              name="strength"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Your Weaknesses <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-10 mt-3">
                            <input
                              type="text"
                              name="weakness"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Your Idols/Role Model{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-10 mt-3">
                            <input
                              type="text"
                              name="role_model"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Co-Curricular activities you have participated in{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-10 mt-3">
                            <input
                              type="text"
                              name="activities"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputstudentname"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Father's Occupation{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <label
                              htmlFor="inputstudentname"
                              className="col-form-label"
                            >
                              Works at
                            </label>
                          </div>
                          <div className="col-sm-2 mt-1">
                            <input
                              type="text"
                              name="father_works_at"
                              className="form-control"
                              onChange={handleChange}
                              id="inputstudentage"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <label
                              htmlFor="inputstudentname"
                              className="col-form-label"
                            >
                              As
                            </label>
                          </div>
                          <div className="col-sm-2 mt-1">
                            <input
                              type="text"
                              name="father_works_as"
                              className="form-control"
                              onChange={handleChange}
                              id="inputstudentage"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputstudentname"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Mother's Occupation{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-1 mt-1">
                            <label
                              htmlFor="inputstudentname"
                              className="col-form-label"
                            >
                              Works at
                            </label>
                          </div>
                          <div className="col-sm-2 mt-1">
                            <input
                              type="text"
                              name="mother_works_at"
                              className="form-control"
                              onChange={handleChange}
                              id="inputstudentage"
                            />
                          </div>
                          <div className="col-sm-1 mt-1">
                            <label
                              htmlFor="inputstudentname"
                              className="col-form-label"
                            >
                              As
                            </label>
                          </div>
                          <div className="col-sm-2 mt-1">
                            <input
                              type="text"
                              name="mother_works_as"
                              className="form-control"
                              onChange={handleChange}
                              id="inputstudentage"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Father's Annual Income{""}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-3 mt-3">
                            <input
                              type="text"
                              name="father_annual_income"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2 mt-1 col-form-label text-right"
                          >
                            Mother's Annual Income{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-3 mt-1">
                            <input
                              type="text"
                              name="mother_annual_income"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {(type === "8th-9th" ||
                      type === "10th-12th" ||
                      type === "college-graduate") && (
                        <div className="form-group row">
                          <label
                            htmlFor="inputparentmobile"
                            className="col-sm-2  col-form-label text-right"
                          >
                            What you aspire to be{""}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div className="col-sm-10 mt-3">
                            <input
                              type="text"
                              name="aspire"
                              className="form-control"
                              onChange={handleChange}
                              id="inputparentmobile"
                            />
                          </div>
                        </div>
                      )}

                    {(type === "8th-9th" || type === "10th-12th") && (
                      <div className="form-group row">
                        <label
                          htmlFor="inputparentmobile"
                          className="col-sm-2 mt-1 col-form-label text-right"
                        >
                          Your parents's opinions about your career{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="col-sm-10 mt-5">
                          <input
                            type="text"
                            name="parents_opinion_about_your_career"
                            className="form-control"
                            onChange={handleChange}
                            id="inputparentmobile"
                          />
                        </div>
                      </div>
                    )}

                    {(type === "8th-9th" ||
                      type === "10th-12th" ||
                      type === "college-graduate") && (
                        <div className="form-group">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="col-form-label"
                          >
                            {(type === "8th-9th" || type === "10th-12th") && (
                              <div>
                                What you want to achieve in your life, where do you see yourself in the upcoming years, what motivates/demotivates you, your dream college and job, etc.
                                <br />
                                Your dream college, dream job etc.{""}
                                <span style={{ color: "red" }}>*</span>
                              </div>
                            )}

                            {type === "college-graduate" && (
                              <div>Top companies that you would want to work in <span style={{ color: "red" }}>*</span></div>
                            )}
                          </label>
                          <textarea
                            name="description"
                            className="form-control"
                            onChange={handleChange}
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                      )}

                    <div className="form-group">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="col-form-label"
                      >
                        The reason you opted for career counselling
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        name="reason"
                        className="form-control"
                        onChange={handleChange}
                        id=""
                        rows="3"
                      ></textarea>
                    </div>

                    <div className="form-group row  text-center">
                      <div className="col-sm-12 mt-10">
                        <button
                          className="btn btn-success btn-lg"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Records;
