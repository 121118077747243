import { careerLibrarySearchAPI } from "../../Service";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const AutoComplete = ({ data }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [suggestionsActive, setSuggestionsActive] = useState(false);
    const [value, setValue] = useState("");
    const [name, SetName] = useState("");
    const [submitButton, SetSubmitButton] = useState(true);
    let navigate = useNavigate();

    const handleChange = async (e) => {
        const query = e.target.value.toLowerCase();
        setValue(e.target.value);
        if (query.length >= 1) {
            let userData = {
                name: query,
            };
            let res = await careerLibrarySearchAPI(userData);
            if (res?.is_success === true) {
                let responseData = res.data;
                setSuggestions(responseData);
                setSuggestionsActive(true);
            }
        } else {
            SetSubmitButton(true)
            setSuggestionsActive(false);
        }
    };

    const handleClick = (e) => {
        setSuggestions([]);
        setValue(e.target.innerText);
        setSuggestionsActive(false);
        SetSubmitButton(false);
    };


    const Suggestions = () => {
        return (
            <ul className="suggestions">
                {suggestions.map((suggestion, index) => {
                    console.log(index, suggestionIndex)
                    return (
                        <Link to={`/library/${suggestion?.slug}`}>
                            <li style={{ align:"left" }}
                                className="text-start"
                                key={suggestion?.slug}
                            >
                            {suggestion?.name}
                            </li>
                        </Link>
                    );
                })}
            </ul>
        );
    };

    const careerLibrarySearch = () => {
        navigate('/library/'+value);
    }

    return (
        <section className="wrapper text-center mt-5 mb-6">
            <div className="row gx-lg-6 gx-xl-8">
                <div className="col-lg-12 col-xl-12">
                    <div className="autocomplete">
                    <i className="uil uil-search search-icon"></i>
                        <input type="text" name="name" className="form-control" value={value} placeholder="Search Career Library"
                            onChange={(e) => {
                                handleChange(e);
                                SetName(e.target.value);
                            }}
                        />
                        {suggestionsActive && <Suggestions />}     
                    </div>
                </div>
                {/* <div className="col-lg-2 col-xl-2">
                    <Button
                        className="btn btn-primary rounded-pill btn-send mb-3"
                        disabled={submitButton}
                        onClick={(e) => {
                            careerLibrarySearch(e);
                        }}
                    >
                        Search
                    </Button>
                </div> */}
            </div>
        </section>
    );
};

export default AutoComplete;