import { Modal,Button } from "react-bootstrap";
import {Link} from "react-router-dom"

function ModalAlert({ModalAlertDisplay,SetModalModalAlertDisplay,ModalAlertMessage,ModalAlertButton,ModalAlertSlug, backdropEvent}) {
    return (
      <div>
    
        <Modal show={ModalAlertDisplay} onHide={SetModalModalAlertDisplay} animation={false} centered size="sm" backdrop={backdropEvent}>
          <Modal.Body className="text-center">
          {ModalAlertMessage.map(function (x, i) {
            return <p key={i}>{x}</p>;
          })}
          
        {ModalAlertButton === 'product' && ModalAlertSlug !== ''?(
          <Link to={ModalAlertSlug} className="btn btn-primary">View Products</Link>
        ):ModalAlertButton === 'profile' && ModalAlertSlug !== ''?(
          <Link to={ModalAlertSlug} className="btn btn-primary">View Profile</Link>
        ):ModalAlertButton === 'ESM' && ModalAlertSlug !== ''?(
          <Link to={ModalAlertSlug} className="btn btn-primary">Subscribe</Link>
        ):<button className="btn btn-sm btn-primary" onClick={(e) => {SetModalModalAlertDisplay(false)}}>Ok</button>}
            </Modal.Body>
        </Modal>
      </div>
    );
  }
  

export default ModalAlert;