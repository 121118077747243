import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { useEffect, useState } from "react";
import { LibraryAPI, ScCategoriesAPI } from "../Service";
import { Link, useParams } from "react-router-dom";
import * as Icon from "react-feather";
import { CLSecondaryCategoryAPI } from "../Service";
import BreadCumb from "../layouts/BreadCumb";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
function ScLibrary() {
  const [showScroll, setShowScroll] = useState(false);
  let { slug } = useParams();
  const [CLSecondaryList, SetCLSecondaryList] = useState({});
  const [MetaDetails, SetMetaDetails] = useState({
    'title': 'Secondary Category',
    'meta_title': '',
    'meta_keywords': '',
    'meta_description': '',
  })
  const list = [
    { name: "Home", url: "/" },
    { name: "Library", url: "/library" },
    { name: CLSecondaryList?.name, url: null },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchCLSecondaryLibrary();
  }, []);

  const fetchCLSecondaryLibrary = async () => {
    let res = await CLSecondaryCategoryAPI(slug);
    if (res?.is_success === true) {
      SetCLSecondaryList(res?.data);
      SetMetaDetails({
        'title': res?.data?.name,
        'meta_title': res?.data?.meta_title,
        'meta_keywords': res?.data?.meta_keywords,
        'meta_description': res?.data?.meta_description,
      })
    }
  };

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />

      {/* BreadCumb */}
      <BreadCumb name={CLSecondaryList?.name} list={list} />

      <section className="wrapper bg-light">
        <div className="container">
          <div className="pricing-wrapper position-relative mt-n15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="blog grid grid-view mt-3">
                  <div className="row isotope gx-md-8 gy-8 mb-8">
                    {CLSecondaryList?.career_libraries &&
                      CLSecondaryList?.career_libraries.map((x, i) => (
                        <article className="item post col-md-4" key={i}>
                          <div className="card">
                            <figure className="card-img-top overlay overlay-1 hover-scale cl-img-border-radius">
                              <Link to={`/library/${x.slug}`}>
                                <img className="img-fluid" style={{ width: '100%', height: 'auto' }}
                                  src={process.env.REACT_APP_BASE_URL + x.image}
                                  alt={x.title}
                                />
                              </Link>
                            </figure>
                            <div className="card-body">
                              <div className="post-header">
                                <Link to={`/library/${x.slug}`}>
                                  <h2 className="post-title h3 mt-1 mb-3 text-center cl-height2">
                                    {x.name}
                                  </h2>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </article>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default ScLibrary;
