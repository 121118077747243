import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import BreadCumb from "../layouts/BreadCumb";
import { GalleryAPI } from "../Service";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";

function Gallery() {
  const [showScroll, setShowScroll] = useState(false);
  const list = [
    { name: "Home", url: "/" },
    { name: "Gallery", url: null },
  ];
  const [galleryList, SetGalleryList] = useState([]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchGalleryList();
  }, []);
  const fetchGalleryList = async (pageNumber = 1) => {
    let res = await GalleryAPI(pageNumber);
    if (res?.is_success === true) {
      SetGalleryList(res.datas);
    }
  };
  const setting = JSON.parse(localStorage.getItem("setting"));

  let MetaDetails = {
    title: "Gallery | " + process.env.REACT_APP_NAME,
    meta_title: "Gallery | " + process.env.REACT_APP_NAME,
    meta_keywords: "Mere Mentor Gallery",
    meta_description:
      "Dive into our gallery to experience the milestones we have achieved as a company and the great memories we’ve made on the way.",
  };

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      <BreadCumb name={"Gallery"} list={list} />
      {/*   <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0">
              {galleryList.map((x, i) => (
                <div className="col-md-6 col-lg-3 mt-4" key={x.id}>
                  <div className="position-relative">
                    <div
                      className="shape rounded bg-soft-blue rellax d-md-block"
                      data-rellax-speed="0"
                      style={{
                        bottom: "-0.75rem",
                        right: "-0.75rem",
                        width: "98%",
                        height: "98%",
                        zIndex: "0",
                      }}
                    ></div>
                    <div className="card">
                      <figure className="card-img-top">
                        <Link to={`/gallery/${x.id}`}>
                          {x?.first_item?.image && (
                            <img
                              className="img-fluid"
                              src={
                                process.env.REACT_APP_BASE_URL +
                                x?.first_item?.image
                              }
                              alt={x.name}
                            />
                          )}

                          {x?.first_item?.thumbnail && (
                            <img
                              className="img-fluid"
                              src={
                                process.env.REACT_APP_BASE_URL +
                                x?.first_item?.thumbnail
                              }
                              alt={x.name}
                            />
                          )}
                        </Link>
                      </figure>
                      <div className="card-body px-6 py-5">
                        <Link to={`/gallery/${x.id}`}>
                          <h4 className="mb-1 text-center">{x.name}</h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}
      <section className="wrapper bg-light">
        <div className="container pt-9 pt-md-11 pb-14 pb-md-16">
          <div className="projects-overflow mt-md-10 mb-10 mb-lg-15">
            {galleryList.map((x, i) => (
              <>
                {i % 2 === 0 ? (
                  <div className="project item">
                    <div className="row">
                      <figure className="col-lg-8 col-xl-8 offset-xl-1 rounded">
                        {x?.first_item?.image && (
                          <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"
                            src={
                              process.env.REACT_APP_BASE_URL +
                              x?.first_item?.image
                            }
                            alt="gallery"
                          />
                        )}
                        {x?.first_item?.thumbnail && (
                          <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"
                            src={
                              process.env.REACT_APP_BASE_URL +
                              x?.first_item?.thumbnail
                            }
                            alt="gallery"
                          />
                        )}
                      </figure>
                      <div
                        className="project-details d-flex justify-content-center flex-column"
                        style={{ right: "10%", bottom: "25%" }}
                      >
                        <div
                          className="card shadow rellax"
                          data-rellax-xs-speed="0"
                          data-rellax-mobile-speed="0"
                        >
                          <div className="card-body">
                            <div className="post-header">
                              <div className="post-category text-line text-purple mb-3">
                                {x.name}
                              </div>
                              {/*  <h2 className="post-title mb-3">
                               {x.name}
                              </h2> */}
                            </div>
                            <div className="post-content">
                              {x?.description && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: x?.description,
                                  }}
                                />
                              )}
                              <Link
                                className="more hover link-purple"
                                to={`/gallery/${x.id}`}
                              >
                                View
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="project item">
                    <div className="row">
                      <figure className="col-lg-8 offset-lg-8 offset-xl-3 rounded">
                        {x?.first_item?.image && (
                          <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"
                            src={
                              process.env.REACT_APP_BASE_URL +
                              x?.first_item?.image
                            }
                            alt="gallery"
                          />
                        )}
                        {x?.first_item?.thumbnail && (
                          <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"
                            src={
                              process.env.REACT_APP_BASE_URL +
                              x?.first_item?.thumbnail
                            }
                            alt="gallery"
                          />
                        )}
                      </figure>
                      <div
                        className="project-details d-flex justify-content-center flex-column"
                        style={{ left: "18%", bottom: "25%" }}
                      >
                        <div
                          className="card shadow rellax"
                          data-rellax-xs-speed="0"
                          data-rellax-mobile-speed="0"
                        >
                          <div className="card-body">
                            <div className="post-header">
                              <div className="post-category text-line text-purple mb-3">
                                {x.name}
                              </div>
                              {/*  <h2 className="post-title mb-3">
                               {x.name}
                              </h2> */}
                            </div>
                            <div className="post-content">
                              {x?.description && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: x?.description,
                                  }}
                                />
                              )}
                              <Link
                                className="more hover link-purple"
                                to={`/gallery/${x.id}`}
                              >
                                View
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </section>
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default Gallery;
