import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import BreadCumb from "../layouts/BreadCumb";
import CounsellorHelper from "../layouts/CounsellorHelper";
import { useEffect, useState } from "react";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";

function Counsellors() {
  const [showScroll, setShowScroll] = useState(false);
  const list = [
    { name: "Home", url: "/" },
    { name: "Counsellors", url: null },
  ];
  const setting = JSON.parse(localStorage.getItem("setting"));
  let MetaDetails = {
    'title': 'Best Career Counsellors | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Best Career Counsellors | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Mere Mentor Counsellors',
    'meta_description': 'Select your Best Career Counsellors in Mere Mentor. Mere Mentor provides certification for counselors in career counselling.',
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  })

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      <BreadCumb name="Counsellors" list={list} />
      <CounsellorHelper />
      <Footer />
      {/* <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} /> */}
    </div>
  );
}

export default Counsellors;
