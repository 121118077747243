import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import React, { useRef, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { fetchActionPlanLibraryAPI, storeGroupCounsellingActionPlanAPI } from "../../Service";
import Cookies from "js-cookie";
import AlertComponent from "../../layouts/Alert";

function GroupCounsellingActionPlan() {
  const list = [
    { name: "Home", url: "/" },
    { name: "Action Plan", url: null },
  ];
  const navigate = useNavigate();
  let { encrypt_id } = useParams();
  const editorRef = useRef(null);
  const animatedComponents = makeAnimated();
  const [library, SetLibrary] = useState([]);
  const [options, SetOptions] = useState([]);
  const [feedack, SetFeedback] = useState("");
  const [improvement, SetImprovement] = useState("");
  const [suggestions, SetSuggestions] = useState("");
  const [careers, Setcareers] = useState("");
  let USER_TOKEN = Cookies.get("usr_token");
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchLibrary();
    if (USER_TOKEN === undefined) {
      navigate("/?is_login=true&is_message=Please Login to Continue");
    } else {
    }
  }, []);

  const fetchLibrary = async () => {
    let res = await fetchActionPlanLibraryAPI();
    if (res?.is_success) {
      SetLibrary(res.libraries);
      let arr = [];
      if (res.libraries.length > 0) {
        res.libraries.map((x, i) => {
          let data = {
            value: x.slug,
            label: x.name,
          };
          arr.push(data);
          if (x.secondary_categories.length > 0) {
            x.secondary_categories.map((y, j) => {
              let data = {
                value: y.slug,
                label: y.name,
              };
              arr.push(data);
            });
          }
        });
      }
      SetOptions(arr);
    }
  };

  const [formValues, setFormValues] = useState([{ addon_description: "" }]);
  let handleChange = (i, value) => {
    let newFormValues = [...formValues];
    newFormValues[i]["addon_description"] = value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { addon_description: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const chageOption = (selectedOption) => {
    console.log(selectedOption);
    let arr = [];
    selectedOption.map((x, i) => {
      arr.push(x.value);
    });
    Setcareers(arr);
  };

  let handleSubmit = async (event) => {
    event.preventDefault();
    let addon_description = [];
    formValues.map((x, i) => {
      addon_description.push(x.addon_description);
    });
    let data = {
      feedback_from_counsellor: feedack,
      area_of_improvements: improvement,
      suggestions: suggestions,
      careers: careers,
      group_counselling_id: encrypt_id,
      addon_description: addon_description,
    };
    let res = await storeGroupCounsellingActionPlanAPI(USER_TOKEN, data);
    if (res?.is_success) {
      navigate("/dashboard");
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      SetAlertDisplay(true)
      SetAlertMessage(res?.errors)
    }
  };

  return (
    <div>
      <HeaderComponent />
      <BreadCumb name={"Group Counselling Action Plan"} list={list} />
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="box-shadow  pt-2 border-radius-7"
                  style={{ backgroundColor: "#e2eafa" }}
                >
                  <div className="py-3 text-center">
                    <h2>Action Plan</h2>
                  </div>
                  <AlertComponent
                    AlertDisplay={AlertDisplay}
                    SetAlertDisplay={SetAlertDisplay}
                    AlertMessage={AlertMessage}
                  />
                  <div className="row mb-3 p-2">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Feedback
                    </label>
                    <div className="col-sm-10">
                      <Editor
                        onEditorChange={(newValue, editor) =>
                          SetFeedback(newValue)
                        }
                        apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
                        init={{
                          height: 250,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                    </div>
                  </div>
                  {formValues.map((element, index) => (
                    <div className="row mb-3 p-2" key={index}>
                      <label className="col-sm-2 col-form-label">
                        {index === 0 ? "Addon Description" : null}
                      </label>

                      <div className="col-sm-8">
                        {/*   <textarea
                          className="form-control"
                          onChange={(e) => handleChange(index, e)}
                          name="addon_description"
                        ></textarea> */}
                        <Editor
                          textareaName="addon_description"
                          onEditorChange={(newValue, editor) =>
                            handleChange(index, newValue)
                          }
                          apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
                          init={{
                            height: 250,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                      </div>
                      <div className="col-sm-2">
                        {index ? (
                          <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            onClick={() => removeFormFields(index)}
                          >
                            Remove
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-success"
                            type="button"
                            onClick={() => addFormFields()}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="row mb-3 p-2">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Careers
                    </label>
                    <div className="col-sm-10">
                      <Select
                        options={options}
                        closeMenuOnSelect={false}
                        isMulti
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        onChange={chageOption}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 p-2">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Area of Improvement
                    </label>
                    <div className="col-sm-10">
                      <Editor
                        onEditorChange={(newValue, editor) =>
                          SetImprovement(newValue)
                        }
                        apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
                        init={{
                          height: 250,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 p-2">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-2 col-form-label"
                    >
                      Suggestions
                    </label>
                    <div className="col-sm-10">
                      <Editor
                        onEditorChange={(newValue, editor) =>
                          SetSuggestions(newValue)
                        }
                        apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
                        init={{
                          height: 250,
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mb-3 p-2 ">
                    <label
                      htmlFor="inputEmail3"
                      className="col-sm-5 col-form-label"
                    ></label>
                    <div className="col-sm-7">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit Report
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default GroupCounsellingActionPlan;
