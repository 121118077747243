import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { useEffect } from "react";
import { LibraryAPI, ScCategoriesAPI } from "../Service";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import * as Icon from "react-feather";
import BreadCumb from "../layouts/BreadCumb";
import { Modal } from "react-bootstrap";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
import AutoComplete from "./AutoComplete/AutoComplete";
import OnlineRegModal from '../layouts/onlineReg';
import Cookies from "js-cookie";

function Library() {
  const [showScroll, setShowScroll] = useState(false);
  const [libraryList, SetLibraryList] = useState([]);
  const [clPrimary, SetCLPrimary] = useState({});
  const [clSecondary, SetClSecondary] = useState([]);
  const [careerLibrary, SetCareerLibrary] = useState([]);
  const [row, SetRow] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let navigate = useNavigate();
  const list = [
    { name: "Home", url: "/" },
    { name: "Library", url: null },
  ];

  const setting = JSON.parse(localStorage.getItem("setting"));
  let MetaDetails = {
    'title': 'Career Library 250+ career options | ' + process.env.REACT_APP_NAME + ' 2022, 2023',
    'meta_title': 'Career Library 250+ career options | ' + process.env.REACT_APP_NAME + '2022, 2023',
    'meta_keywords': 'Mere Mentor Career Library',
    'meta_description': 'Get your complete career information on more than 250+ career options at the Mere Mentor career library. Explore career options, get career guidance & education',
  }

  let USER_TOKEN = Cookies.get("usr_token");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchPrimaryLibrary();
    if (USER_TOKEN == undefined) {
      setTimeout(() => {
        setShowOnlineReg(true)
      }, 90000);
    }
  }, []);

  const fetchPrimaryLibrary = async () => {
    let res = await LibraryAPI();
    if (res?.is_success === true) {
      SetLibraryList(res?.datas);
      var arr = [];
      if (res?.count && res.count > 0) {
        for (var i = 1; i <= res.count; i++) {
          if (i % 3 === 0) {
            arr.push(i);
          }
        }
      }
      SetRow(arr);
    }
  };

  const fetchCLSecondaryLibrary = async (e, data, i) => {
    SetCLPrimary(data);
    setShow(true);
    let j = false;
    /*  row.forEach((element) => {
       let ele = document.getElementsByClassName(`sc-category-${element}`)[0];
       ele.style.display = "none";
       if (j === false) {
         var k = i + 1;
         if (element >= k) {
           ele = document.getElementsByClassName(`sc-category-${element}`)[0];
           ele.style.display = "block";
           j = true;
         }
       }
     }); */
    let res = await ScCategoriesAPI(data);
    if (res?.is_success === true) {
      SetClSecondary(res.c_l_secondary_category);
      SetCareerLibrary(res.career_libraries);
    }
  };

  const closeSecondaryCategory = (e) => {
    let a = e.target.dataset.secondaryCategory;
    let ele = document.getElementsByClassName(
      `sc-category-${e.target.dataset.secondaryCategory}`
    )[0];
    ele.style.display = "none";
  };

  const [modal, setmodal] = useState(false);
  const [showOnlineReg, setShowOnlineReg] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      <BreadCumb name={"Career Library"} list={list} />
      <section className="wrapper career-lib">
        <div className="container">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n20">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row">
              <div className="col-lg-10 mx-auto">

                {/* <button onClick={() => setShowLogin(true)}></button> */}
                <OnlineRegModal show={showOnlineReg} hide={() => setShowOnlineReg(false)} />

                {/* <button className="btn btn-primary" >Open</button> */}

                <AutoComplete></AutoComplete>
                <div className="blog grid grid-view mt-3">
                  <div className="row isotope gx-md-8 gy-8 mb-8">
                    {libraryList.map((x, i) => (
                      <article className="item post col-md-4" key={i}>
                        <div className="card" style={{ minHeight: "400px" }}>
                          <figure className="card-img-top overlay overlay-1 hover-scale">
                            <a
                              onClick={(e) =>
                                fetchCLSecondaryLibrary(e, x, i)
                              }
                              data-library={i}
                            >
                              <img  className="img-fluid" style={{ width: '100%', height: 'auto' }} 
                                src={process.env.REACT_APP_BASE_URL + x.image}
                                alt={x.title}
                              />
                            </a>
                          </figure>
                          <div className="card-body">
                            <div className="post-header">
                              <h2 className="post-title h3 mt-1 mb-3 text-center">
                                <a
                                  onClick={(e) =>
                                    fetchCLSecondaryLibrary(e, x, i)
                                  }
                                  data-library={i}
                                >
                                  {x.name}
                                </a>
                              </h2>
                            </div>
                          </div>
                        </div>
                      </article>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{clPrimary?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Click below to explore any of the following career(s)</strong>

          <div className="career-list-details mt-1">
            <div className="contain-box">
              <div className="row isotope gx-md-8 gy-8 mb-8">
                {clSecondary.map((x, i) => (
                  <article className="item post col-md-4" key={i}>
                    <div className="card">
                      <figure className="card-img-top overlay overlay-1 hover-scale">
                        <Link to={`/library/category/${x.slug}`}>
                          <img className="img-fluid" style={{ width: '100%', height: 'auto' }} 
                            src={process.env.REACT_APP_BASE_URL + x.image}
                            alt={x.title}
                          />
                        </Link>
                      </figure>
                      <div className="card-body">
                        <div className="post-header">
                          <h2 className="post-title h3 mt-1 mb-3 text-center cl-height">
                            <Link to={`/library/category/${x.slug}`}>
                              {x.name}
                            </Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
                {careerLibrary.map((x, i) => (
                  <article className="item post col-md-4" key={i}>
                    <div className="card">
                      <figure className="card-img-top overlay overlay-1 hover-scale cl-img-border-radius">
                        <Link to={`/library/${x.slug}`}>
                          <img className="img-fluid" style={{ width: '100%', height: 'auto' }} 
                            src={process.env.REACT_APP_BASE_URL + x.image}
                            alt={x.title}
                          />
                        </Link>
                      </figure>
                      <div className="card-body">
                        <div className="post-header">
                          <h2 className="post-title h3 mt-1 mb-3 text-center cl-height">
                            <Link to={`/library/${x.slug}`}>{x.name}</Link>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>



      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default Library;
