import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import Sidebar from "../../layouts/Sidebar";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetStatesAPI, GetCityAPI, updateProfileAPI } from "../../Service";
import Message from "../../layouts/Message";
import { Button, Spinner } from "react-bootstrap";
import BackToTop from "../../layouts/BackToTop"
import Seo from "../../layouts/SEO";
import AlertComponent from "../../layouts/Alert";
import validator from "validator";
import Select from "react-select";

function Profile() {
  const list = [
    { name: "Home", url: "/" },
    { name: "Profile", url: null },
  ];
  let USER_TOKEN = Cookies.get("usr_token");
  const [showScroll, setShowScroll] = useState(false);
  let user = JSON.parse(Cookies.get("user"));

  const [userDefaultemail, SetuserDefaultEmail] = useState(user?.email);
  const [userDefaultmobile, SetuserDefaultMobile] = useState(user?.phone);
  const [userDefaultState, SetUserDefaultState] = useState(
    (user.state_id) ? user?.state_id : "DEFAULT"
  );

  const [name, SetName] = useState(user?.name);
  const [email, SetEmail] = useState(user?.email);
  const [mobile, SetMobile] = useState(user?.phone);
  const [gender, SetGender] = useState(user?.gender != null ? user?.gender : 0);
  const [education, SetEducation] = useState(user?.education);
  const [address1, SetAddress1] = useState(user?.address1);
  const [address2, SetAddress2] = useState(user?.address2);
  const [userState, SetUserState] = useState(
    (user.state_id) ? user?.state_id : "DEFAULT"
  );
  const [userCity, SetUserCity] = useState(
    user?.city_id ? user?.city_id : "DEFAULT"
  );
  const [postalCode, SetPostalCode] = useState(user?.postcode);
  const [states, SetStates] = useState([]);
  const [cities, SetCities] = useState([]);
  const [profilePicture, SetProfilePicture] = useState("");
  const [submitButton, SetSubmitButton] = useState(false);
  const [MessageDisplay, SetMessageDisplay] = useState(false);
  const [MessageContent, SetMessageContent] = useState([]);
  const navigate = useNavigate();
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [stateOptions, SetStateOptions] = useState([]);
  const [cityOptions, SetCityOptions] = useState([]);
  const [selectedStateValue, SetselectedStateValue] = useState([]);
  const [selectedCityValue, SetselectedCityValue] = useState([]);

  let MetaDetails = {
    'title': 'My Profile | ' + process.env.REACT_APP_NAME,
    'meta_title': 'My Profile | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'My Profile Mere Mentor',
    'meta_description': 'Mere Mentor is an EdTech organization delivering career counselling services to the students and youth of the country.',
  }

  let [searchParams, setSearchParams] = useSearchParams();
  let is_messages = searchParams.get("is_message");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (is_messages) {
      SetAlertDisplay(true);
      SetAlertMessage([is_messages]);
    }
    if (USER_TOKEN === undefined) {
      navigate("/");
    }
    GetStates();
  }, []);

  const handleChange = (data) => {
    const value = data.replace(/\D/g, "");
    SetPostalCode(value);
  };

  const GetStates = async () => {
    let res = await GetStatesAPI();
    if (res?.is_success === true) {
      var arr = [];
      if (res?.states.length > 0) {
        res?.states.map((z, j) => {
          if(z.id == user?.state_id){
            let selectingdata = {
              value: z.id,
              label: z.name,
            };
            selectedStateValue.push(selectingdata);
          }
          let data = {
            value: z.id,
            label: z.name,
          };
          arr.push(data);
        });
      }
      SetStateOptions(arr);
      SetUserState(user?.state_id)
      if (user?.state_id) {
        GetCities(user?.state_id)
      }
    }
  };

  useEffect(() => {
  }, [userState])
  
  const GetCities = async (data) => {
    let res = await GetCityAPI(data);
    if (res?.is_success === true) {
      SetCities(res?.cities);
      var CityArray = [];
      if (res?.cities.length > 0) {
        res?.cities.map((z, j) => {
          if(z.id == user?.city_id){
            let selectingCitydata = {
              value: z.id,
              label: z.name,
            };
            selectedCityValue.push(selectingCitydata);
          }
          let Citydata = {
            value: z.id,
            label: z.name,
          };
          CityArray.push(Citydata);
        });
      }
      SetCityOptions(CityArray);
      SetUserCity(user?.city_id)
    }
  };

  const isDisabled = (userDefaultState != 'DEFAULT' && userDefaultState != 0) ? true : false;

  const updateProfile = async () => {
    SetSubmitButton(true);
    const formData = new FormData();
    formData.append("profilePicture", profilePicture);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("gender", gender);
    formData.append("education", education);
    formData.append("address1", address1);
    formData.append("address2", address2);
    formData.append("userState", userState);
    formData.append("userCity", userCity);
    formData.append("postalCode", postalCode);

    let err = [];

    var mandatoryFilled = true;
    if (name == "" || name == "null" || name == null || mobile == "" || mobile == "null" || mobile == null || email == "" || email == "null" || 
        email == null || gender === 0 || education == "" || education == "null" || education == null || address1 == "" || address1 == "null" || 
        address1 == null || userState == 'DEFAULT' || userCity == 'DEFAULT' || postalCode == '' || postalCode == 'null' || postalCode == null) {
      err.push("Please fill the mandatory fields");
      mandatoryFilled = false;
    }
    if (mandatoryFilled == true) {
      if (!validator.isLength(name, { min: 2, max: 255 })) {
        err.push("Name should have minimum 3 characters");
      }
      if (!validator.isLength(mobile, { min: 10, max: 10 })) {
        err.push("Mobile Number Must be 10 digits");
      }
      if (!validator.isNumeric(mobile)) {
        err.push("Mobile Number Must be in digits");
      }
      if (email == null || email == 'null' || !validator.isEmail(email)) {
        err.push("Enter a Valid Email");
      }
      if (gender === 'DEFAULT') {
        err.push("Gender is required");
      }
      if (gender === 0) {
        err.push("Gender is required");
      }

      if (education == null || education == 'null') {
        err.push("School/College/Organization is required");
      } else if (!validator.isLength(education, { min: 2, max: 255 })) {
        err.push("School/College/Organization should have minimum 2 characters");
      }

      if (address1 == null || address1 == 'null') {
        err.push("Address 1 is required");
      } else if (!validator.isLength(address1, { min: 3, max: 255 })) {
        err.push("Address should have minimum 3 characters");
      }

      if (userState === 'DEFAULT') {
        err.push("State is required");
      }
      if (userState === 0) {
        err.push("State is required");
      }
      if (userCity === 'DEFAULT') {
        err.push("City is required");
      }
      if (userCity === 0) {
        err.push("City is required");
      }
      if (postalCode == null || postalCode == 'null') {
        err.push("postalCode is required");
      } else if (!validator.isLength(postalCode, { min: 3, max: 255 })) {
        err.push("Postal Code should have minimum 6 characters");
      }
    }

    if (err.length > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      SetAlertDisplay(true);
      SetAlertMessage(err);
      SetSubmitButton(false);
    } else {
      let res = await updateProfileAPI(formData, USER_TOKEN);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      if (res?.is_success == true) {
        SetAlertDisplay(false);
        SetSubmitButton(false);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        SetuserDefaultEmail(res?.usr?.email);
        SetuserDefaultMobile(res?.usr?.phone);
        SetUserDefaultState(res?.usr?.state_id);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        var usr_session = new Date(new Date().getTime() + 180 * 60 * 1000);
        Cookies.set("user", JSON.stringify(res?.usr), {
          expires: usr_session,
        });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors);
        SetSubmitButton(false);
      }
    }
  };

  return (
    <div>
      <HeaderComponent />
      <Seo options={MetaDetails} />
      <BreadCumb name={"My Profile"} list={list} />
      <section className="myprofile-section ptb-50">
        <div className="container">
          <div className="pricing-wrapper position-relative mt-n18 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row justify-content-between main-area">
              <div className="col-lg-3">
                <div className="myprofile-sidebar rounded-3">
                  <div className="card">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="myprofile-content rounded-3 p-5">
                  <div className="dashboard-quick-widget">
                    <AlertComponent
                      AlertDisplay={AlertDisplay}
                      SetAlertDisplay={SetAlertDisplay}
                      AlertMessage={AlertMessage}
                    />
                    <h4 className="mt-4">Personal Information</h4>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        Name <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          title="Name"
                          value={name}
                          onChange={(e) => {
                            SetName(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        Email ID <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        {(userDefaultemail == 'null' || userDefaultemail == null) ? (
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            defaultValue={email}
                            onChange={(e) => {
                              SetEmail(e.target.value);
                            }}
                            title="Email Address"
                            required
                          />
                        ) : (
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            defaultValue={email}
                            onChange={(e) => {
                              SetEmail(e.target.value);
                            }}
                            title="Email Address"
                            readOnly
                          />
                        )}
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        Phone number <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        {(userDefaultmobile == 'null' || userDefaultmobile == null) ? (
                          <input
                            maxLength="10"
                            minLength="10"
                            type="tel"
                            className="form-control"
                            name="phone"
                            defaultValue={mobile}
                            onChange={(e) => {
                              SetMobile(e.target.value);
                            }}
                            title="Mobile No"
                            required
                          />
                        ) : (
                          <input
                            maxLength="10"
                            minLength="10"
                            type="tel"
                            className="form-control"
                            name="phone"
                            defaultValue={mobile}
                            onChange={(e) => {
                              SetMobile(e.target.value);
                            }}
                            title="Mobile No"
                            readOnly
                          />
                        )}
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <select
                          name="gender"
                          className="form-control"
                          defaultValue={gender}
                          onChange={(e) => {
                            SetGender(e.target.value);
                          }}
                          required
                        >
                          <option value=" " disabled selected>
                            Select Gender
                          </option>
                          <option value="1">Male</option>
                          <option value="2">Female</option>
                          <option value="3">Rather not Say</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        School/College/<br></br>Organization <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="education"
                          title="Enter School/College/Organization Name"
                          value={(education !== 'null') ? education : ''}
                          onChange={(e) => {
                            SetEducation(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        Address Line 1 <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          className="form-control"
                          name="address1"
                          title="Address Line 1"
                          value={(address1 !== 'null') ? address1 : ''}
                          onChange={(e) => {
                            SetAddress1(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        Address Line 2
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          name="address2"
                          title="Address Line 2"
                          className="form-control"
                          value={(address2 !== 'null') ? address2 : ''}
                          onChange={(e) => {
                            SetAddress2(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        State <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">

                        <Select
                          defaultValue={selectedStateValue}
                          // isDisabled={isDisabled}
                          options={stateOptions}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={(e) => {
                            SetUserState(e.value);
                            GetCities(e.value);
                          }}
                        />

                        {/* <select
                          name="state"
                          className="form-control"
                          defaultValue={userState}
                          onChange={(e) => {
                            SetUserState(e.target.value);
                            GetCities(e.target.value);
                          }}
                          required
                          disabled={(userDefaultState != 'DEFAULT' && userDefaultState != 0) ? true : false}
                        >
                          <option value="" selected disabled>
                            Select State
                          </option>
                          {states.map((x, i) => (
                            <option value={x.id} key={x.id} selected={(x.id === user?.state_id) ? true : false}>
                              {x.name}
                            </option>
                          ))}
                        </select> */}
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        City <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <Select
                          defaultValue={selectedCityValue}
                          options={cityOptions}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={(e) => {
                            SetUserCity(e.value);
                          }}
                        />
                        {/* <select
                          name="city"
                          className="form-control"
                          defaultValue={userCity}
                          onChange={(e) => {
                            SetUserCity(e.target.value);
                          }}
                          required
                        >
                          <option value="DEFAULT">Select City</option>
                          {cities.map((x, i) => (
                            <option value={x.id} key={x.id} selected={(x.id === user?.city_id) ? true : false}>
                              {x.name}
                            </option>
                          ))}
                        </select> */}
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        Zip/Postal Code <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          maxLength={6}
                          name="postcode"
                          title="Zip/Postal Code"
                          className="form-control"
                          value={(postalCode !== 'null') ? postalCode : ''}
                          onChange={(e) => {
                            handleChange(e.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label className="col-md-3 col-form-label text-dark">
                        Profile Image
                      </label>
                      <div className="col-md-9">
                        <input
                          type="file"
                          className="form-control"
                          name="image"
                          onChange={(e) => SetProfilePicture(e.target.files[0])}
                          onClick={(e) => (e.target.value = null)}
                        />
                      </div>
                    </div>

                    <div className="form-row mt-2">
                      <div className="form-group col-md-12 offset-5">
                        <Button
                          className="btn btn-primary rounded-pill btn-send mb-3"
                          disabled={submitButton}
                          onClick={(e) => {
                            updateProfile(e);
                          }}
                        >
                          {submitButton ? (
                            <div>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Loading...
                            </div>
                          ) : (
                            <div>Save</div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Toast */}
      <Message
        MessageDisplay={MessageDisplay}
        MessageContent={MessageContent}
        SetMessageDisplay={SetMessageDisplay}
      />
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default Profile;
