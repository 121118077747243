import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import "moment-timezone";
import { useNavigate, useParams } from "react-router-dom";
import { invoiceAPI } from "../../Service";
import Cookies from "js-cookie";

function Invoice() {
    let { encrypt_id } = useParams();
    const [detail, SetDetail] = useState({});
    let setting = JSON.parse(localStorage.getItem("setting"));
    const list = [
        { name: "Home", url: "/" },
        { name: "Invoice", url: null },
    ];
    let USER_TOKEN = Cookies.get("usr_token");
    let user = Cookies.get("user");
    let usr = null;
    if (user !== undefined && user !== null) {
        usr = JSON.parse(user);
    }
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (USER_TOKEN === undefined) {
            navigate("/");
        } else {
            fetchInvoice();
        }
    }, [encrypt_id]);

    const fetchInvoice = async () => {
        let res = await invoiceAPI(USER_TOKEN, encrypt_id);
        if (res?.is_success) {
            SetDetail(res?.order);
        } else {
            navigate("/");
        }
    };

    return (
        <div>
            <HeaderComponent />
            <BreadCumb name={"Invoice"} list={list} />
            <section className="test-area pt-70 pb-70">
                <div className="container">
                    <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
                        <div
                            className="shape bg-dot primary rellax w-16 h-18"
                            data-rellax-speed="1"
                            style={{ top: "-4rem", right: "-2.4rem" }}
                        ></div>
                        <div
                            className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
                            data-rellax-speed="1"
                            style={{ bottom: "0.5rem", left: "-2.5rem" }}
                        ></div>
                        <div className="row">
                            <div className="col-md-12 mt-8">
                                <main className="box-shadow">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <strong>Date:</strong> <Moment
                                                tz="Asia/Kolkata"
                                                format="DD/MM/YYYY"
                                            >
                                                {detail?.created_at}
                                            </Moment>
                                        </div>
                                        <div className="col-sm-6 text-sm-right">
                                            {" "}
                                            {detail?.invoice_no && (
                                                <div>
                                                    <strong>Invoice No:</strong> {detail?.invoice_no}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-6 text-sm-right order-sm-1">
                                            {" "}
                                            <strong>User:</strong>
                                            <p>
                                                {usr?.name}
                                                <br />
                                                {usr?.phone}
                                                <br />
                                                {usr?.email}
                                                <br />
                                                {detail?.user?.address1 != null ?? detail?.user?.address1}
                                                <br />
                                                {detail?.user?.citydetail?.name}
                                                <br />
                                                {detail?.user?.state?.name}
                                            </p>
                                        </div>
                                        <div className="col-sm-6 order-sm-0">
                                            {" "}
                                            <strong>Company:</strong>
                                            <p>
                                                {setting?.name}
                                                <br />
                                                {setting?.address}
                                                <br />
                                                {setting?.phone}
                                                <br />
                                                {setting?.email}
                                                <br />
                                                <p className="text-dark mb-0">GST No:33AAHCV5601N1Z5</p>
                                                <p className="text-dark ">SAC CODE: 999352</p>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header px-2 py-0">
                                            <table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <td colSpan="4">
                                                            <strong>Item Details</strong>
                                                        </td>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="card-body px-2 no-border">
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr className="">
                                                            <td>
                                                                <strong>Package Name</strong>
                                                            </td>
                                                            <td colSpan="5">{detail?.sub_category?.title} ({detail?.packagedetail?.name} )</td>
                                                        </tr>
                                                        <tr className="bg-light">
                                                            <td>Payment Method</td>
                                                            <td>Taxable Amount</td>
                                                            <td>CGST(9%)</td>
                                                            <td>SGST(9%)</td>
                                                            <td>IGST(18%)</td>
                                                            <td>Total</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Razorpay</td>
                                                            <td>Rs.{detail?.taxableamount}</td>
                                                            <td>Rs.{detail?.cgst}</td>
                                                            <td>Rs.{detail?.sgst}</td>
                                                            <td>Rs.{detail?.igst}</td>
                                                            <td>Rs.{detail?.amount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong>Amount In words </strong>
                                                            </td>
                                                            <td colSpan="5">{detail?.amountinwords}</td>
                                                        </tr>
                                                        {/*      <tr className="bg-light font-weight-bold">
                              <td className="bg-light-2 ">
                                <strong>Amount In words </strong>
                              </td>
                              <td colSpan="4" className="bg-light-2 ">
                                Rs.{detail?.amount}
                              </td>
                            </tr>
                            {detail?.amount !== "0.00" && (
                              <tr>
                                <td className="bg-light-2">
                                  <strong>Amount In words </strong>
                                </td>
                                <td colSpan="4" className="bg-light-2">
                                  {detail?.amountinwords}
                                </td>
                              </tr>
                            )}
                            {detail?.amount !== "0.00" && (
                              <tr>
                                <td className="bg-light-2">
                                  <strong>Payment Method </strong>
                                </td>
                                <td
                                  colSpan="4"
                                  className="bg-light-2 text-uppercase"
                                >
                                  razorpay
                                </td>
                              </tr>
                            )} */}
                                                    </tbody>
                                                </table>
                                                <p>*This is computer generated bill</p>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Invoice;
