import HeaderComponent from "../layouts/HeaderComponent";
import React, { useEffect, useState } from "react";
import AgoraUIKit, { layout } from "agora-react-uikit";
import { Link, useParams, useNavigate } from "react-router-dom";
import "agora-react-uikit/dist/index.css";
import Cookies from "js-cookie";
import { generateRTCTokenAPI } from "../Service";

function VideoPlatform() {
  let USER_TOKEN = Cookies.get("usr_token");
  let user = Cookies.get("user");
  const [videocall, setVideocall] = useState(false);
  const [isHost, setHost] = useState(false);
  const [isPinned, setPinned] = useState(false);
  const [username, setUsername] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [counsellingDetail, SetCounsellingDetail] = useState({});
  const [CounsellingType, SetCounsellingType] = useState(0);
  const [channel, setChannel] = useState("");
  const [token, setToken] = useState("");
  const [uid, SetUid] = useState(0);
  let { encrypt_id } = useParams();
  const [role, setRole] = useState("host");

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (USER_TOKEN === undefined || user === undefined) {
      navigate("/");
    } else {
      let usr = JSON.parse(Cookies.get("user"));
      setUserDetails(usr);
      if (usr?.type === 2) {
        setHost(true);
        setPinned(true);
      }
      setUsername(usr?.name);
      fetchChannelDetails();
    }
  }, []);

  const fetchChannelDetails = async () => {
    let res = await generateRTCTokenAPI(USER_TOKEN, encrypt_id);
    if (res?.is_success) {
      SetCounsellingDetail(res?.counselling);
      setChannel(res?.counselling?.agora_channel);
      setToken(res?.token);
      setRole(res?.role);
      if (res?.counselling?.agora_channel !== "" && res?.token !== "") {
        let usr = JSON.parse(Cookies.get("user"));
        if (usr?.type === 1) {
          SetUid(res?.counselling?.student_id);
        } else {
          SetUid(res?.counselling?.counsellor_id);
        }
      }
      if (res?.counselling_type === 1) {
        setHost(true);
      }
      if (
        res?.counselling?.agora_channel !== "" &&
        res?.counselling?.agora_token !== ""
      ) {
        //setVideocall(true)
      }
    }
  };

  useEffect(() => {
    /*  console.log('role',role)
    console.log('userDetails',userDetails)
    console.log('channel',channel)
    console.log('token',token) */
  }, [role, userDetails, channel, token]);

  const joinNow = async () => {
    setVideocall(true);
  };

  return (
    <>
      <HeaderComponent />
      <div style={styles.container}>
        <div style={styles.videoContainer}>
          <h1 style={styles.heading} className="mt-2 mb-2">
            {CounsellingType === 1 && <>One To one Counselling</>}
          </h1>
          {videocall ? (
            <>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-8">
                    <h1>
                      One To One Counselling : {counsellingDetail?.counsellor?.name}{" "}
                      &amp; {counsellingDetail?.student?.name}{" "}
                    </h1>
                  </div>
                  <div className="col-md-4">
                    <button
                      className="btn btn-sm btn-primary mb-3"
                      onClick={() => setPinned(!isPinned)}
                    >
                      Change Layout
                    </button>
                  </div>
                </div>


              </div>

              <AgoraUIKit
                rtcProps={{
                  appId: process.env.REACT_APP_AGORA_KEY,
                  channel: channel,
                  token: token, //add your token if using app in secured mode
                  role: role,
                  layout: isPinned ? layout.pin : layout.grid,
                  uid: uid,
                }}
                rtmProps={{
                  username: username || "user",
                  displayUsername: true,
                }}
                callbacks={{
                  EndCall: () => setVideocall(false),
                }}
                styleProps={{
                  localBtnContainer: { backgroundColor: "#54a8c7" },
                }}
              />
            </>
          ) : (
            <>
              <section className="wrapper bg-dark">
                <div className="container py-12 py-md-16 text-center">
                  <div className="row">
                    <div className="col-md-9 col-lg-7 col-xl-7 mx-auto text-center bg-dark">
                      {/*  <h2 className="display-4 mb-3">Join Our Community</h2>
                      <p className="lead fs-lg mb-6 px-xl-10 px-xxl-15">
                        We are trusted by over 5000+ clients. Join them by using
                        our services and grow your business.
                      </p> */}
                      <button onClick={joinNow} className="btn btn-primary rounded">
                        Start Meeting
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              {/* <div style={styles.nav}>
              <h3 style={styles.btn} >
                Start Session
              </h3>
            </div> */}
            </>
          )}
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    width: "95vw",
    height: "100vh",
    display: "flex",
    flex: 1,
    backgroundColor: "#fff",
    marginLeft: "40px",
  },
  heading: { textAlign: "center", marginBottom: 0 },
  videoContainer: { display: "flex", flexDirection: "column", flex: 1 },
  nav: { display: "flex", justifyContent: "space-around" },
  btn: {
    backgroundColor: "#54a8c7",
    cursor: "pointer",
    borderRadius: 5,
    padding: "4px 8px",
    color: "#ffffff",
    fontSize: 20,
  },
  input: { display: "flex", height: 24, alignSelf: "center" },
};

export default VideoPlatform;
