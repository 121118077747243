import axios from "axios";
import { data } from "jquery";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const RegisterAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "register",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const LoginAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "login",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const LogoutAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "logout",
      data: data,
      headers: {
        authorization: `Bearer ${data?.access_token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const GenerateOTPAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "otp-generate",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const VerifyOTPAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "otp-verify",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const UpdatePasswordAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "reset-password",
      data: data,
      headers: {
        authorization: `Bearer ${data?.access_token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const GoogleLoginAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "google-login",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const ForgetPasswordAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "forget-password",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const ResetPasswordAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "reset-forget-password",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const CheckResetPasswordLinkAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "check-reset-password-link",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const FacebookLoginAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "facebook-login",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const ContactUsAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: "contact-us/store",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const BlogListAPI = async (data, tag) => {
  try {
    let related_tag = (tag !== undefined) ? tag : ''
    const config = {
      method: "get",
      url: `blog?page=${data}&tag=${related_tag}`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const BlogDetailAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `blog/${data}`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const LibraryAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: "library",
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const ScCategoriesAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `library/scategories/${data?.id}`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const CLSecondaryCategoryAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `library/category/${data}`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const CareerLibraryAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `library/${data}`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const FAQAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `faqs`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const getPageAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `${data}`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const CounsellorsAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: "counsellors",
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const onlineRegImage = async (data) => {
  try {
    const config = {
      method: "get",
      url: "onlineRegImage",
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const CounsellorsDetailAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `counsellors/${data}`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const ExpertsAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: "experts",
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const ExpertsDetailAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `experts/${data}`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const GalleryAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `gallery`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const GalleryItemAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `gallery/${data}`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const LandingPageAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `landing-page`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetStatesAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `get-states`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const storeLookHereAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: `enquiry`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const OnlineRegApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `addOnlineReg`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetCityAPI = async (data) => {
  try {
    const config = {
      method: "get",
      url: `city/${data}`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateProfileAPI = async (data, token) => {
  try {
    const config = {
      method: "post",
      url: `profile-update`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetAssessmentsAPI = async (token) => {
  try {
    if (token !== null && token !== undefined) {
      const config = {
        method: "get",
        url: `assessments`,
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios(config);
      return resp.data;
    } else {
      const config = {
        method: "get",
        url: `assessments`,
      };
      const resp = await axios(config);
      return resp.data;

    }
  } catch (err) {
    console.log(err);
  }

};

export const GetAssessmentsAPI_indianprocess = async (token) => {
  try {
    if (token !== null && token !== undefined) {
      const config = {
        method: "get",
        url: `brokerAssessments/indianexpress`,
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios(config);
      return resp.data;
    } else {
      const config = {
        method: "get",
        url: `brokerAssessments/indianexpress`,
      };
      const resp = await axios(config);
      return resp.data;

    }
  } catch (err) {
    console.log(err);
  }

};
export const checkBrokerUserExists = async (data) => {
  try {
    const config = {
      method: "get",
      url: `verifyToken/${data}`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }

};

export const GetBrokerAssessmentsAPI = async (token, broker_id) => {
  try {
    if (token !== null && token !== undefined) {
      const config = {
        method: "get",
        url: `brokerAssessments/${broker_id}`,
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios(config);
      return resp.data;

    } else {
      const config = {
        method: "get",
        url: `brokerAssessments/${broker_id}`,
      };
      const resp = await axios(config);
      return resp.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const GetAssessmentDetailAPI = async (data, access_token) => {
  try {
    if (access_token !== undefined && access_token !== null) {
      const config = {
        method: "get",
        url: `assessments/${data}`,
        headers: {
          authorization: `Bearer ${access_token}`,
        },
      };
      const resp = await axios(config);
      return resp.data;
    } else {
      const config = {
        method: "get",
        url: `assessments/${data}`,
      };
      const resp = await axios(config);
      return resp.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getTestQuestionsAPI = async (data, token) => {
  try {
    const config = {
      method: "get",
      url: `assessments/test/${data}`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};
export const updateBrokerStudentPackage = async (data) => {
  try {
    const config = {
      method: "post",
      url: `createPackageBrokerUsers`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateBrokerUser = async (data) => {
  try {
    const config = {
      method: "post",
      url: `updateBrokerUsers`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const StoreAssessmentTestAPI = async (data, token) => {
  try {
    const config = {
      method: "post",
      url: `assessments/store`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const GetGOVAssessmentDetailAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: `gov-assessments/verify-code`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const verifyGOVAssessmentCodeAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: `gov-assessments/verify-code`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const getGOVAssessmentDetailAPI = async (data, token) => {
  try {
    const config = {
      method: "get",
      url: `gov-assessments/${data}`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const getGOVTestQuestionsAPI = async (data, token) => {
  try {
    const config = {
      method: "get",
      url: `gov-assessments/test/${data?.test_id}/${data?.assessment_id}/${data?.language}`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const checkLanguageQuestionsAPI = async (data, token) => {
  try {
    const config = {
      method: "get",
      url: `gov-assessments/language-questions/${data?.slug}/${data?.language}`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const StoreGOVAssessmentTestAPI = async (data, token) => {
  try {
    const config = {
      method: "post",
      url: `gov-assessments/store`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProductsAPI = async (data, token) => {
  try {
    const config = {
      method: "get",
      url: `products`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProductsListAPI = async (data, token) => {
  try {
    if (token === undefined || token === null) {
      const config = {
        method: "get",
        url: `products/${data}`,
        data: data,
      };
      const resp = await axios(config);
      return resp.data;
    } else {
      const config = {
        method: "get",
        url: `products/${data}`,
        data: data,
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      const resp = await axios(config);
      return resp.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const createOrderAPI = async (data, token) => {
  try {
    const config = {
      method: "post",
      url: `order/create`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateOrderAPI = async (data, token) => {
  try {
    const config = {
      method: "post",
      url: `order/update`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchMyOrdersAPI = async (token, pageNumber) => {
  try {
    const config = {
      method: "get",
      url: `my-orders?page=${pageNumber}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchMySubscriptionsAPI = async (token, pageNumber) => {
  try {
    const config = {
      method: "get",
      url: `my-subscriptions?page=${pageNumber}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const checkCounsellingAPI = async (token) => {
  try {
    const config = {
      method: "get",
      url: `check-counselling`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const storeCounsellingAPI = async (data, token) => {
  try {
    const config = {
      method: "post",
      url: `student-counselling`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const getCounsellingListAPI = async (token) => {
  try {
    const config = {
      method: "get",
      url: `student-counselling`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const cancelMeetingAPI = async (data, token) => {
  try {
    const config = {
      method: "post",
      url: `meeting/cancel`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const meetingDetailsAPI = async (encrypt_id, token) => {
  try {
    const config = {
      method: "get",
      url: `meeting/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const studentRecordsStoreAPI = async (token, data) => {
  try {
    const config = {
      method: "post",
      url: `/student-detail/store`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const assesmentReportDownloadAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/assessment/report/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const ReportDownloadAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/report/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const GovReportDownloadAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/gov-report/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const counsellingStatusUpdateAPI = async (token, encrypt_id, data) => {
  try {
    const config = {
      method: "post",
      url: `/assessment/status/${encrypt_id}`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchActionPlanLibraryAPI = async () => {
  try {
    const config = {
      method: "get",
      url: `/career-library`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const storeActionPlanAPI = async (token, data) => {
  try {
    const config = {
      method: "post",
      url: `/action-plan`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const storeStudentRequestQueryAPI = async (token, data) => {
  try {
    const config = {
      method: "post",
      url: `/student-request-query/store`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const storeCounsellorQueryResponseAPI = async (token, data) => {
  try {
    const config = {
      method: "post",
      url: `/counsellor-response/store`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateActionPlanAPI = async (token, actionPlanEncryptId, data) => {
  try {
    const config = {
      method: "post",
      url: `/action-plan/update/${actionPlanEncryptId}`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const myReportAPI = async (token) => {
  try {
    const config = {
      method: "get",
      url: `/my-report`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const actionPlanDownloadAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/actionplan/download/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const feedbackStoreAPI = async (token, data) => {
  try {
    const config = {
      method: "post",
      url: `/feedback/store`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const checkCouponAPI = async (token, data) => {
  try {
    const config = {
      method: "post",
      url: `/check-coupoun`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};


export const invoiceAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/invoice/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};


export const groupCounsellingeAPI = async (token) => {
  try {
    const config = {
      method: "get",
      url: `/student-group-counselling`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};


export const groupCounsellingMeetingDetailsAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/group-meeting/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const testimonialsAPI = async () => {
  try {
    const config = {
      method: "get",
      url: `/testimonial`,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const groupCounsellingStatusUpdateAPI = async (token, encrypt_id, data) => {
  try {
    const config = {
      method: "post",
      url: `/group-assessment/status/${encrypt_id}`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};


export const storeGroupCounsellingActionPlanAPI = async (token, data) => {
  try {
    const config = {
      method: "post",
      url: `/student-group-counselling/store`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const storeGroupCounsellingActionPlanDownloadAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/student-group-counselling/actionplan/download/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const generateRTCTokenAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/generate-rtc-token/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const generateGroupRTCTokenAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/group/generate-rtc-token/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const groupPricingEnquiryStoreApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `/group-pricing-enquiry/store`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const careerLibrarySearchAPI = async (data) => {
  try {
    const config = {
      method: "post",
      url: `/career-library/search`,
      data: data,
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const orderinvoiceDownloadAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/invoice/download/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const getActionPlanDataAPI = async (token, encrypt_id) => {
  try {
    const config = {
      method: "get",
      url: `/edit/actioplan/${encrypt_id}`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const usersWatchedVideoCountAPI = async (token) => {
  try {
    const config = {
      method: "get",
      url: `/user-watched-video-count`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const storeWatchedVideoCountAPI = async (token, data) => {
  try {
    const config = {
      method: "post",
      url: `/store-watched-video-count`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};

export const getLastSubscriptionPackageAPI = async (token) => {
  try {
    const config = {
      method: "post",
      url: `/last-subscription`,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
};



