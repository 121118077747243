import AssessmentNavbar from "./Assessment/Navbar";
import { generateRTCTokenAPI } from "../Service";
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import HeaderComponent from "../layouts/HeaderComponent";
import AgoraRTC from "agora-rtc-sdk-ng";
import { Icon } from "@iconify/react";
import { muteVideo } from "agora-react-uikit";

function VideoCall() {
  let USER_TOKEN = Cookies.get("usr_token");
  let user = Cookies.get("user");
  const [isHost, setHost] = useState(false);
  const [isPinned, setPinned] = useState(false);
  const [username, setUsername] = useState("");
  let { encrypt_id } = useParams();
  const [channel, setChannel] = useState("");
  const [role, setRole] = useState("host");
  const [token, setToken] = useState("");
  const [videocall, setVideocall] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [audioTrack, setAudioTrack] = useState({});
  const [videoTrack, setVideoTrack] = useState({});
  const [screenTrack, setScreenTrack] = useState({});
  const navigate = useNavigate();
  const [mutedAudio, setMutedAudio] = useState(false);
  const [mutedVideo, setMutedVideo] = useState(false);
  const [screenShare, setScreenShare] = useState(false);
  const [client, SetClient] = useState({});
  const [uid, SetUid] = useState("");

  var rtc = {
    // For the local audio and video tracks.
    client: null,
    localAudioTrack: null,
    localVideoTrack: null,
    localScreenTrack: null,
  };

  var options = {
    appId: "c3f88b4c6b404b549252b05730f19b8f",
    channel: channel,
    role: role,
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (USER_TOKEN === undefined || user === undefined) {
      navigate("/");
    } else {
      let usr = JSON.parse(Cookies.get("user"));
      setUserDetails(usr);
      setUsername(usr?.name);
      fetchChannelDetails();
    }
  }, []);

  const fetchChannelDetails = async () => {
    let res = await generateRTCTokenAPI(USER_TOKEN, encrypt_id);
    if (res?.is_success) {
      setChannel(res?.counselling?.agora_channel);
      setToken(res?.token);
      setRole(res?.role);
      if (res?.counselling?.agora_channel !== "" && res?.token !== "") {
        let usr = JSON.parse(Cookies.get("user"));
        if (usr?.type === 1) {
          SetUid(res?.counselling?.student_id);
        } else {
          SetUid(res?.counselling?.counsellor_id);
        }
      }
    }
  };

  useEffect(() => {
    /*  console.log('role',role)
    console.log('userDetails',userDetails)
    console.log('channel',channel)
    console.log('token',token) */
  }, [role, userDetails, channel, token]);

  const startCall = async () => {
    rtc.client = await AgoraRTC.createClient({ mode: "live", codec: "vp8" });
    rtc.client.setClientRole(role);
    await rtc.client.join(options.appId, channel, token, uid);
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    setAudioTrack(rtc.localAudioTrack);
    rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    setVideoTrack(rtc.localVideoTrack);
    await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
    const localPlayerContainer = document.createElement("div");
    localPlayerContainer.id = uid;
    localPlayerContainer.style.width = "640px";
    localPlayerContainer.style.height = "480px";
    document
      .getElementById("user-video-cotainer")
      .appendChild(localPlayerContainer);
    rtc.localVideoTrack.play(localPlayerContainer);
    rtc.localAudioTrack.play(localPlayerContainer);

    rtc.client.on("user-published", async (user, mediaType) => {
      await rtc.client.subscribe(user, mediaType);
      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        const remotePlayerContainer = document.createElement("div");
        remotePlayerContainer.textContent =
          "Remote user " + user.uid.toString();
        remotePlayerContainer.style.width = "640px";
        remotePlayerContainer.style.height = "480px";

        document
          .getElementById("remote-user-video-cotainer")
          .appendChild(remotePlayerContainer);
        remoteVideoTrack.play(remotePlayerContainer);
      }

      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }

      rtc.client.on("user-unpublished", (user) => {
        const remotePlayerContainer = document.getElementById(user.uid);
        remotePlayerContainer.remove();
      });
    });

    SetClient(rtc.client);
  };


  const unMuteAudio = async (e) => {
    if (mutedAudio) {
      setMutedAudio(false);
      audioTrack.setMuted(false);
    } else {
      setMutedAudio(true);
      audioTrack.setMuted(true);
    }
  };

  const unMuteVideo = async (e) => {
    if (mutedVideo) {
      setMutedVideo(false);
      videoTrack.setMuted(false);
    } else {
      setMutedVideo(true);
      videoTrack.setMuted(true);
    }
  };

  const scshare = async (e) => {
    if (screenShare) {
      setScreenShare(false);
      const playerContainer = document.getElementById("screen-share" + uid);
      playerContainer && playerContainer.remove();
      screenTrack.close();
    } else {
      setScreenShare(true);
      let localScreenTrack = await AgoraRTC.createScreenVideoTrack();
      setScreenTrack(localScreenTrack);
      client.publish([audioTrack, videoTrack, localScreenTrack]);
      const localPlayerContainer = document.createElement("div");
      localPlayerContainer.id = "screen-share" + uid;
      localPlayerContainer.style.width = "640px";
      localPlayerContainer.style.height = "480px";
      document
        .getElementById("user-screen-share-cotainer")
        .appendChild(localPlayerContainer);
      localScreenTrack.play(localPlayerContainer);
    }
  };

  const leaveCall = async () => {
    // Destroy the local audio and video tracks.
    audioTrack.close();
    videoTrack.close();

    if (userDetails?.type === 2) {
      // Traverse all remote users.
      client.remoteUsers.forEach((user) => {
        // Destroy the dynamically created DIV container.
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });
    }

    console.log("client", client);
    // Leave the channel.
    await client.leave();
    setVideocall(false);
  };

  const joinNow = async () => {
    setVideocall(true);
    startCall();
  };

  useEffect(() => {
    if (Object.keys(screenTrack).length !== 0) {
      screenTrack.on("track-ended", () => {
        const playerContainer = document.getElementById("screen-share" + uid);
        playerContainer && playerContainer.remove();
      });
    }
  }, [screenTrack]);

  useEffect(() => {
    console.log(client);
  }, [client]);

  return (
    <>
      <HeaderComponent />
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          {videocall ? (
            <>
              <div className="row">
                <div className="col-1">
                  <button
                    className="bnt btn-sm btn-primary"
                    onClick={unMuteAudio}
                  >
                    {mutedAudio ? (
                      <Icon icon="bi:mic-mute-fill" width="30" height="30" />
                    ) : (
                      <Icon icon="bi:mic-fill" width="30" height="30" />
                    )}
                  </button>
                </div>
                <div className="col-1">
                  <button
                    className="bnt btn-sm btn-primary"
                    onClick={unMuteVideo}
                  >
                    {mutedVideo ? (
                      <Icon icon="bi:camera-video-off" width="30" height="30" />
                    ) : (
                      <Icon icon="bi:camera-video" width="30" height="30" />
                    )}
                  </button>
                </div>

                <div className="col-1">
                  <button className="bnt btn-sm btn-primary" onClick={scshare}>
                    {screenShare ? (
                      <Icon icon="bi:pause" width="30" height="30" />
                    ) : (
                      <Icon
                        icon="fluent:share-screen-start-24-regular"
                        width="30"
                        height="30"
                      />
                    )}
                  </button>
                </div>

                <div className="col-1">
                  <button className="bnt btn-sm btn-danger" onClick={leaveCall}>
                    <Icon
                      icon="fluent:call-end-24-regular"
                      width="30"
                      height="30"
                    />
                  </button>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <div id="user-video-cotainer"></div>
                </div>
                {/*  <div className="col-4">
                  <div id="user-screen-share-cotainer"></div>
                </div> */}
                <div className="col-6">
                  <div id="remote-user-video-cotainer"></div>
                </div>
              </div>
            </>
          ) : (
            <>
              <button className="btn btn-primary btn-sm" onClick={joinNow}>
                Join Now
              </button>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default VideoCall;
