import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import Sidebar from "../../layouts/Sidebar";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import { fetchMyOrdersAPI } from "../../Service";
import Pagination from "react-js-pagination";
import "moment-timezone";
import Moment from "react-moment";
import { Icon } from "@iconify/react";
import BackToTop from "../../layouts/BackToTop"
import Seo from "../../layouts/SEO";
import {
  orderinvoiceDownloadAPI,
} from "../../Service";

function Dashboard() {
  const [OrdersList, SetOrdersList] = useState([]);
  const [state, SetState] = useState({});
  const [showScroll, setShowScroll] = useState(false);

  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);

  const list = [
    { name: "Home", url: "/" },
    { name: "Dashboard", url: null },
  ];
  let USER_TOKEN = Cookies.get("usr_token");
  let user = Cookies.get("user");
  let usr = JSON.parse(Cookies.get("user"));
  const navigate = useNavigate();
  let MetaDetails = {
    'title': 'Dashboard | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Dashboard | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Dashboard Mere Mentor',
    'meta_description': 'Mere Mentor is an EdTech organization delivering career counselling services to the students and youth of the country.',
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (USER_TOKEN === undefined || user === undefined) {
      navigate("/");
    } else {
      if (usr?.type === 1) {
        fetchMyOrders();
      }
    }
  }, []);

  const fetchMyOrders = async (pageNumber = 1) => {
    let res = await fetchMyOrdersAPI(USER_TOKEN, pageNumber);
    if (res?.is_success === true) {
      SetState(res?.orders);
      SetOrdersList(res?.orders?.data);
    }
  };

  const download = async (e, id) => {
    let res = await orderinvoiceDownloadAPI(USER_TOKEN, id);
    if (res?.is_success) {
      const linkSource = `data:application/pdf;base64,${res?.file}`;
      const downloadLink = document.createElement("a");
      const fileName = res?.filename;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      SetAlertDisplay(true);
      SetAlertMessage([res?.errors]);
    }
  };

  const subscriptionUpgrade = async () => {
    if ((usr?.phone == 'null' || usr?.phone == null) || (usr?.email == 'null' || usr?.email == null) || (usr?.state_id == 'null' || usr?.state_id == null)) {
      navigate("/profile?is_message=Please Update Your Profile to Proceed");
    } else {
      navigate("/products");
    }
  };

  const assessmentLink = async () => {
    if ((usr?.phone == 'null' || usr?.phone == null) || (usr?.email == 'null' || usr?.email == null) || (usr?.state_id == 'null' || usr?.state_id == null)) {
      navigate("/profile?is_message=Please Update Your Profile to Proceed");
    } else {
      navigate("/assessments");
    }
  };

  return (
    <div>
      <HeaderComponent />
      <Seo options={MetaDetails} />
      <BreadCumb name={"Dashboard"} list={list} />
      <section className="myprofile-section ptb-50">
        <div className="container">
          <div className="pricing-wrapper position-relative mt-n18 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row justify-content-between main-area">
              <div className="col-lg-3">
                <div className="myprofile-sidebar rounded-3">
                  <div className="card">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="myprofile-content rounded-3 p-5">
                  <div className="section-header">
                    <span className="section-subtitle">Hi, {usr?.name}</span>
                    <h3 className="section-title">Welcome to Mere Mentor</h3>
                  </div>
                  <div className="dashboard-quick-widget">
                    {usr?.type === 1 && (
                      <div className="row mt-4">
                        <div className="col-md-6">
                          {/* <div className="card text-white bg-danger">
                            <div className="card-body text-center">
                              <Link to="/products">
                                <h3 className="card-title text-white">
                                  <i className="bx bxs-cart"></i> SUBSCRIBE NOW
                                </h3>
                              </Link>
                            </div>
                          </div> */}
                          <div className="d-grid gap-1 pb-2">
                            {/* <Link to="/products" className="btn btn-danger btn-lg">
                             
                              <Icon icon="ant-design:shopping-cart-outlined" width="30" height="30"  /> 
                              &nbsp;&nbsp;
                              SUBSCRIBE/UPGRADE
                             
                            </Link> */}
                            <button className="btn btn-danger btn-lg"
                              onClick={(e) => {
                                subscriptionUpgrade();
                              }}
                            >

                              <Icon icon="ant-design:shopping-cart-outlined" width="30" height="30" />
                              &nbsp;&nbsp;
                              SUBSCRIBE/UPGRADE

                            </button>
                          </div>
                        </div>

                        <div className="col-md-6">
                          {/*  <div className="card text-white bg-success">
                            <div className="card-body text-center">
                              <Link to="/assessments">
                                <h3 className="card-title text-white">
                                  <i className="bx bxs-edit"></i> TAKE
                                  ASSESSMENT
                                </h3>
                              </Link>
                            </div>
                          </div> */}
                          <div className="d-grid gap-1 pb-2">
                            {/* <Link to="/assessments" className="btn btn-success btn-lg"> */}
                            <button className="btn btn-success btn-lg"
                              onClick={(e) => {
                                assessmentLink();
                              }}
                            >
                              <Icon icon="bi:pencil-square" width="25" height="25" />&nbsp;&nbsp;TAKE
                              ASSESSMENT

                            </button>
                            {/* </Link> */}
                          </div>
                        </div>
                        {/* <div className="col-md-4">
                          <div className="d-grid gap-1">
                           <Link to="/exam-stress-management" className="btn btn-info btn-lg">
                              <Icon icon="bi:pencil-square" width="25" height="25" />&nbsp;&nbsp;Exam Stress Management
                            </Link>
                          </div>
                        </div> */}
                      </div>
                    )}

                    {usr?.type === 1 && (
                      <div>
                        <h4 className="mt-4">Recent Orders</h4>
                        <div className="table-responsive my-4">
                          <table className="table table-bordered bg-white">
                            <thead>
                              <tr>
                                <th scope="col">Order ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Total</th>
                                <th scope="col">Date</th>
                                <th scope="col">Order Status</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {OrdersList.map((x, i) => (
                                <tr key={i}>
                                  <td>{x?.invoice_no}</td>
                                  <td>
                                    {x?.sub_category?.title}({x?.sub_category?.category?.name} )
                                  </td>
                                  <td>{x?.amount}</td>
                                  <td>
                                    <Moment
                                      tz="Asia/Kolkata"
                                      format="D MMM YYYY"
                                    >
                                      {x.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    {x?.status === 0 && (
                                      <span className="badge bg-danger">
                                        Failed
                                      </span>
                                    )}
                                    {x?.status === 1 && (
                                      <span className="badge bg-success">
                                        Paid
                                      </span>
                                    )}
                                    {x?.status === 2 && (
                                      <span className="badge bg-warning">
                                        Pending
                                      </span>
                                    )}
                                    {x?.status === 3 && (
                                      <span className="badge bg-danger">
                                        Cancelled
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {x?.invoice_report != null && x?.sub_category?.is_free === 1 ? (
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={(e) => {
                                          download(e, x?.encryptid);
                                        }}
                                      >
                                        <Icon
                                          icon="bxs:download"
                                          width="20"
                                          height="20"
                                        />{" "}
                                        Invoice Download
                                      </button>
                                    ) : (<></>)}

                                    {x?.sub_category?.is_free === 1 ? (
                                      <Link to={`/invoice/${x.encryptid}`} className="btn btn-sm btn-primary mt-1" target={`_blank`}>View Invoice</Link>
                                    ) : (
                                      " - "
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <Pagination
                          activePage={
                            state?.current_page ? state?.current_page : 0
                          }
                          itemsCountPerPage={
                            state?.per_page ? state?.per_page : 0
                          }
                          totalItemsCount={state?.total ? state?.total : 0}
                          pageRangeDisplayed={5}
                          onChange={(pageNumber) => {
                            fetchMyOrders(pageNumber);
                          }}
                          itemClass="page-item"
                          linkClass="page-link"
                          className="mt-1"
                        />
                        <p className="float-right">
                          Showing {state?.from ? state?.from : 0}-
                          {state?.to ? state?.to : 0} of{" "}
                          {state?.total ? state?.total : 0} entries
                        </p>

                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default Dashboard;
