import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/aqua.css";
import "../assets/css/fonts/dm.css";
import "../assets/css/fonts/thicccboi.css";
import "../assets/css/autocomplete.css";
import {
  Navbar,
  NavDropdown,
  Nav,
  Container,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Modal, ModalBody } from "react-bootstrap";
import * as Icon from "react-feather";
import validator from "validator";
import {
  RegisterAPI,
  LoginAPI,
  GenerateOTPAPI,
  VerifyOTPAPI,
  UpdatePasswordAPI,
  GoogleLoginAPI,
  ForgetPasswordAPI,
  FacebookLoginAPI,
} from "../Service";
import { useNavigate, Link, useSearchParams, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import AlertComponent from "./Alert";
import Message from "./Message";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleOneTapLogin from "react-google-one-tap-login";
import IndianEpressImage from '../assets/images/IE-education-logo-black.png';

function HeaderComponent() {
  let setting = JSON.parse(localStorage.getItem("setting"));
  let [searchParams, setSearchParams] = useSearchParams();
  let is_login = searchParams.get("is_login");
  let is_message = searchParams.get("is_message");
  let check_login = is_login ? true : false;
  const [login, SetLogin] = useState(check_login);
  const [register, SetRegister] = useState(false);
  const [otpLogin, SetOTPLogin] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [name, SetName] = useState("");
  const [isBroker, setIsBroker] = useState(false);
  const [isDecrypt, setIsDecrypt] = useState('');
  const [email, SetEmail] = useState("");
  const [mobile, SetMobile] = useState("");
  const [password, SetPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const navigate = useNavigate();
  const [MessageDisplay, SetMessageDisplay] = useState(false);
  const [MessageContent, SetMessageContent] = useState([]);
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [otpMobile, SetOTPMobile] = useState(true);
  const [otp, SetOTP] = useState("");
  const [resetPassword, SetResetPassword] = useState(false);
  const [confirmPassword, SetConfirmPassword] = useState("");
  const [forgetPassword, SetForgetPassword] = useState(false);
  const [isOneTapLoginEnabled, SetisOneTapLoginEnabled] = useState(false);
  let USER_TOKEN = Cookies.get("usr_token");
  let usr = Cookies.get("user");
  console.log('////////////', usr, USER_TOKEN);

  if (usr !== undefined) {
    usr = JSON.parse(Cookies.get("user"));
  }
  let GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
  let FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

  useEffect(() => {
    fetchSettingApi();
    let broker = localStorage.getItem('broker');
    let brokerDecrypted = localStorage.getItem('brokerdecryptrd');
    setIsDecrypt(brokerDecrypted);
    setIsBroker(broker);
    if (USER_TOKEN === undefined && is_login) {
      SetAlertDisplay(true);
      SetAlertMessage([is_message]);
    }
    if (location.pathname.split('/')[3] == 'indianexpress' || localStorage.getItem('broker') == 'indianexpress') {
      setIndianEpress(true);
    }
    else {
      setIndianEpress(false);
    }
  }, []);

  const CloseMenu = () => {
    setIsMenuOpen(false);
    document.body.classList.remove('no-scroll');
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const Logout = (res) => {
    localStorage.removeItem('indianexpress');
  }

  const [IndianEpress, setIndianEpress] = useState(false);
  const handleCredentialResponse = (res) => {
    console.log(res);
  };

  const fetchSettingApi = async () => {
    await axios.get("general-settings").then((res) => {
      setting = res.data.setting;
      localStorage.setItem("setting", JSON.stringify(res.data.setting));
    });
  };

  const createAccount = async () => {
    let data = {
      name: name,
      email: email,
      phone: mobile,
      password: password,
    };

    var err = [];
    if (!validator.isLength(name, { min: 3, max: 255 })) {
      err.push("Name should have minimum 3 characters");
    }
    if (!validator.isLength(mobile, { min: 10, max: 10 })) {
      err.push("Mobile Number Must be 10 digits");
    }
    if (!validator.isNumeric(mobile)) {
      err.push("Mobile Number Must be in digits");
    }
    if (email != "" && !validator.isEmail(email)) {
      err.push("Enter a Valid Email");
    }
    if (!validator.isLength(password, { min: 8, max: 255 })) {
      err.push("Enter a Valid Password");
    }

    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      if (email == "") {
        delete data.email;
      }
      let res = await RegisterAPI(data);
      if (res?.is_success === true) {
        var usr_session = new Date(new Date().getTime() + 1440 * 60 * 1000);
        Cookies.set("usr_token", res?.usr_token, { expires: usr_session });
        Cookies.set("user", JSON.stringify(res?.user), {
          expires: usr_session,
        });
        SetAlertDisplay(false);
        SetAlertMessage([]);
        SetLogin(false);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        setTimeout(() => {
          navigate("/dashboard");
        }, 2000);
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
      }
    }
  };

  const LoginUsingEmail = async () => {
    let data = {
      email: email,
      password: password,
      phone: email,
    };
    var err = [];
    if (email !== "") {
      if (validator.isNumeric(email)) {
        if (!validator.isLength(email, { min: 10, max: 10 })) {
          err.push("Enter a Valid Mobile Number");
        }
      } else if (!validator.isEmail(email)) {
        err.push("Enter a Valid Email");
      }
    } else {
      err.push("Phone Number (or) Email is required");
    }

    if (!validator.isLength(password, { min: 8, max: 255 })) {
      err.push("Enter a Valid Password");
    }

    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      if (email == "") {
        delete data.email;
      }
      if (mobile == "") {
        delete data.phone;
      }
      let res = await LoginAPI(data);
      if (res?.is_success === true) {
        var usr_session = new Date(new Date().getTime() + 1440 * 60 * 1000);
        Cookies.set("usr_token", res?.usr_token, { expires: usr_session });
        Cookies.set("user", JSON.stringify(res?.user), {
          expires: usr_session,
        });
        SetisOneTapLoginEnabled(false);
        SetLogin(false);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        navigate("/dashboard");
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
      }
    }
  };

  const LoginUsingOTP = async () => {
    let data = {
      phone: mobile,
    };
    let err = [];

    if (mobile === "") {
      err.push("Mobile Number is required");
    }

    if (
      mobile != "" &&
      !validator.isLength(mobile, { min: 10, max: 10 }) &&
      !validator.isNumeric(mobile)
    ) {
      err.push("Enter Valid Mobile Number");
    }

    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      let res = await GenerateOTPAPI(data);
      if (res?.is_success === true) {
        SetOTPMobile(false);
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
      }
    }
  };

  const VerifyOTP = async (e) => {
    let data = {
      phone: mobile,
      otp: otp,
    };

    let err = [];
    if (!validator.isLength(mobile, { min: 10, max: 10 })) {
      err.push("Mobile Number Must be 10 digits");
    }
    if (!validator.isNumeric(mobile)) {
      err.push("Mobile Number Must be in digits");
    }
    if (!validator.isNumeric(otp)) {
      err.push("OTP Must be in digits");
    }
    if (!validator.isLength(otp, { min: 6, max: 6 })) {
      err.push("OTP should be 6 digits only");
    }
    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      let res = await VerifyOTPAPI(data);
      if (res?.is_success === true) {
        var usr_session = new Date(new Date().getTime() + 1440 * 60 * 1000);
        Cookies.set("usr_token", res?.usr_token, { expires: usr_session });
        Cookies.set("user", JSON.stringify(res?.usr), {
          expires: usr_session,
        });
        SetOTPLogin(false);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        navigate("/dashboard");
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
      }
    }
  };

  const updatePassword = async (e) => {
    let data = {
      password: password,
      confirm_password: confirmPassword,
      access_token: USER_TOKEN,
    };
    let err = [];
    if (!validator.isLength(password, { min: 8, max: 255 })) {
      err.push("Enter a Valid Password");
    }
    if (!validator.equals(confirmPassword, password)) {
      err.push("Password and Confirm Password Doesn't match");
    }

    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      let res = await UpdatePasswordAPI(data);
      if (res?.is_success === true) {
        SetResetPassword(false);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
      }
    }
  };

  const ForgetPasswordLink = async (e) => {
    let data = {
      email: email,
    };

    var err = [];

    if (email != "" && !validator.isEmail(email)) {
      err.push("Enter a Valid Email");
    }

    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      let res = await ForgetPasswordAPI(data);

      if (res?.is_success === true) {
        SetForgetPassword(false);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
      }
    }
  };

  const onSuccessGoogleResponse = async (response) => {
    let data = {
      google_id: response.googleId,
      email: response.profileObj.email,
      name: response.profileObj.name,
      imageUrl: response.profileObj.imageUrl,
    };
    let res = await GoogleLoginAPI(data);
    if (res?.is_success === true) {
      var usr_session = new Date(new Date().getTime() + 1440 * 60 * 1000);
      Cookies.set("usr_token", res?.usr_token, { expires: usr_session });
      Cookies.set("user", JSON.stringify(res?.user), { expires: usr_session });
      SetLogin(false);
      SetMessageDisplay(true);
      SetMessageContent(res?.message);
      navigate("/dashboard");
    } else {
      SetAlertDisplay(true);
      SetAlertMessage(res?.errors ?? []);
    }
  };

  const onFailureGoogleResponse = (response) => {
    console.log(response);
  };

  const onSuccessFacebookResponse = async (response) => {
    let data = {
      email: response?.email,
      name: response?.name,
      imageUrl: response?.picture?.data?.url,
      facebook_id: response?.userID,
    };
    let res = await FacebookLoginAPI(data);

    if (res?.is_success === true) {
      var usr_session = new Date(new Date().getTime() + 1440 * 60 * 1000);
      Cookies.set("usr_token", res?.usr_token, { expires: usr_session });
      Cookies.set("user", JSON.stringify(res?.user), { expires: usr_session });
      SetLogin(false);
      SetMessageDisplay(true);
      SetMessageContent(res?.message);
      navigate('/dashboard');
    } else {
      console.log(res);
      SetAlertDisplay(true);
      SetAlertMessage(res?.errors ?? []);
    }
  };

  const onFailureFacebookResponse = (response) => {
    console.log(response);
  };

  const handleOneTapSuccessResponse = async (google_res) => {
    let data = {
      google_id: google_res.sub,
      email: google_res.email,
      name: google_res.name,
      imageUrl: google_res.picture,
    };
    let res = await GoogleLoginAPI(data);
    if (res?.is_success === true) {
      var usr_session = new Date(new Date().getTime() + 1440 * 60 * 1000);
      Cookies.set("usr_token", res?.usr_token, { expires: usr_session });
      Cookies.set("user", JSON.stringify(res?.user), { expires: usr_session });
      SetLogin(false);
      SetRegister(false);
      SetOTPLogin(false);
      SetMessageDisplay(true);
      SetMessageContent(res?.message);
      navigate("/dashboard");
    } else {
      SetAlertDisplay(true);
      SetAlertMessage(res?.errors ?? []);
    }
  };
  const [show, setShow] = useState(false);

  const hideDropdown = e => {
    let options = document.querySelectorAll(
      `.dropdown-menu`
    );
    for (let index = 0; index < options.length; index++) {
      const element = options[index];
      if (element.classList.contains("show")) {
        element.classList.remove("show");
      }
    }

    let optionsmenu = document.querySelectorAll(
      `.dropdown-toggle`
    );
    for (let index = 0; index < optionsmenu.length; index++) {
      const element = optionsmenu[index];
      if (element.classList.contains("show")) {
        element.classList.remove("show");
      }
    }
  }

  const [toggle, setToggle] = useState(true);
  const [expandToggle, setExpandToggle] = useState(true);

  const expand = toggle ? "collapse-show" : "collapse";
  const collapsed = toggle ? "collapse-button" : "collapsed collapsed-button";

  const showHideFloatingIcon = (value) => {
    if (value === true) {
      setToggle(false);
      setExpandToggle(false);
    } else {
      setToggle(true);
      setExpandToggle(true);
    }
  }

  const location = useLocation();


  return (
    <div className="content-wrapper">
      <header className="wrapper bg-gray">
        {!IndianEpress &&
          <div class="icon-bar">
            <a href={"tel:" + setting?.phone} data-url={"tel:" + setting?.phone} class={`call ${expand}`}><i class="uil uil-phone-volume"></i></a>
            <a href={"https://api.whatsapp.com/send?phone=" + setting?.whatsapp + "&text=Hello+Mere+Mentor%2C+Help+me+in+my+career+selection+process.+Kindly+contact+me+soon"} target="_blank" rel="noopener" data-url={"https://api.whatsapp.com/send?phone=" + setting?.whatsapp + "&text=Hello+Mere+Mentor%2C+Help+me+in+my+career+selection+process.+Kindly+contact+me+soon"} class={`whatsapp ${expand}`}><i class="uil uil-whatsapp"></i></a>
            <a href={"mailto:" + setting?.email} class={`envelope ${expand}`}><i class="uil uil-envelope "></i></a>
            <a href="#" class={`${collapsed}`} data-bs-toggle="collapse" aria-expanded={expandToggle} onClick={() => {
              showHideFloatingIcon(toggle);
            }}
            ><i class="uil uil-angle-right"></i></a>
          </div>
        }

        {!IndianEpress &&
          <Navbar collapseOnSelect expand="lg" variant="dark" className="fancy">
            <Container>
              <Navbar.Brand href="/">
                <Link to="/">
                  <img className="img-fluid"
                    src={process.env.REACT_APP_BASE_URL + setting?.logo}
                    alt="merementor"
                    style={{ 'height': '35px', width: '100%' }}
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleMenu} />
              <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" in={isMenuOpen}>
                <Nav className="me-auto">
                  <li className="nav-item" onClick={CloseMenu}>
                    <Link className="color nav-link" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item" onClick={CloseMenu}>
                    <Link className="color nav-link" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item" onClick={CloseMenu}>
                    {isBroker ? <Link className="color nav-link" to={`/assessments/broker/${isBroker}/search?student=${isDecrypt}`}>
                      Assessments
                    </Link> : <Link className="color nav-link" to="/assessments">
                      Assessments
                    </Link>}
                  </li>
                  <li className="nav-item">
                    <li className="nav-item dropdown">
                      <li class="nav-link dropdown-toggle" data-bs-toggle="dropdown"
                      >
                        <Link to="#" className="product-menu-nav-link">
                          Products
                        </Link>
                      </li>
                      <ul className="dropdown-menu"
                        onMouseLeave={hideDropdown} style={{ minWidth: "200px" }}
                      >
                        <li className="nav-item" onClick={CloseMenu}>
                          <Link className="dropdown-item product-other-menu-sub-nav-link" to="/products">
                            Products
                          </Link>
                        </li>
                        <li className="nav-item" onClick={CloseMenu}>
                          <Link className="dropdown-item product-other-menu-sub-nav-link" to="/group-pricing">
                            Group Pricing
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </li>
                  <li className="nav-item" onClick={CloseMenu}>
                    <Link className="color nav-link" to="/library">
                      Career Library
                    </Link>
                  </li>
                  <li className="nav-item" onClick={CloseMenu}>
                    <Link className="color nav-link" to="/blog">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item" onClick={CloseMenu}>
                    <Link className="color nav-link" to="/contact-us">
                      Contact
                    </Link>
                  </li>
                  <li>
                  {USER_TOKEN !== undefined ? (<div>
                 <li className="nav-item" onClick={CloseMenu}>
                  <Link className="color nav-link" to="/dashboard">
                  Dashboard
                </Link>
                </li>
                </div>):null}
                <Navbar.Collapse className="justify-content-end ">
            {USER_TOKEN !== undefined ? (
                  <div className="logoutOtherHeader" onClick={CloseMenu}>
                      <Link
                        className="btn btn-sm btn-primary leftSign rounded-pill float-left-sm"
                        to="/logout"
                      >
                        Logout
                      </Link>
                  </div>
                ) : (
                  <div className="login" onClick={CloseMenu}>
                      <button
                        className="btn btn-sm btn-primary rounded-pill leftSign float-left-sm"
                        onClick={() => {
                          SetLogin(true);
                          SetAlertDisplay(false);
                          SetAlertMessage([]);
                          SetisOneTapLoginEnabled(true);
                        }}
                      >
                        Sign In
                      </button>
                  </div>
                )}
              </Navbar.Collapse>
                </li>
                </Nav>
              </Navbar.Collapse>
            
            </Container>
          </Navbar>
        }

        {IndianEpress &&
          <Navbar collapseOnSelect expand="lg" bg="light" variant="dark" className="fancy">
            <Container>
              <img className="img-fluid"
                src={IndianEpressImage}
                alt="merementor"
                style={{ 'width': '200px', 'margin': '10px', height: 'auto' }}
              />
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse className="justify-content-end">
                {USER_TOKEN !== undefined ? (
                  <a to="/logout"
                    className="btn btn-sm btn-primary rounded-pill float-right-sm login"
                    href="https://education.indianexpress.com"
                  >
                    Logout
                  </a>
                ) : (
                  <div className="login">
                    <button
                      className="btn btn-sm btn-primary rounded-pill float-right-sm"
                      onClick={() => {
                        SetLogin(true);
                        SetAlertDisplay(false);
                        SetAlertMessage([]);
                        SetisOneTapLoginEnabled(true);
                      }}
                    >
                      Sign In
                    </button>
                  </div>
                )}
              </Navbar.Collapse>
            </Container>
          </Navbar>
        }
      </header>

      {/* Login With Email */}
      <Modal
        show={login}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          SetLogin(false);
          SetAlertDisplay(false);
          SetAlertMessage([]);
          SetisOneTapLoginEnabled(false);
        }}
      >
        <ModalBody className="login-modal text-center">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              SetLogin(false);
              SetAlertDisplay(false);
              SetAlertMessage([]);
              SetisOneTapLoginEnabled(false);
            }}
          ></button>
          <h3 className="mb-4 text-center">
            Login to {process.env.REACT_APP_NAME}
          </h3>

          <AlertComponent
            AlertDisplay={AlertDisplay}
            SetAlertDisplay={SetAlertDisplay}
            AlertMessage={AlertMessage}
          />

          <div className="text-start mb-3">
            <div className="form-floating mb-4">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <Icon.Mail />
                </span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email/Phone"
                  onChange={(e) => {
                    SetEmail(e.target.value);
                  }}
                  id="loginEmail"
                  required
                />
              </div>
            </div>
            <div className="form-floating mb-4">
              <div className="input-group mb-3">
                <input
                  type={isRevealPwd ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  onChange={(e) => {
                    SetPassword(e.target.value);
                  }}
                  id="loginPassword"
                />
                <span
                  className="input-group-text"
                  onClick={() => {
                    setIsRevealPwd((prevState) => !prevState);
                  }}
                >
                  {isRevealPwd ? <Icon.EyeOff /> : <Icon.Eye />}
                </span>
              </div>
            </div>
            <button
              className="btn btn-primary rounded-pill btn-login w-100 mb-2"
              onClick={(e) => {
                LoginUsingEmail(e);
              }}
            >
              Sign In
            </button>
            <button
              className="btn btn-primary rounded-pill btn-login w-100 mb-2"
              onClick={(e) => {
                SetLogin(false);
                SetRegister(false);
                SetMobile("");
                SetOTPMobile(true);
                SetOTPLogin(true);
                SetisOneTapLoginEnabled(true);
              }}
            >
              Sign In Via OTP
            </button>
          </div>
          <p className="mb-1">
            <a
              href="#"
              className="hover"
              onClick={(e) => {
                SetRegister(SetAlertDisplay);
                SetLogin(false);
                SetForgetPassword(true);
                SetAlertDisplay(false);
                SetAlertMessage([]);
              }}
            >
              Forgot Password?
            </a>
          </p>
          <p className="mb-0">
            Don't have an account?{" "}
            <a
              href="#"
              className="hover"
              onClick={(e) => {
                SetRegister(true);
                SetLogin(false);
                SetAlertDisplay(false);
                SetAlertMessage([]);
              }}
            >
              Sign up
            </a>
          </p>
          <div className="divider-icon my-4">or</div>
          <div id="custom-one-tap-container"></div>

          <nav className="nav social justify-content-center text-center">
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="btn btn-circle btn-sm btn-google"
                >
                  <i className="uil uil-google"></i>
                </button>
              )}
              buttonText="Login"
              onSuccess={onSuccessGoogleResponse}
              onFailure={onFailureGoogleResponse}
              cookiePolicy={"single_host_origin"}
            />
            &nbsp;&nbsp;
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={onSuccessFacebookResponse}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className="btn btn-circle btn-sm btn-facebook-f"
                >
                  <i className="uil uil-facebook-f"></i>
                </button>
              )}
            />
            {/* <a href="#" className="btn btn-circle btn-sm btn-twitter">
              <i className="uil uil-twitter"></i>
            </a> */}
          </nav>
        </ModalBody>
      </Modal>

      {/* Login Via OTP */}
      <Modal
        show={otpLogin}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          SetOTPLogin(false);
          SetAlertDisplay(false);
          SetAlertMessage([]);
          SetisOneTapLoginEnabled(false);
        }}
      >
        <ModalBody className="login-modal text-center">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              SetOTPLogin(false);
              SetAlertDisplay(false);
              SetAlertMessage([]);
              SetisOneTapLoginEnabled(false);
            }}
          ></button>
          <h3 className="mb-4 text-center">
            Login to {process.env.REACT_APP_NAME}
          </h3>

          <AlertComponent
            AlertDisplay={AlertDisplay}
            SetAlertDisplay={SetAlertDisplay}
            AlertMessage={AlertMessage}
          />

          <div className="text-start mb-3">
            {otpMobile ? (
              <div>
                <div className="form-floating mb-4">
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <Icon.Phone />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile"
                      onChange={(e) => {
                        SetMobile(e.target.value);
                      }}
                      value={mobile}
                      required
                    />
                  </div>
                </div>
                <button
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  onClick={(e) => {
                    LoginUsingOTP(e);
                  }}
                >
                  Generate OTP
                </button>
              </div>
            ) : (
              <div>
                <div className="form-floating mb-4">
                  <div className="input-group mb-3">
                    <span className="input-group-text">
                      <Icon.MessageSquare />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="OTP"
                      onChange={(e) => {
                        SetOTP(e.target.value);
                      }}
                      value={otp}
                      required
                    />
                  </div>
                </div>
                <button
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  onClick={(e) => {
                    VerifyOTP(e);
                  }}
                >
                  OTP Verify
                </button>
                <button
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  onClick={(e) => {
                    SetOTPMobile(true);
                  }}
                >
                  Change Mobile Number
                </button>
              </div>
            )}
            <button
              className="btn btn-primary rounded-pill btn-login w-100 mb-2"
              onClick={(e) => {
                SetOTPLogin(false);
                SetLogin(true);
              }}
            >
              Sign In Via Email
            </button>
          </div>
          <p className="mb-1">
            <a
              href="#"
              className="hover"
              onClick={(e) => {
                SetRegister(SetAlertDisplay);
                SetLogin(false);
                SetForgetPassword(true);
                SetAlertDisplay(false);
                SetAlertMessage([]);
              }}
            >
              Forgot Password?
            </a>
          </p>
          <p className="mb-0">
            Don't have an account?{" "}
            <a
              href="#"
              className="hover"
              onClick={(e) => {
                SetRegister(true);
                SetLogin(false);
                SetAlertDisplay(false);
                SetAlertMessage([]);
              }}
            >
              Sign up
            </a>
          </p>
          <div className="divider-icon my-4">or</div>
          <nav className="nav social justify-content-center text-center">
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="btn btn-circle btn-sm btn-google"
                >
                  <i className="uil uil-google"></i>
                </button>
              )}
              buttonText="Login"
              onSuccess={onSuccessGoogleResponse}
              onFailure={onFailureGoogleResponse}
              cookiePolicy={"single_host_origin"}
            />
            &nbsp;&nbsp;
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={onSuccessFacebookResponse}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className="btn btn-circle btn-sm btn-facebook-f"
                >
                  <i className="uil uil-facebook-f"></i>
                </button>
              )}
            />
          </nav>
        </ModalBody>
      </Modal>

      {/* Register */}
      <Modal
        show={register}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          SetRegister(false);
          SetAlertDisplay(false);
          SetAlertMessage([]);
          SetisOneTapLoginEnabled(false);
        }}
      >
        <ModalBody className="text-center">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              SetRegister(false);
              SetAlertDisplay(false);
              SetAlertMessage([]);
              SetisOneTapLoginEnabled(false);
            }}
          ></button>
          <h3 className="mb-4 text-center">
            Create Account - {process.env.REACT_APP_NAME}
          </h3>
          <AlertComponent
            AlertDisplay={AlertDisplay}
            AlertMessage={AlertMessage}
            SetAlertDisplay={SetAlertDisplay}
          />
          <div className="text-start mb-3">
            <div className="form-floating mb-4">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <Icon.User />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  onChange={(e) => {
                    SetName(e.target.value);
                  }}
                  id="loginName"
                  required
                />
              </div>
            </div>
            <div className="form-floating mb-4">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <Icon.Mail />
                </span>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => {
                    SetEmail(e.target.value);
                  }}
                  id="loginEmail"
                  required
                />
              </div>
            </div>
            <div className="form-floating mb-4">
              <div className="input-group mb-3">
                <span className="input-group-text">+91</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile"
                  onChange={(e) => {
                    SetMobile(e.target.value);
                  }}
                  id="loginMobile"
                  required
                />
              </div>
            </div>
            <div className="form-floating mb-4">
              <div className="input-group mb-3">
                <input
                  type={isRevealPwd ? "text" : "password"}
                  className="form-control"
                  placeholder="Password"
                  onChange={(e) => {
                    SetPassword(e.target.value);
                  }}
                  id="loginPassword"
                />
                <span
                  className="input-group-text"
                  onClick={() => {
                    setIsRevealPwd((prevState) => !prevState);
                  }}
                >
                  {isRevealPwd ? <Icon.EyeOff /> : <Icon.Eye />}
                </span>
              </div>
            </div>
            <button
              className="btn btn-primary rounded-pill btn-login w-100 mb-2"
              onClick={(e) => {
                createAccount();
              }}
            >
              Sign Up
            </button>
          </div>
          <p className="mb-0">
            Already have an account?{" "}
            <a
              href="#"
              className="hover"
              onClick={(e) => {
                SetRegister(false);
                SetLogin(true);
                SetAlertDisplay(false);
                SetAlertMessage([]);
              }}
            >
              Sign in
            </a>
          </p>
        </ModalBody>
      </Modal>

      {/* Reset Password */}
      <Modal
        show={resetPassword}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          SetResetPassword(false);
          SetAlertDisplay(false);
          SetAlertMessage([]);
          SetisOneTapLoginEnabled(false);
        }}
      >
        <ModalBody className="text-center">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              SetResetPassword(false);
              SetAlertDisplay(false);
              SetAlertMessage([]);
              SetisOneTapLoginEnabled(false);
            }}
          ></button>
          <h3 className="mb-4 text-center">
            Reset Password - {process.env.REACT_APP_NAME}
          </h3>
          <AlertComponent
            AlertDisplay={AlertDisplay}
            AlertMessage={AlertMessage}
            SetAlertDisplay={SetAlertDisplay}
          />
          <div className="text-start mb-3">
            <div className="form-floating mb-4">
              <div className="input-group mb-3">
                <input
                  type={isRevealPwd ? "text" : "password"}
                  className="form-control"
                  placeholder="New Password"
                  onChange={(e) => {
                    SetPassword(e.target.value);
                  }}
                  id="loginPassword"
                />
                <span
                  className="input-group-text"
                  onClick={() => {
                    setIsRevealPwd((prevState) => !prevState);
                  }}
                >
                  {isRevealPwd ? <Icon.EyeOff /> : <Icon.Eye />}
                </span>
              </div>
            </div>
            <div className="form-floating mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Confirm Password"
                onChange={(e) => {
                  SetConfirmPassword(e.target.value);
                }}
              />
              <label>Confirm Password</label>
            </div>
            <button
              className="btn btn-primary rounded-pill btn-login w-100 mb-2"
              onClick={(e) => {
                updatePassword(e);
              }}
            >
              Update Password
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Forget Password */}
      <Modal
        show={forgetPassword}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          SetForgetPassword(false);
          SetAlertDisplay(false);
          SetAlertMessage([]);
          SetisOneTapLoginEnabled(false);
        }}
      >
        <ModalBody className="text-center">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              SetForgetPassword(false);
              SetAlertDisplay(false);
              SetAlertMessage([]);
              SetisOneTapLoginEnabled(false);
            }}
          ></button>
          <h3 className="mb-4 text-center">
            Forget Password - {process.env.REACT_APP_NAME}
          </h3>
          <AlertComponent
            AlertDisplay={AlertDisplay}
            AlertMessage={AlertMessage}
            SetAlertDisplay={SetAlertDisplay}
          />
          <div className="text-start mb-3">
            <div className="form-floating mb-4">
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email"
                  onChange={(e) => {
                    SetEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <button
              className="btn btn-primary rounded-pill btn-login w-100 mb-2"
              onClick={(e) => {
                ForgetPasswordLink(e);
              }}
            >
              Send me Link
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Toast */}
      <Message
        MessageDisplay={MessageDisplay}
        MessageContent={MessageContent}
        SetMessageDisplay={SetMessageDisplay}
      />

      {USER_TOKEN === undefined && (
        <GoogleOneTapLogin
          onError={(error) => console.log(error)}
          disabled={isOneTapLoginEnabled}
          onSuccess={(res) => handleOneTapSuccessResponse(res)}
          googleAccountConfigs={{
            client_id: process.env.REACT_APP_GOOGLE_ONE_TAP_KEY,
            cancel_on_tap_outside: true,
          }}
        />
      )}
    </div>
  );
}

export default HeaderComponent;
