import { parsePath, Route, BrowserRouter as Router, Routes, useLocation, useNavigate, Navigate   } from "react-router-dom";
import React, { Suspense } from 'react';
import { useEffect } from 'react';
import Home from "./components/Home";
import ForgetPassword from "./components/ForgetPassword";
import ContactUs from "./components/ContactUs";
import Blog from "./components/Blog";
import BlogDetail from "./components/BlogDetail";
import AboutUs from "./components/AboutUs";
import Library from "./components/Library";
import ScLibrary from "./components/ScLibrary";
import CareerLibrary from "./components/CareerLibrary";
import Faq from "./components/Faq";
import Page from "./components/Page";
import Counsellors from "./components/Counsellors";
import CounsellorsDetail from "./components/CounsellorDetail";
import Gallery from "./components/Gallery";
import GalleryItem from "./components/GalleryItem";
import Dashboard from "./components/MyProfile/Dashboard";
import Logout from "./components/Auth/Logout";
import Profile from "./components/MyProfile/Profile";
import AssessmentDetail from "./components/Assessment/AssessmentDetail";
import Assessment from "./components/Assessment";
import BrokerAssessment from "./components/BrokerAssessment";
import GOVAssessmentDetail from "./components/Assessment/GOVAssessmentDetail";
import Products from "./components/Products";
import ProductDetail from "./components/ProductDetail";
import MySubscription from "./components/MyProfile/MySubscription";
import MyCounselling from "./components/MyProfile/Counselling";
import Records from "./components/MyProfile/Record";
import Guidelines from "./components/MyProfile/Guidelines";
import MeetingDetails from './components/MyProfile/MeetingDetails';
import ActionPlan from "./components/MyProfile/ActionPlan";
import EditActionPlan from "./components/MyProfile/EditActionPlan";
import MyReport from './components/MyProfile/MyReport'
import ChangePassword from "./components/MyProfile/ChangePassword";
import Invoice from "./components/MyProfile/Invoice";
import GroupCounselling from "./components/MyProfile/GroupCounselling";
import GroupCounsellingMeetingDetails from "./components/MyProfile/GroupCounsellingMeetingDetails";
import GroupCounsellingActionPlan from "../src/components/MyProfile/GroupCounsellingActionPlan";
import VideoPlatform from "./components/VideoPlatform";
import GroupVideoPlatform from "./components/GroupVideoPlatform";
import VideoCall from "./components/VideoCall";
import NotFound from "./components/NotFound";
import ThankYou from "./components/ThankYouPage";
import GroupPricingEnquiry from "./components/GroupPricingEnquiry";
import ExamStressManagement from "./components/ExamStressManagement";
import EarlyChildhood from "./components/EarlyChildhood";
import ProductPage from "./components/ProductPage"
import Experts from "./components/Experts";
import ExpertsDetail from "./components/ExpertsDetail";
import Inspiria from "./components/inspiria";

// const handleRedirect = (toPath) => {
//   return ({ navigate }) => {
//     console.log('Redirecting to:', toPath);
//     navigate(toPath, { replace: true });
//     return null; // Return null to prevent rendering
//   };
// };
// const handleRedirect = (toPath) => {
//   return ({ navigate }) => {
//     console.log('Redirecting to:', toPath);
//     navigate(toPath, { replace: true });
//     return null; // Return null to prevent rendering
//   };
// };

const App = () => {
  const redirectUrls = [
  '/blog/career-after-ba-in-english-career-options-benefits',
  '/demo-childhood',
  '/blog/list-of-government-competitive',
  '/blog/step-by-step-career-options-for-a-full-stack-developer',
  '/blog/comparison-of-cbse-vs-cisce',
  '/blog/tips-for-choosing-the-best-college-for-civil-engineering',
  '/blog/career',
  '/blog/science',
  '/blog/entrance',
  '/blog/prepare-competitive-government',
  '/top-technology-trends-and-jobs-article',
  '/blog/comparison-of-cbse-vs',
  '/library/cse',
  '/blog/find-career-options-for-hospitality-and-tourism-in-india',
  '/career-counselling-certification-course-in-india',
  '/career-guidance-after',
  '/public',
  '/career-counselling-in-manali',
  '/blog/list-of-government',
  '/public',
  '/best-career-counselling-in-chennai',
  '/career',
  '/blog/indian-career-counselling-crisis',
  '/blog/distance-correspondence-b-tech-electronics-and-communication-engineering'
];
  const handleRedirect = (toPath) => ({ navigate }) => {
    console.log('Redirecting to:', toPath);
    navigate(toPath, { replace: true });
    return null;
  };
  const location = useLocation();
  const navigate = useNavigate();
  // useEffect(() => {
  //   const redirect = handleRedirect('/');
  //   redirect({ navigate });
  // }, [navigate]);
  useEffect(() => {
    const currentPath = location.pathname;
    if (redirectUrls.includes(currentPath)) {
      console.log('Redirecting to home page...');
    }
  }, [location.pathname, redirectUrls]);
 
  return (
    <Routes>
      {/* Home */}
      <Route path="/" element={<Home />} />
      {/* Auth */}
      <Route path="/password/reset/:token" element={<ForgetPassword />} />
      <Route path="/logout" element={<Logout />} />

      {/* Countact Us */}
      <Route path="/contact-us" element={<ContactUs />} />

      {/* Blog */}
      <Route path="/blog/" element={<Blog />} />
      <Route path="/blog/:slug" element={<BlogDetail />} />

      {/* About Us */}
      <Route path="/about-us" element={<AboutUs />} />

      {/* Library */}
      <Route path="/library" element={<Library />} />
      <Route path="/library/category/:slug" element={<ScLibrary />} />
      <Route path="/library/:slug" element={<CareerLibrary />} />

      {/* FAQ */}
      <Route path="/faq" element={<Faq />} />

      {/* Counsellors */}
      <Route path="/counsellors" element={<Counsellors />} />
      <Route path="/counsellor/:slug" element={<CounsellorsDetail />} />

      {/* Experts */}
      <Route path="/Experts" element={<Experts />} />
      <Route path="/Expert/:slug" element={<ExpertsDetail />} />

      {/* Gallery */}
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/gallery/:id" element={<GalleryItem />} />

      {/* MyProfile */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/my-subscription" element={<MySubscription />} />
      <Route path="/my-counselling" element={<MyCounselling />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/my-report" element={<MyReport />} />
      <Route path="/counselling/create" element={<Records />} />
      <Route path="/counselling/guidelines" element={<Guidelines />} />
      <Route path="/meeting/:encrypt_id" element={<MeetingDetails />} />
      <Route path="/group-meeting/:encrypt_id" element={<GroupCounsellingMeetingDetails />} />
      <Route path="/meeting/action-plan/:encrypt_id" element={<ActionPlan />} />
      <Route path="/edit/action-plan/:encrypt_id" element={<EditActionPlan />} />
      <Route path="/invoice/:encrypt_id" element={<Invoice />} />
      <Route path="/my-group-counselling" element={<GroupCounselling />} />
      <Route path="/group-meeting/action-plan/:encrypt_id" element={<GroupCounsellingActionPlan />} />
      <Route path="/counselling/:encrypt_id" element={<VideoPlatform />} />
      <Route path="/group/counselling/:encrypt_id" element={<GroupVideoPlatform />} />
      <Route path="/assessments/broker/:broker_id/:student_id" element={<BrokerAssessment />} />

      {/* Assessments */}
      <Route path="/assessments" element={<Assessment />} />
      <Route path="/assessments/indianexpress" element={<Assessment />} />
      {["/assessments/:slug"].map((path, index) =>
        <Route path={path} element={<AssessmentDetail />} />
      )}

      {/* Government Assessments */}
      <Route path="/ga-assessments/:slug" element={<GOVAssessmentDetail />} />

      {/* Products */}
      <Route path="/products/" element={<Products />} />
      <Route path="/products/:slug" element={<ProductDetail />} />

      {/* Landing Pages */}
      <Route path="/:slug" element={<Page />} />

      <Route path="/404" element={<NotFound />} />

      <Route path="/thank-you" element={<ThankYou />} />

      <Route path="/thank-you/gtm" element={<ThankYou />} />

      <Route path="/group-pricing" element={<GroupPricingEnquiry />} />

      <Route path="/libraries/:slug" element={<CareerLibrary />} />

      <Route path="/exam-stress-management" element={<ExamStressManagement />} />

      <Route path="/early-childhood" element={<EarlyChildhood />} />

      <Route path="/certified-professional-career-counselling-cpcc" element={<ProductPage />} />

      <Route path="/inspiria-psychometrics" element={<Inspiria />} />

      <Route
        path="/blog/career-after-ba-in-english-career-options-benefits"
        element={<Navigate to="/" />}
      />
      <Route
        path="/demo-childhood"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/list-of-government-competitive"
        element={<Navigate to="/" />}
      />
       <Route
        path="/step-by-step-career-options-for-a-full-stack-developer"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/comparison-of-cbse-vs-cisce"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/tips-for-choosing-the-best-college-for-civil-engineering"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/career"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/science"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/entrance"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/prepare-competitive-government"
        element={<Navigate to="/" />}
      />
       <Route
        path="/top-technology-trends-and-jobs-article"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/comparison-of-cbse-vs"
        element={<Navigate to="/" />}
      />
       <Route
        path="/library/cse"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/find-career-options-for-hospitality-and-tourism-in-india"
        element={<Navigate to="/" />}
      />
       <Route
        path="/career-counselling-certification-course-in-india"
        element={<Navigate to="/" />}
      />
       <Route
        path="/career-guidance-after"
        element={<Navigate to="/" />}
      />
       <Route
        path="/public"
        element={<Navigate to="/" />}
      />
       <Route
        path="/career-counselling-in-manali"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/list-of-government"
        element={<Navigate to="/" />}
      />
       <Route
        path="/public"
        element={<Navigate to="/" />}
      />
       <Route
        path="/best-career-counselling-in-chennai]"
        element={<Navigate to="/" />}
      />
       <Route path="/career" element={<Navigate to="/" />} />
       <Route
        path="/blog/indian-career-counselling-crisis"
        element={<Navigate to="/" />}
      />
       <Route
        path="/blog/distance-correspondence-b-tech-electronics-and-communication-engineering"
        element={<Navigate to="/" />}
      />

    </Routes>
  
  );
};

export default App;
