import { useParams } from "react-router-dom";
import "../../assets/css/plugins.css";
import "../../assets/css/custom.css";
import "../../assets/css/style.css";
import { Link, useNavigate , useLocation, useSearchParams} from "react-router-dom";
import {
    Modal,
    Button,
    Spinner,
    Tooltip,
    Overlay,
    OverlayTrigger,
    Toast,
} from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import {
    GetAssessmentDetailAPI,
    getTestQuestionsAPI,
    StoreAssessmentTestAPI,
    checkBrokerUserExists,
    LoginAPI,
    updateBrokerStudentPackage,
    updateBrokerUser,
} from "../../Service";
import AssessmentNavbar from "./Navbar";
import Cookies from "js-cookie";
import { Icon } from "@iconify/react";
import MyTimer from "../Assessment/Timer";
import ModalAlert from "../../layouts/ModalAlert";

function AssessmentDetail() {
    let { slug } = useParams();
    const location = useLocation();
    let USER_TOKEN = Cookies.get("usr_token");
    let usr = Cookies.get('user');
    const target = useRef(null);
    const [show, setShow] = useState(false);

    const [TestList, SetTestList] = useState([]);
    let navigate = useNavigate();

    /* Assessment Hooks */
    const [AssessmentInstructions, SetAssessmentInstructions] = useState(false);
    const [agree, SetAgree] = useState(false);
    const [begin, SetBegin] = useState(false);
    const [Detail, SetDetail] = useState({});
    const [token , setToken] = useState(null);
    const [isLastTest, SetIsLastTest] = useState(false);

    /* Test Details Hooks */
    const [TestInstructions, SetTestInstructions] = useState(false);
    const [TestDetail, SetTestDetail] = useState({});
    const [Test, SetTest] = useState(false);
    const [AssessmentTestID, SetAssessmentTestID] = useState("");
    const [TestID, SetTestID] = useState("");
    const [CompletedTest, SetCompletedTest] = useState([]);
    const [AssessmentTestIdArr, SetAssessmentTestIdArr] = useState([]);
    const [TestIdArr, SetTestIdArr] = useState([]);
    const [secondsInTimer, SetSeconds] = useState(0);

    /* Test Question Hooks */
    const [questionArr, SetQuestionArr] = useState([]);
    const [questionType, SetQuestionType] = useState(0);
    const [userAnswer, SetUserAnswer] = useState([]);
    const [CurrentKey, SetCurrentkey] = useState(0);
    const [prev, SetPrev] = useState(false);
    const [beginMsg, SetBeginMsg] = useState("");
    const [SaveProgress, SetSaveProgress] = useState(false);
    const [ShowSaveProgress, SetShowSaveProgress] = useState(false);
    const [isAssessmentHaveTimer, SetAssessmentTimer] = useState(false);
    const [VARKMessage, SetVARKMessage] = useState("");
    const [ButtonName, SetButtonName] = useState("Take Assessment");
    const [CompletedTestIDArray, SetCompletedTestIDArray] = useState([]);

    //Auto Close Modal
    const [ShowAutocloseTest, SetShowAutocloseTest] = useState(false);
    const [ShowRetakeTest, SetShowRetakeTest] = useState(false);
    const [expiredStatus, SetExpiredStatus] = useState(0);

    const [mainTitle, SetMainTitle] = useState("");

    const [ModalAlertDisplay, SetModalModalAlertDisplay] = useState(false);
    const [ModalAlertMessage, SetModalAlertMessage] = useState([]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if(location.search.split('?')[1])
        {
             checkIfBrokerUserExists();
        }
        else {
            if (USER_TOKEN === undefined) {
                navigate("/assessments?is_login=true&is_message=Please login to take assessment");
            }
            fetchAssessmentDetail();
        }

        // var element = document.getElementById('chat-bot-launcher-container').style.display = "none";
        // element.classList.add('chat-bot-launcher-container-assessment');

    }, []);
    const checkIfBrokerUserExists = async () => {
        try{
            let brokerData = {
                  'assesment': location.search.split('?')[1],
                  'is_assesment_completed': 0,
                  'status': 0
            }
                await updateBrokerUser(brokerData);
                let userData = JSON.parse(usr);
            let data = {
                        user_id: userData.id.toString(),
                        package: location.search.split('?')[1]
                       }
        let response = await updateBrokerStudentPackage(data);
                if(response.is_success === true)
                {
                await fetchAssessmentDetail();
                }else
                Toast.error('Check all details')
            }
    catch {
        throw new Error(`Something failed`);
    }
        // else{
        //      if (USER_TOKEN === undefined) {
        //     navigate("/assessments?is_login=true&is_message=Please login to take assessment");
        //  }
        // }
    }

    var timeconvert = (duration) => {
        const sec = parseInt(duration * 60); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
        let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        // if (hours   < 10) {hours   = "0"+hours;}
        // if (minutes < 10) {minutes = "0"+minutes;}
        // if (seconds < 10) {seconds = "0"+seconds;}
        // return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS

        var hDisplay = hours > 0 ? hours + (hours == 1 ? " hr " : " hrs ") : "";
        var mDisplay = minutes > 0 ? minutes + (minutes == 1 ? " min " : " mins ") : "";
        var sDisplay = seconds > 0 ? seconds + (seconds == 1 ? " sec" : " secs") : "";

        return hDisplay + mDisplay + sDisplay;
    }

    const fetchAssessmentDetail = async () => {
        let newToken = USER_TOKEN;

        let res = await GetAssessmentDetailAPI(slug, newToken);
        if (res?.is_success === true) {
            SetCompletedTestIDArray(res?.completedTestIdArray);
            SetButtonName(res?.assessment?.is_assessment_completed);
            SetAssessmentInstructions(true);
            SetTestList(res.assessment.assessment_tests);
            let assessment_arr = [];
            let test_arr = [];
            res.assessment.assessment_tests.forEach((element) => {
                if (res.assessment.is_paid_package === true) {
                    assessment_arr.push(element.id);
                    test_arr.push(element.test_id);
                } else {
                    if (element.test.is_paid === 0) {
                        assessment_arr.push(element.id);
                        test_arr.push(element.test_id);
                    }
                }
            });
            SetTestIdArr(test_arr);
            SetAssessmentTestIdArr(assessment_arr);
            SetDetail(res.assessment);
            SetCompletedTest(res?.completed_test);
            let remaining_test = [];
            let remaining_test_id = [];
            remaining_test = assessment_arr.filter(
                (val) => !res.completed_test.includes(val)
            );
            remaining_test_id = test_arr.filter(
                (val) => !res.completedTestIdArray.includes(val)
            );
            SetTestID(remaining_test_id[0]);
            SetAssessmentTestID(remaining_test[0]);
        } else if (res?.is_success === "login") {
            navigate("/assessments?is_login=true&is_message=Please login to take assessment");
        } else {
            let message = res?.errors[0];
            navigate(`/assessments?is_messages=${message}&button=${res?.button}&slug=${res?.slug}`);
        }
    };

    const fetchTestQuestions = async (test_id) => {
        let newToken = token!= null || undefined ? token : USER_TOKEN;
        if (newToken !== undefined && test_id !== "") {
            let res = await getTestQuestionsAPI(test_id, newToken);
            if (res?.is_success === true) {
                SetAssessmentInstructions(false);
                SetTest(false);
                SetTestInstructions(true);
                SetTestDetail(res?.data?.test);
                SetMainTitle(res?.data?.test?.name);
                // if (res?.data?.test?.duration && res.data.test.duration !== null) {
                //   const time = new Date();
                //   SetSeconds(time.setSeconds(time.getSeconds() + parseInt(res.data.test.duration * 60)));
                //   SetAssessmentTimer(true);
                //   SetExpiredStatus(1)
                // } else {
                //   SetAssessmentTimer(false);
                // }
                SetQuestionArr(res?.data?.TestQuestions);
                SetQuestionType(res?.data?.TestQuestions[0]?.question?.type);
                SetCurrentkey(0);
                SetSaveProgress(false);
            }
        } else {
            navigate("/assessments?is_login=true&is_message=Please login to take assessment");
        }
    };

    const StartAssessment = (e) => {
        if (agree === "1") {
            SetBeginMsg("");
            SetBegin(true);
            fetchTestQuestions(AssessmentTestID);
        } else {
            SetBegin(false);
            SetBeginMsg("Tick the check box to proceed");
        }
    };

    const GetStarted = (e) => {
        if (TestDetail?.duration && TestDetail.duration !== null) {
            const time = new Date();
            SetSeconds(time.setSeconds(time.getSeconds() + parseInt(TestDetail.duration * 60)));
            SetAssessmentTimer(true);
            SetExpiredStatus(1)
        } else {
            SetAssessmentTimer(false);
        }

        SetAssessmentInstructions(false);
        SetTestInstructions(false);
        SetTest(true);
    };

    const Next = (e) => {
        let options = document.querySelectorAll(
            `.question-option-${CurrentKey} li`
        );

        for (let index = 0; index < options.length; index++) {
            const element = options[index];
            if (questionType === 3) {
                element.style.background = "";
            } else {
                if (element.classList.contains("active")) {
                    element.classList.remove("active");
                }
            }
        }
        if (questionType === 3) {
            e.target.style.background = e.target.dataset.color;
            e.target.dataset.answered = true;
        } else {
            e.target.classList.add("active");
        }

        let arr = [];
        let data = {
            question_id: e.target.dataset.questionId,
            question_type_id: questionType,
            option: e.target.dataset.optionKey,
            category_id: e.target.dataset.categoryId,
        };
        arr.push(data);
        let new_arr = [];
        userAnswer.forEach((element) => {
            if (element.question_id === data.question_id) {
                new_arr = userAnswer.filter(
                    (value) => value.question_id != e.target.dataset.questionId
                );
            }
        });
        if (Array.isArray(new_arr) && new_arr.length) {
            SetUserAnswer([...new_arr, ...arr]);
        } else {
            SetUserAnswer([...userAnswer, ...arr]);
        }
        //SkipQuestion();
    };

    const PrevQuestion = (e) => {
        //Fade Out Current Question
        let ele = document.getElementsByClassName(`question-key-${CurrentKey}`)[0];
        if (ele.classList.contains("fadeIn") === true) {
            ele.classList.add("fadeOut");
        }
        let prev_key = parseInt(CurrentKey - 1);
        setTimeout(() => {
            SetCurrentkey(prev_key);
            let prev_ele = document.getElementsByClassName(
                `question-key-${prev_key}`
            )[0];
            prev_ele.classList.remove("fadeOut");
            prev_ele.classList.add("fadeIn");
        }, 500);
        if (prev_key === 0) {
            SetPrev(false);
        }
    };

    const SkipQuestion = async (e) => {
        SetPrev(true);
        SetShowSaveProgress(true);
        if (CurrentKey !== undefined) {
            let check_next_question = questionArr.length;
            let nxt_question = parseInt(CurrentKey + 1);
            if (check_next_question > nxt_question) {
                //Fade Out Current Question
                let ele = document.getElementsByClassName(
                    `question-key-${CurrentKey}`
                )[0];
                if (ele.classList.contains("fadeIn") === true) {
                    ele.classList.remove("fadeIn");
                    ele.classList.add("fadeOut");
                }

                //Fade In Next Question
                setTimeout(() => {
                    SetCurrentkey(nxt_question);
                    let nxt_ele = document.getElementsByClassName(
                        `question-key-${nxt_question}`
                    )[0];
                    if (nxt_ele.classList.contains("fadeIn") !== true) {
                        nxt_ele.classList.add("fadeIn");
                    }
                }, 500);

            }
        }
    };

    const VARKOption = async (e) => {
        if (e.target.checked) {
            if (userAnswer.length > 0) {
                //Check If User Answered this Question or not
                userAnswer.forEach((element) => {
                    if (element.question_id !== e.target.dataset.questionId) {
                        //If Not Exists
                        let arr = [];
                        let data = {
                            question_id: e.target.dataset.questionId,
                            question_type_id: questionType,
                            option: [e.target.dataset.option],
                            category_id: TestDetail?.id,
                        };
                        arr.push(data);
                        SetUserAnswer([...userAnswer, ...arr]);
                        SetVARKMessage("");
                    } else {
                        //If Exists
                        let arr = [];
                        let ele = element;
                        let existing_option = element.option;
                        if (existing_option.includes(e.target.dataset.option) === false) {
                            existing_option.push(e.target.dataset.option);
                        }
                        let options = existing_option;
                        let data = {
                            question_id: ele.question_id,
                            question_type_id: ele.question_type_id,
                            option: options,
                            category_id: ele.category_id,
                        };
                        arr.push(data);
                        let new_arr = [];
                        new_arr = userAnswer.filter(
                            (value) => value.question_id != e.target.dataset.questionId
                        );
                        SetUserAnswer([...new_arr, ...arr]);
                        SetVARKMessage("");
                    }
                });
            } else {
                let arr = [];
                let data = {
                    question_id: e.target.dataset.questionId,
                    question_type_id: questionType,
                    option: [e.target.dataset.option],
                    category_id: TestDetail?.id,
                };
                arr.push(data);
                SetUserAnswer([...userAnswer, ...arr]);
                SetVARKMessage("");
            }
        } else {
            //Remove Unchecked Option
            userAnswer.forEach((element) => {
                if (element.question_id === e.target.dataset.questionId) {
                    let arr = [];
                    let ele = element;
                    let existing_option = element.option;
                    let options = existing_option.filter(function (ele) {
                        return ele != e.target.dataset.option;
                    });

                    if (Array.isArray(options) && options.length) {
                        let data = {
                            question_id: ele.question_id,
                            question_type_id: ele.question_type_id,
                            option: options,
                            category_id: ele.category_id,
                        };
                        arr.push(data);
                        let new_arr = [];
                        new_arr = userAnswer.filter(
                            (value) => value.question_id != e.target.dataset.questionId
                        );
                        SetUserAnswer([...new_arr, ...arr]);
                    } else {
                        let new_arr = [];
                        new_arr = userAnswer.filter(
                            (value) => value.question_id != e.target.dataset.questionId
                        );
                        SetUserAnswer([...new_arr]);
                    }
                }
            });
        }
    };

    const nextVarkQuestion = (e) => {
        if (userAnswer.length > 0) {
            userAnswer.forEach((ele) => {
                if (ele.question_id === e.target.dataset.questionId) {
                    if (!ele.option || ele.option.length === 0) {
                        SetVARKMessage("Please choose an answer");
                    } else {
                        SetVARKMessage("");
                        SkipQuestion();
                    }
                } else {
                    SetVARKMessage("Please choose an answer");
                }
            });
        } else {
            SetVARKMessage("Please choose an answer");
        }
    };

    function changeBackground(e) {
        let answered = e.target.dataset.answered;
        if (answered === "false") {
            e.target.style.background = e.target.dataset.color;
        }
    }

    function removeBackground(e) {
        if (e.target.dataset.answered === "false") {
            e.target.style.background = "";
        }
    }

    const CustomNext = async (e) => {
        let options = document.querySelectorAll(
            `.question-option-${CurrentKey} li`
        );
        let option = null;
        for (let index = 0; index < options.length; index++) {
            const element = options[index];
            if (element.classList.contains("active")) {
                option = parseInt(index + 1);
            }
        }
        if (option === null) {
            SetVARKMessage("Please choose an answer");
        } else {
            SetVARKMessage("");
            SkipQuestion();
        }
    };

    const SelectOption = async (e) => {
        SetVARKMessage("");
        let options = document.querySelectorAll(
            `.question-option-${CurrentKey} li`
        );

        for (let index = 0; index < options.length; index++) {
            const element = options[index];
            if (questionType === 3) {
                element.style.background = "";
                if (element.classList.contains("active")) {
                    element.classList.remove("active");
                }
            } else {
                if (element.classList.contains("active")) {
                    element.classList.remove("active");
                }
            }
        }
        if (questionType === 3) {
            e.target.style.background = e.target.dataset.color;
            e.target.dataset.answered = true;
            e.target.classList.add("active");
        } else {
            e.target.classList.add("active");
        }

        let option = null;
        for (let index = 0; index < options.length; index++) {
            const element = options[index];
            if (element.classList.contains("active")) {
                option = parseInt(index + 1);
            }
        }

        if (option !== null) {
            const filtered = userAnswer.filter(obj => {
                return obj.question_id !== e.target.dataset.questionId;
            });
            let arr = [];
            let data = {
                question_id: e.target.dataset.questionId,
                question_type_id: questionType,
                option: e.target.dataset.optionKey,
                category_id: TestDetail?.id,
            };
            arr.push(data);
            SetUserAnswer([...filtered, ...arr]);
            SetVARKMessage("");
            // if (userAnswer.length > 0) {
            //     userAnswer.forEach((element) => {
            //         if (element.question_id !== e.target.dataset.questionId) {
            //             //If Not Exists
            //             let arr = [];
            //             let data = {
            //                 question_id: e.target.dataset.questionId,
            //                 question_type_id: questionType,
            //                 option: e.target.dataset.optionKey,
            //                 category_id: TestDetail?.id,
            //             };
            //             arr.push(data);
            //             SetUserAnswer([...userAnswer, ...arr]);
            //             SetVARKMessage("");
            //         } else {
            //             //If Exists
            //             let arr = [];
            //             let ele = element;
            //             let data = {
            //                 question_id: ele.question_id,
            //                 question_type_id: ele.question_type_id,
            //                 option: e.target.dataset.optionKey,
            //                 category_id: ele.category_id,
            //             };
            //             arr.push(data);
            //             let new_arr = [];
            //             new_arr = userAnswer.filter(
            //                 (value) => value.question_id != e.target.dataset.questionId
            //             );
            //             SetUserAnswer([...new_arr, ...arr]);
            //             SetVARKMessage("");
            //         }
            //     });
            // } else {
            //     let arr = [];
            //     let data = {
            //         question_id: e.target.dataset.questionId,
            //         question_type_id: questionType,
            //         option: e.target.dataset.optionKey,
            //         category_id: TestDetail?.id,
            //     };
            //     arr.push(data);
            //     SetUserAnswer([...arr]);
            // }
        }
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return null;
        } else {
            // Render a countdown
            return (
                <span>
                    {hours}:{minutes}:{seconds}
                </span>
            );
        }
    };

    const autocompleteTest = async () => {
        if (userAnswer.length >= 3) {
            getAllQuestionData();
        } else {
            SetShowRetakeTest(true)
            SetShowAutocloseTest(false)
        }
    };

    let newUserUnAnsData = [];

    const getAllQuestionData = async () => {
        let userAnsweredQuestion = [];
        let newArr = [];

        userAnswer.forEach((ans, index) => {
            userAnsweredQuestion.push(parseInt(ans.question_id))
        })

        questionArr.forEach((element) => {
            if (userAnsweredQuestion.includes(element.question_bank_id)) {
                newUserUnAnsData = userAnswer.filter(
                    (value) => value.question_id
                );
                SetUserAnswer([...newUserUnAnsData]);
            } else {
                let data = {
                    question_id: element.question_bank_id,
                    question_type_id: questionType,
                    option: 0,
                    category_id: TestDetail?.id,
                };
                newUserUnAnsData.push(data);
                SetUserAnswer([...newUserUnAnsData]);
            }
        })

        SetShowAutocloseTest(true);
        UpdateTest(newUserUnAnsData);
    };

    const retakeTest = () => {
        window.location.reload(true);
    }

    useEffect(() => {
        if (expiredStatus === 2) {
            autocompleteTest();
        }
    }, [expiredStatus]);

    const UpdateTest = async (userAnswer) => {
        let newToken = token!= null || undefined ? token : USER_TOKEN;
        if (questionArr.length === userAnswer.length || expiredStatus === 2) {
            SetSaveProgress(true);
            let data = {
                assesment_id: Detail?.id,
                assesment_test_id: AssessmentTestID,
                test_id: TestID,
                question: userAnswer,
            };
            if (userAnswer.length >= 3) {
                let res = await StoreAssessmentTestAPI(data, newToken);
                if (res?.is_success === true) {
                    SetModalModalAlertDisplay(true);
                    SetModalAlertMessage(["You have completed " + mainTitle]);

                    SetCompletedTestIDArray(res?.completed_test)
                    SetShowAutocloseTest(false)
                    let arr = [];
                    arr.push(AssessmentTestID);
                    SetCompletedTest([...CompletedTest, ...arr]);
                    const currentIndex = AssessmentTestIdArr.indexOf(AssessmentTestID);
                    const nextIndex = (currentIndex + 1) % AssessmentTestIdArr.length;
                    if (nextIndex === 0) {
                        SetUserAnswer([]);
                        SetTest(false);
                        SetIsLastTest(true);
                        let brokerData = {
                            'assesment': location.search.split('?')[1],
                            'is_assesment_completed': 1,
                      }
                           await updateBrokerUser(brokerData);
                    } else {
                        SetAssessmentTestID(AssessmentTestIdArr[nextIndex]);
                        SetTestID(TestIdArr[nextIndex]);
                        fetchTestQuestions(AssessmentTestIdArr[nextIndex]);
                        SetUserAnswer([]);
                    }
                }
            } else {
                SetShowRetakeTest(true)
                SetShowAutocloseTest(false)
            }
        } else {
            SetVARKMessage("Please choose an answer");
        }
    };

    useEffect(() => {
    }, [userAnswer, ShowAutocloseTest])

    return (
        <div>
            <div className="content-wrapper assessment-page">
                <AssessmentNavbar />
                <section className="Roadmap" data-testid="roadmap">
                    <div className="assessment-with-progress">


                        <div className="assessment Roadmap__inner">
                            {/* Assessment Instructions */}
                            {AssessmentInstructions && (
                                <div className="Welcome">
                                    <div className="">
                                        <div className="Welcome__intro">
                                            <div className="">
                                                <h1 className="post-title mb-3 mt-2 text-white">
                                                    Welcome back!{" "}
                                                </h1>

                                                <div className="row text-light">
                                                    <h1 className="text-light">General Instructions</h1>
                                                    {Detail?.description && (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: Detail?.description,
                                                            }}
                                                        />
                                                    )}
                                                </div>

                                                <div className="text-light">
                                                    <div className="form-check">
                                                        <input
                                                            type="checkbox"
                                                            id="formBasicCheckbox"
                                                            className="form-check-input begin-start"
                                                            value="1"
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    SetAgree(e.target.value);
                                                                    SetBeginMsg("");
                                                                } else {
                                                                    SetAgree("0");
                                                                }
                                                            }}
                                                        />
                                                        <label
                                                            title=""
                                                            htmlFor="formBasicCheckbox"
                                                            className="form-check-label"
                                                        >
                                                            {" "}
                                                            I am ready to begin
                                                        </label>
                                                    </div>
                                                    <p className="text-danger">{beginMsg}</p>
                                                </div>

                                                {ButtonName === 1 && (
                                                    <div className="my-1">
                                                        <button
                                                            type="button"
                                                            className="btn btn-lg btn-light w-100 my-1 text-dark mb-10"
                                                            onClick={(e) => {
                                                                StartAssessment(e);
                                                            }}
                                                        >
                                                            Take Assessment
                                                        </button>
                                                    </div>
                                                )}

                                                {ButtonName === 2 && (
                                                    <div className="my-1">
                                                        <Link
                                                            to="/my-report"
                                                            className="btn btn-lg btn-light w-100 my-1 text-dark mb-10"
                                                        >
                                                            View Report
                                                        </Link>
                                                    </div>
                                                )}

                                                {ButtonName === 3 &&
                                                    Detail?.is_paid_package === true && (
                                                        <div className="my-1">
                                                            <button
                                                                type="button"
                                                                className="btn btn-lg btn-light w-100 my-1 text-dark mb-10"
                                                                onClick={(e) => {
                                                                    StartAssessment(e);
                                                                }}
                                                            >
                                                                Resume
                                                            </button>
                                                        </div>
                                                    )}

                                                {ButtonName === 3 &&
                                                    Detail?.is_paid_package === false && (
                                                        <div className="my-1">
                                                            <Link
                                                                to={`/products/${slug}`}
                                                                className="btn btn-lg btn-light w-100 my-1 text-dark mb-10"
                                                            >
                                                                Subscribe to complete Full Assessment
                                                            </Link>
                                                        </div>
                                                    )}

                                                {ButtonName === 4 && (
                                                    <div className="my-1">
                                                        <button
                                                            type="button"
                                                            className="btn btn-lg btn-light w-100 my-1 text-dark mb-10"
                                                            onClick={(e) => {
                                                                StartAssessment(e);
                                                            }}
                                                        >
                                                            Resume
                                                        </button>
                                                    </div>
                                                )}

                                                {/*  <div>
          <img
            src="https://res.cloudinary.com/hnpb47ejt/image/upload/q_auto,f_auto,w_744/v1593735997/gdusahihr8mwukf90zqz"
            className="rounded"
            alt=""
          />
        </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Test  Instructions */}
                            {TestInstructions && (
                                <div className="Welcome">
                                    <div className="">
                                        <div className="Welcome__intro">
                                            <div className="">
                                                <h1 className="post-title mb-3 mt-2 text-white">
                                                    {TestDetail?.name}
                                                </h1>
                                                <div className="row text-light">
                                                    {TestDetail?.description && (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: TestDetail?.description,
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <br />
                                                <div className="row text-light">
                                                    <h1 className="text-light">Instructions</h1>
                                                    {TestDetail?.instructions && (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: TestDetail?.instructions,
                                                            }}
                                                        />
                                                    )}
                                                </div>

                                                <div className="my-1">
                                                    <button
                                                        type="button"
                                                        className="btn btn-lg btn-light w-100 my-1 text-dark mb-10"
                                                        onClick={(e) => {
                                                            GetStarted(e);
                                                        }}
                                                    >
                                                        Get Started
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Test */}
                            {Test && (
                                <div className="Assessment">
                                    <div className="Assessment__scroll-container">
                                        <div className="">
                                            <div className="Assessment__Item--forward-appear-done Assessment__Item--forward-enter-done">
                                                <div className="Assessment__Item Assessment__RadioItem Assessment__RadioItem--active">
                                                    {isAssessmentHaveTimer && (
                                                        <MyTimer expiryTimestamp={secondsInTimer} SetExpiredStatus={SetExpiredStatus} expiredStatus={expiredStatus} />
                                                    )}

                                                    <div style={{ minHeight: "auto" }}>


                                                        {questionArr.map((y, j) => (
                                                            <div
                                                                key={j}
                                                                style={{
                                                                    display:
                                                                        CurrentKey === j ? "block" : "none",
                                                                }}
                                                                className={`question-key-${j}`}
                                                            >
                                                                {/* Question */}
                                                                <span className="post-title mb-3 mt-2 text-light img-optimized">
                                                                    {y?.question?.question && (
                                                                        <>
                                                                            <span class='text-yellow'>Question: {CurrentKey + 1 + " out of " + questionArr.length}</span>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: y?.question?.question,
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </span>

                                                                {/* Option */}
                                                                {/* Type 1 (MCQ) && Type 2 (True (or) False) */}
                                                                {(y?.question?.type === 1 ||
                                                                    y?.question?.type === 2) && (
                                                                        <div className="assessment-choices-list">
                                                                            <ul
                                                                                className={`assessment-choices-list-style-1  opt-width-100 question-option-${j}`}
                                                                            >
                                                                                {y?.question?.option.options.length
                                                                                    ? y.question.option.options.map(
                                                                                        (x, i) => (
                                                                                            x !== null ? (
                                                                                                x.includes("<img") ? (
                                                                                                    <li className={`assessment-choices-list-style-1-image-li`}
                                                                                                        key={i}
                                                                                                        data-option-key={parseInt(
                                                                                                            i + 1
                                                                                                        )}
                                                                                                        data-question-id={
                                                                                                            y?.question?.id
                                                                                                        }
                                                                                                        data-category-id={
                                                                                                            y?.question
                                                                                                                ?.question_category_id
                                                                                                        }
                                                                                                        onClick={(e) => {
                                                                                                            SelectOption(e);
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: x,
                                                                                                            }}
                                                                                                            style={{
                                                                                                                pointerEvents: "none",
                                                                                                            }}
                                                                                                        />
                                                                                                        {/*  {Parser().parse(x)} */}
                                                                                                    </li>
                                                                                                ) : (
                                                                                                    <li
                                                                                                        key={i}
                                                                                                        data-option-key={parseInt(
                                                                                                            i + 1
                                                                                                        )}
                                                                                                        data-question-id={
                                                                                                            y?.question?.id
                                                                                                        }
                                                                                                        data-category-id={
                                                                                                            y?.question
                                                                                                                ?.question_category_id
                                                                                                        }
                                                                                                        onClick={(e) => {
                                                                                                            SelectOption(e);
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                                __html: x,
                                                                                                            }}
                                                                                                            style={{
                                                                                                                pointerEvents: "none",
                                                                                                            }}
                                                                                                        />
                                                                                                        {/*  {Parser().parse(x)} */}
                                                                                                    </li>
                                                                                                )
                                                                                            ) : null
                                                                                        )
                                                                                    )
                                                                                    : null}
                                                                            </ul>
                                                                        </div>
                                                                    )}

                                                                {/* Type 3 (Custom Option Value) , Ex:Smiley */}
                                                                {y?.question?.type === 3 && (
                                                                    <div
                                                                        className={`assessment-choices-list mt-3 text-center question-option-${j}`}
                                                                    >
                                                                        <ul className="list-inline assessment-choices-list-style-2">
                                                                            {y?.question?.option.length
                                                                                ? y.question.option.map((x, i) =>
                                                                                    x.smiley_image !== null ? (
                                                                                        <OverlayTrigger
                                                                                            key={i}
                                                                                            placement={`bottom`}
                                                                                            overlay={
                                                                                                <Tooltip id={`tooltip-bottom`}>
                                                                                                    {x.answer}
                                                                                                </Tooltip>

                                                                                            }
                                                                                            data-answered={false}
                                                                                            data-color={x.smiley_color}
                                                                                        /*   onEnter={changeBackground}
                                                                                          onExit={removeBackground} */
                                                                                        >
                                                                                            <li
                                                                                                key={i}
                                                                                                className="list-inline-item mb-8"
                                                                                                onMouseOver={
                                                                                                    changeBackground
                                                                                                }
                                                                                                onMouseLeave={
                                                                                                    removeBackground
                                                                                                }

                                                                                                data-answered={false}
                                                                                                data-color={x.smiley_color}
                                                                                                data-option-key={parseInt(
                                                                                                    i + 1
                                                                                                )}
                                                                                                data-question-id={
                                                                                                    y?.question?.id
                                                                                                }
                                                                                                data-category-id={
                                                                                                    y?.question
                                                                                                        ?.question_category_id
                                                                                                }
                                                                                                onClick={(e) => {
                                                                                                    SelectOption(e);
                                                                                                }}
                                                                                            >
                                                                                                <Icon
                                                                                                    icon={x.smiley_code}
                                                                                                    color="white"
                                                                                                    style={{
                                                                                                        pointerEvents: "none",
                                                                                                    }}
                                                                                                />
                                                                                            </li>
                                                                                        </OverlayTrigger>
                                                                                    ) : null
                                                                                )
                                                                                : null}
                                                                        </ul>
                                                                    </div>
                                                                )}

                                                                {/* Type 4 (VARK - Multiple CheckBox)*/}
                                                                {y?.question?.type === 4 && (
                                                                    <div className="assessment-choices-list">
                                                                        {y?.question?.option.length && (
                                                                            <div>
                                                                                {y.question.option.map((x, i) => (
                                                                                    <div className="form-check" key={i}>
                                                                                        <input
                                                                                            className={`form-check-input vark-questions-${j}`}
                                                                                            type="checkbox"
                                                                                            value={x.value}
                                                                                            data-question-id={
                                                                                                y?.question?.id
                                                                                            }
                                                                                            data-option={x.category}
                                                                                            onChange={VARKOption}
                                                                                        />
                                                                                        <label className="form-check-label text-white">
                                                                                            {x.option}
                                                                                        </label>
                                                                                    </div>
                                                                                ))}
                                                                                {VARKMessage && (
                                                                                    <p className="text-danger">
                                                                                        {VARKMessage}
                                                                                    </p>
                                                                                )}
                                                                                <div className="text-center">
                                                                                    <span className="mb-3 mt-2 text-mute text-white text-center">
                                                                                        {(prev && questionArr.length != parseInt(CurrentKey + 1)) && (
                                                                                            <button
                                                                                                className="btn btn-sm btn-primary"
                                                                                                style={{ cursor: "pointer", marginRight: "10px" }}
                                                                                                onClick={(e) => {
                                                                                                    PrevQuestion(e);
                                                                                                }}
                                                                                            >
                                                                                                <Icon
                                                                                                    icon="whh:arrowleft"
                                                                                                    width="15"
                                                                                                    height="15"
                                                                                                /> Previous &nbsp;
                                                                                            </button>
                                                                                        )}
                                                                                    </span>
                                                                                    {j !== questionArr.length - 1 && (
                                                                                        <button
                                                                                            className="btn btn-sm btn-primary"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            data-question-id={y?.question?.id}
                                                                                            onClick={nextVarkQuestion}
                                                                                        >
                                                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                            <Icon
                                                                                                icon="whh:arrowright"
                                                                                                width="15"
                                                                                                height="15"
                                                                                                style={{
                                                                                                    pointerEvents: "none",
                                                                                                }}
                                                                                            />
                                                                                        </button>
                                                                                    )}
                                                                                    {(prev && questionArr.length != parseInt(CurrentKey + 1)) && (
                                                                                        <h3 className="text-white pt-4 d-none">{CurrentKey + 1 + " out of " + questionArr.length}</h3>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}

                                                                {/* Type 5 (QCAC) */}
                                                                {y?.question?.type === 5 && (
                                                                    <div className="assessment-choices-list">
                                                                        <ul
                                                                            className={`assessment-choices-list-style-1 opt-width-100 question-option-${j}`}
                                                                        >
                                                                            {y?.question?.option.length
                                                                                ? y.question.option.map((x, i) =>
                                                                                    x !== null ? (
                                                                                        <li
                                                                                            key={i}
                                                                                            data-option-key={parseInt(
                                                                                                i + 1
                                                                                            )}
                                                                                            data-question-id={
                                                                                                y?.question?.id
                                                                                            }
                                                                                            data-category-id={
                                                                                                y?.question
                                                                                                    ?.question_category_id
                                                                                            }
                                                                                            onClick={(e) => {
                                                                                                SelectOption(e);
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: x.option,
                                                                                                }}
                                                                                                style={{
                                                                                                    pointerEvents: "none",
                                                                                                }}
                                                                                            />
                                                                                        </li>
                                                                                    ) : null
                                                                                )
                                                                                : null}
                                                                        </ul>
                                                                    </div>
                                                                )}

                                                                {questionArr.length >
                                                                    parseInt(CurrentKey + 1) ? (
                                                                    <div className="text-center">
                                                                        {/*  <button
                                        className="btn btn-sm btn-secondary mt-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          SkipQuestion(e);
                                        }}
                                      >
                                        Skip Question
                                      </button> */}
                                                                        {y?.question?.type != 4 && (
                                                                            <div>
                                                                                {VARKMessage && (
                                                                                    <p className="text-danger">
                                                                                        {VARKMessage}
                                                                                    </p>
                                                                                )}

                                                                                <div className="text-center">
                                                                                    <span className="mb-3 mt-2 text-mute text-white text-center">
                                                                                        {prev && (
                                                                                            <button
                                                                                                className="btn btn-sm btn-primary"
                                                                                                style={{ cursor: "pointer", marginRight: "10px" }}
                                                                                                onClick={(e) => {
                                                                                                    PrevQuestion(e);
                                                                                                }}
                                                                                            >
                                                                                                <Icon
                                                                                                    icon="whh:arrowleft"
                                                                                                    width="15"
                                                                                                    height="15"
                                                                                                /> Previous &nbsp;
                                                                                            </button>
                                                                                        )}
                                                                                    </span>

                                                                                    <button
                                                                                        className="btn btn-sm btn-primary "
                                                                                        style={{ cursor: "pointer", marginLeft: "10px" }}
                                                                                        data-question-id={y?.question?.id}
                                                                                        data-category-id={
                                                                                            y?.question?.question_category_id
                                                                                        }
                                                                                        onClick={CustomNext}
                                                                                    >
                                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Next&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                        <Icon
                                                                                            icon="whh:arrowright"
                                                                                            width="15"
                                                                                            height="15"
                                                                                            style={{
                                                                                                pointerEvents: "none",
                                                                                            }}
                                                                                        />
                                                                                    </button>

                                                                                    <h3 className="text-white pt-4 fs-12 font-weight-normal d-none">{CurrentKey + 1 + " out of " + questionArr.length}</h3>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div className="text-center">
                                                                        {VARKMessage && (
                                                                            <p className="text-danger">
                                                                                {VARKMessage}
                                                                            </p>
                                                                        )}
                                                                        <span className="text-mute text-white text-center">
                                                                            {prev && (
                                                                                <button
                                                                                    className="btn btn-sm btn-primary mb-10 mt-1"
                                                                                    style={{ cursor: "pointer", marginRight: "10px" }}
                                                                                    onClick={(e) => {
                                                                                        PrevQuestion(e);
                                                                                    }}
                                                                                >
                                                                                    <Icon
                                                                                        icon="whh:arrowleft"
                                                                                        width="15"
                                                                                        height="15"
                                                                                    /> Previous &nbsp;
                                                                                </button>
                                                                            )}
                                                                        </span>

                                                                        {ShowSaveProgress && (
                                                                            <button
                                                                                className="btn btn-light btn-sm text-dark mb-10 mt-1"
                                                                                disabled={SaveProgress}
                                                                                onClick={(e) => {
                                                                                    UpdateTest(userAnswer);
                                                                                }}
                                                                            >
                                                                                {SaveProgress ? (
                                                                                    <div>
                                                                                        <Spinner
                                                                                            as="span"
                                                                                            animation="grow"
                                                                                            size="sm"
                                                                                            role="status"
                                                                                            aria-hidden="true"
                                                                                        />{" "}
                                                                                        Loading...
                                                                                    </div>
                                                                                ) : (
                                                                                    <div> Save Progress</div>
                                                                                )}
                                                                            </button>
                                                                        )}

                                                                        <h3 className="text-white pt-4 d-none">{CurrentKey + 1 + " out of " + questionArr.length}</h3>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {isLastTest && (
                                <div className="Welcome">
                                    <div className="">
                                        <div className="Welcome__intro text-center">
                                            <div className="">
                                                <h1 className="post-title mb-3 mt-2 text-white">
                                                    Click the below button to unlock your Career choices!
                                                </h1>
                                                <Link
                                                    className="btn btn-sm btn-primary"
                                                    to="/my-report"
                                                >
                                                    View Report
                                                </Link>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>


                        <div
                            className="assessment-progress minimized"
                            id="minimized-mobile-menu"
                        >
                            <div className="assessment-progress--inner" id="journey">
                                <div className="Journey">
                                    <div className="Journey--maximized">
                                        <div className="Journey__toggle">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fal"
                                                data-icon="chevron-up"
                                                className="svg-inline--fa fa-chevron-up fa-w-14"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                onClick={(e) => {
                                                    let ele = document.getElementById(
                                                        "minimized-mobile-menu"
                                                    );
                                                    ele.classList.add("minimized");
                                                }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M4.465 366.475l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L224 178.053l195.494 195.493c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-211.05-211.051c-4.686-4.686-12.284-4.686-16.971 0L4.465 349.505c-4.687 4.686-4.687 12.284 0 16.97z"
                                                ></path>
                                            </svg>
                                        </div>
                                        <h2 className="Journey__head sr-only">Your Progress</h2>
                                        <div className="Journey__body">
                                            {TestList.map((x, i) => (
                                                <div
                                                    className={`${CompletedTestIDArray.includes(x?.test?.id)
                                                        ? "Journey__milestone--complete"
                                                        : "Journey__milestone--not-started"
                                                        }`}
                                                    key={i}
                                                >
                                                    <div className="Journey__milestone__map">
                                                        <div className="Journey__milestone__map__marker">
                                                            <div className="Journey__milestone__map__marker__icon uil uil-check"></div>
                                                        </div>
                                                        {i !== 0 && (
                                                            <div className="Journey__milestone__map__trail">
                                                                <div
                                                                    className="Journey__milestone__map__trail__progress"
                                                                    style={{ transform: "translateY(0%)" }}
                                                                ></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="Journey__milestone__body">
                                                        <h3 className="Journey__milestone__name">
                                                            {Detail?.is_paid_package === false &&
                                                                x?.test?.is_paid === 1 && (
                                                                    <Icon icon="bi:lock" width="25" height="25" className="text-red" />
                                                                )}
                                                            {Detail?.is_paid_package === true &&
                                                                x?.test?.is_paid === 1 && (
                                                                    <Icon icon="bi:unlock" width="25" className="text-green" />
                                                                )}{" "}
                                                            &nbsp;&nbsp;
                                                            {x?.test?.name}
                                                        </h3>
                                                        <div>
                                                            {x?.test?.duration && (
                                                                <p className="Journey__milestone__description">
                                                                    {" "}
                                                                    - {x?.test?.duration > 1 ? timeconvert(x?.test?.duration) : timeconvert(x?.test?.duration)}
                                                                </p>
                                                            )}
                                                            {/*  {x?.test?.description && (
                                  <p className="Journey__milestone__description">
                                    {" "}
                                    - {x?.test?.description}
                                  </p>
                                )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="Journey--minimized">
                                        <div>
                                            <div className="Journey__head"> <div className="Journey__toggle">
                                                <svg
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fal"
                                                    data-icon="chevron-up"
                                                    className="svg-inline--fa fa-chevron-up fa-w-14"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512"
                                                    onClick={(e) => {
                                                        let ele = document.getElementById(
                                                            "minimized-mobile-menu"
                                                        );
                                                        ele.classList.remove("minimized");
                                                    }}
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M4.465 366.475l7.07 7.071c4.686 4.686 12.284 4.686 16.971 0L224 178.053l195.494 195.493c4.686 4.686 12.284 4.686 16.971 0l7.07-7.071c4.686-4.686 4.686-12.284 0-16.97l-211.05-211.051c-4.686-4.686-12.284-4.686-16.971 0L4.465 349.505c-4.687 4.686-4.687 12.284 0 16.97z"
                                                    ></path>
                                                </svg>
                                            </div></div>
                                            <div className="Journey__next-name">
                                                Check progress
                                            </div>
                                        </div>
                                        <div className="Journey__right">

                                        </div>
                                        <div className="Journey__progress">
                                            <div className="Journey__milestone--complete">
                                                <div className="Journey__milestone__trail">
                                                    <div
                                                        className="Journey__milestone__trail__progress"
                                                        style={{ transform: "translateX(0%)" }}
                                                    ></div>
                                                </div>
                                                <div className="Journey__milestone__marker"></div>
                                            </div>
                                            <div className="Journey__milestone--complete">
                                                <div className="Journey__milestone__trail">
                                                    <div
                                                        className="Journey__milestone__trail__progress"
                                                        style={{ transform: "translateX(0%)" }}
                                                    ></div>
                                                </div>
                                                <div className="Journey__milestone__marker"></div>
                                            </div>
                                            <div className="Journey__milestone--complete">
                                                <div className="Journey__milestone__trail">
                                                    <div
                                                        className="Journey__milestone__trail__progress"
                                                        style={{ transform: "translateX(0%)" }}
                                                    ></div>
                                                </div>
                                                <div className="Journey__milestone__marker"></div>
                                            </div>
                                            <div className="Journey__milestone--complete">
                                                <div className="Journey__milestone__trail">
                                                    <div
                                                        className="Journey__milestone__trail__progress"
                                                        style={{ transform: "translateX(0%)" }}
                                                    ></div>
                                                </div>
                                                <div className="Journey__milestone__marker"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>

            {/*     <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Detail?.description && (
            <div
              dangerouslySetInnerHTML={{
                __html: Detail?.description,
              }}
            />
          )}
          <div className="row">
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label=" I am ready to begin"
                value="1"
                onChange={(e) => {
                  SetAgree(e.target.value);
                }}
              />
            </Form.Group>
          </div>
          <div className="row">
            <button
              className="btn btn-primary rounded-pill"
              onClick={(e) => {
                StartAssessment(e);
              }}
            >
              Start Assessment
            </button>
          </div>
        </Modal.Body>
      </Modal> */}

            <Modal
                show={ShowAutocloseTest}
                onHide={() => {
                    ShowAutocloseTest(false);
                }}
                backdrop="static"
                centered
                keyboard={false}
            >
                <Modal.Body>
                    <center>
                        <Spinner animation="border" />
                    </center>
                    <center>Your time for this section is up</center>
                    <center>Please wait till we save your progress...</center>
                </Modal.Body>
            </Modal>

            <Modal
                show={ShowRetakeTest}
                onHide={() => {
                    SetShowRetakeTest(false);
                }}
                backdrop="static"
                centered
                keyboard={false}
            >
                <Modal.Body>
                    <center>
                        <Icon icon="icomoon-free:cancel-circle" height="25" width="25" /> &nbsp;&nbsp; Minimum number of questions not attempted.
                    </center>
                    <center>Please take a re-test.</center>
                    <center>
                        <button className="bnt btn-sm btn-primary mt-3" onClick={retakeTest}>Okay</button>
                    </center>
                </Modal.Body>
            </Modal>

            <ModalAlert
                ModalAlertDisplay={ModalAlertDisplay}
                SetModalModalAlertDisplay={SetModalModalAlertDisplay}
                ModalAlertMessage={ModalAlertMessage}
                // ModalAlertButton={ModalAlertButton}
                // ModalAlertSlug={ModalAlertSlug}
                backdropEvent="static"
            />
        </div>
    );
}

export default AssessmentDetail;
