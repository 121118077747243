import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Image from "../assets/img/user-default.png";
import * as Icon from "react-feather";
import { getLastSubscriptionPackageAPI } from "../Service";
import { useState, useEffect } from "react";

function Sidebar() {
  const [ExamStressManagementStatus,setExamStressManagementStatus] = useState(false);
  const [dataShow,setdataShow] = useState(false);
  let USER_TOKEN = Cookies.get("usr_token");

  let user = Cookies.get("user");

  let location = useLocation();
  if (user !== undefined) {
    user = JSON.parse(user);
  }
  let navigate = useNavigate();
  useEffect(() => {
    if (USER_TOKEN === undefined || user === undefined) {
      navigate("/");
    }
    window.scrollTo({ top: 0 });
    getLastSubscriptionPackage();
  }, []);

  const getLastSubscriptionPackage = async () => {
    let res = await getLastSubscriptionPackageAPI(USER_TOKEN);
    if (res?.is_success === true) {
      if(res?.data == null || res?.data == "null"){
        setdataShow(false);
        setExamStressManagementStatus(false);
      }else if((res?.data!= null || res?.data != "null") && res?.data?.get_package?.esm_active == "0"){
        setdataShow(false);
        setExamStressManagementStatus(false);
      }else if((res?.data!= null || res?.data != "null") && res?.data?.get_package?.esm_active == "1"){
        setdataShow(true);
        setExamStressManagementStatus(true);
      }
    }
  };

  return (
    <div>
      <div className="card-body text-center">
        {user?.image ? (
          <img style={{ width: '100%', height: 'auto' }}
            src={process.env.REACT_APP_BASE_URL + user?.image}
            className="user-image img-fluid"
            alt=""
          />
        ) : (
          <img style={{ width: '100%', height: 'auto' }}  src={Image} className="img-fluid user-image" alt="" />
        )}

        <h5 className="card-title mt-2 mb-1 text-primary">
          {user?.name ? user?.name.toUpperCase() : ""}
        </h5>
        <p className="card-text">{user?.phone}</p>
      </div>
      <ul className="list-group list-group-flush">
        <li
          className={`list-group-item ${
            location.pathname === "/dashboard" ? "active" : ""
          }`}
        >
          <Link to="/dashboard">
            <Icon.Home size={20} />
            &nbsp; Dashboard
          </Link>
        </li>
        <li
          className={`list-group-item ${
            location.pathname === "/my-counselling" ? "active" : ""
          }`}
        >
          <Link to="/my-counselling">
            <Icon.User size={20} />
            &nbsp;My Counselling
          </Link>
        </li>
        <li
          className={`list-group-item ${
            location.pathname === "/my-group-counselling" ? "active" : ""
          }`}
        >
          <Link to="/my-group-counselling">
            <Icon.Users size={20} />
            &nbsp;Group Counselling
          </Link>
        </li>
        {user?.type == 1 && (
          <li
            className={`list-group-item ${
              location.pathname === "/my-report" ? "active" : ""
            }`}
          >
            <Link to="/my-report">
              <Icon.FileText size={20} />
              &nbsp;My Report
            </Link>
          </li>
        )}
        
        {(user?.type == 1 && dataShow) && (
          <li
            className={`list-group-item ${
              location.pathname === "/exam-stress-management" ? "active" : ""
            }`}
          >
            <Link to="/exam-stress-management">
              <Icon.FileText size={20} />
              &nbsp;Exam Stress Management
            </Link>
          </li>
        )}

        <li
          className={`list-group-item ${
            location.pathname === "/profile" ? "active" : ""
          }`}
        >
          <Link to="/profile">
            <Icon.Edit size={20} />
            &nbsp; My Profile
          </Link>
        </li>
        {user?.type === 1 && (
          <li
            className={`list-group-item ${
              location.pathname === "/my-subscription" ? "active" : ""
            }`}
          >
            <Link to="/my-subscription">
              <Icon.ShoppingCart size={20} />
              &nbsp; My Subscription
            </Link>
          </li>
        )}
        <li className={`list-group-item ${
              location.pathname === "/change-password" ? "active" : ""
            }`}>
          <Link to="/change-password">
            <Icon.Key size={20} />
            &nbsp;Change Password
          </Link>
        </li>
        <li className="list-group-item">
          <Link to="/logout" className="text-danger">
            <Icon.LogOut size={20} />
            &nbsp;Logout
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
