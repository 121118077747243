import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import Sidebar from "../../layouts/Sidebar";
import { fetchMySubscriptionsAPI } from "../../Service";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import 'moment-timezone';
import Moment from 'react-moment';
import BackToTop from "../../layouts/BackToTop"
import Seo from "../../layouts/SEO";

function MySubscription() {
  const [showScroll, setShowScroll] = useState(false);
  const list = [
    { name: "Home", url: "/" },
    { name: "My Subscription", url: null },
  ];
  const navigate = useNavigate();
  const [OrdersList, SetOrdersList] = useState([]);
  const [state, SetState] = useState({});
  let USER_TOKEN = Cookies.get("usr_token");
  let MetaDetails = {
    'title': 'My Subscription | ' + process.env.REACT_APP_NAME,
    'meta_title': 'My Subscription | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'My Subscription Mere Mentor',
    'meta_description': 'Mere Mentor is an EdTech organization delivering career counselling services to the students and youth of the country.',
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    if (USER_TOKEN === undefined) {
      navigate("/");
    }
    MySubscriptionList();
  }, []);

  const MySubscriptionList = async (pageNumber = 1) => {
    let res = await fetchMySubscriptionsAPI(USER_TOKEN, pageNumber);
    if (res?.is_success === true) {
      SetState(res?.orders)
      SetOrdersList(res?.orders?.data)
    }
  };

  return (
    <div>
      <HeaderComponent />
      <Seo options={MetaDetails} />
      <BreadCumb name={"My Subscription"} list={list} />
      <section className="myprofile-section ptb-50">
        <div className="container">
          <div className="pricing-wrapper position-relative mt-n18 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row justify-content-between main-area">
              <div className="col-lg-3">
                <div className="myprofile-sidebar rounded-3">
                  <div className="card">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="myprofile-content rounded-3 p-5">
                  <div className="dashboard-quick-widget">
                    <h4 className="mt-4">My Subscription</h4>
                    <div className="table-responsive my-4">
                      <table className="table table-bordered bg-white">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Expiry Date</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {OrdersList.map((x, i) => (
                            <tr key={i}>
                              <td>{x?.package?.title}({x?.category?.name})</td>
                              <td><Moment tz="Asia/Kolkata" format="D MMM YYYY">{x.expired_at}</Moment></td>
                              <td>
                                {x?.is_active === 1 && (
                                  <span className="badge bg-success">Active</span>
                                )}
                                {x?.is_active === 0 && (
                                  <span className="badge bg-danger">Expired</span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    < Pagination
                      activePage={state?.current_page ? state?.current_page : 0}
                      itemsCountPerPage={state?.per_page ? state?.per_page : 0}
                      totalItemsCount={state?.total ? state?.total : 0}
                      pageRangeDisplayed={5}
                      onChange={(pageNumber) => {
                        MySubscriptionList(pageNumber)
                      }}
                      itemClass="page-item"
                      linkClass="page-link"
                      className="mt-1"
                    />
                    <p className="float-right">Showing {state?.from ? state?.from : 0}-{state?.to ? state?.to : 0} of {state?.total ? state?.total : 0} entries</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default MySubscription;
