import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import { Link, useParams, useNavigate } from "react-router-dom";
import { meetingDetailsAPI } from "../../Service";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "moment-timezone";
import Moment from "react-moment";

function MeetingDetails() {
  let { encrypt_id } = useParams();
  const list = [
    { name: "Home", url: "/" },
    { name: "Counselling Meeting Details", url: null },
  ];
  const [MeetingStatus, SetMeetingStatus] = useState(1);
  let USER_TOKEN = Cookies.get("usr_token");
  let usr = Cookies.get("user");
  if (usr !== undefined) {
    usr = JSON.parse(Cookies.get("user"));
  }
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (USER_TOKEN === null) {
      navigate("/");
    }
    fetchmeetingDetailsAPI();
  }, []);

  const [detail, SetDetail] = useState({});
  const fetchmeetingDetailsAPI = async () => {
    let res = await meetingDetailsAPI(encrypt_id, USER_TOKEN);
    if (res?.is_success) {
      SetDetail(res?.details);
      SetMeetingStatus(res?.details?.meetingstatus)
    } else {
      navigate("/");
    }
  };

  let status = {
    0: "Pending",
    1: "Meeting to be scheduled",
    2: "Meeting is scheduled",
    3: "Meeting is re-scheduled",
    4: "Meeting is completed",
    5: "Meeting is cancelled",
    6: "Counsellor Report Submitted",
    7: "Report Sent",
  };

  return (
    <div>
      <HeaderComponent />
      <BreadCumb name={"Counselling Meeting Details"} list={list} />
      <section className="ptb-50 counselling-time">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className=""
                style={{ backgroundColor: "transparent" }}
              >
                <div className="row justify-content-md-center">
                  <div className="col-md-7">
                    <div className="card  p-2  mt-n18">
                      <time
                        dateTime={`${detail?.first_prefer_date} ${detail?.websitetimeformat}`}
                        className="icon"
                      >
                        <em>
                          {new Date(detail?.first_prefer_date).getDay() === 0 &&
                            "Sun"}
                          {new Date(detail?.first_prefer_date).getDay() === 1 &&
                            "Mon"}
                          {new Date(detail?.first_prefer_date).getDay() === 2 &&
                            "Tue"}
                          {new Date(detail?.first_prefer_date).getDay() === 3 &&
                            "Wed"}
                          {new Date(detail?.first_prefer_date).getDay() === 4 &&
                            "Thu"}
                          {new Date(detail?.first_prefer_date).getDay() === 5 &&
                            "Fri"}
                          {new Date(detail?.first_prefer_date).getDay() === 6 &&
                            "Sat"}
                        </em>
                        <strong>
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            0 && "Jan"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            1 && "Feb"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            2 && "Mar"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            3 && "Apr"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            4 && "May"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            5 && "Jun"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            6 && "July"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            7 && "Aug"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            8 && "Sep"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            9 && "Oct"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            10 && "Nov"}
                          {new Date(detail?.first_prefer_date).getMonth() ===
                            11 && "Dec"}
                        </strong>
                        <span>
                          {new Date(detail?.first_prefer_date).getDate()}
                        </span>
                      </time>

                      <div className="clearfix"></div>

                      <div className="row justify-content-center">
                        <div className="col-md-5">
                          <p className="mb-0 text-center">
                            <strong>{status[detail?.status]}</strong>
                          </p>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-md-7">
                          <p>&nbsp;</p>
                          {usr?.type === 1 && (
                            <p><b>Counsellor :</b> {detail?.counsellor?.name}</p>
                          )}

                          {usr?.type === 2 && (
                            <p><b>Student :</b>{detail?.student?.name}</p>
                          )}

                          <p><b>Meeting ID :</b> {detail?.id}</p>
                          <p>
                            <b>Meeting Date :{" "}</b>
                            <Moment tz="Asia/Kolkata" format="D MMM YYYY">
                              {detail?.first_prefer_date}
                            </Moment>
                          </p>
                          <p><b>Meeting Time :</b> {detail?.websitetimeformat}</p>
                          <p>
                            <b>Platform :</b> {" "}
                            {detail?.platform === "1" && (
                              <span className="badge bg-primary">
                                Mere Mentor
                              </span>
                            )}
                            {detail?.platform === "2" && (
                              <span className="badge bg-primary">
                                Google Meet
                              </span>
                            )}
                            {detail?.platform === "3" && (
                              <span className="badge bg-primary"><b>Zoom</b></span>
                            )}
                          </p>
                          {detail?.platform !== "1" && (
                            <p>
                              <b>Link : </b>{" "}
                              <a
                                target="blank"
                                href={detail?.link}
                                className="badge bg-success"
                              >
                                {detail?.link}
                              </a>
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>

                <div className="who-we-are mb-5">
                  <div className="row align-items-center">
                    <div className="col-lg-12 text-center">
                      {detail?.platform === "1" ? (
                        <>

                          {MeetingStatus === 1 && (
                            <button type="button"
                              className="btn btn-primary btn-lg mt-3 mb-5"
                            >
                              Not Started
                            </button>
                          )}

                          {MeetingStatus === 2 && (
                            <Link
                              target="blank"
                              className="btn btn-primary btn-lg mt-3 mb-5"
                              to={`/counselling/${encrypt_id}`}
                            >
                              Let's Start
                            </Link>
                          )}

                          {MeetingStatus === 3 && (
                            <span className="badge bg-primary mt-3">
                              <h1 className="text-white mt-3">Meeting Completed</h1>
                            </span>
                          )}

                        </>
                      ) : (
                        <a
                          target="blank"
                          className="btn btn-primary btn-lg mt-3 mb-5"
                          href={detail?.link}
                        >
                          Let's Start
                        </a>
                      )}
                    </div>

                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-7">
                      <div className="d-flex flex-row">
                        <ul className="who-we-are-list mt-5 mb-3">
                          <li className="maxwidth-100 mt-2">
                            <span>1</span>
                            You can start the counselling session by clicking the "let's start". The button will only work when the time for your session will come
                          </li>
                          <li className="maxwidth-100 mt-2">
                            <span>2</span>
                            A session of 1 hour will be conducted. Your parents can also join towards the end of the session. you will be informed towards the end of the session to call them
                          </li>
                          <li className="maxwidth-100 mt-2">
                            <span>3</span>
                            You require the google meet application to start the counselling session. To join meeting, copy the link and paste in google meet window
                          </li>
                          <li className="maxwidth-100 mt-2">
                            <span>4</span>
                            Keep a notebook,pen/pencil with you. you can note down the important points mentioned by the counsellor.
                          </li>
                          <li className="maxwidth-100 mt-2">
                            <span>5</span>
                            Please check that your internet is working well. your device should have a camera, headphones/speaker and microphone, Make sure everything is working fine. Do check this prior to the session
                          </li>
                          <li className="maxwidth-100 mt-2">
                            <span>6</span>
                            To have a healthy session, please dont be hesitant during the session. Put up all your doubts &amp; questions ready before the session. So that you can clarify with the counsellor during the session
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default MeetingDetails;
