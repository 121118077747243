import { useEffect, useState } from "react";
import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { useNavigate, Link } from "react-router-dom";
import AlertComponent from "../layouts/Alert";
import { Button, Spinner } from "react-bootstrap";
import validator from "validator";
import Message from "../layouts/Message";
import { GetStatesAPI, getProductsAPI, groupPricingEnquiryStoreApi } from "../Service";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import BreadCumb from "../layouts/BreadCumb";

function GroupPricingEnquiry() {
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [name, SetName] = useState("");
  const [organization, SetOrganization] = useState("");
  const [email, SetEmail] = useState("");
  const [mobile, SetMobile] = useState("");
  const [states, SetStates] = useState([]);
  const [userState, SetUserState] = useState(0);
  const [products, SetProducts] = useState([]);
  const [userProduct, SetUserProduct] = useState(0);
  const [studentCount, SetStudentCount] = useState();
  const [submitButton, SetSubmitButton] = useState(false);
  const navigate = useNavigate();
  const [MessageDisplay, SetMessageDisplay] = useState(false);
  const [MessageContent, SetMessageContent] = useState([]);
  let initialFormData = "";

  const [inputField, setInputField] = useState(initialFormData)

  const [value, setSliderValue] = useState(100);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    GetStates();
    getProducts();
    SetStudentCount(100);
  }, []);

  const groupPricingSubmit = async (e) => {
    SetSubmitButton(true);
    let data = {
      name: name,
      organization: organization,
      email: email,
      mobile: mobile,
      state: userState,
      product: userProduct,
      studentCount: studentCount,
    };

    let err = [];
    var mandatoryFilled = true;
    if (name == "" || mobile == "" || email == "" || organization == '' || userState === 0 || userProduct === 0) {
      err.push("Please fill the mandatory fields");
      mandatoryFilled = false;
    }

    if (mandatoryFilled == true) {
      if (!validator.isLength(name, { min: 3, max: 255 })) {
        err.push("Name should have minimum 3 characters");
      }
      if (!validator.isLength(mobile, { min: 10, max: 10 })) {
        err.push("Mobile Number Must be 10 digits");
      }
      if (!validator.isNumeric(mobile)) {
        err.push("Mobile Number Must be in digits");
      }
      if (!validator.isEmail(email)) {
        err.push("Enter a Valid Email");
      }
      if (organization == "") {
        err.push("Organization is required");
      }
      if (userState === 0) {
        err.push("State is required");
      }
      if (userProduct === 0 || userProduct == 'undefined' || userProduct === undefined) {
        err.push("Product is required");
      }
      if (studentCount === 0 || isNaN(parseFloat(studentCount))) {
        err.push("Student Count is required and it Must be creater than 0.");
      }
    }

    if (err.length > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      SetAlertDisplay(true);
      SetAlertMessage(err);
      SetSubmitButton(false);
    } else {
      let res = await groupPricingEnquiryStoreApi(data);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
      if (res?.is_success === true) {
        SetSubmitButton(false);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        setTimeout(() => {
          navigate('/products');
        }, 2000);
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
        SetSubmitButton(false);
      }
    }
  };


  const GetStates = async () => {
    let res = await GetStatesAPI();
    if (res?.is_success === true) {
      SetStates(res?.states);
    }
  };

  const getProducts = async () => {
    let res = await getProductsAPI();
    if (res?.is_success === true) {
      SetProducts(res?.categories);
    }
  };

  const handleSliderChange = (newValue) => {
    setSliderValue(newValue);
    SetStudentCount(newValue);
  };

  const handleInputChange = (event) => {
    if (event.target.value < 0) {
      setSliderValue(0);
      SetStudentCount(0);
    } else if (event.target.value > 1000) {
      setSliderValue(1000);
      SetStudentCount(1000);
    }
    setSliderValue(event.target.value === '' ? '' : Number(event.target.value));
    SetStudentCount(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setSliderValue(0);
      SetStudentCount(0);
    } else if (value > 1000) {
      setSliderValue(1000);
      SetStudentCount(1000);
    }
  };

  const list = [
    { name: "Home", url: "/" },
    { name: "Group Pricing", url: "/group-pricing" },
  ];

  return (
    <>
      <HeaderComponent />
      <Message
        MessageDisplay={MessageDisplay}
        MessageContent={MessageContent}
        SetMessageDisplay={SetMessageDisplay}
      />
      <BreadCumb name={"Group Pricing"} list={list} />
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            >
            </div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            >
            </div>
            <div className="row">
              <div className="col-lg-2">
              </div>
              <div className="col-lg-8">
                <div className="quote-item">
                  <div className="content d-none">
                    <h3 className="text-center">Group Pricing</h3>
                  </div>
                  <AlertComponent
                    AlertDisplay={AlertDisplay}
                    SetAlertDisplay={SetAlertDisplay}
                    AlertMessage={AlertMessage}
                  />
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Your name *"
                          onChange={(e) => {
                            SetName(e.target.value);
                          }}
                          value={name}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          name="organization"
                          className="form-control"
                          placeholder="Your Organization Name *"
                          onChange={(e) => {
                            SetOrganization(e.target.value);
                          }}
                          value={organization}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group mb-3">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          required=""
                          placeholder="Your email address *"
                          onChange={(e) => {
                            SetEmail(e.target.value);
                          }}
                          value={email}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group mb-3">
                        <input
                          type="number"
                          minLength="10"
                          maxLength="10"
                          name="phone"
                          className="form-control"
                          required=""
                          placeholder="Your phone number *"
                          onChange={(e) => {
                            SetMobile(e.target.value);
                          }}
                          value={mobile}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group mb-3">
                        <select
                          className="form-control form-select"
                          name="state"
                          defaultValue={userState}
                          onChange={(e) => {
                            SetUserState(e.target.value);
                          }}
                        >
                          <option value="0" disabled>
                            Please select your State
                          </option>
                          {states.map((x, i) => (
                            <option value={x.id} key={x.id}>
                              {x.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group mb-3">
                        <select
                          className="form-control form-select"
                          name="product"
                          defaultValue={userProduct}
                          onChange={(e) => {
                            SetUserProduct(e.target.value);
                          }}
                        >
                          <option value="0" disabled>
                            Please select your Product
                          </option>
                          {products.map((x, i) => (
                            <option value={x.id} key={x.id}>
                              {x.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <label
                        className="mt-1 col-form-label text-right"
                      >
                        How many licenses do you want to purchase?{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="form-group mb-3">
                        <Slider
                          min={25}
                          max={1000}
                          value={typeof value === 'number' ? value : 0}
                          onChange={handleSliderChange}
                          aria-labelledby="input-slider"
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group mb-3">
                        <input
                          name="studentCount"
                          className="form-control"
                          type="text"
                          value={value}
                          size="small"
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          inputProps={{
                            step: 10,
                            min: 0,
                            max: 1000,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 text-center">
                      <Button
                        className="btn btn-primary rounded-pill btn-send mb-3"
                        disabled={submitButton}
                        onClick={(e) => {
                          groupPricingSubmit(e);
                        }}
                      >
                        {submitButton ? (
                          <div>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />{" "}
                            Loading...
                          </div>
                        ) : (
                          <div>Submit</div>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default GroupPricingEnquiry;