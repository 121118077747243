import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";

function HomeBanner() {
  const setting = JSON.parse(localStorage.getItem("setting"));
  return (
    <section className="wrapper bg-dark-blue main-banner">
      <div className="container">
        <div className="card bg-soft-primary bg-dark-blue rounded-4 mb-14">
          <div className="navigation-wrapper">
            <div className="card-body pb-0 pt-xl-9 pb-lg-12 px-xl-12 bg-dark-blue rounded-4">
              <div className="row gx-lg-8 gx-xl-0 gy-10 align-items-center">
                <div className="col-lg-6 order-lg-2 d-flex position-relative ">
                  <img rel="preload" style={{ width: '100%', height: 'auto' }}
                    className="img-fluid ms-auto mx-auto me-lg-8"
                    src={process.env.REACT_APP_BASE_URL + setting?.banner}
                    alt={setting?.title}
                  />
                </div>
                <div
                  className="col-lg-6 text-center text-lg-start"
                  data-cues=""
                  data-group="page-title"
                  data-delay="600"
                >
                  <div
                    className=" text-center text-lg-start order-2 order-lg-0"
                    data-cues=""
                    data-group="page-title"
                    data-delay="600"
                  >
                    <h1 className="display-2 mb-2 mx-md-10 mx-lg-0 title-1">
                      Pathway To Your Passion{" "}
                    </h1>
                    <h1 style={{ fontSize: '22px' }} className="display-2 mb-5 mx-md-10 mx-lg-0 title-2">
                      <Typewriter
                        options={{
                          strings: [
                            "Step by step to the Alps",
                            "Fitting all the pieces...",
                            "Onwards and upwards...",
                          ],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </h1>
                    <p className="lead fs-23 mb-7 description">
                      Tech based Career Counselling Organization <br /> that strives to
                      make you dream big
                    </p>
                    <div
                      className="d-flex justify-content-center justify-content-lg-start mb-4"
                      data-cues=""
                      data-group="page-title-buttons"
                      data-delay="900"
                    >
                      <span>
                        <Link to="/assessments" className="btn btn-lg btn-primary rounded-pill me-2 scroll home-banner-button">
                          Take A Free Assessment
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeBanner;
