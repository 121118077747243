import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import Sidebar from "../../layouts/Sidebar";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Badge } from "react-bootstrap";
import { myReportAPI, ReportDownloadAPI, GovReportDownloadAPI } from "../../Service";
import { Icon } from "@iconify/react";
import AlertComponent from "../../layouts/Alert";
import "moment-timezone";
import Moment from "react-moment";
import BackToTop from "../../layouts/BackToTop"
import Seo from "../../layouts/SEO";
function MyReport() {
   const list = [
      { name: "Home", url: "/" },
      { name: "MyReport", url: null },
   ];
   let USER_TOKEN = Cookies.get("usr_token");
   const navigate = useNavigate();
   const [showScroll, setShowScroll] = useState(false);
   const [AlertDisplay, SetAlertDisplay] = useState(false);
   const [AlertMessage, SetAlertMessage] = useState([]);
   const [report, SetReport] = useState([]);
   const [govreport, SetGovReport] = useState([]);
   let MetaDetails = {
      'title': 'My Report | ' + process.env.REACT_APP_NAME,
      'meta_title': 'My Report | ' + process.env.REACT_APP_NAME,
      'meta_keywords': 'My Report Mere Mentor',
      'meta_description': 'Mere Mentor is an EdTech organization delivering career counselling services to the students and youth of the country.',
   }

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      });
      if (USER_TOKEN === undefined) {
         navigate("/");
      }
      fetchMyReportAPI();
   }, []);

   const fetchMyReportAPI = async () => {
      let res = await myReportAPI(USER_TOKEN);
      if (res?.is_success) {
         SetAlertMessage([]);
         SetAlertDisplay(false);
         SetReport(res?.reports);
         SetGovReport(res?.gov_reports);
      } else {
         SetAlertDisplay(true);
         SetAlertMessage([res?.errors]);
      }
   };

   const download = async (e, id) => {
      let res = await ReportDownloadAPI(USER_TOKEN, id);
      if (res?.is_success) {
         const linkSource = `data:application/pdf;base64,${res?.file}`;
         const downloadLink = document.createElement("a");
         const fileName = res?.filename;
         downloadLink.href = linkSource;
         downloadLink.download = fileName;
         downloadLink.click();
      } else {
         SetAlertDisplay(true);
         SetAlertMessage([res?.errors]);
      }
   };

   const govDownload = async (e, id) => {
      console.log('id', id);
      let res = await GovReportDownloadAPI(USER_TOKEN, id);
      if (res?.is_success) {
         const linkSource = `data:application/pdf;base64,${res?.file}`;
         const downloadLink = document.createElement("a");
         const fileName = res?.filename;
         downloadLink.href = linkSource;
         downloadLink.download = fileName;
         downloadLink.click();
      } else {
         SetAlertDisplay(true);
         SetAlertMessage([res?.errors]);
      }
   };

   return (
      <div>
         <HeaderComponent />
         <Seo options={MetaDetails} />
         <BreadCumb name={"My Report"} list={list} />
         <section className="myprofile-section ptb-50">
            <div className="container">
               <div className="pricing-wrapper position-relative mt-n18 mb-12 mb-md-15">
                  <div
                     className="shape bg-dot primary rellax w-16 h-18"
                     data-rellax-speed="1"
                     style={{ top: "-4rem", right: "-2.4rem" }}
                  ></div>
                  <div
                     className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
                     data-rellax-speed="1"
                     style={{ bottom: "0.5rem", left: "-2.5rem" }}
                  ></div>
                  <div className="row justify-content-between main-area">
                     <div className="col-lg-3">
                        <div className="myprofile-sidebar rounded-3">
                           <div className="card">
                              <Sidebar />
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-9">
                        <div className="myprofile-content rounded-3 p-5">
                           <div className="dashboard-quick-widget">
                              <h4 className="mt-4">My Report</h4>
                              <br />
                              <AlertComponent
                                 AlertDisplay={AlertDisplay}
                                 SetAlertDisplay={SetAlertDisplay}
                                 AlertMessage={AlertMessage}
                              />
                              <br />
                              <div className="table-responsive">
                                 <table className="table table-bordered bg-white">
                                    <thead>
                                       <tr>
                                          <th scope="col">S.No.</th>
                                          <th scope="col">Name</th>
                                          <th scope="col">Status</th>
                                          <th scope="col">Date</th>
                                          <th scope="col">Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {report.length>0 && report.map((x, i) => (
                                          <tr key={i}>
                                             <td>{i + 1}</td>
                                             <td>{x?.assessment?.name}</td>
                                             <td>
                                                <Badge pill bg="success">
                                                   Completed
                                                </Badge>{" "}
                                             </td>
                                             <td>
                                                <Moment tz="Asia/Kolkata" format="D MMM YYYY">
                                                   {x?.updated_at}
                                                </Moment>
                                             </td>

                                             <td>
                                                <button
                                                   className="bnt btn-sm btn-primary"
                                                   onClick={(e) => {
                                                      download(e, x?.encryptid);
                                                   }}
                                                >
                                                   <Icon
                                                      icon="bxs:download"
                                                      width="20"
                                                      height="20"
                                                   />{" "}
                                                   Download
                                                </button>
                                             </td>
                                          </tr>
                                       ))}
                                       {console.log('govreport', govreport)}
                                       {govreport.length>0 && govreport.map((x, i) => (

                                          <tr key={i}>
                                             <td>{i + 1}</td>
                                             <td>{x?.assessment?.name}</td>
                                             <td>
                                                <Badge pill bg="success">
                                                   Completed
                                                </Badge>{" "}
                                             </td>
                                             <td>
                                                <Moment tz="Asia/Kolkata" format="D MMM YYYY">
                                                   {x?.updated_at}
                                                </Moment>
                                             </td>
                                             <td>
                                                <button
                                                   className="bnt btn-sm btn-primary"
                                                   onClick={(e) => {
                                                      govDownload(e, x?.encryptid);
                                                   }}
                                                >
                                                   <Icon
                                                      icon="bxs:download"
                                                      width="20"
                                                      height="20"
                                                   />{" "}
                                                   Download
                                                </button>
                                             </td>
                                          </tr>
                                       ))}
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <Footer />
         <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
      </div>
   );
}
export default MyReport;
