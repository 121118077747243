function BackToTop({showScroll,setShowScroll}){

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
          setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
          setShowScroll(false);
        }
      };
    
      const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      };
    
      window.addEventListener("scroll", checkScrollTop);

    return(
        <div
        className="progress-wrap active-progress"
        onClick={scrollTop}
        style={{ display: showScroll ? "block" : "none" }}
      >
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    )
}
export default BackToTop;