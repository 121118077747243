import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import * as Icon from "react-feather";
import { useEffect, useState } from "react";
import validator from "validator";
import AlertComponent from "../layouts/Alert";
import { ResetPasswordAPI,CheckResetPasswordLinkAPI } from "../Service";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Message from "../layouts/Message";
import BreadCumb from "../layouts/BreadCumb";

export default function ForgetPassword() {

  let [searchParams, setSearchParams] = useSearchParams();
  let email = searchParams.get("email");
  let { token } = useParams();
  const [password, SetPassword] = useState("");
  const [confirmPassword, SetConfirmPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const navigate = useNavigate();
  const [MessageDisplay, SetMessageDisplay] = useState(false);
  const [MessageContent, SetMessageContent] = useState([]);

  useEffect(() => {
    checkLink();
  }, []);

    const checkLink = async() => {
        let data = {
            token:token,
            email:email
        }
        let res = await CheckResetPasswordLinkAPI(data);
        console.log(res)
        if(res?.is_success === false){
            SetAlertDisplay(true);
            SetAlertMessage(res?.errors ?? []);
            setTimeout(() => {
               // navigate('/')
            },1000)
        }else{
            SetAlertDisplay(false);
            SetAlertMessage([]);
        }
    }

  const updatePassword = async () => {
    let data = {
      password: password,
      confirm_password: confirmPassword,
      email: email,
      token: token,
    };

    var err = [];
    if (!validator.isLength(password, { min: 8, max: 255 })) {
      err.push("Password must be of minimum 8 characters");
    }
    if (!validator.equals(confirmPassword, password)) {
      err.push("Password and Confirm Password Doesn't match");
    }

    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      let res = await ResetPasswordAPI(data);
      if (res?.is_success) {
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        setTimeout(() => {
            navigate('/')
        },3000)
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
      }
    }
  };

  const list = [
    { name: "Home", url: "/" },
    { name: "Reset Password", url: null },
  ];

  return (
    <div>
      <HeaderComponent />
      <BreadCumb name={"Reset Password"} list={list} />
      <section className="wrapper bg-light blogs">
        <div className="container pt-10 pb-18 mb-8 text-left">
          <div className="row ">
            <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 mt-n20 ">
              <div className="contact-form card p-5">
                <AlertComponent
                  AlertDisplay={AlertDisplay}
                  SetAlertDisplay={SetAlertDisplay}
                  AlertMessage={AlertMessage}
                />

                <div className="row">
                  <div className="col-md-8 offset-2">
                    <div className="form-group mb-4">
                    <label for="exampleFormControlInput1">Email address</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          value={email}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 offset-2">
                    <div className="form-group mb-4">
                      <label for="exampleFormControlInput1">Password</label>
                      <div className="input-group mb-3">
                        <input
                          type={isRevealPwd ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => {
                            SetPassword(e.target.value);
                          }}
                        />
                        <span
                          className="input-group-text"
                          onClick={() => {
                            setIsRevealPwd((prevState) => !prevState);
                          }}
                        >
                          {isRevealPwd ? <Icon.EyeOff /> : <Icon.Eye />}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 offset-2">
                    <div className="form-group mb-4">
                      <label htmlFor="confirm_password">Confirm Password *</label>
                      <input
                        type="text"
                        id="confirm_password"
                        className="form-control"
                        placeholder="Confirm Password"
                        required
                        onChange={(e) => {
                          SetConfirmPassword(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary rounded-pill btn-send mb-3"
                      onClick={(e) => {
                        updatePassword(e);
                      }}
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Toast */}
      <Message
        MessageDisplay={MessageDisplay}
        MessageContent={MessageContent}
        SetMessageDisplay={SetMessageDisplay}
      />

      <Footer />
    </div>
  );
}
