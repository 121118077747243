import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import BreadCumb from "../layouts/BreadCumb";
import { useState, useEffect } from "react";
import { getProductsAPI } from "../Service";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
import ModalAlert from "../layouts/ModalAlert";
import { Form, Button, Modal, Alert } from "react-bootstrap";

function Products() {
  let [searchParams, setSearchParams] = useSearchParams();
  let is_messages = searchParams.get("is_messages");
  let button = searchParams.get("button");
  let slug = searchParams.get("slug");

  const [showScroll, setShowScroll] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [Category, SetCategory] = useState([]);
  const list = [
    { name: "Home", url: "/" },
    { name: "Products", url: null },
  ];
  const handleShow = (e, type) => {
    setShow(true);
  };

  const setting = JSON.parse(localStorage.getItem("setting"));
  let MetaDetails = {
    'title': 'Products | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Products | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Mere Mentor Products',
    'meta_description': 'Aims to reduce the gap in the career selection problems faced by 8th-9th, 10th-12th, College Graduates, Working Professional',
  }

  const [ModalAlertDisplay, SetModalModalAlertDisplay] = useState(false);
  const [ModalAlertMessage, SetModalAlertMessage] = useState([]);
  const [ModalAlertButton, SetModalAlertButton] = useState("");
  const [ModalAlertSlug, SetModalAlertSlug] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (is_messages) {
      SetModalModalAlertDisplay(true);
      SetModalAlertMessage([is_messages]);
      SetModalAlertButton(button);
      SetModalAlertSlug(slug)
    }
    getProducts();
  }, []);

  const getProducts = async () => {
    let res = await getProductsAPI();
    if (res?.is_success === true) {
      SetCategory(res?.categories);
    }
  };

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      <BreadCumb name="Products" list={list} />
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>

            {/*  <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0">
              {Category.map((x, i) => (
                <div className="col-md-3 col-lg-3 mt-3" key={i}>
                  <div className="position-relative">
                    <div
                      className="shape rounded bg-soft-blue rellax d-md-block"
                      data-rellax-speed="0"
                      style={{
                        bottom: "-0.75rem",
                        right: "-0.75rem",
                        width: "98%",
                        height: "98%",
                        zIndex: "0",
                      }}
                    ></div>
                    <div className="card">
                      <figure className="card-img-top text-center mt-5">
                        <Link to={`/products/${x.slug}`}>
                          <Icon
                            icon={x?.code}
                            color="black"
                            width="100"
                            height="100"
                          />
                        </Link>
                      </figure>
                      <div className="card-body px-6 py-5">
                        <Link to={`/products/${x.slug}`}>
                          <h4 className="mb-1 text-center">{x.name}</h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}

            <div className="row gx-md-5 gy-5 text-center">
              {Category.map((x, i) => (
                <div className="col-md-6 col-xl-3" key={i}>
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <Icon
                        icon={x?.icon_code}
                        color={x?.color}
                        width="100"
                        height="100"
                      />
                      <h4 className="mt-1">{x.name}</h4>
                      <p className="mb-2">
                        {x?.short_description && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: x?.short_description,
                            }}
                          />
                        )}
                      </p>
                      <Link
                        to={`/products/${x.slug}`}
                        className="btn
                        btn-sm
                        btn-primary"
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="col-md-6 col-xl-6">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <h4 className="mt-1">For vendor Pricing</h4>
                    <Link
                      to={`/group-pricing/`}
                      className="btn
                      btn-sm
                      btn-primary"
                    >
                      View
                    </Link>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ModalAlert
        ModalAlertDisplay={ModalAlertDisplay}
        SetModalModalAlertDisplay={SetModalModalAlertDisplay}
        ModalAlertMessage={ModalAlertMessage}
        ModalAlertButton={ModalAlertButton}
        ModalAlertSlug={ModalAlertSlug}
        backdropEvent="static"
      />

      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default Products;
