import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import AlertComponent from "../../layouts/Alert";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import "moment-timezone";
import Moment from "react-moment";
import { Icon } from "@iconify/react";
import Sidebar from "../../layouts/Sidebar";
import { groupCounsellingeAPI } from "../../Service";
import Cookies from "js-cookie";
import {
  ReportDownloadAPI,
  groupCounsellingStatusUpdateAPI,
  storeGroupCounsellingActionPlanDownloadAPI
} from "../../Service";
import { Modal, Button, Table } from "react-bootstrap";
import BackToTop from "../../layouts/BackToTop"
import Seo from "../../layouts/SEO";

function GroupCounselling() {
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [CompleteMeeting, SetCompleteMeeting] = useState(false);
  const list = [
    { name: "Home", url: "/" },
    { name: "Group Counselling", url: null },
  ];
  const [Detail, SetDetail] = useState([]);
  let USER_TOKEN = Cookies.get("usr_token");
  let user = JSON.parse(Cookies.get("user"));
  const [counsellingEncryptId, SetcounsellingEncryptId] = useState(false);
  let MetaDetails = {
    'title': 'Group Career Counselling | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Group Career Counselling | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Group Career Counselling Mere Mentor',
    'meta_description': 'Mere Mentor is an EdTech organization delivering career counselling services to the students and youth of the country.',
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchGroupCounsellingList();
  }, []);

  const fetchGroupCounsellingList = async () => {
    let res = await groupCounsellingeAPI(USER_TOKEN);
    if (res?.is_success) {
      SetDetail(res?.counsellings);
    }
  };

  const download = async (e, id) => {
    let res = await ReportDownloadAPI(USER_TOKEN, id);
    if (res?.is_success) {
      const linkSource = `data:application/pdf;base64,${res?.file}`;
      const downloadLink = document.createElement("a");
      const fileName = res?.filename;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      SetAlertDisplay(true);
      SetAlertMessage([res?.errors]);
    }
  };

  const confirmMeeting = async () => {
    let data = {
      status: 4,
    };
    let res = await groupCounsellingStatusUpdateAPI(
      USER_TOKEN,
      counsellingEncryptId,
      data
    );
    if (res?.is_success) {
      SetCompleteMeeting(false);
      fetchGroupCounsellingList();
    } else {
      SetAlertDisplay(true);
      SetAlertMessage([res?.errors]);
    }
  };

  const actionPlanDownload = async (e, id) => {
    let res = await storeGroupCounsellingActionPlanDownloadAPI(USER_TOKEN, id);
    if (res?.is_success) {
      if (res?.file) {
        const linkSource = `data:application/pdf;base64,${res?.file}`;
        const downloadLink = document.createElement("a");
        const fileName = res?.filename;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else {
        SetAlertDisplay(true);
        SetAlertMessage([res?.message]);
      }
    } else {
      SetAlertDisplay(true);
      SetAlertMessage([res?.errors]);
    }
  }

  return (
    <div>
      <HeaderComponent />
      <Seo options={MetaDetails} />
      <BreadCumb name={"Group Counselling"} list={list} />
      <section className="myprofile-section ptb-50">
        <div className="container">
          <div className="pricing-wrapper position-relative mt-n18 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row justify-content-between main-area">
              <div className="col-lg-3">
                <div className="myprofile-sidebar rounded-3">
                  <div className="card">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="myprofile-content rounded-3 p-5">
                  <div className="dashboard-quick-widget">
                    <h4 className="mt-4">Group Counselling</h4>
                    <br />
                    <AlertComponent
                      AlertDisplay={AlertDisplay}
                      SetAlertDisplay={SetAlertDisplay}
                      AlertMessage={AlertMessage}
                    />

                    <div className="table-responsive my-4">
                      <table className="table table-bordered bg-white">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">
                              {user?.type === 1 ? "Counsellor " : "Vendor "}
                              Name
                            </th>
                            {user?.type === 1 && <th>Assessment Report</th>}
                            <th scope="col">Date & Time</th>
                            <th scope="col">Platform & Duration</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Detail.map((x, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{x?.name}</td>
                              {user?.type === 1 && (
                                <td>
                                  {x?.student_assessment_detail?.encryptid && (
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={(e) => {
                                        download(
                                          e,
                                          x?.student_assessment_detail
                                            ?.encryptid
                                        );
                                      }}
                                    >
                                      <Icon
                                        icon="bxs:download"
                                        width="20"
                                        height="20"
                                      />{" "}
                                      Report
                                    </button>
                                  )}
                                  {x?.action_plan_details?.vendor_group_counselling_id && (
                                    <button
                                      className="btn btn-sm btn-success mt-1"
                                      onClick={(e) => {
                                        actionPlanDownload(
                                          e,
                                          x?.encryptid
                                        );
                                      }}
                                    >
                                      <Icon
                                        icon="bxs:download"
                                        width="20"
                                        height="20"
                                      />{" "}
                                      Action Plan
                                    </button>
                                  )}
                                </td>
                              )}
                              <td>
                                <Moment tz="Asia/Kolkata" format="D MMM YYYY">
                                  {x?.first_prefer_date}
                                </Moment>
                                &nbsp;&amp;&nbsp;&nbsp;
                                {x?.websitetimeformat}
                              </td>

                              <td>
                                {x?.platform === "1" && (
                                  <span className="badge bg-primary">
                                    MereMentor
                                  </span>
                                )}
                                {x?.platform === "2" && (
                                  <span className="badge bg-primary">
                                    Google Meet
                                  </span>
                                )}
                                {x?.platform === "3" && (
                                  <span className="badge bg-primary">Zoom</span>
                                )}
                                {x?.duration && (
                                  <div>{x?.duration} &amp; in mins</div>
                                )}
                              </td>
                              <td>
                                {x?.status === 0 && (
                                  <span className="badge bg-warning">
                                    Pending
                                  </span>
                                )}
                                {x?.status === 1 && (
                                  <span className="badge bg-warning">
                                    Meeting to be scheduled
                                  </span>
                                )}
                                {x?.status === 2 && (
                                  <span className="badge bg-success">
                                    Meeting is scheduled
                                  </span>
                                )}
                                {x?.status === 3 && (
                                  <span className="badge bg-warning">
                                    Meeting is re-scheduled
                                  </span>
                                )}
                                {x?.status === 4 && (
                                  <span className="badge bg-success">
                                    Meeting is completed
                                  </span>
                                )}
                                {x?.status === 5 && (
                                  <span className="badge bg-danger">
                                    Meeting is cancelled
                                  </span>
                                )}
                                {x?.status === 6 && (
                                  <span className="badge bg-primary">
                                    Counsellor Report Submitted
                                  </span>
                                )}
                                {x?.status === 7 && (
                                  <span className="badge bg-success">
                                    Report Sent
                                  </span>
                                )}
                              </td>
                              <td>
                                {x?.status === 2 && (
                                  <Link
                                    to={`/group-meeting/${x?.encryptid}`}
                                    target="_blank"
                                    className="btn btn-sm btn-primary mt-1"
                                  >
                                    <Icon
                                      icon="bx:video"
                                      width="25"
                                      height="25"
                                    />{" "}
                                    Meeting Details
                                  </Link>
                                )}
                                {user?.type === 2 &&
                                  x?.status === 2 &&
                                  x?.timeremaining === 0 && (
                                    <button
                                      className="btn btn-sm btn-success mt-1"
                                      onClick={(e) => {
                                        SetCompleteMeeting(true);
                                        SetcounsellingEncryptId(x.encryptid);
                                      }}
                                    >
                                      <Icon
                                        icon="carbon:task-complete"
                                        width="25"
                                        height="25"
                                      />{" "}
                                      Complete Meeting
                                    </button>
                                  )}

                                {user.type === 2 && x.status === 4 && (
                                  <Link
                                    to={`/group-meeting/action-plan/${x.encryptid}`}
                                    className="btn btn-sm btn-success"
                                    target={"_blank"}
                                  >
                                    Submit Report
                                  </Link>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={CompleteMeeting}
        onHide={(e) => {
          SetCompleteMeeting(false);
        }}
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Complete Meeting</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to complete the meeting?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              SetCompleteMeeting(false);
            }}
            size="sm"
          >
            No
          </Button>
          <Button variant="primary" size="sm" onClick={confirmMeeting}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default GroupCounselling;
