import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { useEffect, useState } from "react";
import { LibraryAPI, ScCategoriesAPI } from "../Service";
import { Link, useParams, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import { CareerLibraryAPI } from "../Service";
import { Accordion } from "react-bootstrap";
import BreadCumb from "../layouts/BreadCumb";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";

function CareerLibrary() {
  const [showScroll, setShowScroll] = useState(false);
  let { slug } = useParams();
  const [CL, SetCL] = useState({});
  const [faqs, SetFaqs] = useState([]);
  const [MetaDetails, SetMetaDetails] = useState({
    'title': "",
    'meta_title': "",
    'meta_keywords': "",
    'meta_description': "",
    'ldJson': {}
  });
  let navigate = new useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchCLContent();
  }, []);
  const list = [
    { name: "Home", url: "/" },
    { name: "Library", url: "/library" },
    { name: CL?.name, url: null },
  ];
  const fetchCLContent = async () => {
    let res = await CareerLibraryAPI(slug);
    if (res?.is_success === true) {
      SetCL(res?.data);
      SetFaqs(res?.data?.faqs);
      let entity = [];
      res.data.faqs.map((x, i) => {
        let faq = {
          "@type": "Question",
          "name": x.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": x.answer
          }
        }
        entity.push(faq)
      })
      let datas = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": entity
      }
      SetMetaDetails({
        'title': res?.data?.meta_title,
        'meta_title': res?.data?.meta_title,
        'meta_keywords': res?.data?.meta_tags,
        'meta_description': res?.data?.meta_description,
        'ldJson': datas
      });
    } else {
      navigate('/404')
    }
  };

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />

      <BreadCumb className = "careerMob" name={CL?.name} list={list} />

      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>

            <div className="row gx-lg-8 gx-xl-12">
              <div className="col-lg-8">
                <div className="blog single">
                  <div className="card">
                    <div className="card-body">
                      <div className="classic-view">
                        <article className="post">
                          <div className="post-content mb-5">
                            {CL?.section_1_title && (
                              <h4 id="section_1">
                                <div className="row">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                  </div>
                                  <div className="col-md-11">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_1_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription1 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription1,
                                }}
                              />
                            )}
                            {CL?.section_2_title && (
                              <h4 id="section_2">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_2_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription2 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription2,
                                }}
                              />
                            )}
                            {CL?.section_3_title && (
                              <h4 id="section_3">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_3_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription3 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription3,
                                }}
                              />
                            )}
                            {CL?.section_4_title && (
                              <h4 id="section_4">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_4_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription4 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription4,
                                }}
                              />
                            )}
                            {CL?.section_5_title && (
                              <h4 id="section_5">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_5_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription5 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription5,
                                }}
                              />
                            )}
                            {CL?.section_6_title && (
                              <h4 id="section_6">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_6_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription6 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription6,
                                }}
                              />
                            )}
                            {CL?.section_7_title && (
                              <h4 id="section_7">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_7_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription7 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription7,
                                }}
                              />
                            )}
                            {CL?.section_8_title && (
                              <h4 id="section_8">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_8_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription8 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription8,
                                }}
                              />
                            )}
                            {CL?.section_9_title && (
                              <h4 id="section_9">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_9_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription9 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription9,
                                }}
                              />
                            )}
                            {CL?.section_10_title && (
                              <h4 id="section_10">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_10_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription10 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription10,
                                }}
                              />
                            )}
                            {CL?.section_11_title && (
                              <h4 id="section_11">
                                <div className="row mobile-flex">
                                  <div className="col-md-1">
                                    <Icon.FileText size={20} />
                                    {"  "}
                                  </div>
                                  <div className="col-md-11 mobileScreen">
                                    <div style={{ fontSize: '17px' }}
                                      dangerouslySetInnerHTML={{
                                        __html: CL.section_11_title,
                                      }}
                                    />
                                  </div>
                                </div>
                              </h4>
                            )}
                            {CL?.customdescription11 && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CL.customdescription11,
                                }}
                              />
                            )}
                          </div>
                          <hr />
                          {faqs.length > 0 && (
                            <div>
                              <h2 className="text-center">
                                Frequently Asked Questions
                              </h2>
                              <div
                                id="accordion-3"
                                className="accordion-wrapper"
                              >
                                {faqs.map((x, i) => (
                                  <Accordion
                                    defaultActiveKey={i}
                                    className="card accordion-item"
                                    key={i}
                                  >
                                    <Accordion.Item
                                      eventKey="0"
                                      className="mt-1"
                                    >
                                      <Accordion.Header className="card-header">
                                        {x.question}
                                      </Accordion.Header>
                                      <Accordion.Body className="card-body">
                                        {x.answer}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                ))}
                              </div>
                              <hr />
                            </div>
                          )}
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="widget-area col-lg-4 sidebar mt-11 mt-lg-0">
                <div className="card  border-primary">
                  <div className="card-header">Summary</div>
                  <div className="card-body widget_categories">
                    <ul>
                      {CL?.section_1_title && (
                        <li>
                          <a href="#section_1">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_1_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_2_title && (
                        <li>
                          <a href="#section_2">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_2_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_3_title && (
                        <li>
                          <a href="#section_3">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_3_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_4_title && (
                        <li>
                          <a href="#section_4">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_4_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_5_title && (
                        <li>
                          <a href="#section_5">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_5_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_6_title && (
                        <li>
                          <a href="#section_6">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_6_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_7_title && (
                        <li>
                          <a href="#section_7">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_7_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_8_title && (
                        <li>
                          <a href="#section_8">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_8_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_9_title && (
                        <li>
                          <a href="#section_9">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_9_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_10_title && (
                        <li>
                          <a href="#section_10">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_10_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                      {CL?.section_11_title && (
                        <li>
                          <a href="#section_11">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: CL.section_11_title,
                              }}
                            />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <br />
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default CareerLibrary;