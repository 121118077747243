import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import BreadCumb from "../layouts/BreadCumb";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { GalleryItemAPI } from "../Service";
import GLightbox from "glightbox";
import BackToTop from "../layouts/BackToTop";
function GalleryItem() {
  const [showScroll, setShowScroll] = useState(false);
  let { id } = useParams();
  const [galleryItemList, SetGalleryItemList] = useState([]);
  const list = [
    { name: "Home", url: "/" },
    { name: "Gallery", url: "/gallery" },
    { name: "test", ur: null },
  ];
  const [isOpen, SetisOpen] = useState(false);
  const [photoIndex, SetPhotoIndex] = useState(0);
  const [images, SetImages] = useState(0);
  const [Items, SetItems] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchGalleryItemDetail();
  }, [id]);

  const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
  });

  const gallery = (index) => {
    const myGallery = GLightbox({
      elements: Items,
      autoplayVideos: true,
    });
    myGallery.openAt(index);
  };

  const fetchGalleryItemDetail = async () => {
    let res = await GalleryItemAPI(id);
    if (res?.is_success === true) {
      SetGalleryItemList(res?.datas?.items);
      let arr = [];
      res?.datas?.items.map((x, i) => {
        if (x.type === 1) {
          //Image
          let data = {
            href: process.env.REACT_APP_BASE_URL + x.image,
            type: "image",
            alt: "gallery image",
          };
          arr.push(data);
        } else if (x.type === 2) {
          //Video
          let data = {
            href: x.url,
            type: "video",
            source: "youtube", //vimeo, youtube or local
            width: 900,
          };
          arr.push(data);
        }
      });
      SetItems(arr);
    }
  };

  return (
    <div>
      <HeaderComponent />
      <BreadCumb name={"Gallery"} list={list} />
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0">
              {galleryItemList.map((x, i) => (
                <div className="col-md-6 col-lg-3 mt-4" key={i}>
                  <div className="position-relative">
                    <div
                      className="shape rounded bg-soft-blue rellax d-md-block"
                      data-rellax-speed="0"
                      style={{
                        bottom: "-0.75rem",
                        right: "-0.75rem",
                        width: "98%",
                        height: "98%",
                        zIndex: "0",
                      }}
                    ></div>
                    <div className="card">
                      <figure className="card-img-top">
                        {x.type === 2 && (
                          <button
                            className="btn btn-circle btn-primary btn-play ripple mx-auto mb-5 position-absolute"
                            style={{
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                              zIndex: "3",
                            }}
                            onClick={(e) => {
                              gallery(i);
                            }}
                          >
                            <i className="icn-caret-right"></i>
                          </button>
                        )}
                        {x.type === 1 && (
                          <img style={{ width: '100%', height: 'auto' }}
                            className="img-fluid border-radius-7"
                            src={process.env.REACT_APP_BASE_URL + x.image}
                            alt={`gallery`}
                            onClick={(e) => {
                              gallery(i);
                            }}
                          />
                        )}

                        {x.type === 2 && (
                          <img style={{ width: '100%', height: 'auto' }}
                            className="img-fluid border-radius-7"
                            src={process.env.REACT_APP_BASE_URL + x.thumbnail}
                            alt={`gallery`}
                          />
                        )}
                      </figure>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default GalleryItem;
