import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LandingPageAPI } from "../Service";
import {Button} from 'react-bootstrap';
import GoogleReview from "../assets/img/google-review.png";

function Footer() {
  let setting = JSON.parse(localStorage.getItem("setting"));
  let [landingPage, SetLandingPage] = useState([]);
  let [marketingPage, SetMarketingPage] = useState([]);

  useEffect(() => {
    getLandingPages();
    // var element = document.getElementById('chat-bot-launcher-container');
    // element.classList.remove('chat-bot-launcher-container-assessment');
  }, []);

  const getLandingPages = async () => {
    let res = await LandingPageAPI();
    SetLandingPage(res?.landing_pages);
    SetMarketingPage(res?.marketing_pages);
  };

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

  return (
    <>
      <section className="wrapper bg-light angled  lower-end">
        <div className="container py-4 py-md-1"></div>
      </section>
      <footer className="bg-dark text-inverse">
        <div className="container py-13 py-md-8">
          <div className="mt-10"></div>
          <div className="row gy-6 gy-lg-0">

            <div className="col-md-12 col-lg-3">
              <div className="widget">
                <h3 className="widget-title text-white mb-3">About Us</h3>
                <p className="mb-5">{setting?.description}</p>
                <nav className="nav social social-white">
                  {/* <a href={setting?.twitter} target="_blank">
                    <i className="uil uil-twitter"></i>
                  </a> */}
                  <a href={setting?.facebook} target="_blank">
                    <i className="uil uil-facebook-f fb"></i>
                  </a>
                  <a href={setting?.instagram} target="_blank">
                    <i className="uil uil-instagram"></i>
                  </a>
                  <a href={setting?.youtube} target="_blank">
                    <i className="uil uil-youtube"></i>
                  </a>
                  <a href={setting?.linkedin} target="_blank">
                    <i className="uil uil-linkedin"></i>
                  </a>
                  <a href = 'https://whatsapp.com/channel/0029Va5UPYx6GcGFbEqGfv1y' target="_blank">
                    <i className="uil uil-whatsapp"></i>
                  </a>
                </nav>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="widget">
                <h3 className="widget-title text-white mb-3">Get in Touch</h3>
                <address className="pe-xl-15 pe-xxl-1">
                  <p>
                    <span><i className="uil uil-location-pin-alt"></i> </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: setting?.address,
                      }}
                    />
                  </p>
                </address>
                <a href={`mailto:${setting?.email}`}><i className="uil uil-envelope"></i> {setting?.email}</a>
                <br /> <a href={`tel:${setting?.phone}`}><i className="uil uil-phone-volume"></i> {setting?.phone}</a>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="widget">
                <h4 className="widget-title text-white mb-3">Know More</h4>
                <ul className="list-unstyled  mb-0">
                  <li>
                    <Link to="/counsellors">Counsellors</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/return-cancellation-policy">
                      Return &amp; Cancellation Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li>
  <a href="https://www.merementor.com/sitemap.xml" target="_blank" rel="noopener noreferrer">Sitemap</a>
</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-lg-3">
              <div className="widget">
                <h4 className="widget-title text-white mb-3"></h4> 
                <div className="box">
                        <a href="https://g.page/merementor/review?kd" target={'blank'}>
                            <img className="img-fluid" src={GoogleReview}  width="100" style={{width: '100%', height: 'auto'}} alt="Merementor Google Review" />
                        </a>
                    </div>
                <div className="newsletter-wrapper"></div>
              </div>
            </div>
          </div>

          <div className="mt-10"></div>
          <div className="col-lg-12 col-sm-6">
            <div className="single-footer-widget pl-5 text-center">
              <ul className="quick-links p-0">
                {landingPage.map((x, i) => 
                    <li className="d-inline-block mb-1" key={i}>
                      <Link to ={`/${x.slug}`}>{x?.banner_title}</Link> {(landingPage.length ===(i+1))?'':(<>| &nbsp;</>) }
                    </li>
                )}
              </ul>
            </div>
          </div>
          <div className="mt-10"></div>
          <div className="col-lg-12 col-sm-6">
            <div className="single-footer-widget pl-5 text-center">
              <ul className="quick-links p-0">
                {marketingPage.map((x, i) => 
                    <li className="d-inline-block mb-1" key={i}>
                      <Link to ={`/${x.slug}`}>{x?.banner_title}</Link> {(marketingPage.length ===(i+1))?'':(<>| &nbsp;</>) }
                    </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center">
          <p className="pt-7 pb-2 mb-0">© 2022. All Rights Reserved by <Link to="/">Mere Mentor</Link></p>
       </div>
      </footer>
    </>
  );
}

export default Footer;
