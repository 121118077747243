import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import BreadCumb from "../layouts/BreadCumb";
import { useEffect, useState } from "react";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
import ExpertsHelper from "../layouts/ExpertsHelper";

function Experts() {
  const [showScroll, setShowScroll] = useState(false);
  const list = [
    { name: "Home", url: "/" },
    { name: "Experts", url: null },
  ];
  const setting = JSON.parse(localStorage.getItem("setting"));
  let MetaDetails = {
    'title': 'Best Career Experts | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Best Career Experts | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Mere Mentor Experts',
    'meta_description': 'Select your Best Career Experts in Mere Mentor. Mere Mentor provides certification for counselors in career counselling.',
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  })

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      <BreadCumb name="Experts" list={list} />
      <ExpertsHelper />
      <Footer />
      {/* <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} /> */}
    </div>
  );
}

export default Experts;
