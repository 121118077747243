import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import BreadCumb from "../layouts/BreadCumb";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getPageAPI, GetStatesAPI } from "../Service";
import { Accordion, Modal } from "react-bootstrap";
import validator from "validator";
import AlertComponent from "../layouts/Alert";
import { Button, Spinner, ModalBody } from "react-bootstrap";
import { storeLookHereAPI } from "../Service";
import Message from "../layouts/Message";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
import OnlineRegModal from '../layouts/onlineReg';
import Cookies from "js-cookie";
import axios from "axios";

function Page() {
  let setting = JSON.parse(localStorage.getItem("setting"));
  let { slug } = useParams();
  const [showScroll, setShowScroll] = useState(false);
  const [states, SetStates] = useState([]);
  const [PageDetail, SetPageDetail] = useState("");
  const [type, SetType] = useState(0);
  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [mobile, SetMobile] = useState("");
  const [qualification, SetQualification] = useState("DEFAULT");
  const [qualification2, SetQualificationn] = useState("DEFAULT");
  const [userState, SetUserState] = useState(0);
  const [message, SetMessage] = useState("");
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [submitButton, SetSubmitButton] = useState(false);
  const navigate = useNavigate();
  const [MessageDisplay, SetMessageDisplay] = useState(false);
  const [MessageContent, SetMessageContent] = useState([]);
  const [lookhere, SetLookhere] = useState(false);
  const [MetaDetails, SetMetaDetails] = useState({
    'title': '',
    'meta_title': '',
    'meta_keywords': '',
    'meta_description': '',
    'ldJson': {}
  })

  const list = [
    { name: "Home", url: "/" },
    { name: PageDetail?.title, url: null },
  ];

  let USER_TOKEN = Cookies.get("usr_token");

  useEffect(() => {
    if (window.location.pathname === '/certified-professional-career-counselling') {
      SetQualificationn('DEFAULT');
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    GetStates();
    getPage();
    if (USER_TOKEN == undefined) {
      setTimeout(() => {
        setShowOnlineReg(true)
      }, 90000);
    }
    fetchSettingApi();
  }, [slug]);

  const fetchSettingApi = async () => {
    await axios.get("general-settings").then((res) => {
      setting = res.data.setting;
      localStorage.setItem("setting", JSON.stringify(res.data.setting));
    });
  };

  const handleClose = () => setShow(false);
  const [showOnlineReg, setShowOnlineReg] = useState(false);
  const [show, setShow] = useState(false);

  const getPage = async () => {
    let res = await getPageAPI(slug);
    if (res?.is_success === true) {
      SetPageDetail(res?.page);
      let entity = [];
      if (res?.type === 2 && res?.page?.faqs.length > 0) {
        res.page.faqs.map((x, i) => {
          let faq = {
            "@type": "Question",
            "name": x.question,
            "acceptedAnswer": {
              "@type": "Answer",
              "text": x.answer
            }
          }
          entity.push(faq)
        })
      }
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": entity
      }
      SetMetaDetails({
        'title': res?.page?.meta_title,
        'meta_title': res?.page?.meta_title,
        'meta_keywords': res?.page?.meta_tags,
        'meta_description': res?.page?.meta_description,
        'ldJson': entity.length > 0 ? data : null
      })
      SetType(res?.type);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/404')
    }
  };


  const GetStates = async () => {
    let res = await GetStatesAPI();
    if (res?.is_success === true) {
      SetStates(res?.states);
    }
  };

  const enquiry = async (e) => {
    SetSubmitButton(true);
    let data = {
      name: name,
      email: email,
      mobile: mobile,
      qualification: qualification,
      qualification2: qualification2,
      state: userState,
      message: message,
      slug: slug,
      page: PageDetail.id,
    };
    let err = [];
    var mandatoryFilled = true;

    var mandatoryFilled = true;
    if (name == "" || name == "null" || name == null || mobile == "" || mobile == "null" || mobile == null || email == "" || email == "null" ||
      email == null || userState === 0 || message == '' || message == 'null' || message == null) {
      err.push("Please fill the mandatory fields");
      mandatoryFilled = false;
    }
    if (mandatoryFilled == true) {
      if (!validator.isLength(name, { min: 3, max: 255 })) {
        err.push("Name should have minimum 3 characters");
      }
      if (!validator.isLength(mobile, { min: 10, max: 10 })) {
        err.push("Mobile Number Must be 10 digits");
      }
      if (!validator.isNumeric(mobile)) {
        err.push("Mobile Number Must be in digits");
      }
      if (!validator.isEmail(email)) {
        err.push("Enter a Valid Email");
      }
      if (qualification === 'DEFAULT' && qualification2 === 'DEFAULT') {
        err.push("Qualification is required");
      }
      // if (qualification2 === 'DEFAULT' && qualification === 'DEFAULT') {
      //   err.push("Qualification is required");
      // }
      if (userState === 0) {
        err.push("State is required");
      }
      if (validator.isEmpty(message)) {
        err.push("Message is required");
      }
    }

    if (err.length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      SetAlertDisplay(true);
      SetAlertMessage(err);
      SetSubmitButton(false);
    } else {
      let res = await storeLookHereAPI(data);
      if (res?.is_success === true) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        setTimeout(() => {
          if(PageDetail?.banner_title == 'Best Career Counselling in India'
        || PageDetail?.banner_title == 'Career Counselling After 10th-12th'
      || PageDetail?.banner_title == 'Career Guidance College Graduate Students'
    ||  PageDetail?.banner_title == 'Career Guidance For Working Professionals'
  || PageDetail?.banner_title == 'Career Guidance After 12th'){
            navigate('/thank-you/gtm');
          }else{
            navigate('/thank-you');
          }
        }, 2000);

        SetSubmitButton(false);
        SetName("");
        SetEmail("");
        SetMessage("");
        SetQualification("");
        SetQualificationn("");
        SetMobile("DEFAULT");
        SetUserState(0);
        SetLookhere(false)
        SetAlertDisplay(false);
        SetAlertMessage([]);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        // setTimeout(() => {
        //   navigate(`/${slug}`);
        // }, 2000);
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
        SetSubmitButton(false);
      }
    }
  };

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      {type === 1 && (
        <div>
          <BreadCumb name={PageDetail?.title} list={list} />
          <section className="wrapper bg-light">
            <div className="container py-14 py-md-16">
              <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
                <div
                  className="shape bg-dot primary rellax w-16 h-18"
                  data-rellax-speed="1"
                  style={{ top: "-4rem", right: "-2.4rem" }}
                ></div>
                <OnlineRegModal show={showOnlineReg} hide={() => setShowOnlineReg(false)} />

                <div
                  className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
                  data-rellax-speed="1"
                  style={{ bottom: "0.5rem", left: "-2.5rem" }}
                ></div>
                <div className="row">
                  <div className="col-lg-10 mx-auto">
                    <div className="card">
                      <div className="card-body">
                        {PageDetail?.description && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: PageDetail?.description,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      {type === 2 && (
        <div>
          <section
            className="landing-page quote-area pt-5"
            style={{ backgroundColor: "#ecf4fd" }}
          >
            <OnlineRegModal show={showOnlineReg} hide={() => setShowOnlineReg(false)} />
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <img className="img-fluid"
                    src={
                      process.env.REACT_APP_BASE_URL + PageDetail?.banner_image
                    }
                    alt={PageDetail?.banner_image_alt}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      display: "inline-block",
                    }}
                  />
                </div>
                <div className="col-lg-5">
                  <div className="quote-item">
                  <div className="col-lg-12 col-md-12 text-center enquire">
                  <a href={"tel:"+setting?.phone}
                    className="btn btn-primary"
                      data-url={"tel:"+setting?.phone}
                  ><i class="uil uil-phone-volume"></i> &nbsp;
                    Contact Us
                  </a>
                </div>
                    <div className="content text-center">
                      <h1>{PageDetail?.banner_title} - Register with us</h1>
                    </div>
                    <AlertComponent
                      AlertDisplay={AlertDisplay}
                      SetAlertDisplay={SetAlertDisplay}
                      AlertMessage={AlertMessage}
                    />
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Your name *"
                            onChange={(e) => {
                              SetName(e.target.value);
                            }}
                            value={name}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group mb-3">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            required=""
                            placeholder="Your email address *"
                            onChange={(e) => {
                              SetEmail(e.target.value);
                            }}
                            value={email}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group mb-3">
                          <input
                            type="number"
                            minLength="10"
                            maxLength="10"
                            name="phone"
                            className="form-control"
                            required=""
                            placeholder="Your phone number *"
                            onChange={(e) => {
                              SetMobile(e.target.value);
                            }}
                            value={mobile}
                          />
                        </div>
                      </div>

                      {window.location.pathname === '/certified-professional-career-counselling' && (
                        <div className="col-lg-12 col-md-12">
                        <div className="form-group mb-3">
                          <select
                            className="form-control form-select"
                            defaultValue={"DEFAULT"}
                            onChange={(e) => {
                              SetQualificationn(e.target.value);
                            }}
                          >
                            <option value={qualification2} disabled>
                              Please select your Qualification *
                            </option>
                            {/* <option value="8th-9th">8th-9th</option> */}
                            {/* <option value="10th-12th">10th-12th</option> */}
                            {/* <option value="college-graduates">
                              College Graduates
                            </option> */}
                            {/* <option value="working-professionals">
                              Working Professionals
                            </option> */}
                            <option value="Psychology">
                              Psychology
                            </option>
                            <option value="Social Work">
                              Social Work
                            </option>
                            <option value=" Others">
                              Others
                            </option>
                          </select>
                        </div>
                      </div>)}

                      {window.location.pathname != '/certified-professional-career-counselling' && (
                        <div className="col-lg-12 col-md-12">
                        <div className="form-group mb-3">
                          <select
                            className="form-control form-select"
                            defaultValue={"DEFAULT"}
                            onChange={(e) => {
                              SetQualification(e.target.value);
                            }}
                          >
                            <option value={qualification2} disabled>
                              Please select your Qualification *
                            </option>
                            <option value="8th-9th">8th-9th</option>
                            <option value="10th-12th">10th-12th</option>
                            <option value="college-graduates">
                              College Graduates
                            </option>
                            <option value="working-professionals">
                              Working Professionals
                            </option>
                           
                          </select>
                        </div>
                      </div>)}
                      
                      <div  className="col-lg-12 col-md-12">
                        <div className="form-group mb-3">
                          <select
                            className="form-control form-select"
                            name="state"
                            defaultValue={userState}
                            onChange={(e) => {
                              SetUserState(e.target.value);
                            }}
                          >
                            <option value="0" disabled>
                              Please select your State *
                            </option>
                            {states.map((x, i) => (
                              <option value={x.id} key={x.id}>
                                {x.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group mb-3">
                          <textarea
                            className="form-control"
                            rows="3"
                            placeholder="Message *"
                            onChange={(e) => {
                              SetMessage(e.target.value);
                            }}
                            defaultValue={message}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <Button
                          className="btn btn-primary rounded-pill btn-send mb-3"
                          disabled={submitButton}
                          onClick={(e) => {
                            enquiry(e);
                          }}
                        >
                          {submitButton ? (
                            <div>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Loading...
                            </div>
                          ) : (
                            <div>Submit</div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="blog-details-area py-5">
            <div className="container">
              <div className="row ">
                <div className="col-lg-12 col-md-12 ">
                  <div className="blog-details-desc">
                    <div className="article-content">
                      <h2>{PageDetail?.title1}</h2>
                      {PageDetail?.description1 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: PageDetail?.description1,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-12 col-md-12 ">
                  <div className="blog-details-desc">
                    <div className="article-content">
                      <h2>{PageDetail?.title2}</h2>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="blog-details-desc">
                    <div className="article-content">
                      {PageDetail?.description2 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: PageDetail?.description2,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 ">
                  <div className="blog-details-desc mb-2">
                    <div className="article-content">
                      <img className="img-fluid" style={{ width: '100%', height: 'auto' }} 
                        src={
                          process.env.REACT_APP_BASE_URL +
                          PageDetail?.description2_image
                        }
                        alt={PageDetail?.description2_image_alt}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-lg-12 col-md-12 text-center ">
                  <Button
                    className="btn btn-primary"
                    onClick={(e) => {
                      SetLookhere(true);
                    }}
                  >
                    Take Assessment
                  </Button>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-12 col-md-12 ">
                  <div className="blog-details-desc pt-3 pb-1">
                    <div className="article-content">
                      <h2>{PageDetail?.title3}</h2>
                      {PageDetail?.description3 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: PageDetail?.description3,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {PageDetail?.title4 && (
                <div className="row ">
                  <div className="col-lg-12 col-md-12 ">
                    <div className="blog-details-desc py-3">
                      <div className="article-content">
                        <h2 className="text-center pb-3">{PageDetail?.title4}</h2>
                        {PageDetail?.description4 && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: PageDetail?.description4,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row ">
                <div className="col-lg-12 col-md-12 text-center ">
                  <button
                    className="btn btn-primary"
                    onClick={(e) => {
                      SetLookhere(true);
                    }}
                  >
                    Know the Best In You
                  </button>
                </div>
              </div>
            </div>
          </section>
          {PageDetail.faqs && (
            <div>
              <section className="faq-area pb-5 pt-1">
                <div className="container">
                  <div className="section-title">
                    <h2 className="text-center">Frequently Asked Questions</h2>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          {PageDetail?.faqs.length > 0 && (
                            <div>
                              <div
                                id="accordion-3"
                                className="accordion-wrapper"
                              >
                                {PageDetail?.faqs.map((x, i) => (
                                  <Accordion
                                    defaultActiveKey={i}
                                    className="card accordion-item"
                                    key={i}
                                  >
                                    <Accordion.Item
                                      eventKey="0"
                                      className="mt-1"
                                    >
                                      <Accordion.Header className="card-header">
                                        {x.question}
                                      </Accordion.Header>
                                      <Accordion.Body className="card-body">
                                        {x.answer}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </Accordion>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      )}
      {/* Toast */}
      <Message
        MessageDisplay={MessageDisplay}
        MessageContent={MessageContent}
        SetMessageDisplay={SetMessageDisplay}
      />
      {/* Enquiry Pop-up */}
      <Modal
        show={lookhere}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          SetLookhere(false);
        }}
      >
        <ModalBody className="text-center">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              SetLookhere(false);
              SetAlertDisplay(false);
              SetAlertMessage([]);
            }}
          ></button>
          <h3 className="mb-4 text-center">Online Registration</h3>
          <AlertComponent
            AlertDisplay={AlertDisplay}
            AlertMessage={AlertMessage}
            SetAlertDisplay={SetAlertDisplay}
          />
          <div className="text-start mb-3">
            <div className="form-floating mb-4">
              <div className="col-lg-12 col-md-12">
                <div className="form-group mb-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your name"
                    onChange={(e) => {
                      SetName(e.target.value);
                    }}
                    value={name}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group mb-3">
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    required=""
                    placeholder="Your email address"
                    onChange={(e) => {
                      SetEmail(e.target.value);
                    }}
                    value={email}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group mb-3">
                  <input
                    type="number"
                    minLength="10"
                    maxLength="10"
                    name="phone"
                    className="form-control"
                    required=""
                    placeholder="Your phone number"
                    onChange={(e) => {
                      SetMobile(e.target.value);
                    }}
                    value={mobile}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group mb-3">
                  <select
                    className="form-control form-select"
                    defaultValue={"DEFAULT"}
                    onChange={(e) => {
                      SetQualification(e.target.value);
                    }}
                  >
                    <option value={qualification} disabled>
                      Please select your Qualification
                    </option>
                    <option value="8th-9th">8th-9th</option>
                    <option value="10th-12th">10th-12th</option>
                    <option value="college-graduates">College Graduates</option>
                    <option value="working-professionals">
                      Working Professionals
                    </option>
                  </select>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group mb-3">
                  <select
                    className="form-control form-select"
                    name="state"
                    defaultValue={userState}
                    onChange={(e) => {
                      SetUserState(e.target.value);
                    }}
                  >
                    <option value="0" disabled>
                      Please select your State
                    </option>
                    {states.map((x, i) => (
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group mb-3">
                  <textarea
                    className="form-control"
                    rows="3"
                    onChange={(e) => {
                      SetMessage(e.target.value);
                    }}
                    defaultValue={message}
                  ></textarea>
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <Button
                  className="btn bnt-primary rounded-pill btn-login w-100 mb-2"
                  disabled={submitButton}
                  onClick={(e) => {
                    enquiry(e);
                  }}
                >
                  {submitButton ? (
                    <div>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Loading...
                    </div>
                  ) : (
                    <div>Submit</div>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default Page;
