import {ToastContainer,Toast} from 'react-bootstrap';
import { useState } from "react";

function Message({MessageDisplay,MessageContent,SetMessageDisplay}){
    
    return (
        <div>
        <ToastContainer position="top-end" className="p-3">
            <Toast onClose={() => SetMessageDisplay(false)} bg="info" show={MessageDisplay} delay={3000} autohide>
            <Toast.Header>
                <strong className="me-auto">Success</strong>
            </Toast.Header>
            <Toast.Body className='text-white'>
                {MessageContent.map(function(x, i) {
                    return (
                        <span key={i}>
                            {x}
                            <br/>
                        </span>
                    )
                })}
            </Toast.Body>
            </Toast>
        </ToastContainer>
        </div>
    )   
}


export default Message;