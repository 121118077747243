/* import { ReactComponent as EIGHTH } from "../assets/img/best/8-9.svg";
import { ReactComponent as TENTH } from "../assets/img/best/10-12.svg";
import { ReactComponent as CG } from "../assets/img/best/cg.svg";
import { ReactComponent as WP } from "../assets/img/best/wp.svg";
import { ReactComponent as CSR } from "../assets/img/best/csr.svg";
import { ReactComponent as GOV } from "../assets/img/best/gov.svg";
import { ReactComponent as ECH } from "../assets/img/best/ech.svg"; */


import EIGHTHPNG  from "../assets/img/best/8-9.png";
import TENTHPNG  from "../assets/img/best/10-12.png";
import CGPNG  from "../assets/img/best/cg.png";
import WPPNG  from "../assets/img/best/wp.png";
import CSRPNG  from "../assets/img/best/csr.png";
import GOVPNG  from "../assets/img/best/gov.png";
import ECHPNG  from "../assets/img/best/ech.png";
import EarlyEducation from "../assets/img/early-education.png";
import { Link } from "react-router-dom";
import {Modal, ModalDialog} from "react-bootstrap";
import Cookies from "js-cookie";
import { useState,useEffect } from "react";
import AlertComponent from "./Alert";
import {verifyGOVAssessmentCodeAPI} from "../Service";
import {useNavigate} from "react-router-dom";
import GLightbox from "glightbox";

function Services({showEarlyEducation,setshowEarlyEducation}) {

  let USER_TOKEN = Cookies.get("usr_token");
  const [gov,setGov] = useState(false);
  const [err,seterr] = useState(false);
  const [errMsg,SetErrMsg] = useState([]);
  const [Code, SetCode] = useState("");
  const [AssessmentType,SetAssessmentType] = useState("0");
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [AlertButton, SetAlertButton] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  let navigate = useNavigate();

  const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
  });

  useEffect(() => {
     return () => {
      setGov(false);
      seterr(false);
      SetErrMsg([]);
      SetCode("");
      SetAlertDisplay(false);
      SetAlertMessage([]);
      SetAlertButton(null);
      setShow(false);
    } 
  }, [])
  

  const getGOVAssessment = async(type) =>{
    let err = [];
    if(USER_TOKEN === undefined){
      navigate("assessments?is_login=true&is_message=Please login to take assessment");
    }else{
      if(err.length > 0){
        seterr(true)
        SetErrMsg(err)
      }else{
        SetAlertDisplay(false);
        SetAlertMessage([]);
        setShow(true)
        SetAssessmentType(type)
      } 

    }
  }

  const checkAssessment = async() => {
    let data = {
      code: Code,
      type: AssessmentType,
    };
    let res = await verifyGOVAssessmentCodeAPI(data);
    if(res?.is_success === true) {
      navigate(`/ga-assessments/${res?.assessment?.slug}`);
    }else {
      SetAlertDisplay(true);
      SetAlertMessage(res?.errors);
    }
  }

  const earlyEducation = (index) => {
    const myGallery = GLightbox({
      elements: [
        {
          href: EarlyEducation,
          type: "image",
          alt: "Early Education"
        },
      ],
    });
    myGallery.openAt(index);
  };
  
  return (
    <>
    <section className="wrapper bg-light">
      <div className="container pt-1 pt-md-12 pb-md-14 pb-3 text-center">
        <div className="row">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <h2 className="display-1 mb-10 px-xl-10">Know the Best in You</h2>
          </div>
        </div>
        <div className="position-relative">
          <div
            className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
            data-rellax-speed="1"
            style={{ bottom: "-0.5rem", right: "-2.2rem", zIndex: "0" }}
          ></div>
          <div
            className="shape bg-dot primary rellax w-16 h-17"
            data-rellax-speed="1"
            style={{ top: "-0.5rem", left: "-2.5rem", zIndex: "0" }}
          ></div>
          <div className="row gx-md-5 gy-5 text-center">
            <div className="col-md-6 col-xl-3">
              <div className="card shadow-lg">
                <div className="card-body">
                      <img src={EIGHTHPNG}  width="300" className="img-fluid" style={{width: '30%', height: 'auto'}} alt={`eight`} />
                  <h4 className="mt-3">8th-9th</h4>
                  <p className="mb-2">
                    Looking for the perfect <br />
                    stream and subjects <br />
                    you will enjoy?
                  </p>
                  <Link className="btn btn-sm btn-primary" to={`/assessments/8th-9th`}>
                    Start
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card shadow-lg">
                <div className="card-body">
                  {/* <TENTH className="svg-inject icon-svg icon-svg-md text-yellow mb-3" /> */}
                  <img src={TENTHPNG}  width="300" className="img-fluid" style={{width: '30%', height: 'auto'}} alt={`tenth`} />
                  <h4 className="mt-3">10th-12th</h4>
                  <p className="mb-2">
                    Looking for the <br />
                    right course to pursue <br /> your dreams?
                  </p>

                  <Link className="btn btn-sm btn-primary" to={`/assessments/10th-12th`}>
                    Start
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card shadow-lg">
                <div className="card-body">
                  {/* <CG className="svg-inject icon-svg icon-svg-md text-yellow mb-3" /> */}
                  <img src={CGPNG} width="300" className="img-fluid" style={{width:'30%', height: 'auto'}} alt={`cg`} />
                  <h4 className="mt-3">College Graduates</h4>
                  <p className="mb-2">
                    Caught between choosing <br />a specialization and
                    <br /> getting a job?
                  </p>
                  <Link className="btn btn-sm btn-primary" to={`/assessments/college-graduates`}>
                    Start
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card shadow-lg">
                <div className="card-body">
                 {/*  <WP className="svg-inject icon-svg icon-svg-md text-yellow mb-3" /> */}
                  <img src={WPPNG} className="img-fluid" style={{'width':'30%', height: 'auto'}} alt={`wp`} />
                  <h4 className="mt-3">Working Professionals</h4>
                  <p className="mb-2">
                    Not satisfied with your <br />
                    current job? Want to <br />
                    switch career paths?
                  </p>
                  <Link className="btn btn-sm btn-primary" to={`/assessments/working-professionals`}>
                    Start
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-md-5 gy-5 text-center mt-5">
            <div className="col-md-3">
              <div className="card shadow-lg">
                <div className="card-body">
                {/*   <ECH className="svg-inject icon-svg icon-svg-md text-yellow mb-3" /> */}
                  <img src={ECHPNG} width="200" className="img-fluid" style={{width:'20%', height: 'auto'}} alt={`ech`} />
                  <h4 className="mt-3">Early Education</h4>
                  {/* <button
                    className="btn btn-sm btn-primary"
                    onClick={(e)=>{earlyEducation(0)}}
                  >
                    Learn More
                  </button> */}
                  <Link className="btn btn-sm btn-primary" to={`/early-childhood`}>
                  Early Education 
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card shadow-lg">
                <div className="card-body">
                <img 
  src={ECHPNG} 
  width="200" 
  className="img-fluid" 
  style={{ width: '20%', height: 'auto' }} 
  alt="ech" 
/>
                  <h4 className="mt-3"> Certified Program</h4>
                  <Link className="btn btn-sm btn-primary" to={`/certified-professional-career-counselling-cpcc`}>
                  Certified Program
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card shadow-lg">
                <div className="card-body">
                 {/*  <GOV className="svg-inject icon-svg icon-svg-md text-yellow mb-3" /> */}
                  <img src={GOVPNG} className="img-fluid" style={{'width':'20%', height: 'auto'}} alt={`gov`} />
                  <h4 className="mt-3">For Government</h4>
                  <button
                    className="btn btn-sm btn-primary"
                   onClick={(e)=>getGOVAssessment(2)}
                  >
                    Start
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card shadow-lg">
                <div className="card-body">
               {/*    <CSR className="svg-inject icon-svg icon-svg-md text-yellow mb-3" /> */}
               <img src={CSRPNG} width="200" className="img-fluid" style={{width: '20%', height: 'auto'}} alt={`csr`} />
                  <h4 className="mt-3">For NGO &amp; CSR</h4>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={(e)=>getGOVAssessment(1)}
                  >
                    Start
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

     <Modal
     show={showEarlyEducation}
     onHide={setshowEarlyEducation}
     centered
     size="lg"
     closeButton
     
   >
     <Modal.Body style={{'backgroundColor':'transparent'}}>
       <div className="row">
       <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"  src={EarlyEducation} alt="Early Education" />
       </div>
     </Modal.Body>
   </Modal>

   <Modal
     show={err}
     onHide={(e) => {seterr(false)}}
     backdrop="static"
     keyboard={false}
     centered
   >
     <Modal.Header closeButton>
       <Modal.Title>Error</Modal.Title>
     </Modal.Header>
     <Modal.Body>
     {errMsg.map((x,i)=>(
       <div key={i}>
         {x}
       </div>
     ))}
     </Modal.Body>
   </Modal>
   <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
          ></button>
          <h3 className="mb-4 text-center">Enter Assessment Code</h3>
          <AlertComponent
            AlertDisplay={AlertDisplay}
            SetAlertDisplay={SetAlertDisplay}
            AlertMessage={AlertMessage}
          />
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Code"
                onChange={(e) => SetCode(e.target.value)}
              />
            </div>
            <div className="col-md-3"></div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <button className="btn btn-primary" onClick={checkAssessment}>
                Proceed
              </button>
            </div>
            <div className="col-md-4"></div>
          </div>
        </Modal.Body>
      </Modal>
   </>
  );
}

export default Services;
