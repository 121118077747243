import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import BreadCumb from "../layouts/BreadCumb";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ExpertsDetailAPI } from "../Service";
import Seo from "../layouts/SEO";

function ExpertsDetail() {
  let { slug } = useParams();
  const [Data, SetData] = useState([]);
  const list = [
    { name: "Home", url: "/" },
    { name: "Experts", url: "/experts" },
    { name: Data?.name, url: null },
  ];
  const [MetaDetails, SetMetaDetails] = useState({
    'title': '',
    'meta_title': '',
    'meta_keywords': '',
    'meta_description': '',
  })
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchExpertsDetail();
  }, []);

  const fetchExpertsDetail = async () => {
    let res = await ExpertsDetailAPI(slug);
    if (res?.is_success === true) {
      SetData(res?.data);
      SetMetaDetails({
        'title': res?.data?.meta_title,
        'meta_title': res?.data?.meta_title,
        'meta_keywords': res?.data?.meta_tags,
        'meta_description': res?.data?.meta_description,
      })
    }
  };

  return (
    <div>
      <HeaderComponent />
      <Seo options={MetaDetails} />
      <BreadCumb name={Data?.name} list={list} />
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <img className="img-fluid"
                      src={process.env.REACT_APP_BASE_URL + Data.image}
                      alt={Data?.name}
                      style={{
                        "maxWidth": "100%",
                        'height': "auto",
                        'display': "inline-block",
                      }}
                    />
                    <div
                      className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
                      data-rellax-speed="1"
                      style={{ zIndex: "9",left: "-2.5rem" }}
                    ></div>
                    <h4 className="text-center">{Data?.name}</h4>
                  </div>
                  <div className="col-lg-8 col-md-8">
                    <h2>Expertise</h2>
                    {Data.information && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: Data.information,
                        }}
                      />
                    )}
                    <h2>Bio</h2>
                    {Data.skills && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: Data.skills,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ExpertsDetail;
