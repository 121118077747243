import { CounsellorsAPI } from "../Service";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function CounsellorHelper() {
  const [Data, SetData] = useState([]);
  const [IsDataReceived, SetIsDataReceived] = useState(false);
  let location = useLocation();
  useEffect(() => {
    //window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchCounsellorsList();
  }, []);

  const fetchCounsellorsList = async () => {
    let res = await CounsellorsAPI();
    if (res?.is_success === true) {
      SetData(res?.datas);
      SetIsDataReceived(true);
    }
  };
  const options = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  }

  return (
    <div>
      {IsDataReceived && (
        <section className="wrapper bg-light">
          <div className="container pt-5 pt-md-5 pb-md-8 pb-1">
            {location.pathname !== "/counsellors" && (
              <div className="row mb-3">
                <div className="col-md-10 col-xl-9 col-xxl-7 mx-auto text-center">
                  <h2 className="display-1 mb-3 px-lg-14">Our Expert Counsellors</h2>
                </div>
              </div>
            )}
            <div className="position-relative">
              <div
                className="shape rounded-circle bg-soft-yellow rellax w-16 h-16"
                style={{ bottom: "0.5rem", right: "-1.7rem" }}
                data-rellax-speed="1"
              ></div>
              <div
                className="shape rounded-circle bg-line red rellax w-16 h-16"
                style={{ top: "0.5rem", left: "-1.7rem" }}
                data-rellax-speed="1"
              ></div>
              <OwlCarousel
                items={4}
                className="carousel owl-carousel gap-small"
                autoplay={true}
                nav={false}
                dots={true}
                autoplayTimeout={5000}
                responsive={options}
                loop
                margin={30}
                aria-label="Counsellor carousel"
              >
                {Data.map((x, i) => (
                  <div className="item" key={i} role="group" aria-labelledby={`item-${i}`}>
                    <div className="item-inner">
                      <div className="position-relative shape-mobile">
                        <div className="shape rounded bg-soft-primary rellax d-md-block" data-rellax-speed="0" style={{ bottom: "-0.75rem", right: "-0.75rem", width: "98%", height: "98%", zIndex: "0", transform: "translate3d(0px, 0px, 0px)" }}></div>
                        <Link to={`/counsellor/${x.username}`} aria-labelledby={`card-${i}`}>
                          <div className="card" style={{ Height: "350px" }}>
                            <figure className="card-img-top">
                              <img
                                className="img-fluid" style={{ Height: "270px",  width: '100%' }}
                                src={process.env.REACT_APP_BASE_URL + x.image}
                                alt={x.name}
                                loading = {"lazy"}
                              />
                            </figure>
                            <div className="card-body px-6 py-5">
                              <h4  id={`card-${i}`} className="mb-1">{x.name}</h4>
                              <p className="mb-0">{x.position}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default CounsellorHelper;
