import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutAPI } from "../../Service";
import Cookies from "js-cookie";

function Logout() {
  let USER_TOKEN = Cookies.get("usr_token");
  const navigate = useNavigate();
  useEffect(() => {
    if (USER_TOKEN !== undefined) {
      Logout();
    } else {
      navigate("/");
    }
  }, []);

  const Logout = async (e) => {
    let data = {
      access_token: USER_TOKEN,
    };
    let res = await LogoutAPI(data);
    if (res?.is_success === true) {
      Cookies.remove("usr_token");
      Cookies.remove("user");
      localStorage.removeItem('broker');
      navigate("/");
    }
  };
  return <div></div>;
}

export default Logout;
