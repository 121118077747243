import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import GLightbox from "glightbox";
import MotivationImage1  from "../assets/img/motivational/1.webp";
import MotivationImage2  from "../assets/img/motivational/2.webp";
import MotivationImage3  from "../assets/img/motivational/3.webp";
import MotivationImage4  from "../assets/img/motivational/4.webp";

/* Why career counselling is need of the day */

function Motivated() {
  const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
  });

  const careerVideo = (index) => {
    const myGallery = GLightbox({
      elements: [
        {
          href: "https://www.youtube.com/watch?v=3qLJOSL9S7E",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://www.youtube.com/watch?v=-O3JBfA60co",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://www.youtube.com/watch?v=7EXHD96J_ws",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://www.youtube.com/watch?v=umT4a7Lza0c",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
      ],
      autoplayVideos: true,
    });
    myGallery.openAt(index);
  };

  const options ={
    0: {
      items: 1,
  },
  600: {
      items: 2,
  },
  1000: {
      items: 4,
  },
  }

  return (
    <div>
      <section className="wrapper bg-light">
        <div className="container pt-11 pb-md-1">
          <div className="row">
            <div className="col-lg-9 col-xl-8 col-xxl-7 mx-auto text-center">
              <h2 className="display-1 mb-10">Motivational Stock</h2>
            </div>
          </div>
        </div>
        <div className="container px-md-6">
          <OwlCarousel
            items={4}
            className="carousel owl-carousel gap-small"
            loop
            margin={30}
            responsive={options}
          >
            <div className="item">
              <figure className="rounded mb-6">
                <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(0);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"  src={MotivationImage1} alt="Lets get motivated" />
              </figure>
            </div>

            <div className="item">
              <figure className="rounded mb-6">
              <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(1);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"  src={MotivationImage2} alt="Lets get motivated" />
              </figure>
            </div>

            <div className="item">
              <figure className="rounded mb-6">
              <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(2);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"  src={MotivationImage3} alt="" />
              </figure>
            </div>

            <div className="item">
              <figure className="rounded mb-6">
              <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(3);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"  src={MotivationImage4} alt="Lets get motivated" />
              </figure>
            </div>
           
          </OwlCarousel>
        </div>
      </section>
    </div>
  );
}

export default Motivated;
