import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { useState, useEffect } from "react";
import { useSearchParams, Link, useLocation } from "react-router-dom";
import { BlogListAPI } from "../Service";
import Pagination from "react-js-pagination";
import BreadCumb from "../layouts/BreadCumb";
import Moment from "react-moment";
import "moment-timezone";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
import OnlineRegModal from '../layouts/onlineReg';
import Cookies from "js-cookie";

function Blog() {

  const setting = JSON.parse(localStorage.getItem("setting"));
  const [searchParams, setSearchParams] = useSearchParams();
  let tag = searchParams.get("tag");
  const [blogList, SetBlogList] = useState([]);
  const [showScroll, setShowScroll] = useState(false);

  const [state, setData] = useState({});
  const list = [
    { name: "Home", url: "/" },
    { name: "Blog", url: null },
  ];
  let MetaDetails = {
    title: "Official Blog | " + process.env.REACT_APP_NAME,
    meta_title: "Official Blog | " + process.env.REACT_APP_NAME,
    meta_keywords: "Mere Mentor Official Blogs",
    meta_description:
      "Official Blog section mere mentor following here. Click and get more information related to Career Counsellor",
  };

  let USER_TOKEN = Cookies.get("usr_token");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchBlogList();
    if (USER_TOKEN == undefined) {
      setTimeout(() => {
        setShowOnlineReg(true)
      }, 90000);
    }
  }, []);

  const handleClose = () => setShow(false);
  const [showOnlineReg, setShowOnlineReg] = useState(false);
  const [show, setShow] = useState(false);

  const fetchBlogList = async (pageNumber = 1, tag) => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    tag = params.get('tag');

    let related_tag = (tag !== undefined) ? tag : ''
    let res = await BlogListAPI(pageNumber, related_tag);
    if (res?.is_success === true) {
      setData(res.blogs);
      SetBlogList(res.blogs.data);
    }
  };
  const truncate = (str, max, suffix) =>
    str.length < max
      ? str
      : `${str.substr(
        0,
        str.substr(0, max - suffix.length).lastIndexOf(" ")
      )}${suffix}`;

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />

      <BreadCumb name={"Blog"} list={list} />
      <section className="wrapper bg-light blogs">
        <OnlineRegModal show={showOnlineReg} hide={() => setShowOnlineReg(false)} />
        <div className="container py-14 py-md-16 mobileView">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="blog grid grid-view mt-3">
                  <div className="row isotope gx-md-8 gy-8 mb-8">

                    {blogList.map((x, i) => (
                      <article className="item post col-md-4" key={i}>
                        <div className="card">
                          <figure className="card-img-top overlay overlay-1 hover-scale">
                            <Link className="link-dark" to={`/blog/${x.slug}`}>
                              <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"
                                src={process.env.REACT_APP_BASE_URL + x.image}
                                alt={x.title}
                              />
                            </Link>
                          </figure>
                          <div className="card-body">
                            <div className="post-header">
                              <i className="uil uil-calendar-alt"></i>{" "}
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                <Moment tz="Asia/Kolkata" format="D MMM YYYY">
                                  {x.created_at}
                                </Moment>
                              </span>
                              <h2 className="post-title h3 mt-1 mb-3 bl-height">
                                <Link
                                  className="link-dark"
                                  to={`/blog/${x.slug}`}
                                >
                                  {x.title}
                                </Link>
                              </h2>
                            </div>
                            {/* <div className="post-content">
                              {x.description && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: truncate(x.description, 95, "..."),
                                  }}
                                />
                              )}
                            </div> */}
                          </div>
                        </div>
                      </article>
                    ))}
                  </div>
                </div>

                <div className="row mobileOnly" style={{ position: "relative", left: "29%" }}>
                  <div className="col-md-12">
                    <nav className="d-flex" aria-label="pagination">
                      {state?.current_page ? (
                        <div>
                          <Pagination
                            activePage={
                              state?.current_page ? state?.current_page : 0
                            }
                            itemsCountPerPage={
                              state?.per_page ? state?.per_page : 0
                            }
                            totalItemsCount={state?.total ? state?.total : 0}
                            pageRangeDisplayed={5}
                            onChange={(pageNumber, tag) => {
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                              fetchBlogList(pageNumber, tag);
                            }}
                            itemClass="page-item"
                            linkClass="page-link"
                            className="mt-1"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </nav>
                  </div>
                </div>
                <div className="row" style={{ position: "absolute", left: "40%" }}>
                  <div className="col-md-12">
                    <p className="mt-2">
                      Showing {state?.from ? state?.from : 0}-
                      {state?.to ? state?.to : 0} of{" "}
                      {state?.total ? state?.total : 0} entries
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default Blog;
