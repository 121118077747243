import HeaderComponent from "../layouts/HeaderComponent";

import { useState, useEffect } from "react";
import { Form, Button, Spinner, ModalBody, Modal, Alert } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";


import Cookies from "js-cookie";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
import validator from "validator";
import { storeLookHereAPI } from "../Service";
import { getPageAPI, GetStatesAPI } from "../Service";
import AlertComponent from "../layouts/Alert";

import introductionThumbNail from '../assets/img/photos/home-image/home-screen-image.jpg';
import introductionimagesrcset from '../assets/img/photos/home-image/home-screen-image.jpg';

import spendingStressImage1 from '../assets/img/photos/home-image/1.jpg';
import spendingStressImage2 from '../assets/img/photos/home-image/2.jpg';
import spendingStressImage3 from '../assets/img/photos/home-image/3.jpg';
import spendingStressImage4 from '../assets/img/photos/home-image/4.jpg';

import spendingStressSrcImage1 from '../assets/img/photos/home-image/1.jpg';
import spendingStressSrcImage2 from '../assets/img/photos/home-image/2.jpg';
import spendingStressSrcImage3 from '../assets/img/photos/home-image/3.jpg';
import spendingStressSrcImage4 from '../assets/img/photos/home-image/4.jpg';

import financeImage1 from '../assets/img/photos/home-image/5.jpg';
import financeImage2 from '../assets/img/photos/home-image/6.jpg';
import financeImage3 from '../assets/img/photos/home-image/7.jpg';

import financeSrcSetImage1 from '../assets/img/photos/home-image/5.jpg';
import financeSrcSetImage2 from '../assets/img/photos/home-image/6.jpg';
import financeSrcSetImage3 from '../assets/img/photos/home-image/7.jpg';

import whoWeAreImage1 from '../assets/img/photos/why-the-childhood-image/1.png';
import whoWeAreImage2 from '../assets/img/photos/why-the-childhood-image/2.png';
import whoWeAreImage3 from '../assets/img/photos/why-the-childhood-image/3.png';

import whoWeAreSrcSetImage1 from '../assets/img/photos/why-the-childhood-image/1.png';
import whoWeAreSrcSetImage2 from '../assets/img/photos/why-the-childhood-image/2.png';
import whoWeAreSrcSetImage3 from '../assets/img/photos/why-the-childhood-image/3.png';

import ourValueImage1 from '../assets/img/photos/dive-into-project-image/3.png';
import ourValueImage2 from '../assets/img/photos/dive-into-project-image/4.png';

import ourValueSrcSetImage1 from '../assets/img/photos/dive-into-project-image/3.png';
import ourValueSrcSetImage2 from '../assets/img/photos/dive-into-project-image/4.png';
import internetImg from '../assets/img/internet.png';
import timeImg from '../assets/img/time.png';
import tickImg from '../assets/img/tick.png';
import schoolImg from '../assets/img/schools.jpeg';
import collegeImg from '../assets/img/college.jpeg';
import firmImg from '../assets/img/firms.jpeg';
import ncda from '../assets/img/ncda.png';
// import ncdaCertificate from '../assets/img/ncdaCertificate.png';
import ncdaCertificate from '../assets/img/ncda.jpeg';
import greenTick from '../assets/img/greenTick.png';
import crossTick from '../assets/img/crossTick.png';
import rating from '../assets/img/ratingStar.png';
import testiProfile from '../assets/img/testimonialProfile.png';
import whiterating from '../assets/img/whiteRating.png';
import Icon from '../assets/img/searchIcon.png';
import job from '../assets/img/jobProgram.png';
import liveTraining from '../assets/img/live.png';
import studyMat from '../assets/img/study.png';
import holdingCareer from '../assets/img/holding.png';
import strucutre from '../assets/img/coreForm.png';
import sess from '../assets/img/session.png';
import growing from '../assets/img/grow.jpg';
import Market from '../assets/img/evolveJobMarket.jpg';
import awrns from '../assets/img/increasedVolume.jpg';
import Counsellers from '../assets/img/qualificationProfile.jpg';
import recognitionStudent from '../assets/img/growingStudent.jpg';
import googleReview from '../assets/img/google.png';
import careerGrp from '../assets/img/careerGraph.png';
import factorGrp from '../assets/img/factorCareerGraph.png';
import oneImg from '../assets/img/testiOne.jpg';
import twoImg from '../assets/img/testiTwo.jpg';
import threeImg from '../assets/img/testiThree.jpg';
// import tickBoxImg from '../asstes/img/tickBox.png';
// import liveTraining from '../asstes/img/liveTraining.png';
// import core from '../asstes/img/core.png';
// import counsellingSession from '../asstes/img/counsellingSession.png';
// import interactive from '../asstes/img/interactive.png';
// import studyMaterial from '../asstes/img/studyMaterial.png';

import partnerImage1 from '../assets/img/brands/c1.png';
import partnerImage2 from '../assets/img/brands/c2.png';
import partnerImage3 from '../assets/img/brands/c3.png';
import partnerImage4 from '../assets/img/brands/c4.png';
import partnerImage5 from '../assets/img/brands/c5.png';
import partnerImage6 from '../assets/img/brands/c6.png';

import weareProudImage from '../assets/img/photos/slider-image/2.png';
import weareProudSrcSetImage from '../assets/img/photos/slider-image/2.png';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Footer from "../layouts/Footer";
import Ticker from "../layouts/CounterUpVisibility";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "../assets/css/owl.css"

export default function ProductPage() {

    const setting = JSON.parse(localStorage.getItem("setting"));
    const [showScroll, setShowScroll] = useState(false);
    const [checkOut, SetCheckOut] = useState(false);
    const [message, SetMessage] = useState("");
    const [submitButton, SetSubmitButton] = useState(false);
    const [name, SetName] = useState("");
    const [email, SetEmail] = useState("");
    const [qualification, SetQualification] = useState("DEFAULT");
    const [mobile, SetMobile] = useState("");
    const [AlertDisplay, SetAlertDisplay] = useState(false);
    const [AlertMessage, SetAlertMessage] = useState([]);
    const [MessageDisplay, SetMessageDisplay] = useState(false);
    const [MessageContent, SetMessageContent] = useState([]);
    const [qualification2, SetQualificationn] = useState("DEFAULT");
    const [userState, SetUserState] = useState(0);
    const [lookhere, SetLookhere] = useState(false);
    const [states, SetStates] = useState([]);
    const [faqs, SetFaqs] = useState([
        {question: "What is CPCC?",
        answer: "Certified Professional for Career Counselling (CPCC) is a specialized training program tailored for individuals aspiring to work independently as counsellors or enhance their qualifications in the field. Acquiring this certification allows you to provide counselling services to students either through contractual arrangements with educational institutions or private organizations or independently as a freelance counsellor. "},
        {question: "How to Enroll for the course?",
        answer: "Enrollment for the Foundation path begins with visiting our website, where you can access the application form at no cost. For Level 2 and Level 3, applicants are selected based on their eligibility and analysis of their Statement of Purpose (SOP)."},
        {question: "How to become a globally Certified Career Counsellor?",
        answer: "To become a globally certified career counsellor, you must hold a bachelor's degree in any field. With Mere Mentor’s CPCC program, you can gain expertise and the latest industry knowledge, along with practical hands-on support. Upon completing the course, you will be awarded an NCDA certificate, enabling you to become a globally certified career counsellor."},
        {question: "Is the Certified Professional for Career Counsellor program suitable for individuals from non-psychology backgrounds?",
        answer: "Yes, individuals from non-psychology backgrounds are suitable for the Certified Professional for Career Counsellor program. The curriculum is comprehensive and designed to provide a holistic understanding of career counselling and equip participants with the necessary skills. Success in this profession depends not only on academic background but also on passion, dedication, and aptitude for the role."},
        {question: "Is this course offered on weekends?",
        answer: "Yes, the CPCC course has a total duration of 16 weeks. Classes will be conducted online on Saturdays and Sundays every week."},
        {question: "What is the process once I enrol in the certification course?",
        answer: "Once you enrol in the certification course, you'll progress through different levels. At the end of each level, there will be assessments and assignments. Completing a level allows you to advance to the next one and receive certification for that level. This process helps you gradually build your skills and knowledge, ensuring you become a certified professional step by step."}
    ]);

    let USER_TOKEN = Cookies.get("usr_token");
    let user = Cookies.get("user");
    const [Code, SetCode] = useState("");

    let MetaDetails = {
        'title': 'Certified Professional for Career Counselling (CPCC) | ' + process.env.REACT_APP_NAME,
        'meta_title': 'Certified Professional for Career Counselling (CPCC) | ' + process.env.REACT_APP_NAME,
        'meta_keywords': 'Mere Mentor CPCC',
        'meta_description': 'Certified Professional for Career Counselling (CPCC) is the Global Certified Online program with Live Training and Projects by Expert Counsellors in India.'
    }

    let navigate = useNavigate();
    const list = [
        { name: "Home", url: "/" },
        { name: " CPCC| MereMentor", url: null },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0 });
        GetStates();
    }, []);
    const GetStates = async () => {
        let res = await GetStatesAPI();
        if (res?.is_success === true) {
          SetStates(res?.states);
        }
      };
    const checkout = () => {
        if (USER_TOKEN !== undefined) {
        SetCheckOut(true);
        } else {
          navigate("/products?is_login=true&is_message=Please login to make subscription");
        }
      };
      const enquiry = async (e) => {
        SetSubmitButton(true);
        let data = {
          name: name,
          email: email,
          mobile: mobile,
          qualification: qualification,
          qualification2: qualification2,
          state: userState,
          message: message,
        //   slug: slug,
        //   page: PageDetail.id,
        };
        let err = [];
        var mandatoryFilled = true;
    
        var mandatoryFilled = true;
        if (name == "" || name == "null" || name == null || mobile == "" || mobile == "null" || mobile == null || email == "" || email == "null" ||
          email == null || userState === 0 || message == '' || message == 'null' || message == null) {
          err.push("Please fill the mandatory fields");
          mandatoryFilled = false;
        }
        if (mandatoryFilled == true) {
          if (!validator.isLength(name, { min: 3, max: 255 })) {
            err.push("Name should have minimum 3 characters");
          }
          if (!validator.isLength(mobile, { min: 10, max: 10 })) {
            err.push("Mobile Number Must be 10 digits");
          }
          if (!validator.isNumeric(mobile)) {
            err.push("Mobile Number Must be in digits");
          }
          if (!validator.isEmail(email)) {
            err.push("Enter a Valid Email");
          }
          if (qualification === 'DEFAULT' && qualification2 === 'DEFAULT') {
            err.push("Qualification is required");
          }
          // if (qualification2 === 'DEFAULT' && qualification === 'DEFAULT') {
          //   err.push("Qualification is required");
          // }
          if (userState === 0) {
            err.push("State is required");
          }
          if (validator.isEmpty(message)) {
            err.push("Message is required");
          }
        }
    
        if (err.length > 0) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          SetAlertDisplay(true);
          SetAlertMessage(err);
          SetSubmitButton(false);
        } else {
          let res = await storeLookHereAPI(data);
          if (res?.is_success === true) {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
            setTimeout(() => {
              navigate('/thank-you');
            }, 2000);
    
            SetSubmitButton(false);
            SetName("");
            SetEmail("");
            SetMessage("");
            SetQualification("");
            SetQualificationn("");
            SetMobile("DEFAULT");
            SetUserState(0);
            SetLookhere(false)
            SetAlertDisplay(false);
            SetAlertMessage([]);
            SetMessageDisplay(true);
            SetMessageContent(res?.message);
            // setTimeout(() => {
            //   navigate(`/${slug}`);
            // }, 2000);
          } else {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
            SetAlertDisplay(true);
            SetAlertMessage(res?.errors ?? []);
            SetSubmitButton(false);
          }
        }
      };

    return (
        <div>
            <Seo options={MetaDetails} />

            <HeaderComponent />
            <section className="wrapper bg-dark-blue main-banner">
                <div className="container pt-11 pt-md-13 pb-11 pb-md-19 pb-lg-10">
                    <div className="row">
                        <div className="col-lg-8 certifiedClass">
                            <h1 className="display-1 text-white mb-4 px-md-15 px-lg-0">Certified Professional for <br></br> Career Counselling (CPCC)</h1>
                            <p className="text-white">Accelerate Your Career with Global Recognition & Expertise through Our Certification Program</p>
                            <img  className="img-fluid googleReview" src={googleReview}/>
                            <div className="googlerating">
                                <p>4.7</p>
                                <span><img className="img-fluid" style={{ width: '100%', height: 'auto' }} src = {rating} /></span>
                                <span><img className="img-fluid" style={{ width: '100%', height: 'auto' }}  src = {rating} /></span>
                                <span><img className="img-fluid" style={{ width: '100%', height: 'auto' }}  src = {rating} /></span>
                                <span><img className="img-fluid" style={{ width: '100%', height: 'auto' }}  src = {rating} /></span>
                                <span><img className="img-fluid" style={{ width: '100%', height: 'auto' }}  src = {whiterating} /></span>
                            </div>
                            <div className="row certifiedCareer">
                                <div className="col-lg-4 mobile">
                                    <img className="img-fluid" style={{width: "30px", height: "30px"}} alt="tickImg" src={tickImg}></img>
                                    <div className="certifiedProgram">
                                    <p className="text-dark para"> 200+ Certified Career Counsellors</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mobile">
                                    <img className="img-fluid" style={{width: "30px", height: "30px"}} alt="timeImg" src={timeImg}></img>
                                    <div className="certifiedProgram">
                                    <p className="text-dark para">Duration of the Program 12 – 16 weeks</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 mobile">
                                    <img className="img-fluid" style={{width: "30px", height: "30px"}} alt="internetImg" src={internetImg}></img>
                                    <div className="certifiedProgram">
                                    <p className="text-dark para">Mode of Learning - Online</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <AlertComponent
                      AlertDisplay={AlertDisplay}
                      SetAlertDisplay={SetAlertDisplay}
                      AlertMessage={AlertMessage}
                    />
                            <div className="formRight">
                                <h3 className="text-white text-center pt-2">Check your Eligibility</h3>
                                <div className="inputBox">
                                    <input
                                      type="text"
                                      name="name"
                                      className="form-control"
                                      placeholder="Your name *"
                                      onChange={(e) => {
                                        SetName(e.target.value);
                                      }}
                                      value={name} />
                                </div>
                                <div className="inputBox">
                                    <input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    required=""
                                    placeholder="Your email address *"
                                    onChange={(e) => {
                                      SetEmail(e.target.value);
                                    }}
                                    value={email} />
                                </div>
                                <div className="inputBox">
                                    <input
                                      type="number"
                                      minLength="10"
                                      maxLength="10"
                                      name="phone"
                                      className="form-control"
                                      required=""
                                      placeholder="Your phone number *"
                                      onChange={(e) => {
                                        SetMobile(e.target.value);
                                      }}
                                      value={mobile} />
                                </div>
                                <div className="inputBox">
                                <select
                            className="form-control form-select"
                            defaultValue={"DEFAULT"}
                            onChange={(e) => {
                              SetQualification(e.target.value);
                            }}
                          >
                            <option value={qualification2} disabled>
                              Please select your Qualification *
                            </option>
                            <option value="8th-9th">8th-9th</option>
                            <option value="10th-12th">10th-12th</option>
                            <option value="college-graduates">
                              College Graduates
                            </option>
                            <option value="working-professionals">
                              Working Professionals
                            </option>
                           
                          </select>
                                </div>
                                <div className="inputBox">
                                <select
                            className="form-control form-select"
                            name="state"
                            defaultValue={userState}
                            onChange={(e) => {
                              SetUserState(e.target.value);
                            }}
                          >
                            <option value="0" disabled>
                              Please select your State *
                            </option>
                            {states.map((x, i) => (
                              <option value={x.id} key={x.id}>
                                {x.name}
                              </option>
                            ))}
                          </select>
                                </div>
                               
                          <div className="text-center textArea">
                          <textarea
                            rows="3"
                            placeholder="Message *"
                            onChange={(e) => {
                              SetMessage(e.target.value);
                            }}
                            defaultValue={message}
                          ></textarea>
                          </div>
                        
                     
                                <div className="text-center mb-3">
                                <Button
                          className="btn btn-primary rounded-pill btn-send mb-3"
                          disabled={submitButton}
                          onClick={(e) => {
                            enquiry(e);
                          }}
                        >
                          {submitButton ? (
                            <div>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Loading...
                            </div>
                          ) : (
                            <div>Submit</div>
                          )}
                        </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-light">
                <div className="container py-5 py-md-10">
                    {/* <div className="headerHeading">
                        <h1 className="text-white">DAILY UPDATES<br></br>(1120 x 141 )</h1>
                    </div> */}
                    <div className="text-center py-5">
                        <h3>Why to choose CPCC in Merementor?</h3>
                        <p>Global Certified professionals at Mere Mentor provide invaluable expertise and guidance to navigate your career counselling journey effectively.
                             Our certified career counsellors undergo intensive training, equipping them with the latest industry insights and counselling techniques. Our dedication to empowering students is evident in the transformative impact of CPCC,
                              assisting individuals in making informed career decisions and reaching their maximum potential.</p>
                    </div>
                    <div className="mainBox">
                        <div className=" boxes">
                            <img className="img-fluid" alt="job" src={job}></img>
                            <h2 className="text-white text-center">100% Job Guarantee Program</h2>
                        </div>
                        <div className=" boxes">
                            <img className="img-fluid" alt="liveTraining"  src={liveTraining}></img>
                            <h2 className="text-white text-center">Live Training</h2>
                        </div>
                        <div className=" boxes">
                            <img className="img-fluid" alt="studyMat" src={studyMat}></img>
                            <h2 className="text-white text-center">Study Materials</h2>
                        </div>
                    </div>
                    <div className="mainBox">
                        <div className=" boxes">
                            <img className="img-fluid" alt="holding Career" src={holdingCareer}></img>
                            <h2 className="text-white text-center">5+ hand holding counselling sessions</h2>
                        </div>
                        <div className=" boxes">
                            <img className="img-fluid" alt="strucutre" src={strucutre}></img>
                            <h2 className="text-white text-center">Strengthen your core with a structured form of training</h2>
                        </div>
                        <div className=" boxes">
                            <img className="img-fluid" alt="Interactive sessions"  src={sess}></img>
                            <h2 className="text-white text-center">Interactive sessions</h2>
                        </div>
                    </div>
                    <div className="text-center py-4 demandCareer">
                        <h1>Demand for Career Counsellors in India</h1>
                        <p>
                        The demand for career counsellors in India is surging, fuelled by a multitude of factors:
                        </p>
                    </div>
                    <div className="text-center py-4 demandCareer">
                        <div className="consellingImage">
                            <img className="img-fluid" alt="recognition Student"  src= {recognitionStudent}/>
                        </div>
                        <h1>Growing Student Population</h1>
                        <p>
                        India boasts the world's largest youth population, with over 600 million under 25 years old. This young demographic requires guidance and support in navigating the increasingly complex career landscape
                        </p>
                    </div>
                    <div className="text-center py-4 demandCareer">
                        <div className="consellingImage">
                            <img className="img-fluid" alt="Market"  src= {Market}/>
                        </div>
                        <h1>Evolving Job Market</h1>
                        <p>
                        The Indian job market is undergoing rapid transformation. Automation, digitization, and the emergence of new industries create a need for individuals to adapt and make informed career choices.
                        </p>
                    </div>
                    <div className="text-center py-4 demandCareer">
                        <div className="consellingImage">
                            <img className="img-fluid" alt="awrns"  src= {awrns}/>
                        </div>
                        <h1>Increased Awareness</h1>
                        <p>
                        As awareness about career-related challenges rises, more individuals seek guidance from career counselors, reflecting a growing acknowledgment of the importance of informed decision-making and support in achieving professional goals.
                        </p>
                    </div>
                    <div className="text-center py-4 demandCareer">
                        <div className="consellingImage">
                            <img className="img-fluid" alt="Counsellers"  src= {Counsellers}/>
                        </div>
                        <h1>Scarcity of Qualified Counsellors</h1>
                        <p>
                        Currently, India has a massive gap between the demand and supply of qualified career counsellors. Estimates suggest the country needs at least 1.5 million career counsellors, while the current number stands below 5,000.
                        </p>
                    </div>
                    <div className="text-center py-4 demandCareer">
                        <div className="consellingImage">
                            <img className="img-fluid" alt="growing"  src= {growing}/>
                        </div>
                        <h1>Growing Recognition</h1>
                        <p>
                        Individuals recognize the value of career counseling in adapting to changing job markets, its importance in navigating career transitions and seizing new opportunities is increasingly acknowledged
                        </p>
                    </div>
                    <div className="headerHeadingTwo">
                        <h3 className="text-white">Navigating Career Paths with Institutions</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 schoolImg">
                            <img className="img-fluid" style={{ width: '100%', height: 'auto' }} src = {schoolImg} alt="Schools"></img>
                        </div>
                        <div className="col-lg-6 mt-15 schools">
                        <h2>Schools</h2>
                            <p>Guide students in exploring career options and nurturing their potential from an early age.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mt-15 schools">
                        <h2>College</h2>
                            <p>Assist students in making informed decisions about their majors, internships, and career goals.</p>
                        </div>
                        <div className="col-lg-6 schoolImg">
                            <img className="img-fluid" style={{ width: '100%', height: 'auto' }}  src = {collegeImg}></img>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 schoolImg">
                            <img className="img-fluid" style={{ width: '100%', height: 'auto' }}  src = {firmImg}></img>
                        </div>
                        <div className="col-lg-6 mt-15 schools">
                        <h2>Firms</h2>
                            <p>Guide employees in career planning and skill development to foster growth within the organization.</p>
                        </div>
                    </div>
                    <div className="mt-8 ncdaCertificatePara">
                        <h1>NCDA Certification <span>
                            <img alt="NCDA Certification"  className="img-fluid ncdaImg" src = {ncda}></img>
                            </span></h1>
                        <p>NCDA stands as the premier association in the United States for career and vocational development. NCDA certifications hold international recognition in over 50+ countries worldwide. Mere Mentor provides NCDA certification to assist people in becoming Certified Career Counselling Professionals (CPCC).</p>
                    </div>
                    <div className="row mt-10">
                        <div className="col-lg-6 text-center ncdaCertificatee">
                            <img style={{ width: '100%', height: 'auto' }}  className="img-fluid ncdaCertificate" src = {ncdaCertificate}></img>
                        </div>
                        <div className="col-lg-6 listNcda">
                            <ul>
                                <li>Elevate your career with Merementor's NCDA Certification Program.</li>
                                <li>Unlock global recognition.</li>
                                <li>Gain competitive expert guidance through our CPCC Training Program.</li>
                                <li>Empower yourself to change lives with professional career counselling</li>
                            </ul>
                        </div>
                    </div>
                    <div className="mt-8 ncdaCertificatePara">
                        <h1>CPCC Programs<span>
                            <img alt="CPCC Programs" className="img-fluid ncdaImg" src = {ncda}></img>
                            </span></h1>
                    </div>
                    <div className="tableData">
                        <table>
                            <thead>
                                <th><h3>Features  of the Program</h3></th>
                                <th><h3>Foundation Level</h3></th>
                                <th><h3>Proficiency Level</h3></th>
                                <th><h3>Expert Level</h3></th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p>Mere Mentor Certificate</p></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                </tr>
                                <tr>
                                    <td><p>Mere Mentor Badge</p></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                </tr>
                                <tr>
                                    <td><p>Live Training</p></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                </tr>
                                <tr>
                                    <td><p>Training Material</p></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                </tr>
                                <tr>
                                    <td><p>Personalized Mentoring</p></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                </tr>
                                <tr>
                                    <td><p>100% Placement Guarantee</p></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                </tr>
                                <tr>
                                    <td><p>5 Hand Holding Sessions</p></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                </tr>
                                <tr>
                                    <td><p>Case Studies</p></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                </tr>
                                <tr>
                                    <td><p>NCDA Certificate</p></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={crossTick}></img></td>
                                    <td><img className="img-fluid" alt="greenTick" src={greenTick}></img></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="graph mt-5 stats">
                        <h1 className="display-1">Stats of career counselling</h1>
                        <div className="row">
                            <div className="col-lg-6">
                                <img style={{ width: '100%', height: 'auto' }} className="img-fluid graphImg" src={careerGrp} />
                            </div>
                            <div className="col-lg-6">
                                <img style={{ width: '100%', height: 'auto' }} className="img-fluid graphImg" src={factorGrp} />
                            </div>
                        </div>
                    </div>
                    <div className="tablePara mt-5">
                        <p>Career counselors are essential advisers in today's dynamic work market. The impact is evident—71% of respondents are looking for job fulfilment, 68% value counselling support, and 80% report feeling more confident after getting advice. Invest in your future by entering the fulfilling field of career counselling right now</p>
                    </div>
                    <div className="headingIndependent">
                        <p>Start your independent counselling practice</p>
                        <div className="text-center mb-3 independentBtn">
                        <a href={"tel:"+setting?.phone}
                    className="btn btn-primary"
                      data-url={"tel:"+setting?.phone}
                  ><i class="uil uil-phone-volume"></i> &nbsp;
                    Contact Us
                  </a>
                        </div>
                    </div>
                    <div className="testimonials mt-6">
                        <p>Testimonials</p>
                    </div>
                    <div className="testi">
                        <div className="testimonialsDetail">
                            <img className="img-fluid testiProfile" src={oneImg}></img>
                            <div className="testImg">
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {whiterating}/></span>
                            </div>
                            <div className="testPara">
                                <p>The CPCC program was well-structured and rigorous, ensuring participants meet the necessary standards to become certified career counsellors. I have completed my Proficiency level and eagerly await the opportunity to begin the Expert level -- Sanjay</p>
                            </div>
                        </div>
                        <div className="testimonialsDetail testiSec">
                            <img className="testiProfile" src={threeImg}></img>
                            <div className="testImg">
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {whiterating}/></span>
                            </div>
                            <div className="testPara">
                                <p>The course content was comprehensive and covered a wide range of topics relevant to career counselling. From understanding individual strengths and weaknesses to exploring various career paths and counselling techniques, the course provided a solid foundation -- Pooja</p>
                            </div>
                        </div>
                        <div className="testimonialsDetail">
                            <img className="testiProfile" src={twoImg}></img>
                            <div className="testImg">
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {rating}/></span>
                                <span><img className="img-fluid" alt="rating" src = {whiterating}/></span>
                            </div>
                            <div className="testPara">
                                <p>The instructors were knowledgeable and experienced in the field of career counselling. Their insights, guidance, and materials were invaluable in helping me understand the nuances of career counselling and develop my skills – Amit</p>
                            </div>
                        </div>
                    </div>
                    <div className="howWeHelp mt-5">
                        <p>How can we help ?</p>
                    </div>
                    <div className="searchBox">
                        <input type="text"></input>
                        <img src= {Icon} />
                    </div>
                     <div className="row faqss">
                     <h1 class="display-1 faqs">Frequently Asked Questions</h1>
              <div className="col-lg-10 mx-auto">
                <div className="card">
                  <div className="card-body">
                    {faqs.length > 0 && (
                      <div>
                        <div id="accordion-3" className="accordion-wrapper">
                          {faqs.map((x, i) => (
                            <Accordion
                              defaultActiveKey={i}
                              className="card accordion-item"
                              key={i}
                            >
                              <Accordion.Item eventKey="0" className="mt-1">
                                <Accordion.Header className="card-header">
                                  {x.question}
                                </Accordion.Header>
                                <Accordion.Body className="card-body">
                                  {x.answer}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
                </div>
            </section>

            {/* <div className="superImpose">
            <a class="btn btn-sm btn-primary" onClick={(e) => checkout()}>Start</a>
            </div> */}

            <Modal
        show={checkOut}
        onHide={(e) => {
          SetCheckOut(false);
        
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           Modal Title
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped table-bordered table-hover">
            <tbody>
              <tr>
                <th>Plan</th>
                <td>Plan</td>
              </tr>
              <tr>
                <th>Subtotal</th>
                <td>&#8377; 20000</td>
              </tr>
              <tr>
                <th>Discount</th>
                <td>
                   0
                </td>
              </tr>
              <tr>
                <th>Coupon Amount</th>
                <td>&#8377; 0</td>
              </tr>
              <tr>
                <th>Total</th>
                <td>&#8377; 20000</td>
              </tr>
             
            </tbody>
          </table>
          <div className="row p-2">
            <button
              className="btn btn-xs btn-primary"
            //   onClick={(e) => {
            //     handlePayment();
            //   }}
            >
              Continue Payment
            </button>
          </div>
        </Modal.Body>
      </Modal>

            <Footer />
            <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
        </div>
    );
}
