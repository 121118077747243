import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import Sidebar from "../../layouts/Sidebar";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import "moment-timezone";
import Moment from "react-moment";
import { Icon } from "@iconify/react";
import {
  checkCounsellingAPI,
  getCounsellingListAPI,
  cancelMeetingAPI,
  assesmentReportDownloadAPI,
  counsellingStatusUpdateAPI,
  actionPlanDownloadAPI,
  feedbackStoreAPI,
  storeStudentRequestQueryAPI,
  storeCounsellorQueryResponseAPI
} from "../../Service";
import AlertComponent from "../../layouts/Alert";
import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from "react";
import { Modal, Button, Table, Spinner } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BackToTop from "../../layouts/BackToTop"
import Seo from "../../layouts/SEO";
import Message from "../../layouts/Message";

function Counselling() {
  const editorRef = useRef(null);
  const [showScroll, setShowScroll] = useState(false);
  const animatedComponents = makeAnimated();
  const list = [
    { name: "Home", url: "/" },
    { name: "Counselling", url: null },
  ];
  let USER_TOKEN = Cookies.get("usr_token");
  let user = JSON.parse(Cookies.get("user"));
  const navigate = useNavigate();
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [CounsellingList, SetCounsellingList] = useState([]);
  const [isCancelReason, SetIsCancelReason] = useState(false);
  const [meetingId, SetMeetingId] = useState(null);
  const [ReasonErr, SetReasonErr] = useState("");
  const [SuccessModal, SetSuccessModal] = useState(false);
  const [Report, SetReport] = useState(false);
  const [showStudentRecord, SetshowStudentRecord] = useState(false);
  const [Record, SetRecord] = useState([]);
  const [CompleteMeeting, SetCompleteMeeting] = useState(false);
  const [counsellingEncryptId, SetcounsellingEncryptId] = useState(false);
  const [MessageDisplay, SetMessageDisplay] = useState(false);
  const [MessageContent, SetMessageContent] = useState([]);
  const [feedbackModalShow, SetfeedbackModalShow] = useState(false);
  const [counsellingId, SetcounsellingId] = useState();
  const [requestQueryCount, SetrequestQueryCount] = useState()

  const [studentCounsellingId, SetstudentCounsellingId] = useState();
  const [actionPlanId, SetactionPlanId] = useState();

  const [displayStudentRequestQueryModal, SetdisplayStudentRequestQueryModal] = useState(false);
  const [counsellorResponseQueryModal, setcounsellorResponseQueryModal] = useState(false);

  const [studentRequestQueryMessageModal, SetstudentRequestQueryMessageModal] = useState(false);
  const [studentRequestQueryMessage, SetstudentRequestQueryMessage] = useState("");
  const [viewRequestQueryResponseModal, SetviewRequestQueryResponseModal] = useState();
  const [requestQueryResponseData, setrequestQueryResponseData] = useState();

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  let MetaDetails = {
    'title': 'Career Counselling | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Career Counselling | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Career Counselling Mere Mentor',
    'meta_description': 'Mere Mentor is an EdTech organization delivering career counselling services to the students and youth of the country.',
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (USER_TOKEN === undefined || user === null) {
      navigate("/");
    }
    fetchgetCounsellingListAPI();
    SetrequestQueryCount(2);
  }, []);

  const fetchcheckCounsellingAPI = async () => {
    let res = await checkCounsellingAPI(USER_TOKEN);
    if (res?.is_success) {
      navigate(`/counselling/create?type=${res?.slug}`);
    } else if (res?.is_not_subscribed) {
      SetAlertDisplay(true);
      SetAlertMessage(res?.errors);
      setTimeout(() => {
        navigate(`/products/${res?.slug}`);
      }, 500);
    } else {
      SetAlertDisplay(true);
      SetAlertMessage([res?.errors]);
    }
  };

  const fetchgetCounsellingListAPI = async () => {
    let res = await getCounsellingListAPI(USER_TOKEN);
    if (res?.is_success) {
      SetCounsellingList(res?.counsellings);
    }

    // {CounsellingList.map((x, i) => (

    // ))}
  };

  const SubmitReason = async () => {
    if (editorRef.current) {
      let data = {
        reason: editorRef.current.getContent(),
        meeting_id: meetingId,
      };
      let res = await cancelMeetingAPI(data, USER_TOKEN);
      if (res?.is_success) {
        SetAlertMessage([]);
        SetAlertDisplay(false);
        SetIsCancelReason(false);
        SetSuccessModal(true);
        fetchgetCounsellingListAPI();
      } else {
        SetAlertDisplay(true);
        SetAlertMessage([res?.errors]);
      }
    }
  };

  const SubmitStudentRequestQuery = async () => {
    if (editorRef.current) {
      let data = {
        student_query: editorRef.current.getContent(),
        stdeunt_counselling_id: studentCounsellingId,
        action_plan_id: actionPlanId,
      };
      let res = await storeStudentRequestQueryAPI(USER_TOKEN, data);
      if (res?.is_success) {
        SetdisplayStudentRequestQueryModal(false);
        SetSuccessModal(true);
        SetstudentRequestQueryMessageModal(true);
        SetstudentRequestQueryMessage(res?.message);
        SetstudentCounsellingId("");
        SetactionPlanId("");
        fetchgetCounsellingListAPI();
      } else {
        SetAlertDisplay(true);
        SetAlertMessage([res?.errors]);
      }
    }
  };

  const SubmitCounsellorResponseQuery = async () => {
    if (editorRef.current) {
      let data = {
        counsellor_response: editorRef.current.getContent(),
        response_query_id: actionPlanId,
      };
      let res = await storeCounsellorQueryResponseAPI(USER_TOKEN, data);
      if (res?.is_success) {
        setcounsellorResponseQueryModal(false);
        SetSuccessModal(true);
        SetstudentRequestQueryMessageModal(true);
        SetstudentRequestQueryMessage(res?.message);
        SetactionPlanId("");
        fetchgetCounsellingListAPI();
      } else {
        SetAlertDisplay(true);
        SetAlertMessage([res?.errors]);
      }
    }
  };

  const cancelMeeeting = (e, meeting_id) => {
    SetIsCancelReason(true);
    SetMeetingId(meeting_id);
  };

  const displayStudentRecord = (e, record) => {
    SetRecord(JSON.parse(record.user_details));
    SetshowStudentRecord(true);
  };

  const download = async (e, id) => {
    let res = await assesmentReportDownloadAPI(USER_TOKEN, id);
    if (res?.is_success) {
      const linkSource = `data:application/pdf;base64,${res?.file}`;
      const downloadLink = document.createElement("a");
      const fileName = res?.filename;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      SetAlertDisplay(true);
      SetAlertMessage([res?.errors]);
    }
  };

  const actionPlanDownload = async (e, id) => {
    let res = await actionPlanDownloadAPI(USER_TOKEN, id);
    if (res?.is_success) {
      const linkSource = `data:application/pdf;base64,${res?.file}`;
      const downloadLink = document.createElement("a");
      const fileName = res?.filename;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } else {
      SetAlertDisplay(true);
      SetAlertMessage([res?.errors]);
    }
  };

  let initialFormData = "";

  const [inputField, setInputField] = useState(initialFormData)

  const [submitButtons, SetSubmitButtons] = useState(false);

  const handleChange = (e) => {
    setInputField({
      ...inputField,
      counsellingId: counsellingId,
      [e.target.name]: e.target.value.trim(),
    });
  };


  const feedbackModalData = async (e, id) => {
    SetfeedbackModalShow(true);
    SetcounsellingId(id);
  };

  const feedbackFormDetailStore = async (e, id) => {
    e.preventDefault();

    let res = await feedbackStoreAPI(USER_TOKEN, inputField);
    SetfeedbackModalShow(false);
    if (res?.is_success) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      fetchgetCounsellingListAPI();
      SetMessageDisplay(true);
      SetMessageContent(res?.message);
    } else {
      SetAlertDisplay(true);
      SetAlertMessage([res?.errors]);
    }
  };

  const confirmMeeting = async () => {
    let data = {
      status: 4,
    };
    let res = await counsellingStatusUpdateAPI(
      USER_TOKEN,
      counsellingEncryptId,
      data
    );
    if (res?.is_success) {
      SetCompleteMeeting(false);
      fetchgetCounsellingListAPI();
    } else {
      SetAlertDisplay(true);
      SetAlertMessage([res?.errors]);
    }
  };

  const checkRequestQueryData = async (requestQueryData) => {
    if (requestQueryData.length > 0) {
      {
        requestQueryData.map((value, i) => {
          if (value.status != 4) {
            SetdisplayStudentRequestQueryModal(false);
            SetAlertDisplay(true);
            SetAlertMessage(["Your Query Request Was in Progress, Please Wait Untill Your Got Response From Counsellor."]);
          } else {
            SetdisplayStudentRequestQueryModal(true);
          }
        })
      }
    } else {
      SetdisplayStudentRequestQueryModal(true);
    }
  };

  const checkCounsellorReponseQueryData = async (requestQueryData) => {
    if (requestQueryData.length > 0) {
      {
        requestQueryData.map((value, i) => {
          if (value.status != 4) {
            setcounsellorResponseQueryModal(true);
            setrequestQueryResponseData(value);
            SetactionPlanId(value?.encryptid);
          }
        })
      }
    }
  };

  const viewRequestQueryData = async (value) => {
    if (value.status == 4) {
      SetviewRequestQueryResponseModal(true);
      setrequestQueryResponseData(value);
    } else {
      SetAlertDisplay(true);
      SetAlertMessage(["Something Went Wrong"]);
    }
  };

  return (
    <div>
      <Message
        MessageDisplay={MessageDisplay}
        MessageContent={MessageContent}
        SetMessageDisplay={SetMessageDisplay}
      />
      <HeaderComponent />
      <Seo options={MetaDetails} />
      <BreadCumb name={"My Counselling"} list={list} />
      <section className="myprofile-section ptb-50">
        <div className="container">
          <div className="pricing-wrapper position-relative mt-n18 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row justify-content-between main-area">
              <div className="col-lg-3">
                <div className="myprofile-sidebar rounded-3">
                  <div className="card">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="myprofile-content rounded-3 p-5">
                  <div className="dashboard-quick-widget ">
                    <h4 className="mt-4 fs-14">
                      My Counselling
                      {user?.type === 1 && (
                        <button
                          className="btn btn-primary btn-sm btn-hover float-right"
                          onClick={fetchcheckCounsellingAPI}
                        >
                          <Icon icon="bx:alarm-add" width="25" height="25" />{" "}
                          Request Counselling
                        </button>
                      )}
                    </h4>
                    <br />
                    <AlertComponent
                      AlertDisplay={AlertDisplay}
                      SetAlertDisplay={SetAlertDisplay}
                      AlertMessage={AlertMessage}
                    />

                    <div className="table-responsive my-4">
                      <table className="table table-bordered bg-white">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">
                              {user?.type === 1 ? "Counsellor" : "Student"} Name
                            </th>
                            {user?.type === 2 && (
                              <th>
                                Category
                              </th>
                            )}
                            <th>Report</th>
                            <th scope="col">Date & Time</th>
                            <th scope="col">Platform & Duration</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {CounsellingList.map((x, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td className="text-primary">
                                {user?.type === 1 && x?.counsellor?.name
                                  ? x?.counsellor?.name
                                  : ""}

                                {user?.type === 2 && x?.student?.name
                                  ? x?.student?.name
                                  : ""}
                              </td>

                              {user?.type === 2 && (
                                <td>
                                  {x?.category?.name}
                                </td>
                              )}

                              <td>
                                <button
                                  className="btn btn-sm btn-success"
                                  onClick={(e) => {
                                    download(e, x?.encryptid);
                                  }}
                                >
                                  <Icon
                                    icon="bxs:download"
                                    width="20"
                                    height="20"
                                  />{" "}
                                  Assessment Report
                                </button>

                                {x?.status === 7 && user?.type === 1 && x?.session_bringing_clarity === null ? (
                                  <button
                                    className="btn btn-sm btn-primary mt-1" key={i}
                                    onClick={(e) => {
                                      feedbackModalData(e, x?.encryptid);
                                      SetAlertDisplay(false);
                                      SetAlertMessage([]);
                                    }}
                                  >
                                    <Icon
                                      icon="bxs:download"
                                      width="20"
                                      height="20"
                                    />{" "}
                                    Action Plan
                                  </button>
                                ) : (
                                  x?.status === 7 && user?.type === 1 && x?.session_bringing_clarity != null ? (
                                    <button
                                      className="btn btn-sm btn-primary mt-1"
                                      onClick={(e) => {
                                        actionPlanDownload(e, x?.encryptid);
                                      }}
                                    >
                                      <Icon
                                        icon="bxs:download"
                                        width="20"
                                        height="20"
                                      />{" "}
                                      Action Plan
                                    </button>
                                  ) : (
                                    (user?.type === 1) ? (
                                      <button
                                        className="btn btn-sm btn-primary mt-1" key={i}
                                        disabled
                                      >
                                        <Icon
                                          icon="bxs:download"
                                          width="20"
                                          height="20"
                                        />{" "}
                                        Action Plan
                                      </button>
                                    ) : <></>
                                  )
                                )}

                                {x?.status === 7 && user?.type === 2 ? (
                                  <button
                                    className="btn btn-sm btn-primary mt-1"
                                    onClick={(e) => {
                                      actionPlanDownload(e, x?.encryptid);
                                    }}
                                  >
                                    <Icon
                                      icon="bxs:download"
                                      width="20"
                                      height="20"
                                    />{" "}
                                    Action Plan
                                  </button>
                                ) : ((user?.type === 2) ? (
                                  <button
                                    className="btn btn-sm btn-primary mt-1" key={i}
                                    disabled
                                  >
                                    <Icon
                                      icon="bxs:download"
                                      width="20"
                                      height="20"
                                    />{" "}
                                    Action Plan
                                  </button>
                                ) : (<></>)
                                )}
                              </td>
                              <td>
                                <Moment tz="Asia/Kolkata" format="D MMM YYYY">
                                  {x?.first_prefer_date}
                                </Moment>{" "}
                                &amp;&nbsp;&nbsp;
                                {x?.websitetimeformat}
                              </td>
                              <td>
                                {x?.platform === "1" && (
                                  <span className="badge bg-primary">
                                    MereMentor
                                  </span>
                                )}
                                {x?.platform === "2" && (
                                  <span className="badge bg-primary">
                                    Google Meet
                                  </span>
                                )}
                                {x?.platform === "3" && (
                                  <span className="badge bg-primary">Zoom</span>
                                )}
                                {x?.duration && (
                                  <div>{x?.duration} Mins</div>
                                )}
                              </td>
                              <td>
                                {x?.status === 0 && (
                                  <span className="badge bg-warning">
                                    Pending
                                  </span>
                                )}
                                {x?.status === 1 && (
                                  <span className="badge bg-warning">
                                    Meeting to be scheduled
                                  </span>
                                )}
                                {x?.status === 2 && (
                                  <span className="badge bg-success">
                                    Meeting is scheduled
                                  </span>
                                )}
                                {x?.status === 3 && (
                                  <span className="badge bg-warning">
                                    Meeting is re-scheduled
                                  </span>
                                )}
                                {x?.status === 4 && (
                                  <span className="badge bg-success">
                                    Meeting is completed
                                  </span>
                                )}
                                {x?.status === 5 && (
                                  <span className="badge bg-danger">
                                    Meeting is cancelled
                                  </span>
                                )}
                                {x?.status === 6 && (
                                  <span className="badge bg-primary">
                                    Counsellor Report Submitted
                                  </span>
                                )}
                                {x?.status === 7 && (
                                  <span className="badge bg-success">
                                    Report Sent
                                  </span>
                                )}
                              </td>
                              <td>
                                {(x?.status === 0 ||
                                  x?.status === 1 ||
                                  x?.status === 4 ||
                                  x?.status === 5) && <div></div>}
                                {x?.status === 2 && (
                                  <div>
                                    {user?.type === 1 ? (
                                      <div>
                                        <Link
                                          to={`/meeting/${x?.encryptid}`}
                                          target="_blank"
                                          className="btn btn-sm btn-primary mt-1"
                                        >
                                          <Icon
                                            icon="bx:video"
                                            width="25"
                                            height="25"
                                          />{" "}
                                          Meeting Details
                                        </Link>

                                        <button
                                          className="btn btn-sm btn-danger mt-1"
                                          onClick={(e) => {
                                            cancelMeeeting(e, x?.id);
                                          }}
                                        >
                                          <Icon
                                            icon="ic:outline-cancel"
                                            width="20"
                                            height="20"
                                          />{" "}
                                          Cancel Meeting
                                        </button>
                                      </div>
                                    ) : (
                                      <div>
                                        <Link
                                          to={`/meeting/${x?.encryptid}`}
                                          target="_blank"
                                          className="btn btn-sm btn-primary mt-1"
                                        >
                                          <Icon
                                            icon="bx:video"
                                            width="25"
                                            height="25"
                                          />{" "}
                                          Meeting Details
                                        </Link>

                                        <button
                                          className="btn btn-sm btn-success mt-1"
                                          onClick={(e) => {
                                            SetCompleteMeeting(true);
                                            SetcounsellingEncryptId(
                                              x.encryptid
                                            );
                                          }}
                                        >
                                          <Icon
                                            icon="carbon:task-complete"
                                            width="25"
                                            height="25"
                                          />{" "}
                                          Complete Meeting
                                        </button>

                                        {x?.student_detail?.user_details && (
                                          <button
                                            className="btn btn-sm btn-warning mt-1"
                                            onClick={(e) => {
                                              displayStudentRecord(
                                                e,
                                                x.student_detail
                                              );
                                            }}
                                          >
                                            <Icon
                                              icon="akar-icons:file"
                                              width="20"
                                              height="20"
                                            />
                                            &nbsp; Student Report
                                          </button>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                )}
                                {user.type === 2 ? (
                                  <div>
                                    {x?.status === 4 && (
                                      <Link
                                        to={`/meeting/action-plan/${x.encryptid}`}
                                        className="btn btn-sm btn-success"
                                        target={"_blank"}
                                      >
                                        Submit Action Plan
                                      </Link>
                                    )}
                                    {x?.status === 6 && (
                                      <Link
                                        to={`/edit/action-plan/${x.encryptid}`}
                                        className="btn btn-sm btn-primary"
                                        target={"_blank"}
                                      >
                                        Edit Action Plan
                                      </Link>
                                    )}

                                    {x?.status === 7 && (x?.student) && (x?.student?.get_last_counselling) && (x?.student?.get_last_counselling?.action_plan_details) && (x?.student?.get_last_counselling?.action_plan_details?.request_query_count).length > 0 && (
                                      <>
                                        {x?.student?.get_last_counselling?.action_plan_details?.request_query_count.map((element, j) => (
                                          <>
                                            {element?.status == 2 && (
                                              <>
                                                <button
                                                  className="btn btn-sm btn-warning mt-1"
                                                  onClick={(e) => {
                                                    checkCounsellorReponseQueryData(x?.student?.get_last_counselling?.action_plan_details?.request_query_count)
                                                  }}
                                                >
                                                  <Icon
                                                    icon="majesticons:chats-2"
                                                    width="20"
                                                    height="20"
                                                  />
                                                  &nbsp; Response for Query
                                                </button>
                                              </>
                                            )}
                                          </>
                                        ))}
                                      </>
                                    )}

                                  </div>
                                ) : (
                                  <div>
                                    {x?.status === 7 && (x?.action_plan_details) && (requestQueryCount - (x?.action_plan_details?.request_query_count).length) > 0 && (x?.category) && (x?.category?.subscribed_or_not.length > 0) && (
                                      <button
                                        className="btn btn-sm btn-warning mt-1"
                                        onClick={(e) => {
                                          checkRequestQueryData(x?.action_plan_details?.request_query_count)
                                          SetstudentCounsellingId(x?.encryptid)
                                          SetactionPlanId(x?.action_plan_details?.actionplanencryptid)
                                        }}
                                      >
                                        <Icon
                                          icon="majesticons:chats-2"
                                          width="20"
                                          height="20"
                                        />
                                        &nbsp; Request Query ({requestQueryCount - (x?.action_plan_details?.request_query_count).length})
                                      </button>
                                    )}
                                    <>
                                      {x?.action_plan_details && (
                                        <>
                                          {x?.action_plan_details?.request_query_count.map((value, i) => (
                                            <div key={i}>
                                              {value?.status === 1 && (
                                                <span className="badge bg-fuchsia">
                                                  {`Query - ${i + 1} - ${value?.status_text}`}
                                                </span>
                                              )}
                                              {value?.status === 2 && (
                                                <span className="badge bg-primary">
                                                  {`Query - ${i + 1} - ${value?.status_text}`}
                                                </span>
                                              )}
                                              {value?.status === 3 && (
                                                <span className="badge bg-info">
                                                  {`Query - ${i + 1} - ${value?.status_text}`}
                                                </span>
                                              )}
                                              {value?.status === 4 && (
                                                <span className="badge bg-success" style={{ "cursor": "pointer" }}
                                                  onClick={(e) => {
                                                    viewRequestQueryData(value)
                                                  }}
                                                >
                                                  {`Query - ${i + 1} - `}
                                                  <Icon
                                                    icon="emojione-v1:eye"
                                                    width="20"
                                                    height="20"
                                                  /> View Response
                                                </span>
                                              )}
                                            </div>
                                          ))}
                                        </>
                                      )}
                                    </>

                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={isCancelReason}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="lg"
        onHide={(e) => {
          SetIsCancelReason(false);
          SetMeetingId(null);
          SetAlertMessage([]);
          SetAlertDisplay(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertComponent
            AlertDisplay={AlertDisplay}
            SetAlertDisplay={SetAlertDisplay}
            AlertMessage={AlertMessage}
          />
          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
            init={{
              height: 250,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <p className="text-danger">{ReasonErr}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-sm"
            onClick={(e) => {
              SetIsCancelReason(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={SubmitReason} className="btn-sm">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={displayStudentRequestQueryModal}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="lg"
        onHide={(e) => {
          SetdisplayStudentRequestQueryModal(false);
          SetAlertMessage([]);
          SetAlertDisplay(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Requesting Query</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertComponent
            AlertDisplay={AlertDisplay}
            SetAlertDisplay={SetAlertDisplay}
            AlertMessage={AlertMessage}
          />

          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
            init={{
              placeholder: "Enter Your Query..",
              height: 250,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <p className="text-danger">{ReasonErr}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-sm"
            onClick={(e) => {
              SetdisplayStudentRequestQueryModal(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={SubmitStudentRequestQuery} className="btn-sm">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={counsellorResponseQueryModal}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="lg"
        onHide={(e) => {
          setcounsellorResponseQueryModal(false);
          SetAlertMessage([]);
          SetAlertDisplay(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Response Query</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertComponent
            AlertDisplay={AlertDisplay}
            SetAlertDisplay={SetAlertDisplay}
            AlertMessage={AlertMessage}
          />

          <h3
            dangerouslySetInnerHTML={{
              __html: requestQueryResponseData?.student_query,
            }}
          ></h3>

          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
            init={{
              placeholder: "Enter Your Response..",
              height: 250,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <p className="text-danger">{ReasonErr}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-sm"
            onClick={(e) => {
              setcounsellorResponseQueryModal(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={SubmitCounsellorResponseQuery} className="btn-sm">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={viewRequestQueryResponseModal}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="lg"
        onHide={(e) => {
          SetviewRequestQueryResponseModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Query Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3
            dangerouslySetInnerHTML={{
              __html: requestQueryResponseData?.student_query,
            }}
          ></h3>
          <h6
            dangerouslySetInnerHTML={{
              __html: requestQueryResponseData?.counsellor_response,
            }}
          ></h6>
        </Modal.Body>
      </Modal>

      <Modal
        show={SuccessModal}
        backdrop="static"
        onHide={() => {
          SetSuccessModal(false);
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-bold" closeButton>
          <h4>Message</h4>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center">
            <Icon icon="icons8:checked" color="black" width="50" height="50" />{" "}
            {studentRequestQueryMessageModal ? studentRequestQueryMessage : "Meeting Cancelled Successfully"}
          </h4>
        </Modal.Body>
      </Modal>

      <Modal
        show={Report}
        backdrop="static"
        onHide={() => {
          SetReport(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-bold" closeButton>
          <h4>Submit Report</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <label className="text-dark">Feedback :</label>
              <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
                init={{
                  height: 250,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>
            <div className="col-md-6">
              <label className="text-dark">Area of Improvement :</label>
              <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
                init={{
                  height: 250,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-6">
              <label className="text-dark">Suggestions :</label>
              <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                apiKey="5krh3uchkz9kgqluurm23hsgi2r5eju9zmoefeqchlu0zt5e"
                init={{
                  height: 250,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
            </div>
            <div className="col-md-6">
              <label className="text-dark">Careers :</label>
              <Select options={options} closeMenuOnSelect={false} isMulti />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-sm"
            onClick={(e) => {
              SetReport(false);
            }}
          >
            Close
          </Button>
          <Button variant="primary" className="btn-sm">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showStudentRecord}
        backdrop="static"
        onHide={() => {
          SetshowStudentRecord(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-bold" closeButton>
          <h4>Student Record</h4>
        </Modal.Header>
        <Modal.Body className="m-3">
          <Table striped bordered hover>
            <tbody>
              {Object.keys(Record).map((e, i) => (
                <>
                  {Record[e] != null ? (
                    <tr key={i}>
                      <th>{e.toUpperCase().replaceAll("_", " ")}</th>
                      <>
                        {((e=='work_status') || (e=='nature_of_work')) ? (
                          <td>
                            {e == 'work_status' && (
                              <>
                                {Record[e] == 1 && (
                                  "Currently Working "
                                )}
                                {Record[e] == 2 && (
                                  "In-between jobs "
                                )}
                              </>
                            )}
                            {e == 'nature_of_work' && (
                              <>
                                {Record[e] == 1 && (
                                  "Private sector "
                                )}
                                {Record[e] == 2 && (
                                  "Public Sector "
                                )}
                                {Record[e] == 3 && (
                                  "Self - Employment "
                                )}
                              </>
                            )}
                          </td>
                        ) : (
                          <td>
                            {Record[e]}
                          </td>
                          )
                        }
                      </>
                    </tr>
                  ) : null}
                </>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal
        show={CompleteMeeting}
        onHide={(e) => {
          SetCompleteMeeting(false);
        }}
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Complete Meeting</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to complete the meeting?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              SetCompleteMeeting(false);
            }}
            size="sm"
          >
            No
          </Button>
          <Button variant="primary" size="sm" onClick={confirmMeeting}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={feedbackModalShow}
        onHide={(e) => {
          SetfeedbackModalShow(false);
        }}
        size="xl"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              SetfeedbackModalShow(false);
            }}
          ></button>
          <h3 className="mb-4 text-center">Student Feedback </h3>

          <div className="row">
            <label
              htmlFor="inputstudentname"
              className="col-sm-2 col-form-label text-right"
            >
              Email <span className="text-danger">*</span>
            </label>
            <div className="col-lg-12 col-md-12">
              <div className="form-group mb-3">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  required=""
                  placeholder="Your email address"
                  readOnly={true}
                  value={user?.email}
                />
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <label
                htmlFor="inputstudentname"
                className="col-form-label text-right"
              >
                The session was helpful in bringing clarity to my immediate objective and queries. <span className="text-danger">*</span>
              </label>
              <div className="col-sm-12 mt-1">
                <label
                  className="form-check-label"
                >
                  Strongly Disagree
                </label> &nbsp;&nbsp;&nbsp;&nbsp;
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_bringing_clarity"
                    id="class11"
                    value="1"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class11"
                  >
                    1
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_bringing_clarity"
                    id="class12"
                    value="2"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class12"
                  >
                    2
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_bringing_clarity"
                    id="class13"
                    value="3"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class13"
                  >
                    3
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_bringing_clarity"
                    id="class14"
                    value="4"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class14"
                  >
                    4
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_bringing_clarity"
                    id="class15"
                    value="5"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class15"
                  >
                    5
                  </label>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label
                  className="form-check-label"
                >
                  Strongly Agree
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4">
              <label
                htmlFor="inputstudentname"
                className="col-form-label text-right"
              >
                I received the required Information in detail about the different opportunities and alternatives in my field. <span className="text-danger">*</span>
              </label>
              <div className="col-sm-12 mt-1">
                <label
                  className="form-check-label"
                >
                  Strongly Disagree
                </label> &nbsp;&nbsp;&nbsp;&nbsp;
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="received_required_info"
                    id="class21"
                    value="1"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class21"
                  >
                    1
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="received_required_info"
                    id="class22"
                    value="2"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class22"
                  >
                    2
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="received_required_info"
                    id="class23"
                    value="3"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class23"
                  >
                    3
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="received_required_info"
                    id="class24"
                    value="4"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class24"
                  >
                    4
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="received_required_info"
                    id="class25"
                    value="5"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class25"
                  >
                    5
                  </label>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label
                  className="form-check-label"
                >
                  Strongly Agree
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4">
              <label
                htmlFor="inputstudentname"
                className="col-form-label text-right"
              >
                The session was interactive and the counsellor adhered to all my concern with empathy and compassion. <span className="text-danger">*</span>
              </label>
              <div className="col-sm-12 mt-1">
                <label
                  className="form-check-label"
                >
                  Strongly Disagree
                </label> &nbsp;&nbsp;&nbsp;&nbsp;
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_interactive_the_counsellor"
                    id="class31"
                    value="1"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class31"
                  >
                    1
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_interactive_the_counsellor"
                    id="class32"
                    value="2"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class32"
                  >
                    2
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_interactive_the_counsellor"
                    id="class33"
                    value="3"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class33"
                  >
                    3
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_interactive_the_counsellor"
                    id="class34"
                    value="4"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class34"
                  >
                    4
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="session_interactive_the_counsellor"
                    id="class35"
                    value="5"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class35"
                  >
                    5
                  </label>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label
                  className="form-check-label"
                >
                  Strongly Agree
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4">
              <label
                htmlFor="inputstudentname"
                className="col-form-label text-right"
              >
                The session added valuable insight to my academic/career/ personal growth.<span className="text-danger">*</span>
              </label>
              <div className="col-sm-12 mt-1">
                <label
                  className="form-check-label"
                >
                  Strongly Disagree
                </label> &nbsp;&nbsp;&nbsp;&nbsp;
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="academic_career_personal_growth"
                    id="class41"
                    value="1"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class41"
                  >
                    1
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="academic_career_personal_growth"
                    id="class42"
                    value="2"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class42"
                  >
                    2
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="academic_career_personal_growth"
                    id="class43"
                    value="3"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class43"
                  >
                    3
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="academic_career_personal_growth"
                    id="class44"
                    value="4"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class44"
                  >
                    4
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="academic_career_personal_growth"
                    id="class45"
                    value="5"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class45"
                  >
                    5
                  </label>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label
                  className="form-check-label"
                >
                  Strongly Agree
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mt-4">
              <label
                htmlFor="inputstudentname"
                className="col-form-label text-right"
              >
                I will recommend Mere Mentor career counselling to my friends/colleagues.<span className="text-danger">*</span>
              </label>
              <div className="col-sm-12 mt-1">
                <label
                  className="form-check-label"
                >
                  Strongly Disagree
                </label> &nbsp;&nbsp;&nbsp;&nbsp;
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="i_recommend_my_friends_colleagues"
                    id="class41"
                    value="1"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class41"
                  >
                    1
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="i_recommend_my_friends_colleagues"
                    id="class42"
                    value="2"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class42"
                  >
                    2
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="i_recommend_my_friends_colleagues"
                    id="class43"
                    value="3"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class43"
                  >
                    3
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="i_recommend_my_friends_colleagues"
                    id="class44"
                    value="4"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class44"
                  >
                    4
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input style={{ backgroundColor: "#98cbdd" }}
                    className="form-check-input"
                    type="radio"
                    name="i_recommend_my_friends_colleagues"
                    id="class45"
                    value="5"
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="class45"
                  >
                    5
                  </label>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label
                  className="form-check-label"
                >
                  Strongly Agree
                </label>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <label
                htmlFor="inputstudentname"
                className="col-form-label text-right"
              >
                Any suggestion on improvising our career guidance counselling?
              </label>
              <div className="form-group mb-3">
                <textarea
                  name="suggestion_of_improving_career_guidance"
                  className="form-control"
                  required=""
                  placeholder="Your Suggestion"
                  onChange={handleChange}
                  value={handleChange.suggestion_of_improving_career_guidance}
                ></textarea>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <Button
                className="btn btn-primary rounded-pill btn-send mb-3"
                disabled={submitButtons}
                onClick={(e) => {
                  feedbackFormDetailStore(e, 1)
                }}
              >
                {submitButtons ? (
                  <div>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </div>
                ) : (
                  <div>Submit</div>
                )}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default Counselling;
