import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useState } from "react";
import Ticker from "../layouts/CounterUpVisibility";

function Quickinfo(){
        return(
          <section className="wrapper bg-light fact-counts">
              <div className="container">
                
                <div className="row gx-lg-0 gy-10 align-items-center mb-5 mb-md-16">
                  <div className="col-lg-6 order-2 offset-lg-1 grid">
                    <div className="row gx-md-5 gy-5 align-items-center counter-wrapper isotope fact-counts-main">
                      <div className="item col-md-6 box-1">
                        <div className="card shadow-lg">
                          <div className="card-body">
                            <div className="d-flex d-lg-block d-xl-flex flex-row">
                              <div>
                                <div className="icon btn btn-circle btn-lg btn-soft-purple disabled mx-auto me-4 mb-lg-3 mb-xl-0"> <i className="uil uil-presentation-check"></i> </div>
                              </div>
                              <div>
                                <h3 className="counter" style={{ visibility:"visible" }}>
                                  <Ticker className="counter" end={100} suffix=" +" />
                                </h3>
                                <p style={{ fontSize:"16px" }}>National ,International collaborations</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item col-md-6 box-2">
                        <div className="card shadow-lg">
                          <div className="card-body">
                            <div className="d-flex d-lg-block d-xl-flex flex-row">
                              <div>
                                <div className="icon btn btn-circle btn-lg btn-soft-red disabled mx-auto me-4 mb-lg-3 mb-xl-0"> <i className="uil uil-users-alt"></i> </div>
                              </div>
                              <div>
                                <h3 className="counter" style={{ visibility:"visible" }}>
                                  <Ticker className="counter" end={200} suffix=" +" />
                                </h3>
                                <p style={{ fontSize:"16px" }}>School and College partnerships</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="item col-md-6 box-3">
                        <div className="card shadow-lg">
                          <div className="card-body">
                            <div className="d-flex d-lg-block d-xl-flex flex-row">
                              <div>
                                <div className="icon btn btn-circle btn-lg btn-soft-yellow disabled mx-auto me-4 mb-lg-3 mb-xl-0"> <i className="uil uil-user-check"></i> </div>
                              </div>
                              <div>
                                <h3 className="counter" style={{ visibility:"visible" }}>
                                  <Ticker className="counter" end={200} suffix=" +" />
                                </h3>
                                <p style={{ fontSize:"16px" }}>Expert <br />counsellors</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <h3 className="display-4 mb-3 pe-xl-10 text-center-sm">
                      We are trusted by over <br/> 1000000+ students
                    </h3>
                    <p className="lead fs-lg mb-0 pe-xxl-10 text-center-sm">
                    Come on! Join along with us and enhance your career. Get to know your strengths &weaknesses and match them with your skills and interests to find your ideal career path. Shape your career scale with our best team.
                    </p>
                  </div>
                </div>
                
                
                
                
                 {/* <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-11">
                   <div className="col-lg-5">
                     <h3 className="display-4 mb-3 pe-xl-10">
                       We are trusted by over <br/> 1000000+ students
                     </h3>
                     <p className="lead fs-lg mb-0 pe-xxl-10">
                       Come on! Join along with them and enhance your career
                     </p>
                   </div>
                   <div className="col-lg-7 mt-lg-2">
                     <div className="row align-items-center counter-wrapper gy-6 text-center">
                       <div className="col-md-4">
                         <img
                           className="svg-inject icon-svg icon-svg-md text-primary mb-3"
                           src="./assets/img/icons/lineal/check.svg"
                           alt=""
                         />
                         <h3 className="counter"><CountUp end={100} duration={4} />+</h3>
                         <p>National ,International collaborations</p>
                       </div>
                       <div className="col-md-4">
                         <img
                           className="svg-inject icon-svg icon-svg-md text-primary mb-3"
                           src="./assets/img/icons/lineal/user.svg"
                           alt=""
                         />
                         <h3 className="counter"><CountUp end={200} duration={4} />+</h3>
                         <p>School and College partnerships</p>
                       </div>
                       <div className="col-md-4">
                         <img
                           className="svg-inject icon-svg icon-svg-md text-primary mb-3"
                           src="./assets/img/icons/lineal/briefcase-2.svg"
                           alt=""
                         />
                         <h3 className="counter"><CountUp end={200} duration={4} />+</h3>
                         <p>Expert <br />counsellors</p>
                       </div>
                     </div>
                   </div>
                 </div> */}
               </div>
           </section>
        )
}


export default Quickinfo;