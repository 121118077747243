import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import BannerImage from "../assets/img/about-us.png";
import BVideo from "../assets/video/about-us-video/about-us-video.mp4";
import { ReactComponent as Target } from "../assets/img/icons/lineal/target.svg";
import { ReactComponent as MegaPhone } from "../assets/img/icons/lineal/megaphone.svg";
import { ReactComponent as Medal } from "../assets/img/icons/lineal/medal.svg";
import { ReactComponent as Clock } from "../assets/img/icons/lineal/clock-3.svg";
import { ReactComponent as Check } from "../assets/img/icons/lineal/check.svg";
import { ReactComponent as Shop } from "../assets/img/icons/lineal/shop-2.svg";
import { ReactComponent as Team } from "../assets/img/icons/lineal/team.svg";
import { ReactComponent as CC } from "../assets/img/icons/lineal/cloud-computing.svg";
import { ReactComponent as Mobile } from "../assets/img/icons/lineal/smartphone-2.svg";
import { ReactComponent as Rocket } from "../assets/img/icons/lineal/rocket.svg";
import { ReactComponent as Loss } from "../assets/img/icons/lineal/loss.svg";
import { useEffect, useState } from "react";
import BackToTop from "../layouts/BackToTop";
import GLightbox from "glightbox";
import whyusimagegif from "../assets/img/gif/about-us.gif";
import Seo from "../layouts/SEO";
import AnimatedText from 'react-animated-text-content';
import BreadCumb from "../layouts/BreadCumb";

function AboutUs() {
  const [showScroll, setShowScroll] = useState(false);
  const setting = JSON.parse(localStorage.getItem("setting"));
  let MetaDetails = {
    'title': 'About Us | ' + process.env.REACT_APP_NAME,
    'meta_title': 'About Us | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'About Us Mere Mentor',
    'meta_description': 'Mere Mentor is an EdTech organization delivering career counselling services to the students and youth of the country.',
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  const careerVideo = () => {
    const myGallery = GLightbox({
      elements: [
        {
          href: "https://www.youtube.com/watch?v=LnLcQyrFG0c&",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
      ],
      autoplayVideos: true,
    });
    myGallery.open();
  };

  // const list = [
  //   { name: "Home", url: "/" },
  //   { name: "Assessment", url: null },
  // ];

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      {/* <section className="wrapper bg-gray ">
        <div className="container pt-10 pt-md-14 text-center">
          <div className="row">
            <div className="col-xl-6 mx-auto">
              <h1 className="display-1 mb-4">
                <AnimatedText
                  type='chars'
                  interval={0}
                  duration={1}
                  animation={{
                    ease: 'ease-out',
                    scale: 2,
                  }}
                >
                  About Us
                </AnimatedText>
              </h1>
              <p className="lead fs-lg mb-0">
                <AnimatedText
                  type='words'
                  interval={0}
                  duration={1}
                  animation={{
                    y: '50px',
                    ease: 'ease-out',
                  }}
                >
                  No.1 Tech based Career Counselling Platform
                </AnimatedText>
              </p>
            </div>
          </div>
        </div>
        <figure
          className="position-absoute"
          style={{ bottom: "0", left: "0", zIndex: "2" }}
        >
          <img src={BannerImage} alt="About Us" />
        </figure>
      </section> */}
      <BreadCumb name={<div className="col-xl-6 mx-auto">
        <h1 className="display-1 mb-4">
          <AnimatedText
            type='words'
            interval={0}
            duration={1}
            animation={{
              ease: 'ease-out',
              scale: 2,
            }}
          >
            About Us
          </AnimatedText>
        </h1>
        <p className="lead fs-lg mb-0">
          <AnimatedText
            type='words'
            interval={0}
            duration={1}
            animation={{
              y: '50px',
              ease: 'ease-out',
            }}
          >
            No.1 Tech based Career Counselling Platform
          </AnimatedText>
        </p>
      </div>} list="About Us" />
      <section className="wrapper bg-soft-primary">
        <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
          <div
            className="shape bg-dot primary rellax w-16 h-18"
            data-rellax-speed="1"
            style={{ top: "-4rem", right: "-2.4rem" }}
          ></div>
          <div
            className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
            data-rellax-speed="1"
            style={{ bottom: "0.5rem", left: "-2.5rem" }}
          ></div>
        </div>
        <figure
          className="position-absoute"
          style={{ bottom: "0", left: "0", zIndex: "2" }}
        >
          <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={BannerImage} alt="About Us" />
        </figure>
      </section>
      <section className="wrapper bg-white">
        <div className="container pt-8 py-md-16 pb-8">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-5 align-items-center">
            <div className="col-lg-5 position-relative order-2">
              <div className="overlap-grid">
                <div className="item position-relative">
                  {/* <button
                className="btn btn-circle btn-primary btn-play ripple mx-auto mb-5 position-absolute"
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "3",
                }}
                onClick={careerVideo}
              >
                <i className="icn-caret-right"></i>
              </button> */}
                  <figure className="rounded">
                    <img src={whyusimagegif} alt="" />
                  </figure>
                </div>
              </div>
            </div>

            <div className="col-lg-7 ">

              <h2 className="display-4 mb-3 text-center-sm"><MegaPhone className="svg-inject icon-svg icon-svg-md mb-4" />&nbsp;&nbsp;Who we are</h2>
              <div className="aboutus-desctiption">
                <p className="lead fs-lg text-sm-small">
                  Mere Mentor is an EdTech organization delivering career
                  counselling services to the students and youth of the country.
                  We deliver our services to individuals as well as educational
                  systems and institutions to explore the factors that would help
                  them to align their career path in the future, based on their
                  current quests in life.
                </p>
              </div>
              <p className="mb-6">
                Our products are shaped for, and cater to the following groups:
              </p>
              <div className="row gy-3 gx-xl-8">
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Grade 8 th - 9 th</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Grade 10th - 12th</span>
                    </li>
                  </ul>
                </div>

                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>College Graduates</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Working professionals</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container wrapper bg-light">
        <div className="row text-center">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <h3 className="display-4 mb-9 px-xxl-11">What we offer</h3>
          </div>
        </div>

        <div
          className="row gx-lg-8 gx-xl-12 gy-8 mb-7 mb-md-9"
          data-cues=""
          data-group="services"
        >
          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <Target className="svg-inject icon-svg icon-svg-sm text-aqua me-4" />
              </div>
              <div>
                <h4 className="mb-1">Career Assessment</h4>
                <p className="mb-0">
                  Our psychometric assessments explore multiple components that
                  are vital aspects in shaping one’s career pathway.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <Medal className="svg-inject icon-svg icon-svg-sm text-aqua me-4" />
              </div>
              <div>
                <h4 className="mb-1">Career Report</h4>
                <p className="mb-0">
                  A dive into the assessment to understand the various
                  components and how they shape career decisions
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <Clock className="svg-inject icon-svg icon-svg-sm text-aqua me-4" />
              </div>
              <div>
                <h4 className="mb-1">Individual career counselling</h4>
                <p className="mb-0">
                  Counselling session with a career counsellor to elucidate the
                  career report, explore feasibility, and align pathways.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <Check className="svg-inject icon-svg icon-svg-sm text-aqua me-4" />
              </div>
              <div>
                <h4 className="mb-1">Action Plan</h4>
                <p className="mb-0">
                  Individualized plan based on counselling session, charting a
                  streamlined course towards specific goals
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <Shop className="svg-inject icon-svg icon-svg-sm text-aqua me-4" />
              </div>
              <div>
                <h4 className="mb-1">Career Library</h4>
                <p className="mb-0">
                  Vast number of career areas are explained in detail, a vital
                  tool in the time of career exploration and search
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="d-flex flex-row">
              <div>
                <Team className="svg-inject icon-svg icon-svg-sm text-aqua me-4" />
              </div>
              <div>
                <h4 className="mb-1">Expert Connect</h4>
                <p className="mb-0">
                  Session with an expert from a career field, to inspire, sort
                  confusion, and understand the world of work.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row gx-lg-8 gx-xl-10 mt-15 mb-12 align-items-center">
          <div className="col-lg-6">
            <h3 className="display-4 mb-0 text-center-sm visible-sm">Our USP</h3>

            <video width="100%" height="500" controls={false} loop autoPlay={true} muted>
              <source src={BVideo} type="video/mp4" />
            </video>

            {/* <figure>
            <img src={uspimage} alt="" />
            </figure> */}
          </div>

          <div className="col-lg-6">
            <h3 className="display-4 mb-5 text-center-sm visible-md">Our USP</h3>
            <p className="mb-8">
              Mere Mentor is here to help you through the process of determining
              the right pick of stream, course, or college as a pathway to the
              career that would be your best fit. We achieve this through the
              flow of career assessment, career reports, one to one counselling
              and personalized action plan. Our career assessments are built for
              each category, deliberated based on the needs of the age group.
              Our distinctive products and services have the following features:
            </p>
            <div
              className="row gy-6 gx-xxl-8 process-wrapper"
              data-cues=""
              data-group="process"
            >
              <div className="col-md-6">
                {" "}
                <CC className="svg-inject icon-svg icon-svg-sm text-aqua mb-3" />
                <h4 className="mb-1">Digital Career Library</h4>
                <p className="mb-0">
                  Extensive career library with details about a plethora of
                  career paths and how to get there
                </p>
              </div>

              <div className="col-md-6">
                {" "}
                <Mobile className="svg-inject icon-svg icon-svg-sm text-aqua mb-3" />
                <h4 className="mb-1">End to End support</h4>
                <p className="mb-0">
                  Customer support right from the first query to their last
                  quest
                </p>
              </div>

              <div className="col-md-6">
                {" "}
                <Rocket className="svg-inject icon-svg icon-svg-sm text-aqua mb-3" />
                <h4 className="mb-1">Psychometric assessment</h4>
                <p className="mb-0">
                  Our assessment tool is built keeping in mind the unique
                  requirements of the age groups
                </p>
              </div>
              <div className="col-md-6">
                {" "}
                <Loss className="svg-inject icon-svg icon-svg-sm text-aqua mb-3" />
                <h4 className="mb-1">Exam stress Buster</h4>
                <p className="mb-0">
                  The one form of hassle even students carry, and we are here to
                  help you tackle that
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="wrapper bg-soft-primary">
        <div className="container py-14 pt-md-16 pt-lg-0 pb-md-16">
          <div className="row">
            <div className="col-xl-10 mx-auto">
              <div
                className="mt-lg-n20 mt-xl-n22 mb-14 mb-md-16 position-relative"
                data-cue=""
              >
                <a
                  href="./assets/media/movie.mp4"
                  className="btn btn-circle btn-primary btn-play ripple mx-auto mb-5 position-absolute"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    zIndex: "3",
                  }}
                  data-glightbox
                >
                  <i className="icn-caret-right"></i>
                </a>
                <figure className="rounded shadow">
                  <img src="./assets/img/photos/v1.jpg" alt="" />
                </figure>
              </div>

              <h3 className="display-4 mb-10 px-lg-12 px-xl-15 text-center">
                Frequently Asked Questions
              </h3>
              <div className="row" data-cue="">
                <div className="col-lg-6 mb-0">
                  <div id="accordion-1" className="accordion-wrapper">
                    <div className="card accordion-item">
                      <div className="card-header" id="accordion-heading-1-1">
                        <button
                          className="collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#accordion-collapse-1-1"
                          aria-expanded="false"
                          aria-controls="accordion-collapse-1-1"
                        >
                          Can I cancel my subscription?
                        </button>
                      </div>

                      <div
                        id="accordion-collapse-1-1"
                        className="collapse"
                        aria-labelledby="accordion-heading-1-1"
                        data-bs-target="#accordion-1"
                      >
                        <div className="card-body">
                          <p>
                            Fusce dapibus, tellus ac cursus commodo, tortor
                            mauris condimentum nibh, ut fermentum massa justo
                            sit amet risus. Cras mattis consectetur purus sit
                            amet fermentum. Praesent commodo cursus magna, vel
                            scelerisque nisl consectetur et. Cum sociis natoque
                            penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec sed odio dui. Cras justo odio,
                            dapibus ac facilisis.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card accordion-item">
                      <div className="card-header" id="accordion-heading-1-2">
                        <button
                          className="collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#accordion-collapse-1-2"
                          aria-expanded="false"
                          aria-controls="accordion-collapse-1-2"
                        >
                          Which payment methods do you accept?
                        </button>
                      </div>

                      <div
                        id="accordion-collapse-1-2"
                        className="collapse"
                        aria-labelledby="accordion-heading-1-2"
                        data-bs-target="#accordion-1"
                      >
                        <div className="card-body">
                          <p>
                            Fusce dapibus, tellus ac cursus commodo, tortor
                            mauris condimentum nibh, ut fermentum massa justo
                            sit amet risus. Cras mattis consectetur purus sit
                            amet fermentum. Praesent commodo cursus magna, vel
                            scelerisque nisl consectetur et. Cum sociis natoque
                            penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec sed odio dui. Cras justo odio,
                            dapibus ac facilisis.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card accordion-item">
                      <div className="card-header" id="accordion-heading-1-3">
                        <button
                          className="collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#accordion-collapse-1-3"
                          aria-expanded="false"
                          aria-controls="accordion-collapse-1-3"
                        >
                          How can I manage my Account?
                        </button>
                      </div>

                      <div
                        id="accordion-collapse-1-3"
                        className="collapse"
                        aria-labelledby="accordion-heading-1-3"
                        data-bs-target="#accordion-1"
                      >
                        <div className="card-body">
                          <p>
                            Fusce dapibus, tellus ac cursus commodo, tortor
                            mauris condimentum nibh, ut fermentum massa justo
                            sit amet risus. Cras mattis consectetur purus sit
                            amet fermentum. Praesent commodo cursus magna, vel
                            scelerisque nisl consectetur et. Cum sociis natoque
                            penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec sed odio dui. Cras justo odio,
                            dapibus ac facilisis.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div id="accordion-2" className="accordion-wrapper">
                    <div className="card accordion-item">
                      <div className="card-header" id="accordion-heading-2-1">
                        <button
                          className="collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#accordion-collapse-2-1"
                          aria-expanded="false"
                          aria-controls="accordion-collapse-2-1"
                        >
                          How do I get my subscription receipt?
                        </button>
                      </div>

                      <div
                        id="accordion-collapse-2-1"
                        className="collapse"
                        aria-labelledby="accordion-heading-2-1"
                        data-bs-target="#accordion-2"
                      >
                        <div className="card-body">
                          <p>
                            Fusce dapibus, tellus ac cursus commodo, tortor
                            mauris condimentum nibh, ut fermentum massa justo
                            sit amet risus. Cras mattis consectetur purus sit
                            amet fermentum. Praesent commodo cursus magna, vel
                            scelerisque nisl consectetur et. Cum sociis natoque
                            penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec sed odio dui. Cras justo odio,
                            dapibus ac facilisis.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card accordion-item">
                      <div className="card-header" id="accordion-heading-2-2">
                        <button
                          className="collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#accordion-collapse-2-2"
                          aria-expanded="false"
                          aria-controls="accordion-collapse-2-2"
                        >
                          Are there any discounts for people in need?
                        </button>
                      </div>

                      <div
                        id="accordion-collapse-2-2"
                        className="collapse"
                        aria-labelledby="accordion-heading-2-2"
                        data-bs-target="#accordion-2"
                      >
                        <div className="card-body">
                          <p>
                            Fusce dapibus, tellus ac cursus commodo, tortor
                            mauris condimentum nibh, ut fermentum massa justo
                            sit amet risus. Cras mattis consectetur purus sit
                            amet fermentum. Praesent commodo cursus magna, vel
                            scelerisque nisl consectetur et. Cum sociis natoque
                            penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec sed odio dui. Cras justo odio,
                            dapibus ac facilisis.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card accordion-item">
                      <div className="card-header" id="accordion-heading-2-3">
                        <button
                          className="collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#accordion-collapse-2-3"
                          aria-expanded="false"
                          aria-controls="accordion-collapse-2-3"
                        >
                          Do you offer a free trial edit?
                        </button>
                      </div>
                      <div
                        id="accordion-collapse-2-3"
                        className="collapse"
                        aria-labelledby="accordion-heading-2-3"
                        data-bs-target="#accordion-2"
                      >
                        <div className="card-body">
                          <p>
                            Fusce dapibus, tellus ac cursus commodo, tortor
                            mauris condimentum nibh, ut fermentum massa justo
                            sit amet risus. Cras mattis consectetur purus sit
                            amet fermentum. Praesent commodo cursus magna, vel
                            scelerisque nisl consectetur et. Cum sociis natoque
                            penatibus et magnis dis parturient montes, nascetur
                            ridiculus mus. Donec sed odio dui. Cras justo odio,
                            dapibus ac facilisis.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default AboutUs;
