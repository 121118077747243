import Footer from "../layouts/Footer";
import { withRouter } from 'react-router-dom';
import "moment-timezone";
import BackToTop from "../layouts/BackToTop";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../assets/img/logo-cms.png';
import logo1 from '../assets/img/logo.png';
import * as Icon from "react-feather";
import { useState, useEffect, useCallback } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import validator from "validator";
import {
  RegisterAPI,
  LoginAPI,
  GenerateOTPAPI,
  VerifyOTPAPI,
  UpdatePasswordAPI,
  GoogleLoginAPI,
  ForgetPasswordAPI,
  FacebookLoginAPI,
} from "../Service";
import AlertComponent from "../layouts/Alert";

import Cookies from "js-cookie";

function Inspiria() {

  let setting = JSON.parse(localStorage.getItem("setting"));
  let [searchParams, setSearchParams] = useSearchParams();
  let is_login = searchParams.get("is_login");
  let is_message = searchParams.get("is_message");
  let check_login = is_login ? true : false;

  const [login, SetLogin] = useState(check_login);
  const [register, SetRegister] = useState(false);
  const [otpLogin, SetOTPLogin] = useState(false);
  const [name, SetName] = useState("");
  const [isBroker, setIsBroker] = useState(false);
  const [isDecrypt, setIsDecrypt] = useState('');
  const [email, SetEmail] = useState("");
  const [mobile, SetMobile] = useState("");
  const [password, SetPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const navigate = useNavigate();
  const [MessageDisplay, SetMessageDisplay] = useState(false);
  const [MessageContent, SetMessageContent] = useState([]);
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [otpMobile, SetOTPMobile] = useState(true);
  const [otp, SetOTP] = useState("");
  const [resetPassword, SetResetPassword] = useState(false);
  const [confirmPassword, SetConfirmPassword] = useState("");
  const [forgetPassword, SetForgetPassword] = useState(false);
  const [isOneTapLoginEnabled, SetisOneTapLoginEnabled] = useState(false);

  const LoginUsingEmail = async () => {
    let data = {
      email: email,
      password: password,
      phone: email,
    };
    var err = [];
    if (email !== "") {
      if (validator.isNumeric(email)) {
        if (!validator.isLength(email, { min: 10, max: 10 })) {
          err.push("Enter a Valid Mobile Number");
        }
      } else if (!validator.isEmail(email)) {
        err.push("Enter a Valid Email");
      }
    } else {
      err.push("Phone Number (or) Email is required");
    }

    if (!validator.isLength(password, { min: 8, max: 255 })) {
      err.push("Enter a Valid Password");
    }

    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      if (email == "") {
        delete data.email;
      }
      if (mobile == "") {
        delete data.phone;
      }
      let res = await LoginAPI(data);
      if (res?.is_success === true) {
        var usr_session = new Date(new Date().getTime() + 1440 * 60 * 1000);
        Cookies.set("usr_token", res?.usr_token, { expires: usr_session });
        Cookies.set("user", JSON.stringify(res?.user), {
          expires: usr_session,
        });
        SetisOneTapLoginEnabled(false);
        SetLogin(false);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        navigate("/assessments/10th-12th");

      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
      }
    }
  };

  return (
    <div>
      <Navbar bg="light" expand="lg" style={{ boxShadow: "1px 3px 1px #9E9E9E" }}>
        <Container>
          <Navbar.Brand href="#home">
            <div style={{ display: 'flex', padding: '10px' }}>
              <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={logo} alt="Logo" />
              <img src={logo1}  className="img-fluid" alt="Logo" style={{ width: '150px', height: '40px', marginTop: '40px', marginLeft: '15px' }} />
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>



      <div className="text-start mb-3 w-50" style={{ margin: 'auto', marginTop: '100px', border: 'solid 1px grey', padding: '10px', borderRadius: '10px' }}>
        <AlertComponent
          AlertDisplay={AlertDisplay}
          SetAlertDisplay={SetAlertDisplay}
          AlertMessage={AlertMessage}
        />
        <div className="form-floating mb-4">
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <Icon.Mail />
            </span>
            <input
              type="email"
              className="form-control"
              placeholder="Email/Phone"
              onChange={(e) => {
                SetEmail(e.target.value);
              }}
              id="loginEmail"
              required
            />
          </div>
        </div>
        <div className="form-floating mb-4">
          <div className="input-group mb-3">
            <input
              type={isRevealPwd ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              onChange={(e) => {
                SetPassword(e.target.value);
              }}
              id="loginPassword"
            />
            <span
              className="input-group-text"
              onClick={() => {
                setIsRevealPwd((prevState) => !prevState);
              }}
            >
              {isRevealPwd ? <Icon.EyeOff /> : <Icon.Eye />}
            </span>
          </div>
        </div>
        <button
          className="btn btn-primary rounded-pill btn-login w-100 mb-2"
          onClick={(e) => {
            LoginUsingEmail(e);
          }}
        >
          Sign In
        </button>
      </div>
      {/* <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} /> */}
    </div>
  );
}

export default Inspiria;
