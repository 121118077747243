import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from "react-router-dom";
import BreadCumb from "../layouts/BreadCumb";
import {
  verifyGOVAssessmentCodeAPI,
  GetBrokerAssessmentsAPI,
  checkBrokerUserExists,
  LoginAPI,
  updateBrokerUser,
} from "../Service";
import { Icon } from "@iconify/react";
import { Modal, Button, Alert } from "react-bootstrap";
import validator from "validator";
import AlertComponent from "../layouts/Alert";
import Cookies from "js-cookie";
import BackToTop from "../layouts/BackToTop";
import ModalAlert from "../layouts/ModalAlert";
import Seo from "../layouts/SEO";

export default function Assessment() {
  let { broker_id } = useParams();
  const setting = JSON.parse(localStorage.getItem("setting"));
  const [showScroll, setShowScroll] = useState(false);
  const [test, SetTest] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let is_messages = searchParams.get("is_messages");
  let button = searchParams.get("button");
  let slug = searchParams.get("slug");
  const [Type, SetType] = useState(0);
  const handleShow = (e, type) => {
    if (USER_TOKEN === undefined) {
      navigate("/ga-assessments/csr?is_login=true&is_message=Please login to take assessment");
    }
    setShow(true);
    SetType(type);
  };
  let USER_TOKEN = Cookies.get("usr_token");
  let user = Cookies.get("user");
  const [Code, SetCode] = useState("");

  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [AlertButton, SetAlertButton] = useState(null);

  const [ModalAlertDisplay, SetModalModalAlertDisplay] = useState(false);
  const [ModalAlertMessage, SetModalAlertMessage] = useState([]);
  const [ModalAlertButton, SetModalAlertButton] = useState("");
  const [ModalAlertSlug, SetModalAlertSlug] = useState("");

  const [showUpdateProfile, SetShowUpdateProfile] = useState(false)
  let MetaDetails = {
    'title': 'Assessments | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Assessments | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Mere Mentor assessments',
    'meta_description': 'Government Full Assessment, Full Report, Career library, One to one Counselling session, Action Plan, Mere Mentor live sessions.',
  }

  let navigate = useNavigate();
  let location = useLocation();
  const list = [
    { name: "Home", url: "/" },
    { name: "Assessments", url: null },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // let decrypted = CryptoJS.AES.decrypt(`${location.pathname.split('/')[4]}`, "Secret").toString(CryptoJS.enc.Utf8);

    let decrypted = CryptoJS.AES.decrypt(`${location.search.split('=')[1]}`.replace(/%20/g, '+'),"RISK_MENTOR_DATA").toString(CryptoJS.enc.Utf8);
    console.log(location.search.split('=')[1].replace(/%20/g, ''));
    console.log('decryptedVal', decrypted);

    localStorage.setItem('broker', location.pathname.split('/')[3]);
    localStorage.setItem('brokerdecryptrd',`${location.search.split('=')[1]}`.replace(/%20/g, '+'));

    fetchAssessment(decrypted);
    if (is_messages) {
      SetModalModalAlertDisplay(true);
      SetModalAlertMessage([is_messages]);
      SetModalAlertButton(button);
      SetModalAlertSlug(slug)
    }

    console.log(location);
  }, []);

  useEffect(() => {

    let encrypted = CryptoJS.AES.encrypt(`${location.pathname.split('/')[4]}`, "RISK_MENTOR_DATA").toString();
    let decrypted = CryptoJS.AES.decrypt(encrypted, "RISK_MENTOR_DATA");
    console.log('values', encrypted, decrypted.toString(CryptoJS.enc.Utf8));
  })

  const fetchAssessment = async (data) => {
    try {
      if (USER_TOKEN === undefined || USER_TOKEN === null) {

        let res = await checkBrokerUserExists(data);
        if (res.is_success === true && res.message[0] === "User Found" && res.data.status === 1) {
          let loginData = {
            email: res.data.email,
            password: res.password,
            phone: res.data.email,
          }
          let resData = await LoginAPI(loginData);
          if (resData.is_success === true) {
            let usr_session = new Date(new Date().getTime() + 1440 * 60 * 1000);
            Cookies.set("usr_token", resData.usr_token, { expires: usr_session });
            Cookies.set("user", JSON.stringify(resData.user), {
              expires: usr_session,
            })
            let brokerData = {
              'id': resData.user.id,
              'status': 0
            }
            await updateBrokerUser(brokerData);
          }
          else {
            SetAlertDisplay(true);
            SetAlertMessage(['Token Not Verified'])
          }
        }
      }
      let resBroker = await GetBrokerAssessmentsAPI(USER_TOKEN, broker_id);
      if (resBroker?.is_success == true) {
        SetTest(resBroker?.assesments);

      } else {
        SetAlertDisplay(true);
        SetAlertMessage(resBroker?.errors);
      }
    } catch {
      throw new Error(`Something failed`);
    }
  };

  const GovAssessments = [
    {
      name: "CSR",
      type: 1,
      icon: "ph:buildings-duotone",
      color: '#98cbdd'
    },
    {
      name: "Government",
      type: 2,
      icon: "ph:bank-duotone",
      color: '#98cbdd'
    },
  ];

  const getGOVAssessment = async () => {
    let err = [];
    if (validator.isEmpty(Code)) {
      err.push("Enter Assessment Code");
    }
    if (USER_TOKEN === undefined) {
      err.push("Please Login to take Assessment");
    }

    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      SetAlertDisplay(false);
      SetAlertMessage([]);
      let data = {
        code: Code,
        type: Type,
      };
      let res = await verifyGOVAssessmentCodeAPI(data);
      if (res?.is_success === true) {
        navigate(`/ga-assessments/${res?.assessment?.slug}`);
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors);
      }
    }
  };

  const updateProfile = () => {
    SetShowUpdateProfile(true)
  }

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      <BreadCumb name={"Assessments"} list={list} />
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0">
              {AlertButton && AlertButton === "product" ? (
                <Alert
                  className="bg-orange text-white"
                  show={AlertDisplay}
                  onClose={() => SetAlertDisplay(false)}
                  dismissible
                >
                  <ul>
                    {AlertMessage.length && AlertMessage.map(function (x, i) {
                      return (
                        <li key={i} className="text-white">
                          {x}
                        </li>
                      );
                    })}
                  </ul>
                  <Link to="/products" className="btn btn-sm btn-secondary m-3">
                    View Products
                  </Link>
                </Alert>
              ) : (
                <AlertComponent
                  AlertDisplay={AlertDisplay}
                  SetAlertDisplay={SetAlertDisplay}
                  AlertMessage={AlertMessage || []}
                />
              )}
              {test.map((x, i) => (
                <div className="col-md-6 col-lg-3 mt-4" key={i}>
                  <div className="position-relative">
                    <div
                      className="shape rounded bg-soft-blue rellax d-md-block"
                      data-rellax-speed="0"
                      style={{
                        bottom: "-0.75rem",
                        right: "-0.75rem",
                        width: "98%",
                        height: "98%",
                        zIndex: "0",
                      }}
                    ></div>
                    <div className="card text-center">
                      <figure className="card-img-top text-center mt-5">
                        {x.is_assessment_completed === 2 && (
                          <Link to={`/my-report`}>
                            <Icon
                              icon={x?.code}
                              color={x?.color}
                              width="100"
                              height="100"
                            />
                          </Link>
                        )}

                        {(x.is_assessment_completed === 3 ||
                          x.is_assessment_completed === 1 ||
                          x.is_assessment_completed === 4) && (
                            <Link to={`/assessments/${x.slug}`}>
                              <Icon
                                icon={x?.code}
                                color={x?.color}
                                width="100"
                                height="100"
                              />
                            </Link>
                          )}
                      </figure>
                      <div className="card-body px-6 py-5">
                        {(user?.state_id !== null) ? (
                          <div>
                            {/* View Report */}
                            {x.is_assessment_completed === 2 && (
                              <div>
                                <Link to="/my-report">
                                  <h4 className="mb-1 text-center">{x.name}</h4>
                                </Link>
                                <Link
                                  to="/my-report"
                                  className="btn btn-sm btn-primary"
                                >
                                  View Report{" "}
                                  <Icon
                                    icon="carbon:view"
                                    width="25"
                                    height="25"
                                  />
                                </Link>
                              </div>
                            )}

                            {/* Complete Full Assessment */}
                            {(x.is_assessment_completed === 3 ||
                              x.is_assessment_completed === 1) && (
                                <div>
                                  <Link to={`/assessments/${x.slug}?${x.id}`}>
                                    <h4 className="mb-1 text-center">{x.name}</h4>
                                  </Link>
                                  <Link
                                    to={`/assessments/${x.slug}/?${x.id}`}
                                    className="btn btn-sm btn-primary"
                                  >
                                    {x.is_assessment_completed === 1 && (
                                      <button className="btn btn-sm btn-primary">
                                        Start{" "}
                                        <Icon
                                          icon="ant-design:play-circle-twotone"
                                          width="25"
                                          height="25"
                                        />
                                      </button>
                                    )}

                                    {x.is_assessment_completed === 3 && (
                                      <div>
                                        Resume{" "}
                                        <Icon
                                          icon="ant-design:play-circle-twotone"
                                          width="25"
                                          height="25"
                                        />
                                      </div>
                                    )}
                                  </Link>
                                </div>
                              )}

                            {x.is_assessment_completed === 4 && (
                              <div>
                                <Link to={`/assessments/${x.slug}?${x.id}`}>
                                  <h4 className="mb-1 text-center">{x.name}</h4>
                                </Link>
                                <Link
                                  to={`/assessments/${x.slug}?${x.id}`}
                                  className="btn btn-sm btn-primary"
                                >
                                  Resume{" "}
                                  <Icon
                                    icon="ant-design:pause-circle-twotone"
                                    width="25"
                                    height="25"
                                  />
                                </Link>
                              </div>
                            )}
                          </div>
                        ) : (
                          <button className="btn btn-sm btn-primary" onClick={updateProfile}>
                            Start{" "}
                            <Icon
                              icon="ant-design:play-circle-twotone"
                              width="25"
                              height="25"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <br />
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          <h3 className="mb-4 text-center">Enter Assessment Code</h3>
          <AlertComponent
            AlertDisplay={AlertDisplay}
            SetAlertDisplay={SetAlertDisplay}
            AlertMessage={AlertMessage}
          />
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Code"
                onChange={(e) => SetCode(e.target.value)}
              />
            </div>
            <div className="col-md-3"></div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <button className="btn btn-primary" onClick={getGOVAssessment}>
                Proceed
              </button>
            </div>
            <div className="col-md-4"></div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showUpdateProfile}
        onHide={(e) => { SetShowUpdateProfile(false) }}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="text-center">
          <p><Icon
            icon="eva:close-circle-outline"
            color="black"
            width="25"
            height="25"
          />&nbsp;Update Profile to take Assessment</p>
          <Link to="/profile" className="btn btn-sm btn-primary">Update Profile</Link>
        </Modal.Body>
      </Modal>

      {/* <Footer /> */}
      <ModalAlert
        ModalAlertDisplay={ModalAlertDisplay}
        SetModalModalAlertDisplay={SetModalModalAlertDisplay}
        ModalAlertMessage={ModalAlertMessage}
        ModalAlertButton={ModalAlertButton}
        ModalAlertSlug={ModalAlertSlug}
      />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}
