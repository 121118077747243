import { Link } from "react-router-dom";

function BreadCumb({ name, list,image }) {

  return (
    <div>
      <section className={ image? "wrapper image-wrapper bg-soft-primary" : "wrapper bg-soft-primary"} >
        <div className="container pt-7 pb-15 mb-8 text-center paddingMob">
          <div className="row">
            <h1 className={ image? "display-1 mb-4 " : "display-1"}>{name}</h1>
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb">
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BreadCumb;
