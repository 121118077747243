import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import validator from "validator";
import { OnlineRegApi } from "../Service";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../layouts/Alert";
import { onlineRegImage } from "../Service";

const OnlineRegModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [name, SetName] = useState("");
    const [email, SetEmail] = useState("");
    const [phone, Setphone] = useState("");
    const [product, Setproduct] = useState("");
    const [userState, SetUserState] = useState(0);
    const [submitButton, SetSubmitButton] = useState(false);
    const [AlertDisplay, SetAlertDisplay] = useState(false);
    const [AlertMessage, SetAlertMessage] = useState([]);
    const [Category, SetCategory] = useState([]);
    const [qualification, SetQualification] = useState("DEFAULT");

    const navigate = useNavigate();

    const [Data, SetImage] = useState([]);

    const fetchImage = async () => {
        let res = await onlineRegImage();
        if (res?.is_success === true) {
            SetImage(res?.data);
        }
    };
 

    const disableButton = () => {
        buttonRef.current.disabled = true; // this disables the button
    }

    const buttonRef = useRef();

    useEffect(() => {
        // getProducts();
        fetchImage();
    }, []);
    const enquiry = async (e) => {
        let data = {
            name: name,
            email: email,
            product: product,
            phone: phone,
        };

        let err = [];

        var mandatoryFilled = true;
        if (name == "" || name == "null" || name == null || email == "" || email == "null" ||
            email == null) {
            err.push("Please fill the mandatory fields");
            mandatoryFilled = false;
        }
        if (mandatoryFilled == true) {
            if (!validator.isLength(name, { min: 3, max: 255 })) {
                err.push("Name should have minimum 3 characters");
            }
            if (!validator.isLength(name, { min: 0, max: 255 })) {
                err.push("Please Enter Some Products");
            }
            if (!validator.isLength(phone, { min: 10, max: 10 })) {
                err.push("Phone Number Must be 10 digits");
            }
            if (!validator.isEmail(email)) {
                err.push("Enter a Valid Email");
            }
        }


        if (err.length > 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            SetAlertDisplay(true);
            SetAlertMessage(err);
            SetSubmitButton(false);
        } else {
            buttonRef.current.disabled = true;
            let res = await OnlineRegApi(data);
            if (res?.is_success === true) {
                // window.scrollTo({
                //     top: 0,
                //     behavior: 'smooth',
                // })
                setTimeout(() => {
                    navigate('/thank-you');
                }, 2000);
            } 
            // else {
            //     window.scrollTo({
            //         top: 0,
            //         behavior: 'smooth',
            //     })
            // }
        }
    }

    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
                Launch static backdrop modal
            </Button> */}

            <Modal
                show={props.show}
                hide={props.close}
                size='lg'
                centered
            >
                <Modal.Header closeButton onClick={props.hide}>
                    <Modal.Title>Online Registration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AlertComponent
                        AlertDisplay={AlertDisplay}
                        AlertMessage={AlertMessage}
                        SetAlertDisplay={SetAlertDisplay}
                    />
                    <div className="row">
                        <div className="col">
                            <div className="text-start mb-3">
                                <div className="form-floating mb-4">
                                    <div className="form-group mb-3">
                                        <label>Name*</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Name"
                                            name="name"
                                            value={name}
                                            onChange={(e) => {
                                                SetName(e.target.value);
                                            }}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-4">
                                    <div className="form-group mb-3">
                                        <label>Email*</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Enter Email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => {
                                                SetEmail(e.target.value);
                                            }}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-floating mb-4">
                                    <div className="form-group mb-3">
                                        <label>Qualification*</label>
                                        {/* <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Product"
                                            name="product"
                                            value={product}
                                            onChange={(e) => {
                                                Setproduct(e.target.value);
                                            }}
                                            required
                                        /> */}
                                        <select
                                            className="form-control form-select" value={product} name="product"
                                            onChange={(e) => {
                                                Setproduct(e.target.value);
                                            }}
                                        >
                                            <option value="" disabled>
                                                Please select your Qualification
                                            </option>
                                            <option value="8th-9th">8th-9th</option>
                                            <option value="10th-12th">10th-12th</option>
                                            <option value="college-graduates">
                                                College Graduates
                                            </option>
                                            <option value="working-professionals">
                                                Working Professionals
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-floating mb-4">
                                    <div className="form-group mb-3">
                                        <label>Phone*</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Enter Phone"
                                            name="phone"
                                            value={phone}
                                            onChange={(e) => {
                                                Setphone(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <button ref={buttonRef}
                                    className="btn btn-primary rounded-pill btn-login w-100 mb-2" onClick={(e) => {
                                        enquiry(e);
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div className="col-auto">
                            <img className="img-fluid OnlineReg" style={{ width: '100%', height: 'auto' }} 
                                src={process.env.REACT_APP_BASE_URL + Data.image}
                                alt=""
                            />
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}

export default OnlineRegModal;