import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import WhoWeAreimage from "../../assets/img/who-we-are-shape.png";
import { storeCounsellingAPI } from "../../Service";
import { useState, useEffect } from "react";
import AlertComponent from "../../layouts/Alert";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function Guidelines() {
  const list = [
    { name: "Home", url: "/" },
    { name: "Counselling Guidelines", url: null },
  ];
  let first = new Date(Date.now() + 3600 * 1000 * 24);
  let second = new Date(Date.now() + 3600 * 1000 * 72);
  let USER_TOKEN = Cookies.get("usr_token");
  const initialFormData = Object.freeze({
    first_meeting_at: null,
    second_meeting_at: null,
  });
  const navigate = useNavigate();

  const [formData, updateFormData] = useState(initialFormData);
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (USER_TOKEN === undefined) {
      navigate("/?is_login=true&is_message=Please Login to Continue");
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    let err = [];
    if (formData?.first_meeting_at === null) {
      err.push("Please Choose First Preference Date");
    }
    if (formData?.second_meeting_at === null) {
      err.push("Please Choose Second Preference Date");
    }
    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
    } else {
      let res = await storeCounsellingAPI(formData, USER_TOKEN);
      if (res?.is_success) {
        SetAlertDisplay(false);
        SetAlertMessage([]);
        navigate("/my-counselling");
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors);
      }
    }
  };

  return (
    <div>
      <HeaderComponent />
      <BreadCumb name={"Counselling Guidelines"} list={list} />
      <section className="wrapper bg-light guidelines pt-10">
        <div className="container py-14 py-md-1">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15 pt-10">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row text-justify">
              <div className="col-lg-4">
                <div
                  className="event-box-item"
                  style={{ backgroundColor: "#e2eafa" }}
                >
                  <div className="row">
                    <div className="col-md-2">
                      <div className="event-image">
                        <a href="#">
                          <img className="img-fluid"
                            src={WhoWeAreimage}
                            alt="who-we-are"
                            style={{ width: "100%", height: 'auto' }}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-md-10">
                      <div className="event-content">
                        <p>
                          Please be open during the discussion with your
                          counsellor, don't forget that whatever you are going
                          to share with our counsellor is confidential. Discuss
                          all your doubts and make full use of this counselling
                          session and make it a healthy conversation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="event-box-item"
                  style={{ backgroundColor: "#e2eafa" }}
                >
                  <div className="row">
                    <div className="col-md-2">
                      <div className="event-image">
                        <a href="#">
                          <img className="img-fluid"
                            src={WhoWeAreimage}
                            alt="who-we-are"
                            style={{ width: "100%", height: 'auto' }}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-md-10">
                      <div className="event-content">
                        <p>
                          Please make sure you go through your assessment report
                          beforehand so that you have some idea about it. Rest
                          your counsellor will discuss your report in detail
                          during the session.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="event-box-item"
                  style={{ backgroundColor: "#e2eafa" }}
                >
                  <div className="row">
                    <div className="col-md-2">
                      <div className="event-image">
                        <a href="#">
                          <img className="img-fluid"
                            src={WhoWeAreimage}
                            alt="who-we-are"
                            style={{ width: "100%", height: 'auto' }}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-md-10">
                      <div className="event-content">
                        <p>
                          During this session, counsellor will understand you
                          better, help you in choosing the top 5 careers for
                          you. At the end of the process, you get a detailed
                          action plan for your future solving all your needs
                          related to career planning, academic success, and
                          personal and social development.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="event-box-item"
                  style={{ backgroundColor: "#e2eafa" }}
                >
                  <div className="row">
                    <div className="col-md-2">
                      <div className="event-image">
                        <a href="#">
                          <img className="img-fluid"
                            src={WhoWeAreimage}
                            alt="who-we-are"
                            style={{ width: "100%", height: 'auto' }}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-md-10">
                      <div className="event-content">
                        <p>
                          The counselling session will begin with the student
                          and it will be for 45 minutes, and the session will be
                          continued with the parents for 15 minutes. Which in
                          total will be 1 hour.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="event-box-item"
                  style={{ backgroundColor: "#e2eafa" }}
                >
                  <div className="row">
                    <div className="col-md-2">
                      <div className="event-image">
                        <a href="#">
                          <img className="img-fluid"
                            src={WhoWeAreimage}
                            alt="who-we-are"
                            style={{ width: "100%", height: 'auto' }}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-md-10">
                      <div className="event-content">
                        <p>
                          We will guide you, reward the trail which motivates
                          you, excites you or what you love to do. The final
                          choice of choosing the right career for yourself is
                          totally up to you.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div
                  className="event-box-item"
                  style={{ backgroundColor: "#e2eafa" }}
                >
                  <div className="row">
                    <div className="col-md-2">
                      <div className="event-image">
                        <a href="#">
                          <img  className="img-fluid"
                            src={WhoWeAreimage}
                            alt="who-we-are"
                            style={{ width: "100%", height: 'auto' }}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-md-10">
                      <div className="event-content">
                        <p>
                          After your counselling session, you can login to your
                          Mere Mentor account to get your action plan on your
                          shortlisted careers.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 offset-md-2 text-center">
                <div className="event-details-information">
                  <h3>Counselling Preference Date</h3>
                  <AlertComponent
                    AlertDisplay={AlertDisplay}
                    SetAlertDisplay={SetAlertDisplay}
                    AlertMessage={AlertMessage}
                  />
                  <div className="form-group row">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-4 col-form-label"
                    >
                      1st Preference Date
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="datetime-local"
                        className="form-control"
                        name="first_meeting_at"
                        step="2"
                        onChange={handleChange}
                        min={first.toISOString().slice(0, -8)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row mt-3">
                    <label
                      htmlFor="staticEmail"
                      className="col-sm-4 col-form-label"
                    >
                      2nd Preference Date
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="datetime-local"
                        className="form-control"
                        name="second_meeting_at"
                        min={second.toISOString().slice(0, -8)}
                        onChange={handleChange}
                        step="2"
                        required
                      />
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn default-btn btn-primary mt-3"
                    onClick={handleSubmit}
                  >
                    Book Online Session
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Guidelines;
