import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import BreadCumb from "../layouts/BreadCumb";
import { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { FAQAPI } from "../Service";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";

function Faq() {
  const [showScroll, setShowScroll] = useState(false);
  const list = [
    { name: "Home", url: "/" },
    { name: "Faqs", url: null },
  ];
  const [faqs, SetFaqs] = useState([]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetcFaqs();
  }, []);
  const fetcFaqs = async () => {
    let res = await FAQAPI();
    if (res?.is_success === true) {
      SetFaqs(res?.datas);
    }
  };
  const setting = JSON.parse(localStorage.getItem("setting"));
  let MetaDetails = {
    'title': 'Frequently Asked Questions - FAQ | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Frequently Asked Questions - FAQ | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Mere Mentor FAQ',
    'meta_description': 'What is Mere Mentor? Genuine career advisors, a complete platform for career counselling and career assessment for students',
  }
  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      <BreadCumb name="Frequently Asked Questions" list={list} />
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="card">
                  <div className="card-body">
                    {faqs.length > 0 && (
                      <div>
                        <div id="accordion-3" className="accordion-wrapper">
                          {faqs.map((x, i) => (
                            <Accordion
                              defaultActiveKey={i}
                              className="card accordion-item"
                              key={i}
                            >
                              <Accordion.Item eventKey="0" className="mt-1">
                                <Accordion.Header className="card-header">
                                  {x.question}
                                </Accordion.Header>
                                <Accordion.Body className="card-body">
                                  {x.answer}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default Faq;
