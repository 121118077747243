import ImageFadeIn from "react-image-fade-in";

function Partner({ partners }) {
  return (
    <section className="wrapper bg-light" >
      <div className="container pt-1 pb-6">
        <div className="row gx-lg-8 gx-xl-12 gy-10 gy-lg-0 mb-10">
          <div className="col-lg-4 mt-lg-2">
            <h3 className="display-4 mb-3 pe-xxl-5 text-center-sm">
              Associated and<br/>Recognized by
            </h3>
            <p className="lead fs-lg mb-0 pe-xxl-5 text-center-sm">
              Some of the top National <br /> And International Bodies
            </p>
          </div>
          <div className="col-lg-8 mt-lg-2">
            <div
              className="row row-cols-2 row-cols-md-4 gx-0 gx-md-8 gx-xl-12 gy-12"
              data-cues=""
              data-group="clients"
            >
              {partners.filter((x, idx) => idx < 8).map((x, i) => (
                    <div className="col" key={i}>
                      <figure className="px-3 px-md-0 px-xxl-2" data-cue="">
                        <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" 
                          src={process.env.REACT_APP_BASE_URL + x?.image}
                          alt={x?.name}
                        />
                        {/* <ImageFadeIn width={640} height={480} src={process.env.REACT_APP_BASE_URL + x?.image} alt="{x?.name}" /> */}
                      </figure>
                    </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner;
