import HeaderComponent from "../layouts/HeaderComponent";

import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import Cookies from "js-cookie";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";

import introductionThumbNail from '../assets/img/photos/about15.jpg';
import introductionimagesrcset from '../assets/img/photos/about15@2x.jpg';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import weareProudImage from '../assets/img/photos/tm1.jpg';
import weareProudSrcSetImage from '../assets/img/photos/tm1.jpg';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "../assets/css/owl.css"
import "../assets/css/custom.css"

import { usersWatchedVideoCountAPI, storeWatchedVideoCountAPI, getLastSubscriptionPackageAPI } from "../Service";

import ModalAlert from "../layouts/ModalAlert";

import ESMVideo0 from '../assets/img/photos/tm1.jpg';
// import ESMVideo0 from '../assets/video/exam-stress-management/0-Main-Intro.mp4';
// import ESMVideo1 from '../assets/video/exam-stress-management/1-Introduction.mp4';
// import ESMVideo2 from '../assets/video/exam-stress-management/2-Mindfullness.mp4';
// import ESMVideo3 from '../assets/video/exam-stress-management/3-Setting-up-the-environment.mp4';
// import ESMVideo4 from '../assets/video/exam-stress-management/4-Adopt-A-Technique.mp4';
// import ESMVideo5 from '../assets/video/exam-stress-management/5-Prioritize.mp4';
// import ESMVideo6 from '../assets/video/exam-stress-management/6-Study-break.mp4';
// import ESMVideo7 from '../assets/video/exam-stress-management/7-Diet.mp4';
// import ESMVideo8 from '../assets/video/exam-stress-management/8-Social-support.mp4';
import ESMImgMain from '../assets/img/exam-stress/main.jpg';
import ESMImg1 from '../assets/img/exam-stress/1.png';
import ESMImg2 from '../assets/img/exam-stress/2.png';
import ESMImg3 from '../assets/img/exam-stress/3.png';
import ESMImg4 from '../assets/img/exam-stress/4.png';
import ESMImg5 from '../assets/img/exam-stress/5.png';
import ESMImg6 from '../assets/img/exam-stress/6.png';
import ESMImg7 from '../assets/img/exam-stress/7.png';
import ESMImg8 from '../assets/img/exam-stress/8.png';
// import lockPng from '../assets/img/lock-png';

import GLightbox from "glightbox";
import Footer from "../layouts/Footer";

export default function ExamStressManagement() {
  const setting = JSON.parse(localStorage.getItem("setting"));
  const [showScroll, setShowScroll] = useState(false);
  const [videoStatus0, setvideoStatus0] = useState(true);
  const [videoStatus1, setvideoStatus1] = useState(false);
  const [videoStatus2, setvideoStatus2] = useState(false);
  const [videoStatus3, setvideoStatus3] = useState(false);
  const [videoStatus4, setvideoStatus4] = useState(false);
  const [videoStatus5, setvideoStatus5] = useState(false);
  const [videoStatus6, setvideoStatus6] = useState(false);
  const [videoStatus7, setvideoStatus7] = useState(false);
  const [videoStatus8, setvideoStatus8] = useState(false);

  const [isSubscribed, setisSubscribed] = useState(false);

  const [ModalAlertDisplay, SetModalModalAlertDisplay] = useState(false);
  const [ModalAlertMessage, SetModalAlertMessage] = useState([]);
  const [ModalAlertButton, SetModalAlertButton] = useState("");
  const [ModalAlertSlug, SetModalAlertSlug] = useState("");
  const [ExamStressManagementStatus,setExamStressManagementStatus] = useState(false);

  let USER_TOKEN = Cookies.get("usr_token");

  let user = Cookies.get("user");

  let MetaDetails = {
    'title': 'Exam Stress Management | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Exam Stress Management | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Mere Mentor Exam Stress Management',
    'meta_description': 'Exam Stress Management.',
  }

  let navigate = useNavigate();
  const list = [
    { name: "Home", url: "/" },
    { name: "Exam Stress Management", url: null },
  ];

  useEffect(() => {
    if (USER_TOKEN === undefined || user === undefined) {
      navigate("/");
    }
    window.scrollTo({ top: 0 });
    getUserWatchedVideoCount();
    getLastSubscriptionPackage();
  }, []);

  const getLastSubscriptionPackage = async () => {
    var dataShow = false;
    let res = await getLastSubscriptionPackageAPI(USER_TOKEN);
    if (res?.is_success === true) {
      if(res?.data == null || res?.data == "null"){
        dataShow = false;
        setExamStressManagementStatus(false);
      }else if((res?.data!= null || res?.data != "null") && res?.data?.get_package?.esm_status == "0" && res?.data?.get_package?.esm_active == "0"){
        dataShow = false;
        setExamStressManagementStatus(false);
      }else if((res?.data!= null || res?.data != "null") && res?.data?.get_package?.esm_status == "1" && res?.data?.get_package?.esm_active == "1"){
        dataShow = true;
        setExamStressManagementStatus(true);
      }
      console.log(dataShow);
      if(dataShow){
        document.querySelectorAll(".vertical-timeline").forEach(elements => {
          elements.style.display = "block";
        });
      }else{
        document.querySelectorAll(".vertical-timeline").forEach(elements => {
          elements.style.display = "none";
        });
      }
    }
  };

  const getUserWatchedVideoCount = async () => {
    let res = await usersWatchedVideoCountAPI(USER_TOKEN);
    if (res?.is_success == true) {
      setisSubscribed(res?.user?.current_subscriptions?.is_paid_package);
      if (res?.user?.esm_watched_video_count > 0 && res?.user?.current_subscriptions?.is_paid_package == true) {
        for (let index = 1; index <= res?.user?.esm_watched_video_count; index++) {
          if (index == 1) {
            setvideoStatus1(true)
            setvideoStatus2(true)
          } else if (index == 2) {
            setvideoStatus3(true)
          } else if (index == 3) {
            setvideoStatus4(true)
          } else if (index == 4) {
            setvideoStatus5(true)
          } else if (index == 5) {
            setvideoStatus6(true)
          } else if (index == 6) {
            setvideoStatus7(true)
          } else if (index == 7) {
            setvideoStatus7(true)
          } else if (index == 8) {
            setvideoStatus8(true)
          }
        }
      }
    }
  }

  const countStoringAPI = async (value) => {
    const data = {
      count: value
    };
    let res = await storeWatchedVideoCountAPI(USER_TOKEN, data);
  }

  const customLightboxHTML = '<div id="glightbox-body" class="glightbox-container">' +
      '<div class="gloader visible"></div>' +
      '<div class="goverlay"></div>' +
      '<div class="gcontainer">' +
      '<div id="glightbox-slider" class="gslider"></div>' +
      '<button class="gnext gbtn disabled d-none" tabindex="0" aria-label="Next" data-customattribute="example">{nextSVG}</button>' +
      '<button class="gprev gbtn disabled d-none" tabindex="1" aria-label="Previous">{prevSVG}</button>' +
      '<button class="gclose gbtn" tabindex="2" aria-label="Close">{closeSVG}</button>' +
    '</div>' +
  '</div>';

  let customSlideHTML = `<div class="gslide">
    <div class="gslide-inner-content">
        <div class="ginner-container">
            <div class="gslide-media">
            </div>
            <div class="gslide-description">
                <div class="gdesc-inner">
                    <h4 class="gslide-title"></h4>
                    <div class="gslide-desc"></div>
                </div>
            </div>
        </div>
    </div>
</div>`;

  const careerVideo = (index) => {
    const myGallery = GLightbox({
      lightboxHTML: customLightboxHTML,
      slideHTML: customSlideHTML,
      // skin: 'supercool',
      touchNavigation: false,
      draggable: false,
      dragToleranceX: 0,
      dragToleranceY: 0,
      loop: false,
      elements: [
        {
          href: "https://exam-stress-management-videos.s3.ap-south-1.amazonaws.com/0-Main-Intro.mp4",
          type: "video",
          source: "vimeo", //vimeo, youtube or local
          width: 900,
        }, {
          href: 'https://exam-stress-management-videos.s3.ap-south-1.amazonaws.com/1-Introduction.mp4',
          type: "video",
          source: "vimeo", //vimeo, youtube or local
          width: 900,
        }, {
          href: 'https://exam-stress-management-videos.s3.ap-south-1.amazonaws.com/2-Mindfullness.mp4',
          type: "video",
          source: "vimeo", //vimeo, youtube or local
          width: 900,
        }, {
          href: 'https://exam-stress-management-videos.s3.ap-south-1.amazonaws.com/3-Setting-up-the-environment.mp4',
          type: "video",
          source: "vimeo", //vimeo, youtube or local
          width: 900,
        }, {
          href: 'https://exam-stress-management-videos.s3.ap-south-1.amazonaws.com/4-Adopt-A-Technique.mp4',
          type: "video",
          source: "vimeo", //vimeo, youtube or local
          width: 900,
        }, {
          href: 'https://exam-stress-management-videos.s3.ap-south-1.amazonaws.com/5-Prioritize.mp4',
          type: "video",
          source: "vimeo", //vimeo, youtube or local
          width: 900,
        }, {
          href: 'https://exam-stress-management-videos.s3.ap-south-1.amazonaws.com/6-Study-break.mp4',
          type: "video",
          source: "vimeo", //vimeo, youtube or local
          width: 900,
        }, {
          href: 'https://exam-stress-management-videos.s3.ap-south-1.amazonaws.com/7-Diet.mp4',
          type: "video",
          source: "vimeo", //vimeo, youtube or local
          width: 900,
        }, {
          href: 'https://exam-stress-management-videos.s3.ap-south-1.amazonaws.com/8-Social-support.mp4',
          type: "video",
          source: "vimeo", //vimeo, youtube or local
          width: 900,
        },
      ],
      // autoplayVideos: true,
    });
    myGallery.openAt(index);

    myGallery.on('slide_changed', ({ prev, current }) => {

      const { player } = current;

      if (player) {
        if (!player.ready) {
          // If player is not ready
          player.on('ready', (event) => {
            // Do something when video is ready
          });
        }

        player.on('ended', (event) => {
          if (isSubscribed == true) {
            if (index == 0) {
              setvideoStatus1(true)
            } else if (index == 1) {
              countStoringAPI(index);
              setvideoStatus2(true)
            } else if (index == 2) {
              countStoringAPI(index);
              setvideoStatus3(true)
            } else if (index == 3) {
              countStoringAPI(index);
              setvideoStatus4(true)
            } else if (index == 4) {
              countStoringAPI(index);
              setvideoStatus5(true)
            } else if (index == 5) {
              countStoringAPI(index);
              setvideoStatus6(true)
            } else if (index == 6) {
              countStoringAPI(index);
              setvideoStatus7(true)
            } else if (index == 7) {
              countStoringAPI(index);
              setvideoStatus7(true)
            } else if (index == 8) {
              countStoringAPI(index);
            }
          } else if (index != 0 && isSubscribed == false) {
            SetModalModalAlertDisplay(true);
            SetModalAlertMessage(['Please Subscribe the Paid Package, Then Your Can Access it.']);
            SetModalAlertButton('ESM');
            SetModalAlertSlug('/products')
          }
        });
      }
    });
  };

  const UserAlertNotification = (is_subscribed, modalDisplay) => {
    if (is_subscribed == true && modalDisplay == true) {
      SetModalModalAlertDisplay(true);
      SetModalAlertMessage(['Please Watch the Video One by One.']);
    } else if ((is_subscribed == false || is_subscribed == undefined || is_subscribed == 'undefined') && modalDisplay == true) {
      SetModalModalAlertDisplay(true);
      SetModalAlertMessage(["Pease Subscribe/Upgrade your plan to watch the video's."]);
      SetModalAlertButton('ESM');
      SetModalAlertSlug('/products')
    }
  }

  return (
    <div>
      <Seo options={MetaDetails} />

      <HeaderComponent />

      <section className="wrapper bg-light">
        <div className="container">
          <div className="container pt-11 pt-md-13 pb-11 pb-md-19 pb-lg-22 text-center">
            <div className="row">
              <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto">
                <h1 className="display-1 fs-60 mb-4 px-md-15 px-lg-0">Exam Stress <br></br> <span className="underline-3 style-3 orange">Management</span></h1>
                <p className="lead fs-24 lh-sm mb-7 mx-md-13 mx-lg-10">We are a creative company that focuses on long term relationships with customers.</p>

                {videoStatus0 ? (
                  <a class="btn btn-lg btn-primary rounded mb-5"
                    onClick={(e) => {
                      careerVideo(0);
                    }}>Let's get started</a>
                ) : (
                  <a class="btn btn-lg btn-primary rounded mb-5">Let's get started</a>
                )}

              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="wrapper bg-dark">

        <div class="container py-14 py-md-16">
          <div>
            <figure class="rounded mt-md-n21 mt-lg-n23 mb-14 visible-md">
              <div className="video-wave-wrapper d-none">
                <div className="video-main">
                  <div className="promo-video d-none">
                    <div className="waves-block">
                      <div className="waves wave-1"></div>
                      <div className="waves wave-2"></div>
                      <div className="waves wave-3"></div>
                    </div>
                  </div>
                  {videoStatus0 ? (
                    <button
                      className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                      style={{
                        top: "200%",
                        left: "50%",
                        transform: "translate(-25px,10px)",
                        zIndex: "3",
                      }}
                      onClick={(e) => {
                        careerVideo(0);
                      }}
                    >
                      <i className="icn-caret-right"></i>
                    </button>
                  ) : (
                    <button className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                      style={{
                        top: "200%",
                        left: "50%",
                        transform: "translate(-25px,10px)",
                        zIndex: "3",
                      }}
                    >
                      <i className="icn-caret-right"></i>
                    </button>
                  )}
                </div>
              </div>

              {videoStatus0 ? (
                <a class="cursor-pointer"
                  onClick={(e) => {
                    careerVideo(0);
                  }}><img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={ESMImgMain} srcset={ESMImgMain} alt="" /></a>
              ) : (
                <a class="cursor-pointer"><img src={ESMImgMain} srcset={ESMImgMain} alt="" /></a>
              )}
            </figure>
          </div>
          <VerticalTimeline style={{ display: "none" }}>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              // contentStyle={{ background: '#54a8c7', color: '#fff' }}
              // contentArrowStyle={{ borderRight: '7px solid  #54a8c7' }}
              iconStyle={{ background: '#54a8c7', color: '#fff' }}
            >
              <figure className="rounded mb-0">
                <div className="video-wave-wrapper d-none">
                  <div className="video-main">
                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                      </div>
                    </div>
                    {videoStatus1 ? (
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(1);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    ) : (
                      <button className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          UserAlertNotification(isSubscribed, true);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    )}
                  </div>
                </div>
                {videoStatus1 ? (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      careerVideo(1);
                    }}>
                    <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={ESMImg1} alt="exam-stress" /></a>
                ) : (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      UserAlertNotification(isSubscribed, true);
                    }}><img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={ESMImg1} alt="exam-stress" /></a>
                )}
              </figure>
              <span className="vertical-timeline-element-date"><span className="font-weight-bold">Introduction : </span>If you are here, then we guess it’s the exams again. You must be stressed now that you don’t need a further explanation of how it feels but how to deal with it. We hear you. It is completely okay! You are at the right place…..</span>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              // contentArrowStyle={{ borderRight: '7px solid  #54a8c7' }}
              iconStyle={{ background: '#54a8c7', color: '#fff' }}
            >
              <figure className="rounded mb-0">
                <div className="video-wave-wrapper d-none">
                  <div className="video-main">
                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                      </div>
                    </div>
                    {videoStatus2 ? (
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(2);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    ) : (
                      <button className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          UserAlertNotification(isSubscribed, true);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    )}
                  </div>
                </div>
                {videoStatus2 ? (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      careerVideo(2);
                    }}>
                    <img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg2} /></a>
                ) : (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      UserAlertNotification(isSubscribed, true);
                    }}><img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg2} /></a>
                )}

              </figure>
              <span className="vertical-timeline-element-date"><span className="font-weight-bold">Mindfulness : </span>We would have tried many ways to get ourselves relieved from all this pressure to feel the pleasure of being in the present for a few moments. It would feel heaven if we could get that few minutes of relief as if you have nothing to worry about, right? Then let’s talk about mindfulness…..</span>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#54a8c7', color: '#fff' }}

            >
              <figure className="rounded mb-0">
                <div className="video-wave-wrapper d-none">
                  <div className="video-main">
                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                      </div>
                    </div>
                    {videoStatus3 ? (
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(3);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    ) : (
                      <button className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          UserAlertNotification(isSubscribed, true);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    )}
                  </div>
                </div>
                {videoStatus3 ? (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      careerVideo(3);
                    }}>
                    <img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg3} /></a>
                ) : (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      UserAlertNotification(isSubscribed, true);
                    }}><img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg3} /></a>
                )}


              </figure>
              <span className="vertical-timeline-element-date"><span className="font-weight-bold">Setting up the Environment : </span>In the course of getting ready for the exam, gathering the course materials, and scheduling the portions which we have planned to cover, we tend to overlook or give the slightest preference to one of the crucial factors that have the potential to change the entire mind-set...</span>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: '#54a8c7', color: '#fff' }}

            >
              <figure className="rounded mb-0">
                <div className="video-wave-wrapper d-none">
                  <div className="video-main">
                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                      </div>
                    </div>
                    {videoStatus4 ? (
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(4);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    ) : (
                      <button className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          UserAlertNotification(isSubscribed, true);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    )}
                  </div>
                </div>
                {videoStatus4 ? (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      careerVideo(4);
                    }}>
                    <img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg4} /></a>
                ) : (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      UserAlertNotification(isSubscribed, true);
                    }}><img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg4} /></a>
                )}



              </figure>
              <span className="vertical-timeline-element-date"><span className="font-weight-bold">Adopting A Technique : </span>This video clip aims at covering different adopting techniques for learning. You would have observed that each person has their own learning style and fashion. Therefore, there is no universal method to help everyone achieve their higher learning capacity...</span>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              iconStyle={{ background: '#54a8c7', color: '#fff' }}

            >
              <figure className="rounded mb-0">
                <div className="video-wave-wrapper d-none">
                  <div className="video-main">
                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                      </div>
                    </div>
                    {videoStatus5 ? (
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(5);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    ) : (
                      <button className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          UserAlertNotification(isSubscribed, true);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    )}
                  </div>
                </div>
                {videoStatus5 ? (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      careerVideo(5);
                    }}>
                    <img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg5} /></a>
                ) : (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      UserAlertNotification(isSubscribed, true);
                    }}><img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg5} /></a>
                )}

              </figure>
              <span className="vertical-timeline-element-date"><span className="font-weight-bold">Prioritize : </span>Procrastination! There are high chances of coming across this term somewhere or the other. So the next question is, what is procrastination? What does it have to do with my exam preparation? When put simply, procrastination looks like this “postponing a task until the deadline is the next day!”</span>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              iconStyle={{ background: '#54a8c7', color: '#fff' }}

            >
              <figure className="rounded mb-0">
                <div className="video-wave-wrapper d-none">
                  <div className="video-main">
                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                      </div>
                    </div>
                    {videoStatus6 ? (
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(6);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    ) : (
                      <button className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          UserAlertNotification(isSubscribed, true);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    )}
                  </div>
                </div>
                {videoStatus6 ? (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      careerVideo(6);
                    }}>
                    <img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg6} /></a>
                ) : (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      UserAlertNotification(isSubscribed, true);
                    }}><img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg6} /></a>
                )}

              </figure>
              <span className="vertical-timeline-element-date"><span className="font-weight-bold">Study break : </span>We lose track of time and ourselves in our attempt to complete the material. We forget to pause and look around. We end up spending too much time at our table and the study materials, not realizing that we really need a break...</span>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--education"
              iconStyle={{ background: '#54a8c7', color: '#fff' }}

            >
              <figure className="rounded mb-0">
                <div className="video-wave-wrapper d-none">
                  <div className="video-main">
                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                      </div>
                    </div>
                    {videoStatus7 ? (
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(7);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    ) : (
                      <button className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          UserAlertNotification(isSubscribed, true);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    )}
                  </div>
                </div>
                {videoStatus7 ? (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      careerVideo(7);
                    }}>
                    <img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg7} /></a>
                ) : (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      UserAlertNotification(isSubscribed, true);
                    }}><img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg7} /></a>
                )}

              </figure>
              <span className="vertical-timeline-element-date"><span className="font-weight-bold">Diet : </span>In this video, we shall be discussing an essential factor that is crucial considering during exam preparation. We are so preoccupied with exam stress that we go off track in taking healthy food and start binge eating. Let’s admit that we all have taken a handful of chips packets...</span>
            </VerticalTimelineElement><VerticalTimelineElement
              className="vertical-timeline-element--education"
              iconStyle={{ background: '#54a8c7', color: '#fff' }}

            >
              <figure className="rounded mb-0">
                <div className="video-wave-wrapper d-none">
                  <div className="video-main">
                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1"></div>
                        <div className="waves wave-2"></div>
                        <div className="waves wave-3"></div>
                      </div>
                    </div>
                    {videoStatus8 ? (
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(8);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    ) : (
                      <button className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "50%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          UserAlertNotification(isSubscribed, true);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    )}
                  </div>
                </div>
                {videoStatus8 ? (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      careerVideo(8);
                    }}>
                    <img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg8} /></a>
                ) : (
                  <a class="cursor-pointer"
                    onClick={(e) => {
                      UserAlertNotification(isSubscribed, true);
                    }}><img style={{ width: '100%', height: 'auto' }} alt="exam-stress"  className="img-fluid" src={ESMImg8} /></a>
                )}

              </figure>
              <span className="vertical-timeline-element-date"><span className="font-weight-bold">Social support : </span>Yes, stress management during exams requires a lot of effort from within an individual by preparing, organizing, and self-management. Yet, social support has the utmost influence over one’s mind and performance. At the end of the day, all that matters is having a supportive person...</span>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: '#54a8c7', color: '#fff' }}

            />
          </VerticalTimeline>
        </div>
      </section>
      <section className="wrapper bg-light d-none">
        <div class="container py-1 py-md-1 py-sm-10 py-xs-10">
          <div class="card shadow-lg mt-n15 mt-md-n14 mb-14 mb-md-18">
            <div class="row gx-0">
              <div class="col-lg-6">
                <figure><img style={{ "border-radius": "7px", "paddingTop": "0px" }} src={weareProudImage} srcset={weareProudSrcSetImage} alt="" /></figure>
              </div>
              <div class="col-lg-6">
                <div class="p-10 p-md-6 p-lg-6">
                  <div class="swiper-container dots-closer mb-6">
                    <div class="swiper">
                      <div class="swiper-wrapper">
                        <OwlCarousel
                          items={1}
                          className="carousel owl-carousel gap-small"
                          autoplay={true}
                          nav={false}
                          dots={true}
                          autoplayTimeout={1500}
                          loop
                          margin={30}
                        >
                          <div className="swiper-slide">
                            <blockquote className="icon icon-top fs-lg text-center">
                              <div className="blockquote-details justify-content-center text-center">
                                <div className="info ps-0">
                                  <h5 className="mb-1">Vision</h5>
                                </div>
                              </div>
                              <p>“To develop a child’s overall skills and motivate early learning alongside ameliorating the studying environment.”</p>
                            </blockquote>
                          </div>
                          <div className="swiper-slide">
                            <blockquote className="icon icon-top fs-lg text-center">
                              <div className="blockquote-details justify-content-center text-center">
                                <div className="info ps-0">
                                  <h5 className="mb-1">Purpose</h5>
                                </div>
                              </div>
                              <p>“Reconstruct the existing two Anganwadi centres located at Dura Asim and Rangsakona Block into smart anganwadis in a 1700 sq. ft and remodelling the current curriculum.”</p>
                            </blockquote>
                          </div>
                          <div className="swiper-slide">
                            <blockquote className="icon icon-top fs-lg text-center">
                              <div className="blockquote-details justify-content-center text-center">
                                <div className="info ps-0">
                                  <h5 className="mb-1">Mission</h5>
                                </div>
                              </div>
                              <p>“Bring in an advanced updated curriculum to meet the competitive standards; Train the Anganwadi workers; Orient parents' engagement in knowing their child’s progress.”</p>
                            </blockquote>
                          </div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ModalAlert
        ModalAlertDisplay={ModalAlertDisplay}
        SetModalModalAlertDisplay={SetModalModalAlertDisplay}
        ModalAlertMessage={ModalAlertMessage}
        ModalAlertButton={ModalAlertButton}
        ModalAlertSlug={ModalAlertSlug}
      />

      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}
