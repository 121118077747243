import React from "react";
import { useTimer } from "react-timer-hook";

export default function MyTimer({ expiryTimestamp,SetExpiredStatus,expiredStatus }) {
  const {
    seconds,
    minutes,
    hours,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      SetExpiredStatus(2)
    },
  });

  return (
    <div className="w-100">
      <div className="timer-position">
      <div className="timer-style">
        {hours}:{minutes > 9 ? minutes : `0${minutes}`}:
        {seconds > 9 ? seconds : `0${seconds}`}
      </div>
    </div>
    </div>
  
  );
}

