import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import React from 'react';
import { useState } from "react";
import IndianEpressImage from '../../assets/images/IE-education-logo-white.png';

export default function AssessmentNavbar() {
  let setting = JSON.parse(localStorage.getItem("setting"));

  const [heading, setHeading] = useState(localStorage.getItem('indianexpress') === 'true' ? 'Indian Process' : 'Mere Mentor');


  return (
    <div>
      <header className="wrapper bg-gray">
        <nav
          className="navbar navbar-expand-lg center-nav transparent navbar-light" /* style={{  'background': '#1b1b1b' }} */
        >
          <div className="container flex-lg-row flex-nowrap align-items-center">
            <div className="navbar-brand w-100">
              <Link
                to="/"
                target="_blank"
                style={{ fontSize: "24px", fontWeight: "bold", color: "#fff" }}
              >
                {localStorage.getItem('indianexpress') === 'false' || localStorage.getItem('broker') != "indianexpress" &&
                  <p> Mere Mentor </p>  
                }
                {/* {heading} */}
                {localStorage.getItem('indianexpress') === 'true' || localStorage.getItem('broker') == 'indianexpress' &&
                  <img className="img-fluid" src={IndianEpressImage} alt="" style={{ width: '200px', height: 'auto' }} />
                }
              </Link>
            </div>
            <div className="navbar-collapse offcanvas-nav ">
              <div className="offcanvas-header d-lg-none d-xl-none  d-none">
                <Link to="/" target="_blank">
                  <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"
                    src={process.env.REACT_APP_BASE_URL + setting?.logo}
                    alt={process.env.REACT_APP_NAME}
                  />
                </Link>
                <button
                  type="button"
                  className="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
                  aria-label="Close"
                ></button>
              </div>
              <ul className="navbar-nav d-none">
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to="/about-us">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to="/assessments">
                    Assessment
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to="/products">
                    Products
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to="/library">
                    Career Library
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to="/blog">
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" target="_blank" to="/contact-us">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>

            <div className="navbar-other w-100 d-flex ms-auto">
              <ul
                className="navbar-nav flex-row align-items-center ms-auto d-none"
                data-sm-skip="true"
              >
                {/*  <li className="nav-item d-none d-md-block">
                  <a href="#" className="btn btn-sm btn-light text-dark ">
                    Join the membership
                  </a>
                </li> */}
                <li className="nav-item d-lg-none">
                  <div className="navbar-hamburger">
                    <button
                      className="hamburger animate plain"
                      data-toggle="offcanvas-nav"
                    >
                      <span></span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
