import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { useState, useEffect } from "react";
import map from "../assets/img/map.png";
import { Link } from "react-router-dom";
import { ContactUsAPI } from "../Service";
import validator from "validator";
import AlertComponent from "../../src/layouts/Alert";
import Message from "../../src/layouts/Message";
import { Button, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import BreadCumb from "../layouts/BreadCumb";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
import Ticker from "../layouts/CounterUpVisibility";

function ContactUs() {
  const [showScroll, setShowScroll] = useState(false);
  const setting = JSON.parse(localStorage.getItem("setting"));
  const iframe_src =
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.388070997516!2d80.246336!3d12.9656434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6d3a96318d944c65!2sBest%20Career%20Counselling%20in%20Chennai%20%7C%20Mere%20Mentor!5e0!3m2!1sen!2sin!4v1639815166912!5m2!1sen!2sin";
  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [subject, SetSubject] = useState("");
  const [message, SetMessage] = useState("");
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [MessageDisplay, SetMessageDisplay] = useState(false);
  const [MessageContent, SetMessageContent] = useState([]);
  const [submitButton, SetSubmitButton] = useState(false);
  const list = [
    { name: "Home", url: "/" },
    { name: "Contact", url: null },
  ];
  const store = async () => {
    SetSubmitButton(true);
    let data = {
      name: name,
      email: email,
      phone: phone,
      subject: subject,
      message: message,
    };
    var err = [];
    var mandatoryFilled = true;

    if (name == "" || name == "null" || name == null || phone == "" || phone == "null" || phone == null || email == "" || email == "null" ||
        email == null || subject == "" || subject == "null" || subject == null || message == "" || message == "null" || message == null) {
      err.push("Please fill the mandatory fields");
      mandatoryFilled = false;
    }
    if (mandatoryFilled == true) {
      if (!validator.isLength(name, { min: 3, max: 255 })) {
        err.push("Name should have minimum 3 characters");
      }
      if (!validator.isLength(phone, { min: 10, max: 10 })) {
        err.push("Mobile Number Must be 10 digits");
      }
      if (!validator.isNumeric(phone)) {
        err.push("Mobile Number Must be in digits");
      }
      if (!validator.isEmail(email)) {
        err.push("Enter a Valid Email");
      }

      if (validator.isEmpty(subject)) {
        err.push("Subject Field is Required");
      }

      if (validator.isEmpty(message)) {
        err.push("Message Field is Required");
      }
    }

    if (err.length > 0) {
      window.scrollTo({
        top: "900",
        behavior: 'smooth',
      })
      SetAlertDisplay(true);
      SetAlertMessage(err);
      SetSubmitButton(false);
    } else {
      let res = await ContactUsAPI(data);
      if (res?.is_success === true) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        SetAlertDisplay(false);
        SetAlertMessage([]);
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        SetSubmitButton(false);
        SetName("");
        SetEmail("");
        SetPhone("");
        SetSubject("");
        SetMessage("");
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
        SetSubmitButton(false);
      }
    }
  };

  let MetaDetails = {
    'title': 'Career Counsellors Contact | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Career Counsellors Contact | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Mere Mentor Career Counsellors Contact',
    'meta_description': 'Visible Horizon Edu Pvt ltd, IIFL Towers, 7th Floor, 143 MGR Salai, Kandanchavadi, Perungudi, Chennai 600096.',
  }

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />
      <BreadCumb name={"Contact"} list={list} />

      <section className="wrapper bg-light">
        <div className="container">
          <div className="row mb-14 mb-md-16">
            <div className="col-xl-10 mx-auto mt-n19">
              <div className="card">
                <div className="row gx-0">
                  <div className="col-lg-6 align-self-stretch">
                    <div className="map map-full rounded-top rounded-lg-start">
                      <iframe
                        src={iframe_src}
                        style={{ width: "100%", height: "100%", border: "0" }}
                      ></iframe>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="p-10 p-md-11 p-lg-14">
                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 me-4 mt-n1">
                            {" "}
                            <i className="uil uil-location-pin-alt"></i>{" "}
                          </div>
                        </div>
                        <div className="align-self-start justify-content-start">
                          <h5 className="mb-1">Head Office </h5>
                          <address>{setting?.address}</address>
                        </div>
                      </div>

                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 me-4 mt-n1">
                            {" "}
                            <i className="uil uil-location-pin-alt"></i>{" "}
                          </div>
                        </div>
                        <div className="align-self-start justify-content-start">
                          <h5 className="mb-1">Branches</h5>
                          <p className="d-inline-block">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Merementor, c/o Visible horizon Edu Pvt Ltd, Unit 806, Tower II , 8th Floor, Godrej waterside, DP5, Sector V, Bidhannagar, kolkata- 700091.</Tooltip>}>
                              <span>Kolkata, </span>
                            </OverlayTrigger>
                          </p>
                          <p className="d-inline-block">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Moreau Institute Of Integral Training, HV8V+848, brookdene, Jowai Road, Shillong -793003.</Tooltip>}>
                              <span>Meghalaya, </span>
                            </OverlayTrigger>
                          </p>
                          <p className="d-inline-block">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">1st floor,bada Akhara Chowk,opp to Bank of India (Bazar branch) Boddom Bazar.Hazaribagh ,Jharkhand Pin code -825301.</Tooltip>}>
                              <span>Jharkhand</span>
                            </OverlayTrigger>
                          </p>
                          {/* <address>{setting?.address}</address> */}
                        </div>
                      </div>

                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 me-4 mt-n1">
                            <i className="uil uil-phone-volume"></i>{" "}
                          </div>
                        </div>
                        <div>
                          <h5 className="mb-1">Phone</h5>
                          <p>{setting?.phone}</p>
                        </div>
                      </div>

                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 me-4 mt-n1">
                            <i className="uil uil-envelope"></i>
                          </div>
                        </div>
                        <div>
                          <h5 className="mb-1">E-mail</h5>
                          <p className="mb-0">
                            <a
                              href="mailto:{setting?.email}"
                              className="link-body"
                            >
                              {setting?.email}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <h2 className="display-4 mb-3 text-center">
                Ready to Get Started?
              </h2>
              <AlertComponent
                AlertDisplay={AlertDisplay}
                SetAlertDisplay={SetAlertDisplay}
                AlertMessage={AlertMessage}
              />
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-floating mb-4">
                    <input
                      id="form_name"
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      onChange={(e) => {
                        SetName(e.target.value);
                      }}
                      value={name}
                      required
                    />
                    <label htmlFor="form_name">Name *</label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating mb-4">
                    <input
                      id="form_email"
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={(e) => {
                        SetEmail(e.target.value);
                      }}
                      value={email}
                      required
                    />
                    <label htmlFor="form_email">Email *</label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating mb-4">
                    <input
                      id="form_email"
                      type="number"
                      name="phone"
                      className="form-control invalid"
                      placeholder="phone"
                      onChange={(e) => {
                        SetPhone(e.target.value);
                      }}
                      value={phone}
                      required
                    />
                    <label htmlFor="form_email">Phone *</label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating mb-4">
                    <input
                      id="form_email"
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Subject"
                      onChange={(e) => {
                        SetSubject(e.target.value);
                      }}
                      value={subject}
                      required
                    />
                    <label htmlFor="form_email">Subject *</label>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-floating mb-4">
                    <textarea
                      name="message"
                      className="form-control"
                      placeholder="Your message"
                      style={{ height: "150px" }}
                      onChange={(e) => {
                        SetMessage(e.target.value);
                      }}
                    >
                      {message}
                    </textarea>
                    <label htmlFor="form_message">Message *</label>
                  </div>
                </div>

                <GoogleReCaptchaProvider
                  reCaptchaKey={'6LcS4AIfAAAAAGwG1L6GiMjJo38IZyS3CKVYQcyG'}
                  /*  language="[optional_language]"
                    useRecaptchaNet="[optional_boolean_value]"
                    useEnterprise="[optional_boolean_value]" */
                  scriptProps={{
                    async: false, // optional, default to false,
                    defer: false, // optional, default to false
                    appendTo: "head", // optional, default to "head", can be "head" or "body",
                    nonce: undefined, // optional, default undefined
                  }}
                ></GoogleReCaptchaProvider>

                <div className="col-12 text-center">
                  <Button
                    className="btn btn-primary rounded-pill btn-send mb-3"
                    disabled={submitButton}
                    onClick={(e) => {
                      store(e);
                    }}
                  >
                    {submitButton ? (
                      <div>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      <div>Send message</div>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="wrapper image-wrapper bg-auto no-overlay bg-image text-center bg-map"
        data-image-src={map}
      >
        <div className="container pt-0 pb-14 pt-md-16 pb-md-18">
          <div className="row">
            <div className="col-lg-9 col-xxl-8 mx-auto">
              <h3 className="display-4 mb-8 px-xl-12">
                We are trusted by over 1000000+ students. Come on! Join along with them and enhance your career
              </h3>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 col-lg-9 col-xl-7 mx-auto">
              <div className="row align-items-center counter-wrapper gy-4 gy-md-0">
                <div className="col-md-4 text-center">
                  <Ticker className="counter text-primary" end={100} suffix=" +" />
                  <p>National ,International collaborations</p>
                </div>

                <div className="col-md-4 text-center">
                  <Ticker className="counter text-primary" end={200} suffix=" +" />
                  <p>School and College partnerships</p>
                </div>

                <div className="col-md-4 text-center">
                  <Ticker className="counter text-primary" end={200} suffix=" +" />
                  <p>Expert <br />Counsellors</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Toast */}
      <Message
        MessageDisplay={MessageDisplay}
        MessageContent={MessageContent}
        SetMessageDisplay={SetMessageDisplay}
      />
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default ContactUs;
