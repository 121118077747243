import HeaderComponent from "../layouts/HeaderComponent";

import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import Cookies from "js-cookie";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";

import introductionThumbNail from '../assets/img/photos/home-image/home-screen-image.jpg';
import introductionimagesrcset from '../assets/img/photos/home-image/home-screen-image.jpg';

import spendingStressImage1 from '../assets/img/photos/home-image/1.jpg';
import spendingStressImage2 from '../assets/img/photos/home-image/2.jpg';
import spendingStressImage3 from '../assets/img/photos/home-image/3.jpg';
import spendingStressImage4 from '../assets/img/photos/home-image/4.jpg';

import spendingStressSrcImage1 from '../assets/img/photos/home-image/1.jpg';
import spendingStressSrcImage2 from '../assets/img/photos/home-image/2.jpg';
import spendingStressSrcImage3 from '../assets/img/photos/home-image/3.jpg';
import spendingStressSrcImage4 from '../assets/img/photos/home-image/4.jpg';

import financeImage1 from '../assets/img/photos/home-image/5.jpg';
import financeImage2 from '../assets/img/photos/home-image/6.jpg';
import financeImage3 from '../assets/img/photos/home-image/7.jpg';

import financeSrcSetImage1 from '../assets/img/photos/home-image/5.jpg';
import financeSrcSetImage2 from '../assets/img/photos/home-image/6.jpg';
import financeSrcSetImage3 from '../assets/img/photos/home-image/7.jpg';

import whoWeAreImage1 from '../assets/img/photos/why-the-childhood-image/1.png';
import whoWeAreImage2 from '../assets/img/photos/why-the-childhood-image/2.png';
import whoWeAreImage3 from '../assets/img/photos/why-the-childhood-image/3.png';

import whoWeAreSrcSetImage1 from '../assets/img/photos/why-the-childhood-image/1.png';
import whoWeAreSrcSetImage2 from '../assets/img/photos/why-the-childhood-image/2.png';
import whoWeAreSrcSetImage3 from '../assets/img/photos/why-the-childhood-image/3.png';

import ourValueImage1 from '../assets/img/photos/dive-into-project-image/3.png';
import ourValueImage2 from '../assets/img/photos/dive-into-project-image/4.png';

import ourValueSrcSetImage1 from '../assets/img/photos/dive-into-project-image/3.png';
import ourValueSrcSetImage2 from '../assets/img/photos/dive-into-project-image/4.png';

import partnerImage1 from '../assets/img/brands/c1.png';
import partnerImage2 from '../assets/img/brands/c2.png';
import partnerImage3 from '../assets/img/brands/c3.png';
import partnerImage4 from '../assets/img/brands/c4.png';
import partnerImage5 from '../assets/img/brands/c5.png';
import partnerImage6 from '../assets/img/brands/c6.png';

import weareProudImage from '../assets/img/photos/slider-image/2.png';
import weareProudSrcSetImage from '../assets/img/photos/slider-image/2.png';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Footer from "../layouts/Footer";
import Ticker from "../layouts/CounterUpVisibility";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "../assets/css/owl.css"

export default function EarlyChildhood() {

    const setting = JSON.parse(localStorage.getItem("setting"));
    const [showScroll, setShowScroll] = useState(false);

    let USER_TOKEN = Cookies.get("usr_token");
    let user = Cookies.get("user");
    const [Code, SetCode] = useState("");

    let MetaDetails = {
        'title': 'Early Childhood | ' + process.env.REACT_APP_NAME,
        'meta_title': 'Early Childhood | ' + process.env.REACT_APP_NAME,
        'meta_keywords': 'Mere Mentor Early Childhood',
        'meta_description': 'Early Childhood.',
    }

    let navigate = useNavigate();
    const list = [
        { name: "Home", url: "/" },
        { name: "Early Childhood", url: null },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <div>
            <Seo options={MetaDetails} />

            <HeaderComponent />

            <section className="wrapper bg-light">
                <div className="container">
                    <div className="container pt-11 pt-md-13 pb-11 pb-md-19 pb-lg-22 text-center">
                        <div className="row">
                            <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto">
                                <h1 className="display-1 fs-60 mb-4 px-md-15 px-lg-0"><span className="underline-3 style-3 orange">Early Childhood Care Education Project</span></h1>
                                <p className="lead fs-24 lh-sm mb-7 mx-md-13 mx-lg-10">Focusing on the foundation, development and implementation of the early childhood education curriculum in two anganwadis of Meghalaya</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-dark">
                <div className="container py-14 py-md-16">
                    <figure className="rounded mt-md-n21 mt-lg-n23 mb-14"><img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={introductionThumbNail} srcset={introductionimagesrcset} alt="" /></figure>

                    <div className="row">
                        <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                            <h3 className="display-4 text-white mb-10">The Structure of the ECCE Curriculum Framework comprises three sections</h3>
                        </div>
                    </div>
                    <div className="row gx-md-8 gy-8 text-center text-white" data-group={"services"}>
                        <div className="col-md-6 col-lg-4">
                            <div className="icon btn btn-circle btn-lg btn-primary disabled mb-5"> <i className="uil uil-phone-volume"></i> </div>
                            <h4 className="text-white">Foundation of Early Care and Learning</h4>
                            <p className="mb-3">The objectives, pedagogical bases, and the principles of early childhood education.</p>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="icon btn btn-circle btn-lg btn-primary disabled mb-5"> <i className="uil uil-shield-exclamation"></i> </div>
                            <h4 className="text-white">Goals of Early Care and Learning</h4>
                            <p className="mb-3">Comprises details of the goals for different domains of development to ensure holistic development of children under six years.</p>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="icon btn btn-circle btn-lg btn-primary disabled mb-5"> <i className="uil uil-laptop-cloud"></i> </div>
                            <h4 className="text-white">Programme Planning and Practices</h4>
                            <p className="mb-3">Focuses on implementing the principles of early education programme.</p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="wrapper bg-light">
                <div className="container py-14 py-md-17">
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center mb-14 mb-md-17">
                        <div className="col-lg-6 order-lg-2">
                            <div className="row gx-md-5 gy-5 position-relative">
                                <div className="col-5">
                                    <img style={{ width: '100%', height: 'auto' }} className="img-fluid rounded shadow-lg my-5 d-flex ms-auto" src={spendingStressImage1} srcset={spendingStressSrcImage1} alt="" />
                                    <img style={{ width: '100%', height: 'auto' }} className="img-fluid rounded shadow-lg d-flex col-10 ms-auto" src={spendingStressImage2} srcset={spendingStressSrcImage2} alt="" />
                                </div>
                                <div className="col-7">
                                    <img style={{ width: '100%', height: 'auto' }} className="img-fluid rounded shadow-lg mb-5" src={spendingStressImage3} srcset={spendingStressSrcImage3} alt="" />
                                    <img style={{ width: '100%', height: 'auto' }} className="img-fluid rounded shadow-lg d-flex col-11" src={spendingStressImage4} srcset={spendingStressSrcImage4} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="display-4 mb-5 text-center-sm">Strive to ensure opportunities for complete learning and growth.</h3>
                            <p className="mb-5">The curriculum is planned and framed to assure optimal development i.e. enabling kids to grow into blooming persons, for all children, in a way that,</p>
                            <div className="row gy-3">
                                <div className="col-xl-6">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span>Encompasses developmentally appropriate knowledge and skills.</span></li>
                                        <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Provide flexibility for contextualization and diverse needs of young children.</span></li>
                                    </ul>
                                </div>
                                <div className="col-xl-6">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span>Facilitates the adoption of a common pedagogical approach.</span></li>
                                        <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Address the widespread diversity available for the young children in India.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                        <div className="col-lg-6">
                            <div className="row gx-md-5 gy-5 position-relative align-items-center">
                                <div className="col-6">
                                    <img style={{ width: '100%', height: 'auto' }} className="img-fluid rounded shadow-lg d-flex ms-auto" src={financeImage1} srcset={financeSrcSetImage1} alt="" />
                                </div>
                                <div className="col-6">
                                    <img style={{ width: '100%', height: 'auto' }} className="img-fluid rounded shadow-lg mb-5" src={financeImage2} srcset={financeSrcSetImage2} alt="" />
                                    <img style={{ width: '100%', height: 'auto' }} className="img-fluid rounded shadow-lg d-flex col-10" src={financeImage3} srcset={financeSrcSetImage3} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="display-4 mb-5 text-center-sm">Promote quality and excellence in early childhood education for the best.</h3>
                            <p className="mb-5">The purpose of the early childhood education is to develop the emotional, social and cognitive skills of the children by,</p>
                            <div className="row gy-3">
                                <div className="col-xl-6">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span>Providing them with strategies to help them in the process.</span></li>
                                        <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Setting out the broad arrangement of approaches and the experiences.</span></li>
                                    </ul>
                                </div>
                                <div className="col-xl-6">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span>Providing guidelines for practices that would promote optimum learning and development</span></li>
                                        <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Respecting the diversity in the child-rearing practices.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-dark">
                <div className="container py-14 pt-md-12 pb-md-21">
                    <div className="row gx-lg-8 gx-xl-12 gy-5 gy-lg-0 mb-2 text-white align-items-center">
                        <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 mx-auto text-center d-none">
                            <h3 className="display-4 text-white mb-10">We are trusted by over 1000000+ students</h3>
                        </div>
                        <div className="col-lg-5">
                            <h3 className="display-4 text-white mb-3 pe-xxl-15 text-center-sm">We are trusted by over 1000000+ students</h3>
                            <p className="lead fs-lg mb-0 pe-xxl-10 text-center-sm">We aim to reduce the gap in the career selection process faced by parents and students.</p>
                        </div>
                        <div className="col-lg-7 mt-lg-2">
                            <div className="row align-items-center counter-wrapper gy-6 text-center">
                                <div className="col-md-4">
                                    <Ticker className="counter counter-lg text-white" end={100} suffix=" +" />
                                    <p>National, International Collaborations</p>
                                </div>
                                <div className="col-md-4">
                                    <Ticker className="counter counter-lg text-white" end={200} suffix=" +" />
                                    <p>Schools and colleges partnerships</p>
                                </div>
                                <div className="col-md-4">
                                    <Ticker className="counter counter-lg text-white" end={200} suffix=" +" />
                                    <p>expert counsellors</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wrapper bg-light">
                <div className="container py-16 py-md-18">
                    <div className="card shadow-lg mt-n18 mt-md-n23 mb-14 mb-md-18">
                        <div className="row gx-0">
                            <div className="col-lg-6">
                                <figure><img style={{ width: '100%', height: 'auto' }} src={weareProudImage} srcset={weareProudSrcSetImage} alt="" className="img-fluid border-radius-6" /></figure>
                            </div>
                            <div className="col-lg-6">
                                <div className="p-6 pt-md-17 pt-10">
                                    <div className="swiper-container dots-closer mb-6">
                                        <div className="swiper">
                                            <div className="swiper-wrapper">
                                                {/* <OwlCarousel
                                                        // items={1}
                                                        // className="carousel owl-carousel gap-small"
                                                        // autoplay={true}
                                                        // nav={false}
                                                        // dots={true}
                                                        // autoplayTimeout={1500}
                                                        // loop
                                                        // margin={30}
                                                         aria-label="Counsellor carousel"
                                                > */}

                                                <div class="swiper-slide">
                                                    <blockquote class="icon text-center">
                                                        <p>In Collaboration with Meghalaya Government, Mere Mentor's Early Childhood Care and Education project is aimed to develop a child's overall skills. The project helps in cognitive development among the kids and enhances their motor and creative skills.</p>
                                                        <div class="blockquote-details justify-content-center text-center">
                                                            <div class="info ps-0">
                                                                <h5 class="mb-1">Students of Rural Anganwadis of Meghalaya</h5>
                                                            </div>
                                                        </div>
                                                    </blockquote>
                                                </div>
                                                {/* </OwlCarousel> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row gx-lg-8 gx-xl-12 gy-10 mb-10 mb-md-12 align-items-center">
                        <div className="col-lg-6 order-lg-2">
                            <div className="row gx-md-5 gy-5 align-items-center">
                                <div className="col-md-6">
                                    <div className="row gx-md-5 gy-5">
                                        <div className="col-md-10 offset-md-2">
                                            <figure className="rounded"><img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={whoWeAreImage1} srcset={whoWeAreSrcSetImage1} alt="" /></figure>
                                        </div>
                                        <div className="col-md-12">
                                            <figure className="rounded"><img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={whoWeAreImage3} srcset={whoWeAreSrcSetImage3} alt="" /></figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <figure className="rounded"><img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={whoWeAreImage2} srcset={whoWeAreSrcSetImage2} alt="" /></figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="display-4 mb-3 text-center-sm">Why the early childhood care education project in Meghalaya?</h2>
                            <p className="mb-6">To make learning engaging and relevant for rural primary children of Meghalaya, who do not have access to such schools. To push them to question and explore for deep knowledge and learning.</p>
                            <div className="row gy-3 gx-xl-8">
                                <div className="col-xl-6">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span>The curriculum would introduce a fun, interactive and exciting way of learning.</span></li>
                                        <li className="mt-3"><span><i className="uil uil-check"></i></span><span>The curriculum would also provide a platform for rural students to cultivate student leadership and inculcate a spirit of community service.</span></li>
                                    </ul>
                                </div>
                                <div className="col-xl-6">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span>The curriculum would work to cut down the dropout rate for children and help enhance their performance in school examinations and tests.</span></li>
                                        <li className="mt-3"><span><i className="uil uil-check"></i></span><span>The curriculum would encourage them to follow their learning curve and explore their creative sides.</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-lg-8 gx-xl-12 gy-6 mb-14 mb-md-18">
                        <div className="col-lg-4">
                            <div className="d-flex flex-row">
                                <div>
                                    <div className="icon btn btn-circle disabled btn-soft-primary me-4"> <span className="number fs-18">1</span> </div>
                                </div>
                                <div>
                                    <h4>Vision</h4>
                                    <p className="mb-2">To develop a child’s overall skills and motivate early learning alongside ameliorating the studying environment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-row">
                                <div>
                                    <div className="icon btn btn-circle disabled btn-soft-primary me-4"> <span className="number fs-18">2</span> </div>
                                </div>
                                <div>
                                    <h4>Purpose</h4>
                                    <p className="mb-2">Reconstruct the existing two Anganwadi centres located at Dura Asim and Rangsakona Block into smart anganwadis in a 1700 sq. ft and remodelling the current curriculum.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-row">
                                <div>
                                    <div className="icon btn btn-circle disabled btn-soft-primary me-4"> <span className="number fs-18">3</span> </div>
                                </div>
                                <div>
                                    <h4>Mission</h4>
                                    <p className="mb-2">Bring in an advanced updated curriculum to meet the competitive standards; Train the Anganwadi workers; Orient parents' engagement in knowing their child’s progress.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-10 gx-lg-8 gx-xl-12 mb-10 mb-md-14 align-items-center">
                        <div className="col-lg-7 position-relative">
                            <div className="row gx-md-5 gy-5">
                                <div className="col-md-6">
                                    <figure className="rounded mt-md-10 position-relative"><img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={ourValueImage1} srcset={ourValueSrcSetImage1} alt="" /></figure>
                                </div>
                                <div className="col-md-6">
                                    <div className="row gx-md-5 gy-5">
                                        <div className="col-md-12 order-md-2">
                                            <figure className="rounded"><img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={ourValueImage2} srcset={ourValueSrcSetImage2} alt="" /></figure>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="card bg-pale-primary text-center">
                                                <div className="card-body py-11 counter-wrapper">
                                                    <h3 className="counter text-nowrap">5000+</h3>
                                                    <p className="mb-0">Satisfied Customers</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <h2 className="display-4 mb-3 text-center-sm">Dive into the project deliverables</h2>
                            <div className="col-xl-12">
                                <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                    <li><span><i className="uil uil-check"></i></span><span>Reconstructing the existing Anganwadis into Pilot smart Anganwadis.</span></li>
                                    <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Modifying the old infrastructure, revamping and maintaining two smart Anganwadis.</span></li>
                                    <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Remodelling the current curriculum.</span></li>
                                    <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Providing a range of activities and games, to make it easy for the children to grasp difficult concepts.</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="px-lg-5">
                        <div className="row gx-0 gx-md-8 gx-xl-12 gy-8 align-items-center">
                            <div className="col-4 col-md-2">
                                <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src={partnerImage1} alt="" /></figure>
                            </div>
                            <div className="col-4 col-md-2">
                                <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src={partnerImage2} alt="" /></figure>
                            </div>
                            <div className="col-4 col-md-2">
                                <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src={partnerImage3} alt="" /></figure>
                            </div>
                            <div className="col-4 col-md-2">
                                <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src={partnerImage4} alt="" /></figure>
                            </div>
                            <div className="col-4 col-md-2">
                                <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src={partnerImage5} alt="" /></figure>
                            </div>
                            <div className="col-4 col-md-2">
                                <figure className="px-5 px-md-0 px-lg-2 px-xl-3 px-xxl-4"><img src={partnerImage6} alt="" /></figure>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>

            <Footer />
            <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
        </div>
    );
}
