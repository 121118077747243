import GLightbox from "glightbox";
import Expert1 from "../assets/img/expert-explains/expert-explains-cover-1.jpg";
import Expert2 from "../assets/img/expert-explains/expert-explains-cover-2.jpg";
import Expert3 from "../assets/img/expert-explains/expert-explains-cover-3.jpg";
import Expert4 from "../assets/img/expert-explains/expert-explains-4.jpg";
import Expert5 from "../assets/img/expert-explains/Ankit.jpg";
import Expert6 from "../assets/img/expert-explains/Deepthi.jpg";
import Expert7 from "../assets/img/expert-explains/Navya.jpg";
import Expert8 from "../assets/img/expert-explains/Pooja.jpg";
import Expert9 from "../assets/img/expert-explains/Priya.jpg";
import Expert10 from "../assets/img/expert-explains/vatasta.jpg";
import Expert11 from "../assets/img/expert-explains/shrabana_das.jpg";
import Expert12 from "../assets/img/expert-explains/AMANDA-JOSEPH.jpg";
import Expert13 from "../assets/img/expert-explains/KEERTHANA-S.jpg";
import OwlCarousel from "react-owl-carousel";

function ExpertExplains() {
  const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
  });

  const options = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  }

  const careerVideo = (index) => {
    const myGallery = GLightbox({
      elements: [
        {
          href: "https://www.youtube.com/watch?v=49l9yjejdMc",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://www.youtube.com/watch?v=lu4Y7R8-XSA",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://www.youtube.com/watch?v=CXp8kgixvFk",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://www.youtube.com/watch?v=q6Vp60owvZE",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://www.youtube.com/watch?v=GzVqCBlffu4&feature=youtu.be&ab_channel=merementor",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://youtu.be/hbvmq_SEmeY",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://youtu.be/g5UQtJjGF7c",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
        {
          href: "https://youtu.be/ymLPyvJsxdA",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
      ],
      autoplayVideos: true,
    });
    myGallery.openAt(index);
  };

  return (
    <section className="wrapper bg-light">
      <div className="container pt-7 pt-md-10 pb-md-8 pb-1">
        <div className="row">
          <div className="col-lg-9 col-xl-8 col-xxl-7 mx-auto text-center">
            <h2 className="display-1 mb-10">Expert Talks</h2>
          </div>
        </div>
        <div className="container pt-5 pb-3">
          <div className="row">
            {/* <div className="col-md-4">
              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(0);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>

                  <img src={Expert1} alt="Expert Explain" />
                </figure>
              </div>
            </div>
            <div className="col-md-4">
              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(1);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                  <img src={Expert2} alt="Expert Explain" />
                </figure>
              </div>
            </div>
            <div className="col-md-4">
              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(2);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                  <img src={Expert3} alt="Expert Explain" />
                </figure>
              </div>
            </div>
            <div className="col-md-4">
              <div className="item">
                <figure className="rounded mb-6">
                  <button
                    className="btn btn-circle btn-primary btn-play ripple mx-auto mb-5 position-absolute"
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      zIndex: "3",
                    }}
                    onClick={(e) => {
                      careerVideo(3);
                    }}
                  >
                    <i className="icn-caret-right"></i>
                  </button>
                  <img src={Expert4} alt="" />
                </figure>
              </div>
            </div> */}
            <OwlCarousel
              items={4}
              className="carousel owl-carousel gap-small"
              autoplay={true}
              nav={false}
              dots={true}
              autoplayTimeout={5000}
              responsive={options}
              loop
              margin={30}
            >
              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(0);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>

                  <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={Expert1} alt="Expert Explain" />
                </figure>
              </div>
              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(1);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                  <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={Expert2} alt="Expert Explain" />
                </figure>
              </div>
              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(2);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                  <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={Expert3} alt="Expert Explain" />
                </figure>
              </div>

              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(4);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                  <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={Expert7} alt="Expert Explain" />
                </figure>
              </div>

              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(5);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                  <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={Expert10} alt="Expert Explain" />
                </figure>
              </div>

              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(6);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                  <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={Expert12} alt="Expert Explain" />
                </figure>
              </div>

              <div className="item">
                <figure className="rounded mb-6">
                  <div className="video-wave-wrapper">
                    <div className="video-main">
                      <div className="promo-video">
                        <div className="waves-block">
                          <div className="waves wave-1"></div>
                          <div className="waves wave-2"></div>
                          <div className="waves wave-3"></div>
                        </div>
                      </div>
                      <button
                        className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                        style={{
                          top: "60%",
                          left: "50%",
                          transform: "translate(-25px,10px)",
                          zIndex: "3",
                        }}
                        onClick={(e) => {
                          careerVideo(7);
                        }}
                      >
                        <i className="icn-caret-right"></i>
                      </button>
                    </div>
                  </div>
                  <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={Expert13} alt="Expert Explain" />
                </figure>
              </div>

            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ExpertExplains;
