import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { Form, Button, Modal, Alert } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getProductsListAPI,
  createOrderAPI,
  updateOrderAPI,
  checkCouponAPI,
} from "../Service";
import useRazorpay from "react-razorpay";
import Cookies from "js-cookie";
import AlertComponent from "../layouts/Alert";
import BackToTop from "../layouts/BackToTop";
import ModalAlert from "../layouts/ModalAlert";

function ProductDetail() {
  const [showScroll, setShowScroll] = useState(false);
  let { slug } = useParams();
  const [Pricing, SetPricing] = useState([]);
  let setting = JSON.parse(localStorage.getItem("setting"));
  let navigate = useNavigate();
  let USER_TOKEN = Cookies.get("usr_token");
  const Razorpay = useRazorpay();
  let user = Cookies.get("user");
  if (user !== undefined) {
    user = JSON.parse(user);
  }
  const [ProductName, SetProductName] = useState("");
  const [PaymentButton, SetPaymentButton] = useState(false);
  const [SuccessModal, SetSuccessModal] = useState(false);
  const [FailedModal, SetFailedModal] = useState(false);
  const [refresh, SetRefresh] = useState(false);
  const [Detail, SetDetail] = useState([]);
  const [DetailCount, SetDetailCount] = useState();
  const [checkOut, SetCheckOut] = useState(false);
  const [product, SetProduct] = useState({});
  const [coupoun, SetCoupoun] = useState("");
  const [CouponAmount, SetCouponAmount] = useState(0);
  const [Err, SetErr] = useState([]);
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);

  const [ModalAlertDisplay, SetModalModalAlertDisplay] = useState(false);
  const [ModalAlertMessage, SetModalAlertMessage] = useState([]);
  const [ModalAlertButton, SetModalAlertButton] = useState("");
  const [ModalAlertSlug, SetModalAlertSlug] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // if (USER_TOKEN !== undefined) {
      fetchProductList(slug);
    // } else {
      // navigate("/products?is_login=true&is_message=Please login to make subscription");
    // }
  }, [slug, refresh]);

  const fetchProductList = async (slug) => {
    let res = await getProductsListAPI(slug, USER_TOKEN);
    if (res?.is_success === true) {
      SetProductName(res.products.name);
      SetPricing(res?.products?.packages);
      let arr = [];
      let check = false;
      if (res.products.packages.length > 0) {
        res.products.packages.map((x, i) => {

          let button_label = "Choose Plan";

          if (x.amount === "0.00") {
            button_label = 'Take a Demo';
          }

          if (x.issubscribed) {
            button_label = "Subscribed";
            check = true;
          }

          if (check) {
            button_label = "Upgrade Now";
          }
          let decs = x?.description.replaceAll(
            "TICK",
            '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="#4caf50" fill-rule="evenodd" d="M0 7.5a7.5 7.5 0 1 1 15 0a7.5 7.5 0 0 1-15 0Zm7.072 3.21l4.318-5.398l-.78-.624l-3.682 4.601L4.32 7.116l-.64.768l3.392 2.827Z" clip-rule="evenodd"/></svg>'
          );
          decs = decs.replaceAll(
            "CROSS",
            '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#f44337" d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8l-1.4 1.4z"/></svg>'
          );
          let data = {
            id: x?.id,
            amount: x?.amount,
            code: x?.code,
            description: decs,
            discount: x?.discount,
            discount_description: x?.discount_description,
            issubscribed: x?.issubscribed,
            title: x?.title,
            total: x?.total,
            button_label: button_label,
            discount_type: x?.discount_type,
            validity_days: x?.validity_days,
            is_free: x?.is_free,
            is_active: x?.is_active,
            sub_title: x?.sub_title,
            color: x?.color
          };
          arr.push(data);
        });
      }
      SetDetail(arr);
      SetDetailCount(arr.length);
    } else {
      if (res?.status === 401) {
        let message = res?.errors[0];
        navigate(`/products?is_messages=${message}&button=${res?.button}&slug=${res?.slug}`);
        // SetModalModalAlertDisplay(true);
        // SetModalAlertMessage([res?.errors]);
        // SetModalAlertButton(res?.button);
        // SetModalAlertSlug(res?.slug)
      }else{
        navigate("/products");
      }
    }
  };

  const checkout = (e, plan) => {
    if (USER_TOKEN !== undefined) {
    SetPaymentButton(true);
    SetProduct(plan);
    SetCheckOut(true);
    } else {
      navigate("/products?is_login=true&is_message=Please login to make subscription");
    }
  };

  const handlePayment = async (e, product) => {
    SetCheckOut(false);
    let data = {
      package_id: product.id,
      total: product.total,
      coupoun: coupoun,
    };
    let order = await createOrderAPI(data, USER_TOKEN);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (order?.is_success === true) {
      let logo = process.env.REACT_APP_BASE_URL + setting?.logo;
      let total = product.total;
      let title = product.title;
      if (order?.is_razorpay) {
        const options = {
          key: process.env.RAZORPAY_KEY,
          amount: total,
          currency: "INR",
          name: title,
          description: "Visible Horizon Edu Pvt.Ltd",
          image: logo,
          order_id: order.razorpay_order_id,
          handler: async (res) => {
            let datas = {
              package_id: product.id,
              razorpay_order_id: res?.razorpay_order_id,
              razorpay_payment_id: res?.razorpay_payment_id,
              razorpay_signature: res?.razorpay_signature,
              order_id: order?.orderId,
              type: "paid",
            };
            let response = await updateOrderAPI(datas, USER_TOKEN);
            if (response?.is_success === true) {
              SetSuccessModal(true);
              setTimeout(() => {
                navigate(`/assessments/${slug}`);
              }, 3000)
            } else {
              SetFailedModal(true);
            }
          },
          prefill: {
            name: user?.name,
            email: user?.email,
            contact: user?.phone,
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzpay = new Razorpay(options);
        rzpay.on("payment.failed", function (response) {
          console(response.error.code);
          console(response.error.description);
          console(response.error.source);
          console(response.error.step);
          console(response.error.reason);
          console(response.error.metadata.order_id);
          console(response.error.metadata.payment_id);
        });
        rzpay.open();
      } else {
        var couponAppliedTotalValueZero = false;
        if (order?.coupon_applied_total_value_zero) {
          couponAppliedTotalValueZero = order?.coupon_applied_total_value_zero;
        }
        let datas = {
          package_id: product.id,
          order_id: order?.orderId,
          type: "free",
          isPaidValue: couponAppliedTotalValueZero
        };
        let response = await updateOrderAPI(datas, USER_TOKEN);
        if (response?.is_success === true) {
          SetSuccessModal(true);
          setTimeout(() => {
            navigate(`/assessments/${slug}`);
          }, 3000)
        } else {
          SetFailedModal(true);
        }
      }
    } else {
      e.target.removeAttribute("disabled");
      e.target.innerHTML = "Choose Plan";
      SetAlertDisplay(true);
      SetAlertMessage(order?.errors);
    }
  };

  const checkCoupon = async (e, product) => {
    let data = {
      coupoun: coupoun,
      slug: slug,
    };
    let res = await checkCouponAPI(USER_TOKEN, data);
    if (res?.is_success) {
      let amount = 0;
      if (res?.coupon?.discount_type === "2") {
        SetCouponAmount(res?.coupon?.coupon_amount);
      } else if (res?.coupon?.discount_type === "1") {
        let discount = res?.coupon?.coupon_amount;//100.00
        amount = Math.round(product?.total * discount / 100);
        SetCouponAmount(amount);
      }
      SetErr([]);
    } else {
      SetErr(res?.errors);
    }
  };

  if (DetailCount == '2') {
    var percentage = "15%";
  } else {
    var percentage = "0%";
  }
  return (
    <div>
      <HeaderComponent />
      <section className="wrapper bg-soft-primary">
        <div className="container pt-10 pb-20 pt-md-14 pb-md-22 text-center">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <h1 className="display-1 mb-3">{ProductName}</h1>
              <p className="lead mb-0 px-xl-10 px-xxl-13">
                Packages
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper">
        <div className="container pb-14 pb-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "2rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <AlertComponent
              AlertDisplay={AlertDisplay}
              SetAlertDisplay={SetAlertDisplay}
              AlertMessage={AlertMessage}
            />
            <div className="row justify-content-center gy-6 mt-3 mt-md-5">
              {Detail.map((x, i) => (
                <div className="col-md-6 col-lg-4" key={i} style={{ position: "relative" }}>
                  <div className="pricing card text-center" style={{ minHeight: "1010px" }}>
                    <div className="card-body">
                      <Icon
                        icon={x?.code}
                        color={x?.color}
                        width="100"
                        height="100"
                      />
                      <h1 className="mt-3 price-title">{x?.title}</h1>
                      <h5 className="mt-3">{x?.sub_title}</h5>

                      <div className="row">
                        {x.discount && (
                          <s>
                            <span className="h3 text-red">
                              &#8377; {x.amount}
                            </span>
                          </s>
                        )}
                      </div>

                      <div className="prices text-dark mb-3">
                        <div className="price price-show d-inline-block text-center">
                          <span className="price-currency">&#8377;.</span>
                          <span className="price-value ">
                            {x.total !== "0.00"
                              ? parseInt(x.total)
                              : 0}
                          </span>{" "}
                          {/*  <span className="price-duration">month</span> */}
                        </div>
                      </div>

                      {/*  <ul className="icon-list bullet-bg bullet-soft-primary mt-8 mb-9 text-start">
                        <li>
                          <i className="uil uil-check"></i>
                          <span>
                            <strong>1</strong> Project{" "}
                          </span>
                        </li>
                        <li>
                          <i className="uil uil-times bullet-soft-red"></i>
                          <span>
                            {" "}
                            Weekly <strong>Reports</strong>{" "}
                          </span>
                        </li>
                      </ul>
 */}
                      {x?.description && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: x?.description,
                          }}
                        />
                      )}

                      {x?.issubscribed ? (
                        <Button
                          variant="success"
                          className="btn text-white rounded-pill"
                          size="lg"
                          disabled={true}
                        >
                          Subscribed
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          className="btn btn-primary rounded-pill"
                          size="lg"
                          onClick={(e) => checkout(e, x)}
                          data-button-id={x.id}
                          disabled={false}
                        >
                          {x?.button_label}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={FailedModal}
        backdrop="static"
        onHide={() => SetFailedModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <h4>Error Message</h4>
        </Modal.Header>
        <Modal.Body>
          <h3 className="text-center">
            <Icon
              icon="eva:close-circle-outline"
              color="black"
              width="50"
              height="50"
            />{" "}
            Something went wrong
          </h3>
          <br />
        </Modal.Body>
      </Modal>

      <Modal
        show={SuccessModal}
        backdrop="static"
        onHide={() => {
          SetSuccessModal(false);
          SetRefresh(true);
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-bold" closeButton>
          <h4>Message</h4>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center">
            <Icon icon="icons8:checked" color="black" width="50" height="50" />{" "}
            Subscribed Successfully
          </h4>
        </Modal.Body>
      </Modal>

      <Modal
        show={checkOut}
        onHide={(e) => {
          SetCheckOut(false);
          SetCouponAmount(0);
          SetCoupoun("");
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {slug.toUpperCase().replaceAll("-", " ")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped table-bordered table-hover">
            <tbody>
              <tr>
                <th>Plan</th>
                <td>{product?.title}</td>
              </tr>
              <tr>
                <th>Subtotal</th>
                <td>&#8377; {product?.amount}</td>
              </tr>
              <tr>
                <th>Discount</th>
                <td>
                  {product?.discount_type === 1 ? "₹ " : null}{" "}
                  {product?.discount ? parseInt(product?.discount) : "0"}
                  {product?.discount_type === 2 ? " %" : ""}{" "}
                </td>
              </tr>
              <tr>
                <th>Coupon Amount</th>
                <td>&#8377; {CouponAmount}</td>
              </tr>
              <tr>
                <th>Total</th>
                <td>&#8377; {Math.round(product?.total - CouponAmount)}</td>
              </tr>
              {product?.total !== "0.00" && (
                <tr>
                  <td colSpan="2">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Coupon Code"
                        onChange={(e) => {
                          SetCoupoun(e.target.value);
                        }}
                        value={coupoun}
                      />
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={(e) => {
                          checkCoupon(e, product);
                        }}
                      >
                        Apply
                      </button>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={(e) => {
                          SetCoupoun("");
                          SetCouponAmount(0);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                    {Err.map((x, i) => (
                      <p key={i}>{x}</p>
                    ))}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row p-2">
            <button
              className="btn btn-xs btn-primary"
              onClick={(e) => {
                handlePayment(e, product, coupoun);
              }}
            >
              Continue Payment
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
      <ModalAlert
        ModalAlertDisplay={ModalAlertDisplay}
        SetModalModalAlertDisplay={SetModalModalAlertDisplay}
        ModalAlertMessage={ModalAlertMessage}
        ModalAlertButton={ModalAlertButton}
        ModalAlertSlug={ModalAlertSlug}
        backdropEvent="static"
      />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default ProductDetail;
