import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { useEffect } from "react";
// import FourNotFourImage from "../assets/img/illustrations/404.png";
import { Link } from "react-router-dom";

function ThankYou() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  });

  return (
    <>
      <HeaderComponent />
        <section className="wrapper bg-light">
          <div className="container pt-12 pt-md-14 pb-14 pb-md-16">
            <div className="row">
              {/* <div className="col-lg-9 col-xl-8 mx-auto">
                <h1>Thank You</h1>
                <figure className="mb-10"><img className="img-fluid" src={FourNotFourImage} alt="404" /></figure>
              </div> */}

              <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                <h1>Thank You, Our team will connect with you shortly.</h1>
                <Link to="/" className="btn btn-primary rounded-pill">Go to Homepage</Link>
              </div>
            </div>
          </div>
        </section>
      <Footer />
    </>
  )
}

export default ThankYou;