import { Helmet } from "react-helmet";
import { useState } from "react";
function Seo({ options }) {
  const setting = JSON.parse(localStorage.getItem("setting"));

  return (




    <div>
      <Helmet htmlAttributes>
        <title>{options?.title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content={`${options?.meta_title}`} />
        <meta name="description" content={options?.meta_description} />
        <meta name="keywords" content={options?.meta_keywords} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={options?.title} />
        <meta property="og:description" content={options?.meta_description} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content={options?.title} />
        <meta
          property="og:image"
          content={process.env.REACT_APP_BASE_URL + setting?.logo}
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content={options?.meta_description} />
        <meta name="twitter:title" content={options?.meta_title} />
        <meta name="twitter:description" content={options?.meta_description} />
        <meta
          name="twitter:image"
          content={process.env.REACT_APP_BASE_URL + setting?.logo}
        />
      {(options?.ldJson !== null) && (
 <script type="application/ld+json">{JSON.stringify(options.ldJson)}</script>
      )}
       
      </Helmet>
    </div>
  );
}

export default Seo;
