import HeaderComponent from "../../layouts/HeaderComponent";
import Footer from "../../layouts/Footer";
import BreadCumb from "../../layouts/BreadCumb";
import Sidebar from "../../layouts/Sidebar";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Message from "../../layouts/Message";
import { Button, Spinner } from "react-bootstrap";
import AlertComponent from "../../layouts/Alert";
import * as Icon from "react-feather";
import validator from "validator";
import { UpdatePasswordAPI } from '../../Service';
import BackToTop from "../../layouts/BackToTop"
import Seo from "../../layouts/SEO";

function ChangePassword() {
  const list = [
    { name: "Home", url: "/" },
    { name: "Change Password", url: null },
  ];
  let USER_TOKEN = Cookies.get("usr_token");
  let user = JSON.parse(Cookies.get("user"));
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [submitButton, SetSubmitButton] = useState(false);
  const [AlertDisplay, SetAlertDisplay] = useState(false);
  const [AlertMessage, SetAlertMessage] = useState([]);
  const [password, SetPassword] = useState("");
  const [confirmPassword, SetConfirmPassword] = useState("");
  const [MessageDisplay, SetMessageDisplay] = useState(false);
  const [MessageContent, SetMessageContent] = useState([]);
  const [showScroll, setShowScroll] = useState(false);
  let MetaDetails = {
    'title': 'Change Password | ' + process.env.REACT_APP_NAME,
    'meta_title': 'Change Password | ' + process.env.REACT_APP_NAME,
    'meta_keywords': 'Change Password Mere Mentor',
    'meta_description': 'Mere Mentor is an EdTech organization delivering career counselling services to the students and youth of the country.',
  }
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (USER_TOKEN === null) {
      SetAlertDisplay(true);
      SetAlertMessage([]);
    }
  }, []);

  const updatePassword = async (e) => {
    let data = {
      password: password,
      confirm_password: confirmPassword,
      access_token: USER_TOKEN,
    };
    let err = [];
    if(password==null || password=='null' || password==''){
      err.push("Password field is required");
    }else if (!validator.isLength(password, { min: 8, max: 255 })) {
      err.push("Password should have a minumum 8 characters.");
    }
    if (!validator.equals(confirmPassword, password)) {
      err.push("The passwords don't match");
    }

    if (err.length > 0) {
      SetAlertDisplay(true);
      SetAlertMessage(err);
      setTimeout(() => {
        SetAlertDisplay(false);
        }, 3000);
        
    } else {
      let res = await UpdatePasswordAPI(data);
      if (res?.is_success === true) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        SetMessageDisplay(true);
        SetMessageContent(res?.message);
        SetPassword('');
        SetConfirmPassword('')
      } else {
        SetAlertDisplay(true);
        SetAlertMessage(res?.errors ?? []);
      }
    }
  };

  return (
    <div>
      <HeaderComponent />
      <Seo options={MetaDetails} />
      <BreadCumb name={"Change Password"} list={list} />
      <section className="myprofile-section ptb-50">
        <div className="container">
          <div className="pricing-wrapper position-relative mt-n18 mb-12 mb-md-15">
            <div
              className="shape bg-dot primary rellax w-16 h-18"
              data-rellax-speed="1"
              style={{ top: "-4rem", right: "-2.4rem" }}
            ></div>
            <div
              className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed="1"
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            ></div>
            <div className="row justify-content-between main-area">
              <div className="col-lg-3">
                <div className="myprofile-sidebar rounded-3">
                  <div className="card">
                    <Sidebar />
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="myprofile-content rounded-3 p-5">
                  <div className="dashboard-quick-widget">
                    <h4 className="mt-4">Change Password</h4>
                    <AlertComponent
                      AlertDisplay={AlertDisplay}
                      SetAlertDisplay={SetAlertDisplay}
                      AlertMessage={AlertMessage}
                    />
                    <div className="form-floating mb-4">
                      <div className="input-group mb-3">
                        <input
                          type={isRevealPwd ? "text" : "password"}
                          className="form-control"
                          placeholder="New Password"
                          onChange={(e) => {
                            SetPassword(e.target.value);
                          }}
                          value={password}
                          id="loginPassword"
                        />
                        <span
                          className="input-group-text"
                          onClick={() => {
                            setIsRevealPwd((prevState) => !prevState);
                          }}
                        >
                          {isRevealPwd ? <Icon.EyeOff /> : <Icon.Eye />}
                        </span>

                      </div>
                    </div>
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Confirm Password"
                        onChange={(e) => {
                          SetConfirmPassword(e.target.value);
                        }}
                        value={confirmPassword}
                      />
                      <label>Confirm Password</label>
                    </div>

                    <div className="form-row mt-2">
                      <div className="form-group col-md-12 offset-5">
                        <Button
                          className="btn btn-primary rounded-pill btn-send mb-3"
                          disabled={submitButton}
                          onClick={(e) => {
                            updatePassword(e);
                          }}
                        >
                          {submitButton ? (
                            <div>
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />{" "}
                              Loading...
                            </div>
                          ) : (
                            <div>Save</div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Toast */}
      <Message
        MessageDisplay={MessageDisplay}
        MessageContent={MessageContent}
        SetMessageDisplay={SetMessageDisplay}
      />
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default ChangePassword;
