import HeaderComponent from "../layouts/HeaderComponent";
import Footer from "../layouts/Footer";
import { useEffect } from "react";
import { BlogDetailAPI } from "../Service";
import { useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import BreadCumb from "../layouts/BreadCumb";
import Moment from "react-moment";
import "moment-timezone";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
import Expert3 from "../assets/img/expert-explains/expert-explains-cover-3.jpg";
import OnlineRegModal from '../layouts/onlineReg';

function BlogDetail() {
  const [showScroll, setShowScroll] = useState(false);
  const setting = JSON.parse(localStorage.getItem("setting"));
  const [MetaDetails, SetMetaDetails] = useState({
    title: "",
    meta_title: "",
    meta_keywords: "",
    meta_description: "",
    ldJson: {},
  });
  let navigate = new useNavigate();
  let { slug } = useParams();
  const location = useLocation();
  const [detail, SetDetail] = useState({});
  const [backgroundImage, SetbackgroundImage] = useState();
  const [popularPosts, SetPopularPosts] = useState([]);
  const [prevPost, SetPrevPost] = useState({});
  const [nextPost, SetNextPost] = useState({});
  const [faqs, SetFaqs] = useState([]);
  const list = [
    { name: "Home", url: "/" },
    { name: "Blog", url: "/blog" },
  ];

  useEffect(() => {
    document.querySelectorAll(".accordion-button").forEach(elements => {
      if (!elements.classList.contains('collapsed')) {
        elements.click();
      }
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });
    fetchBlogDetail();
    setTimeout(() => {
      setShowOnlineReg(true)
    }, 90000);
  }, [slug]);

  const fetchBlogDetail = async () => {
    let res = await BlogDetailAPI(slug);
    if (res?.is_success === true) {
      SetDetail(res.blog);
      SetbackgroundImage(process.env.REACT_APP_BASE_URL + res.blog.image);
      SetPrevPost(res.prev_blog);
      SetNextPost(res.next_blog);
      SetPopularPosts(res.popular_posts);
      SetFaqs(res?.blog?.faqs);
      let entity = [];
      res.blog.faqs.map((x, i) => {
        let faq = {
          "@type": "Question",
          name: x.question,
          acceptedAnswer: {
            "@type": "Answer",
            text: x.answer,
          },
        };
        entity.push(faq);
      });
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: entity,
      };
      SetMetaDetails({
        title: res?.blog?.meta_title,
        meta_title: res?.blog?.meta_title,
        meta_keywords: res?.blog?.meta_tags,
        meta_description: res?.blog?.meta_description,
        ldJson: entity.length > 0 ? data : null,
      });

    } else {
      navigate("/404");
    }
  };

  const handleClose = () => setShow(false);
  const [showOnlineReg, setShowOnlineReg] = useState(false);
  const [show, setShow] = useState(false);

  const getRelatedBlogs = (related_blog) => {
    navigate({
      pathname: '/blog',
      search: `?tag=${related_blog}`,
    })
  }

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderComponent />

      <BreadCumb name={detail?.title} list={list} image={backgroundImage} />
      <section className="wrapper bg-light">
        <OnlineRegModal show={showOnlineReg} hide={() => setShowOnlineReg(false)} />
        <div className="container py-14 py-md-5">
          <div className="row gx-lg-8 gx-xl-12 mt-n18">
            <div className="col-lg-8">
              <div className="post-footer flex-md-row justify-content-md-between align-items-center mt-8 d-none">
                <div className="mb-0 mb-md-2">
                  {prevPost?.slug && (
                    <Link
                      to={`/blog/${prevPost.slug}`}
                      className="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-start mb-0 me-1"
                    >
                      <i className="uil uil-arrow-left"></i> Prev
                      Post
                    </Link>
                  )}
                </div>
                <div className="mb-0 mb-md-2">
                  {nextPost?.slug && (
                    <Link
                      to={`/blog/${nextPost.slug}`}
                      className="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-end mb-0"
                    >
                      Next Post{" "}
                      <i className="uil uil-arrow-right"></i>
                    </Link>
                  )}
                </div>
              </div>
              <div className="blog single">
                <div className="card">
                  <figure className="card-img-top">
                    <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"
                      src={process.env.REACT_APP_BASE_URL + detail?.image}
                      alt={detail?.title}
                    />
                  </figure>
                  <div className="card-body">
                    <div className="classic-view">
                      <article className="post">
                        <div className="post-content mb-5">
                          {detail.description && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: detail.description,
                              }}
                            />
                          )}
                        </div>
                        <hr />
                        {faqs.length > 0 && (
                          <div>
                            <h2 className="text-center">
                              Frequently Asked Questions
                            </h2>
                            <div
                              id="accordion-3"
                              className="accordion-wrapper"
                            >
                              {faqs.map((x, i) => (
                                <Accordion
                                  defaultActiveKey={i}
                                  className="card accordion-item"
                                  key={i}
                                >
                                  <Accordion.Item
                                    eventKey="0"
                                    className="mt-1"
                                  >
                                    <Accordion.Header className="card-header">
                                      {x.question}
                                    </Accordion.Header>
                                    <Accordion.Body className="card-body">
                                      {x.answer}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              ))}
                            </div>
                            <hr />
                          </div>
                        )}

                        <div className="post-footer d-md-flex flex-md-row justify-content-md-between align-items-center mt-8">
                          <div className="flex-start">
                            {prevPost?.slug && (
                              <Link
                                to={`/blog/${prevPost.slug}`}
                                className="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-start mb-0 me-1"
                              >
                                <i className="uil uil-arrow-left"></i> Prev
                                Post
                              </Link>
                            )}
                          </div>
                          <div className="mb-0 mb-md-2">
                            {nextPost?.slug && (
                              <Link
                                to={`/blog/${nextPost.slug}`}
                                className="btn btn-sm btn-soft-ash rounded-pill btn-icon btn-icon-end mb-0 mt-2"
                              >
                                Next Post{" "}
                                <i className="uil uil-arrow-right"></i>
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="post-footer d-md-flex flex-md-row justify-content-center align-items-center mt-8">
                          <div>
                            <ul className="list-unstyled tag-list mb-0">
                              {detail?.blog_tags && (
                                <div>
                                  {detail?.blog_tags.map((x, i) => (
                                    <li key={i}>
                                      <Link className="btn btn-soft-ash btn-sm rounded-pill mb-0" to={`/blog?tag=${x?.tag?.slug}`}>
                                        {x?.tag?.name}
                                      </Link>
                                      {/* <span className="btn btn-soft-ash btn-sm rounded-pill mb-0" 
                                      onClick={(e) => getRelatedBlogs(x?.tag?.slug)}>
                                        #{x?.tag?.name}
                                      </span> */}
                                    </li>
                                  ))}
                                </div>
                              )}
                            </ul>
                          </div>
                          <div className="mb-0 mb-md-2">
                            <div className="dropdown share-dropdown btn-group">
                              <button
                                className="btn btn-sm btn-red rounded-pill btn-icon btn-icon-start dropdown-toggle mb-0 me-0"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="uil uil-share-alt"></i> Share{" "}
                              </button>
                              <div className="dropdown-menu">
                                <button className="dropdown-item">
                                  <TwitterShareButton
                                    url={
                                      process.env.REACT_APP_WEBSITE_URL +
                                      window.location.pathname
                                    }
                                    quote={"facebook"}
                                    className=""
                                  >
                                    <i className="uil uil-twitter"></i>Twitter
                                  </TwitterShareButton>
                                </button>
                                <button className="dropdown-item">
                                  <FacebookShareButton
                                    url={
                                      process.env.REACT_APP_WEBSITE_URL +
                                      window.location.pathname
                                    }
                                    quote={"facebook"}
                                    className=""
                                  >
                                    <i className="uil uil-facebook"></i>{" "}
                                    Facebook
                                  </FacebookShareButton>
                                </button>
                                {/*  <button className="dropdown-item">
                                  
                                  <LinkedinShareButton
                                    url={
                                      process.env.REACT_APP_WEBSITE_URL +
                                      window.location.pathname
                                    }
                                    quote={"LinkedinShareButton"}
                                    className=""
                                  >
                                      <i className="uil uil-linkedin"></i>Linkedin
                                  </LinkedinShareButton>
                                </button> */}
                                <button className="dropdown-item">
                                  <WhatsappShareButton
                                    url={
                                      process.env.REACT_APP_WEBSITE_URL +
                                      window.location.pathname
                                    }
                                    quote={"WhatsappShareButton"}
                                    className=""
                                  >
                                    <i className="uil uil-whatsapp"></i>{" "}
                                    Whatsapp
                                  </WhatsappShareButton>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <aside className="col-lg-4 sidebar">
              <div className="card p-5 widget">
                <h4 className="widget-title mb-3">Popular Posts</h4>
                <ul className="image-list">
                  {popularPosts.map((x, i) => (
                    <li key={i}>
                      <figure className="rounded">
                        <Link to={`/blog/${x.slug}`}>
                          <img style={{ width: '100%', height: 'auto' }}  className="img-fluid"
                            src={process.env.REACT_APP_BASE_URL + x?.image}
                            alt={x.title}
                          />
                        </Link>
                      </figure>
                      <div className="post-content">
                        <h6 className="mb-2">
                          <Link className="link-dark" to={`/blog/${x.slug}`}>
                            {x.title}
                          </Link>{" "}
                        </h6>
                        <ul className="post-meta">
                          <li className="post-date">
                            <i className="uil uil-calendar-alt"></i>
                            <span>
                              <Moment tz="Asia/Kolkata" format="D MMM YYYY">
                                {x.created_at}
                              </Moment>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </section>
      <Footer />
      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />
    </div>
  );
}

export default BlogDetail;
