import React, { Suspense } from 'react';
import HeaderHomeComponent from "../layouts/HeaderHomeComponent";
import Footer from "../layouts/Footer";
import { useState, useEffect } from "react";
import axios from "axios";
import GLightbox from "glightbox";
import Partner from "../layouts/Partner";
import Quickinfo from "../layouts/QuickInfo";
import ExpertExplains from "../layouts/ExpertExplains";
import HomeBanner from "../layouts/HomeBanner";
import Services from "../layouts/Services";
import Motivated from "../layouts/Motivated";
import whyusimage from "../assets/img/why-merementor.png";
import BackToTop from "../layouts/BackToTop";
import Seo from "../layouts/SEO";
import OnlineRegModal from '../layouts/onlineReg';
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import ExpertsHelper from "../layouts/ExpertsHelper";
import { Helmet } from 'react-helmet';


function Home() {
  const CounsellorHelper = React.lazy(() => import('../layouts/CounsellorHelper'));
  const Ccnd = React.lazy(() => import('../layouts/Ccnd'));
  const USP = React.lazy(() => import('../layouts/USP'));
  const ParentReview = React.lazy(() => import('../layouts/ParentReview'));
  const OurJournal = React.lazy(() => import('../layouts/OurJournal'));
  const Collaborations = React.lazy(() => import('../layouts/Collaborations'));
  const setting = JSON.parse(localStorage.getItem("setting"));
  const [partners, SetPartners] = useState([]);
  const [collabator, SetCollabator] = useState([]);
  const [showEarlyEducation, setshowEarlyEducation] = useState(false);
  const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
  });
  const [showScroll, setShowScroll] = useState(false);
  let MetaDetails = {
    title: "Best Career Counselling Companies in India | Mere Mentor",
    meta_title: setting?.meta_title,
    meta_keywords: setting?.meta_keywords,
    meta_description: setting?.meta_description,
  };

  let [searchParams, setSearchParams] = useSearchParams();
  let is_login = searchParams.get("is_login");
  let is_message = searchParams.get("is_message");
  let check_login = is_login ? true : false;
  const [login, SetLogin] = useState(check_login);
  let USER_TOKEN = Cookies.get("usr_token");
  let usr = Cookies.get("user");
  console.log('////////////', usr, USER_TOKEN);

  useEffect(() => {
    fetchPartners();
    if (USER_TOKEN == undefined) {
      setTimeout(() => {
        setShowOnlineReg(true)
      }, 90000);
    }
    const noscriptElement = document.createElement('noscript');
    const iframeElement = document.createElement('iframe');
    iframeElement.src = 'https://www.googletagmanager.com/ns.html?id=GTM-PB74DW7P';
    iframeElement.height = '0';
    iframeElement.width = '0';
    iframeElement.style.display = 'none';
    iframeElement.style.visibility = 'hidden';
    noscriptElement.appendChild(iframeElement);
    document.body.appendChild(noscriptElement);
  }, []);

  const handleClose = () => setShow(false);
  const [showOnlineReg, setShowOnlineReg] = useState(false);
  const [show, setShow] = useState(false);

  const fetchPartners = async () => {
    await axios.get("partners").then((res) => {
      let partner_arr = [];
      let collabator_arr = [];

      res.data.partners.map((x) => {
        if (x.category === 1) {
          partner_arr.push(x);
        } else {
          collabator_arr.push(x);
        }
      });
      SetPartners(partner_arr);
      SetCollabator(collabator_arr);
    });
  };

  const careerVideo = () => {
    const myGallery = GLightbox({
      elements: [
        {
          href: "https://www.youtube.com/watch?v=LnLcQyrFG0c&",
          type: "video",
          source: "youtube", //vimeo, youtube or local
          width: 900,
        },
      ],
      autoplayVideos: true,
    });
    myGallery.open();
  };

  return (
    <div>
      <Seo options={MetaDetails} />
      <HeaderHomeComponent />

      {/* Banner */}
      <HomeBanner />

      {/* Partner */}
      <Partner partners={partners} />

      {/* Services */}
      <Services
        showEarlyEducation={showEarlyEducation}
        setshowEarlyEducation={setshowEarlyEducation}
      />

      {/* Why Us */}
      <section className="wrapper bg-light">
        <OnlineRegModal show={showOnlineReg} hide={() => setShowOnlineReg(false)} />
        <div className="container">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-18 align-items-center whyus-section">
            <div className="col-md-8 col-lg-6 position-relative">
              <h2 className="display-1 mb-3 text-center-sm visible-sm mt-10">
                Why Mere Mentor
                <br />
                for your Career?
              </h2>
              <p className="lead fs-lg text-center-sm visible-sm">Explore your career clusters with us</p>
              <div className="video-wave-wrapper">
                <div className="video-main">
                  <div className="promo-video">
                    <div className="waves-block">
                      <div className="waves wave-1"></div>
                      <div className="waves wave-2"></div>
                      <div className="waves wave-3"></div>
                    </div>
                  </div>
                  <button
                    className="btn btn-circle btn-primary btn-play video"  aria-label="Play Video"
                    style={{
                      top: "60%",
                      left: "50%",
                      transform: "translate(-25px,10px)",
                      zIndex: "3",
                    }}
                    onClick={careerVideo}
                  >
                    <i className="icn-caret-right"></i>
                  </button>
                </div>
              </div>

              <figure className="rounded">
                <img style={{ width: '100%', height: 'auto' }}  className="img-fluid" src={whyusimage} alt="pathway to ypur passion career counselling" />
              </figure>
            </div>
            <div className="col-lg-6">
              <h2 className="display-1 mb-3 text-center-sm visible-md">
                Why Mere Mentor
                <br />
                for your Career?
              </h2>
              <p className="lead fs-lg text-center-sm visible-md">Explore your career clusters with us</p>
              <p className="mb-6 text-center-sm">
                Mere Mentor is India’s leading digital platform offering career
                guidance, career preference and career decision making source
                with a blend of technology and human expertise
              </p>
              <div className="row gx-xl-12">
                <div className="col-md-12">
                  <div className="d-flex flex-row">
                    <ul className="who-we-are-list mb-0">
                      <li className="maxwidth-100 mt-1">
                        <span>1</span>
                        Assessment:
                        Explore key components in an individual that drive
                        career decisions
                      </li>
                      <li className="maxwidth-100 mt-1">
                        <span>2</span>
                        Career
                        counselling: Sessions that dive deeper to explore
                        possibilities that bring the best in you
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Our USPs */}
      <Suspense fallback={<div>Loading...</div>}>
        <section>
          <USP />

          {/* Why career counselling Video Section */}
          <Ccnd />

          {/* Parents Review */}
          <ParentReview />

          {/* Quick Info */}
          <Quickinfo />

          {/* Our Counsellors */}
          <CounsellorHelper />

           {/* Expert Explains */}
           <ExpertExplains />

          {/* Our Experts */}
          <ExpertsHelper />

          {/* Motivated Video */}
          <Motivated />

          {/* Blogs */}
          <OurJournal />

          {/* Collaborations and Tie-ups */}
          <Collaborations collabator={collabator} />

          <Footer />
        </section>
      </Suspense>

      <BackToTop showScroll={showScroll} setShowScroll={setShowScroll} />

      <Helmet>
      <title>Merementor - Leading Career Counselling and Career Guidance Platform</title>
      <meta name="description" content="Explore expert career counselling and guidance to align your skills and interests with rewarding job opportunities. Plan your professional journey with personalized advice."/>
      <meta name="keywords" content="career counselling, career guidance, vocational advice, professional development"/>
      <meta name="robots" content="index, follow"/>
      </Helmet>
      <Helmet>
        <script>
          {`
          <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PB74DW7P');</script>`}
        </script>
      </Helmet>
    </div>
  );
}

export default Home;
