import { Alert } from "react-bootstrap";

const AlertComponent = ({ AlertDisplay,SetAlertDisplay,AlertMessage }) => {
  return (
    <div>
      <Alert
        variant="danger"
        className="border border-danger rounded-3"
        show={AlertDisplay}
        onClose={() => SetAlertDisplay(false)}
        dismissible
        style={{'borderStyle': 'dotted !important'}}
      >
        <Alert.Heading className="d-none">Alert!</Alert.Heading>
        <div>
          {AlertMessage.length && AlertMessage.map(function (x, i) {
            return <p key={i} className="mb-0">{x}</p>;
          })}
        </div>
      </Alert>
    </div>
  );
};

export default AlertComponent;
